import styled, { css } from 'styled-components';
import { Card, CardMedia } from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';

import colors from 'styles/colors';

const cardHeight = '180px';

const hiddenElementStyle = css`
  display: none;
`;

export const StyledCard = styled(Card)`
  width: 150px;
  height: ${cardHeight};
`;

export const StyledCardMedia = styled(CardMedia)`
  height: ${cardHeight};
  font-size: 20px;
  text-align: center;
  line-height: ${cardHeight};
`;

export const Label = styled.label`
  ${({ disabled }) => disabled && 'cursor: default'};
`;

export const UploadIcon = styled(AddAPhoto)`
  font-size: 30px;
  color: ${({ disabled }) =>
    disabled ? colors.neutralDark : colors.secondary};
`;

export const HiddenImage = styled.img`
  ${hiddenElementStyle}
`;

export const HiddenInput = styled.input`
  ${hiddenElementStyle}
`;
