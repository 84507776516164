import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import NavLink from 'navigation/components/NavLink/NavLink';
import colors from 'styles/colors';
import { ListWrapper } from './MenuList.style';

const styleThemes = {
  desktop: {
    listWrapper: {
      direction: 'row',
      margin: '0 25px',
    },
    link: {
      color: colors.primary,
      padding: '0 15px',
      justifyContent: 'center',
    },
  },
  mobile: {
    listWrapper: {
      direction: 'column',
      margin: '0 0 0 30px',
    },
    link: {
      color: colors.white,
      padding: '10px 0',
      justifyContent: 'flex-start',
    },
  },
};

const MenuList = ({ items, isMobile = false, onNavigationChange = null }) => {
  const { path: currentPath } = useRouteMatch();
  const theme = isMobile ? styleThemes.mobile : styleThemes.desktop;

  return (
    <ThemeProvider theme={theme}>
      <nav>
        <ListWrapper>
          {items.map(({ name, path, notifications }) => (
            <NavLink
              key={name}
              text={name}
              to={path}
              isActive={currentPath.includes(path)}
              badgeCount={notifications}
              onClick={onNavigationChange}
            />
          ))}
        </ListWrapper>
      </nav>
    </ThemeProvider>
  );
};

MenuList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool,
  onNavigationChange: PropTypes.func,
};

export default MenuList;
