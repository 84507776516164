import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';

import api from 'api';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import useFormState from 'shared/hooks/useFormState';
import useRequest from 'shared/hooks/useRequest';
import useProfile from 'shared/hooks/useProfile';
import useRoles from 'auth/hooks/useRoles';
import { FlexBoxSpaceBetween, FlexBoxAlign } from 'styles/layout';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';
import { mapStringIdToNumber, mapFacilityOptions } from 'utils/mappers';
import {
  NewConsultRequestButton,
  AutocompleteDropdown,
  FiltersWrapper,
  FilterBox,
} from './ConsultRequestFilters.style';

const ConsultRequestFilters = ({
  filters,
  onFilterChange,
  onCreateNewConsultRequest,
}) => {
  const { isCustomerOnly } = useRoles();
  const { control, register, setFormValues } = useFormState();
  const { viewClosedConsultRequests } = useProfile();

  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getCustomerFacilities,
  );

  const facilities = useMemo(
    () => facilityData?.facilities ?? [],
    [facilityData],
  );

  useEffect(() => {
    fetchFacilities({ pageSize: MAX_PAGE_SIZE });
  }, [fetchFacilities]);

  useEffect(() => {
    setFormValues({
      ...filters,
      facilityId: mapStringIdToNumber(filters.facilityId),
    });
  }, [filters, setFormValues]);

  return (
    <FlexBoxSpaceBetween mt={0.5} mb={2.5}>
      <FiltersWrapper>
        {facilities.length > 1 && (
          <FilterBox>
            <AutocompleteDropdown
              control={control}
              name="facilityId"
              placeholder="Facility"
              data={mapFacilityOptions(facilities)}
              handleChange={(value) => onFilterChange({ facilityId: value })}
              groupBy={(o) => o.healthSystemName}
            />
          </FilterBox>
        )}
        <FilterBox>
          <SearchField
            inputRef={register}
            name="query"
            onChange={(value) => onFilterChange({ query: value })}
          />
        </FilterBox>
      </FiltersWrapper>
      <FlexBoxAlign>
        {facilities.length > 0 && (
          <NewConsultRequestButton
            type="button"
            color="secondary"
            onClick={onCreateNewConsultRequest}
            icon={<Add />}
          >
            Send New Page
          </NewConsultRequestButton>
        )}
        {!isCustomerOnly ||
          (viewClosedConsultRequests && (
            <FlexBoxAlign ml={2}>
              <ToggleButtonGroup
                buttons={[
                  {
                    value: ConsultRequestStatusEnum.OPEN,
                    content: ConsultRequestStatusEnum.OPEN,
                  },
                  {
                    value: ConsultRequestStatusEnum.CLOSED,
                    content: ConsultRequestStatusEnum.CLOSED,
                  },
                ]}
                control={control}
                name="state"
                handleChange={(value) => onFilterChange({ state: value })}
              />
            </FlexBoxAlign>
          ))}
      </FlexBoxAlign>
    </FlexBoxSpaceBetween>
  );
};

ConsultRequestFilters.propTypes = {
  filters: PropTypes.shape({
    query: PropTypes.string,
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onCreateNewConsultRequest: PropTypes.func.isRequired,
};

export default ConsultRequestFilters;
