import pick from 'lodash/pick';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import {
  ConsultTypeEnum,
  ConsultFieldTypeEnum,
} from 'features/consults/utils/enums';
import { getFieldSummaryValueForType } from 'features/consults/utils/formFields';
import { isEmptyValue } from 'features/consults/utils/validators';
import demographicsFields, {
  clinicDemographicsFields,
  eegDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import hpiFields from 'features/consults/shared/tabs/HPI/formFields';
import examFields from 'features/consults/shared/tabs/Exam/formFields';
import rosFields from 'features/consults/shared/tabs/ROS/formFields';
import pmhFields from 'features/consults/shared/tabs/PMHFHSH/formFields';
import dataFields from 'features/consults/shared/tabs/Data/formFields';
import {
  neuroAssessmentFields,
  clinicAssessmentFields,
} from 'features/consults/shared/tabs/Assessment/formFields';
import planFields from 'features/consults/shared/tabs/Plan/formFields';
import signatureFields, {
  clinicSignatureFields,
} from 'features/consults/shared/tabs/Signature/formFields';
import scoresFields from 'features/consults/pages/neuro/tabs/ScoresNeuro/formFields';
import emgFields from 'features/consults/pages/clinic/tabs/EMG/formFields';
import botoxFields from 'features/consults/pages/clinic/tabs/Botox/formFields';
import eegFields from 'features/consults/pages/eeg/tabs/EEG/formFields';

const neuroConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: demographicsFields,
  },
  [CONSULT_TABS.HPI]: {
    title: 'HPI',
    fields: hpiFields,
  },
  [CONSULT_TABS.SCORES]: {
    title: 'Scores',
    fields: scoresFields,
  },
  [CONSULT_TABS.EXAM]: {
    title: 'Exam',
    fields: examFields,
  },
  [CONSULT_TABS.ROS]: {
    title: 'ROS',
    fields: rosFields,
  },
  [CONSULT_TABS.PMH]: {
    title: 'PMH-FH-SH',
    fields: pmhFields,
  },
  [CONSULT_TABS.DATA]: {
    title: 'Data',
    fields: dataFields,
  },
  [CONSULT_TABS.ASSESSMENT]: {
    title: 'Assessment',
    fields: neuroAssessmentFields,
  },
  [CONSULT_TABS.PLAN]: {
    title: 'Plan',
    fields: planFields,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: signatureFields,
  },
};

const clinicConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: clinicDemographicsFields,
  },
  [CONSULT_TABS.HPI]: {
    title: 'HPI',
    fields: hpiFields,
  },
  [CONSULT_TABS.ROS]: {
    title: 'ROS',
    fields: rosFields,
  },
  [CONSULT_TABS.PMH]: {
    title: 'PMH-FH-SH',
    fields: pmhFields,
  },
  [CONSULT_TABS.EXAM]: {
    title: 'Exam',
    fields: examFields,
  },
  [CONSULT_TABS.EMG]: {
    title: 'EMG',
    fields: emgFields,
  },
  [CONSULT_TABS.BOTOX]: {
    title: 'Botox',
    fields: botoxFields,
  },
  [CONSULT_TABS.DATA]: {
    title: 'Data',
    fields: dataFields,
  },
  [CONSULT_TABS.ASSESSMENT]: {
    title: 'Assessment/Medical Decision Making',
    fields: clinicAssessmentFields,
  },
  [CONSULT_TABS.PLAN]: {
    title: 'Plan',
    fields: planFields,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: clinicSignatureFields,
  },
};

const eegConsultSections = {
  [CONSULT_TABS.DEMOGRAPHICS]: {
    title: 'Demographics',
    fields: eegDemographicsFields,
  },
  [CONSULT_TABS.EEG]: {
    title: 'EEG Interpretation',
    fields: pick(eegFields, [
      // 'timeOfEEGHookUpAt',
      'startTimeOfEEGReadAt',
      'stopTimeOfEEGReadAt',
      'duration',
      'technicalDetailsPredefined',
      'technicalDetailsFreeText',
      'indicationPredefined',
      'indicationFreeText',
      'additionalPatientHistory',
    ]),
  },
  [`${CONSULT_TABS.EEG}-2`]: {
    title: 'Description',
    fields: pick(eegFields, [
      'photicStimulationPredefined',
      'photicStimulationFreeText',
      'hyperventilationPredefined',
      'hyperventilationFreeText',
      'phasesCapturedPredefined',
      'phasesCapturedFreeText',
      'symmetryPredefined',
      'symmetryFreeText',
      'posteriorDominantRhythmPredefined',
      'posteriorDominantRhythmFreeText',
      'predominantFrequenciesFrequency',
      'predominantFrequenciesFrequencyDuration',
      'predominantFrequenciesFreeText',
      'superimposedFrequenciesFrequency',
      'superimposedFrequenciesFrequencyDuration',
      'superimposedFrequenciesFreeText',
      'amplitudePredefined',
      'amplitudeFreeText',
      'reactivityPredefined',
      'reactivityFreeText',
      'variabilityPredefined',
      'variabilityFreeText',
      'continuityPredefined',
      'continuityFreeText',
      'ekgPredefined',
      'ekgFreeText',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-3`]: {
    title: 'Abnormalities',
    fields: pick(eegFields, [
      'stimulationPredefined',
      'stimulationFreeText',
      'epileptiformAbnormalitiesPredefined',
      'epileptiformAbnormalitiesFreeText',
      'focalSlowingPredefined',
      'focalSlowingFreeText',
      'seizurePredefined',
      'seizureFreeText',
      'artifact',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-4`]: {
    title: 'Impression',
    fields: pick(eegFields, [
      'impressionsPredefined',
      'impressionsSubtypePredefined',
      'abnormalityOptionsFreeText',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [`${CONSULT_TABS.EEG}-5`]: {
    title: 'Clinical Correlation',
    fields: pick(eegFields, [
      'impressionsClinicalCorrelationFreeText',
      'additionalComments',
    ]),
    tab: CONSULT_TABS.EEG,
  },
  [CONSULT_TABS.SIGNATURE]: {
    title: 'Logistics',
    fields: signatureFields,
  },
};

const getAdditionalValue = (oldValue, newValue) => {
  const newValueToArray = Array.isArray(newValue) ? newValue : [newValue];
  if (!oldValue) return newValueToArray;
  const oldValueToArray = Array.isArray(oldValue) ? oldValue : [oldValue];
  return [...oldValueToArray, ...newValueToArray];
};

export const hasValue = (value) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return !isEmptyValue(value);
};

export const getSummaryFields = (fields, tabData, facility) =>
  Object.values(fields).reduce((acc, fieldProps) => {
    const fieldValue = tabData[fieldProps.name];
    const { excludeFromSummary, associatedField } = fieldProps;

    // Show field if it has not been explicitly excluded from the summary tab
    if (!excludeFromSummary) {
      if (!associatedField) {
        if (fieldProps.name === demographicsFields.facility.name) {
          return acc.concat({
            ...fieldProps,
            type: ConsultFieldTypeEnum.INPUT,
            value: facility?.name,
          });
        }
        return acc.concat({ ...fieldProps, value: fieldValue });
      }

      // If a field is associated with another one
      // append its value to the associated field
      return acc.map((addedField) => {
        if (addedField.name === associatedField && hasValue(fieldValue)) {
          const additionalValue = getFieldSummaryValueForType({
            ...fieldProps,
            value: fieldValue,
          });
          return {
            ...addedField,
            additionalValue: getAdditionalValue(
              addedField.additionalValue,
              additionalValue,
            ),
          };
        }
        return addedField;
      });
    }
    return acc;
  }, []);

export const getConsultSummaryData = (consultType) => {
  switch (consultType) {
    case ConsultTypeEnum.NEURO:
      return {
        title: 'Teleneurology Consult',
        sections: neuroConsultSections,
      };

    case ConsultTypeEnum.CLINIC:
      return {
        title: 'Clinic',
        sections: clinicConsultSections,
      };

    case ConsultTypeEnum.EEG:
      return {
        title: 'EEG',
        sections: eegConsultSections,
      };

    default:
      return {};
  }
};
