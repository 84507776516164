import Amplify, { Auth } from 'aws-amplify';

import Paths from 'navigation/paths';

Amplify.configure({
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_COGNITO_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,

    authenticationFlowType: 'USER_SRP_AUTH',

    // Google sign-in config
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_OAUTH_CALLBACK_URL,
      redirectSignOut: `${process.env.REACT_APP_OAUTH_CALLBACK_URL}${Paths.Login}`,
      scope: ['openid'],
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWSS3_BUCKET, // REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_REGION, // OPTIONAL -  Amazon service region
    },
  },
});

export default Auth.configure();
