import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';
import BackButton from 'navigation/components/BackButton/BackButton';
import FacilityForm, {
  facilityFields,
} from 'features/admin/components/FacilityForm';
import schema from 'features/admin/components/FacilityForm/validationSchema';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { successActionSelector } from 'store/selectors';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { mapFacilityToApiFormat } from 'utils/mappers';
import { FlexBoxAlign, FlexBoxColumn, FlexBoxColumnFull } from 'styles/layout';

const AddFacilityPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { locationId } = useParams();
  const {
    register,
    handleSubmit,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
    control,
  } = useFormState(
    schema,
    { stateSlice: 'admin', showAsSnackbar: true },
    null,
    {
      [facilityFields.active]: true,
      [facilityFields.codeFromSystem]: true,
    },
  );
  const successAction = useSelector(successActionSelector);

  const { data: networks } = useSelector(({ admin }) => admin.networks);
  const healthSystemName = useSelector(({ admin }) => admin.system.name);

  useEffect(() => {
    dispatch(adminActions.listNetworks({ pageSize: MAX_PAGE_SIZE }));
  }, [dispatch]);

  useEffect(() => {
    if (!healthSystemName) {
      dispatch(adminActions.getHealthSystemById(locationId));
    }
  }, [dispatch, locationId, healthSystemName]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.FACILITY_ADDED, () => {
    dispatch(appStateActions.hideSnackbar());

    history.push(
      `${AdminPaths.HealthSystems}/${locationId}/facility/${successAction.payload.id}`,
      {
        navigateOnBack: AdminPaths.HealthSystems,
      },
    );
  });

  const addFacility = () => {
    const facility = getTrimmedValues();
    const data = mapFacilityToApiFormat(facility, locationId);
    dispatch(adminActions.addFacility(data));
  };

  return (
    <FlexBoxColumn height="100%">
      {healthSystemName && networks && (
        <>
          <FlexBoxAlign>
            <BackButton />
            <PageTitle>{`${healthSystemName}: New Facility`}</PageTitle>
          </FlexBoxAlign>
          <FlexBoxColumnFull p={2}>
            <FormWrapper
              onSubmit={handleSubmit(addFacility)}
              onCancel={() => history.goBack()}
              isSubmitDisabled={!canSubmitForm}
            >
              <FacilityForm
                networks={networks}
                control={control}
                register={register}
                getFieldError={getFieldError}
              />
            </FormWrapper>
          </FlexBoxColumnFull>
        </>
      )}
    </FlexBoxColumn>
  );
};

export default AddFacilityPage;
