import { createSlice } from '@reduxjs/toolkit';

import { logoutUser } from 'auth/store/actionCreators';
import { mapErrorResponse } from 'utils/mappers';
import {
  SLICE_PREFIX,
  createNewConsultRequest,
  listConsultRequests,
  getConsultRequestById,
  acceptConsultRequest,
  declineConsultRequest,
  deleteConsultRequest,
} from './actionCreators';

const initialState = {
  data: [],
  pageCount: 0,
  currentConsultRequest: {},
  error: null,
};

/* eslint-disable no-param-reassign */
const consultRequestsStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, () => initialState)
      .addCase(listConsultRequests.fulfilled, (state, { payload }) => {
        state.data = payload.data;
        state.pageCount = payload.pageCount;
      })
      .addCase(getConsultRequestById.fulfilled, (state, { payload }) => {
        state.currentConsultRequest = payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});
/* eslint-enable no-param-reassign */

const { reducer, actions } = consultRequestsStateSlice;

export { reducer as consultRequestsReducer };
export const consultRequestActions = {
  ...actions,
  createNewConsultRequest,
  listConsultRequests,
  getConsultRequestById,
  acceptConsultRequest,
  declineConsultRequest,
  deleteConsultRequest,
};

export default consultRequestsStateSlice;
