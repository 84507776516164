import React, { forwardRef } from 'react';
import styled from 'styled-components';
import {
  ToggleButtonGroup,
  ToggleButton as MaterialToggleButton,
} from '@material-ui/lab';
import { alpha } from '@material-ui/core/styles';

import colors from 'styles/colors';

export const ButtonGroup = styled(
  forwardRef((props, ref) => (
    <ToggleButtonGroup
      ref={ref}
      classes={{ groupedHorizontal: 'group' }}
      {...props}
    />
  )),
)`
  flex-wrap: wrap;

  .group {
    &:not(:disabled) {
      &:not(:first-child) {
        border: 1px solid ${colors.primary};
      }
    }
  }
`;

export const ToggleButton = styled((props) => (
  <MaterialToggleButton
    classes={{ selected: 'selected', label: 'label' }}
    {...props}
  />
))`
  color: ${colors.primary};
  border: 1px solid ${colors.primary};
  min-width: 120px;
  padding: 6px 7px;

  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  &.selected {
    color: ${colors.primary};
    background: ${alpha(colors.primary, 0.2)};

    &:hover {
      background: ${alpha(colors.primary, 0.3)};
    }
  }

  .label {
    line-height: 1.85;
  }

  ${({ $compact }) =>
    $compact &&
    `
    min-width: 50px;
    font-weight: 400;
    padding: 2px 4px;
    border: 1px solid ${alpha(colors.black, 0.23)} !important;
    color: ${colors.dark};
    border-radius: 16px;
    &:hover {
      background: ${colors.secondary};
    }

    &.selected {
      color: ${colors.white};
      background: ${colors.primary};
      &:hover {
        background: ${colors.primary};
      }
    }

    .label {
      text-transform: none;
      line-height: 1.25;
    }
  `}
`;
