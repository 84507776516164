import sortBy from 'lodash/sortBy';

import FACILITYFORM from 'features/admin/components/FacilityForm/constants';
import { getNamesWithTitle } from 'utils/helpers';

export const mapErrorResponse = ({ payload, error }) => {
  if (payload) {
    if (payload.errors) {
      const reducedErrors = Object.values(payload.errors).reduce((res, err) => {
        if (err.length) return [...res, ...err];
        return res;
      }, []);
      if (reducedErrors.length) return reducedErrors;
    }

    if (payload.title) {
      return payload.title;
    }
  }

  if (error) {
    return error.message;
  }

  return 'Something went wrong. Please try again.';
};

export const mapUserToStoreFormat = (user, challengeName = null) => ({
  username: user.username,
  roles: user.signInUserSession
    ? user.signInUserSession.accessToken.payload['cognito:groups']
    : [],
  ...user.attributes,
  challengeName,
  challengeParam: challengeName ? user.challengeParam : null,
});

// Convert empty strings into "null" before sending a request to the API
export const mapFormFieldsToApiFormat = (formObj) =>
  formObj &&
  Object.entries(formObj).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      acc[key] = value !== '' ? value : null;

      return acc;
    }

    acc[key] = mapFormFieldsToApiFormat(value);
    return acc;
  }, {});

export const mapPhoneNumberToMask = (phoneNumber) => {
  if (!phoneNumber) {
    return null;
  }

  const onlyDigits = phoneNumber.substr(2); // Remove country code
  const areaCode = onlyDigits.substr(0, 3);
  const number = `${onlyDigits.substr(3, 3)}-${onlyDigits.substr(-4)}`;

  return `${areaCode}-${number}`;
};

// Convert facility before sending a request to the API
export const mapFacilityToApiFormat = (facility, locationId = null) => {
  const data = {
    locationId,
    facility: {
      ...facility,
      isIntegrated: Boolean(facility.isIntegrated),
      r1Integration: Boolean(facility.r1Integration),
      networks: {
        ...facility.networks,
      },
    },
  };
  data.facility = mapFormFieldsToApiFormat(data.facility);

  // Convert networks into array correct objects
  data.facility.networks = Object.keys(data.facility.networks).reduce(
    (acc, cur) => {
      const currentNetwork = data.facility.networks[cur];

      if (currentNetwork)
        acc.push({ networkType: cur, networkId: currentNetwork.id });

      return acc;
    },
    [],
  );

  // Convert direct Beam-In into boolean
  if (data.facility.directBeamIn) {
    data.facility.directBeamIn = data.facility.directBeamIn === 'Yes';
  }

  // Convert mobileImagings to array
  data.facility.mobileImagings = Object.values(data.facility.mobileImagings);

  return data;
};

// Convert facility before autofill form
export const mapFacilityToForm = (facility, networks) => {
  const facilityForm = Object.keys(facility).reduce((acc, cur) => {
    const currentField = facility[cur];

    if (cur === FACILITYFORM.directBeamIn) {
      if (currentField !== null) {
        const value = currentField ? 'Yes' : 'No';
        acc[cur] = value;
      }
    } else if (cur === 'networks') {
      const facilities = currentField.reduce((fieldAcc, fieldCur) => {
        const existingNetwork = networks.find(
          (network) => network.id === fieldCur.networkId,
        );
        if (existingNetwork) {
          // eslint-disable-next-line no-param-reassign
          fieldAcc[fieldCur.networkType] = existingNetwork;
        }
        return fieldAcc;
      }, {});
      acc[cur] = facilities;
    } else {
      acc[cur] = currentField;
    }
    return acc;
  }, {});
  return facilityForm;
};

export const mapDoctorNames = (doctors, value = 'id', label = 'name') =>
  doctors.map(({ id, firstName, lastName, title }) => ({
    [value]: id,
    [label]: getNamesWithTitle(firstName, lastName, title),
  }));

export const mapAutocompleteOptions = (options) =>
  options.map(({ id, name }) => ({ value: id, label: name }));

export const mapStringIdToNumber = (id) => (id ? Number(id) : null);

export const mapOptionsToSelect = (options) =>
  options.map(({ label, value }) => ({ value, text: label }));

export const mapFacilityOptions = (facilities) =>
  sortBy(
    facilities.map(({ id, name, ...rest }) => ({
      ...rest,
      value: id,
      label: name,
    })),
    ['healthSystemId', 'name'],
  );
