import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

const assessmentOptions = [
  { value: 'AlteredMentalStatus', label: 'Altered Mentation' },
  { value: 'BellsPalsy', label: 'Bells Palsy' },
  { value: 'Dissection', label: 'Dissection' },
  { value: 'Headache', label: 'Headache' },
  { value: 'HeadacheCluster', label: 'Headache, Cluster' },
  { value: 'HeadacheTension', label: 'Headache, Tension' },
  { value: 'HemorrhagicStroke', label: 'Hemorrhagic Stroke' },
  { value: 'IschemicStrokeAcute', label: 'Ischemic Stroke, Acute' },
  { value: 'IschemicStrokeSubacute', label: 'Ischemic Stroke, Subacute' },
  { value: 'GuillainBarre', label: 'Guillain Barre' },
  { value: 'Meningitis', label: 'Meningitis' },
  { value: 'Migraine', label: 'Migraine' },
  { value: 'MigraineComplex', label: 'Migraine, Complex' },
  { value: 'MigraineOcular', label: 'Migraine, Ocular' },
  { value: 'PRES', label: 'PRES' },
  { value: 'SubarachnoidHemorrhage', label: 'SAH' },
  { value: 'SubduralHematoma', label: 'SDH' },
  { value: 'Seizure', label: 'Seizure' },
  { value: 'StrokeMimic', label: 'Stroke Mimic' },
  { value: 'TransientIschemicAttack', label: 'TIA' },
  { value: 'TransverseMyelitis', label: 'Transverse myelitis' },
  { value: 'TrigeminalNeuralgia', label: 'Trigeminal neuralgia' },
  { value: 'ToddsParalysis', label: "Todd's paralysis" },
  { value: 'Vasculitis', label: 'Vasculitis' },
  { value: 'Weakness', label: 'Weakness' },
];

export const neuroAssessmentFields = {
  impressionPredefined: {
    name: 'impressionPredefined',
    label: 'Impression',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'AlteredMentalStatus', label: 'Altered Mental Status' },
      { value: 'BellsPalsy', label: 'Bells Palsy' },
      { value: 'Dissection', label: 'Dissection' },
      { value: 'Headache', label: 'Headache' },
      {
        value: 'IntracerebralHemorrhage',
        label: 'ICH',
        summaryLabel: 'Intracerebral hemorrhage',
      },
      {
        value: 'IntraventricularHemorrhage',
        label: 'IVH',
        summaryLabel: 'Intraventricular hemorrhage',
      },
      { value: 'IschemicStrokeAcute', label: 'Ischemic Stroke (Acute)' },
      { value: 'IschemicStrokeSubacute', label: 'Ischemic Stroke (Subacute)' },
      { value: 'GuillainBarre', label: 'Guillain Barre' },
      { value: 'Meningitis', label: 'Meningitis' },
      { value: 'Migraine', label: 'Migraine' },
      { value: 'MigraineComplex', label: 'Migraine (Complex)' },
      {
        value: 'ReversibleCerebralVasoconstrictionSyndrome',
        label: 'RCVS',
        summaryLabel: 'Reversible Cerebral Vasoconstriction Syndrome',
      },
      {
        value: 'SubarachnoidHemorrhage',
        label: 'SAH',
        summaryLabel: 'Subarachnoid Hemorrhage',
      },
      {
        value: 'SubduralHematoma',
        label: 'SDH',
        summaryLabel: 'Subdural Hematoma',
      },
      { value: 'Seizure', label: 'Seizure' },
      { value: 'StrokeMimic', label: 'Stroke Mimic' },
      {
        value: 'TransientIschemicAttack',
        label: 'TIA',
        summaryLabel: 'Transient Ischemic Attack',
      },
      { value: 'ToddsParalysis', label: 'Todds Paralysis' },
      { value: 'Vasculitis', label: 'Vasculitis' },
      {
        value: 'Vertebrobasilar',
        label: 'Vertebro basilar',
        summaryLabel: 'Vertebrobasilar Insufficiency Syndrome',
      },
      { value: 'Vertigo', label: 'Vertigo' },
      { value: 'Weakness', label: 'Weakness' },
      { value: 'Other', label: 'Other' },
    ],
  },
  impressionFreeText: {
    name: 'impressionFreeText',
    associatedField: 'impressionPredefined',
    type: ConsultFieldTypeEnum.DICTATION,
    multiline: true,
    rows: 2,
  },
};

export const clinicAssessmentFields = {
  impressionPredefined: {
    name: 'impressionPredefined',
    label: 'Assessment',
    type: ConsultFieldTypeEnum.CHIP,
    options: assessmentOptions,
  },
  impressionFreeText: {
    name: 'impressionFreeText',
    associatedField: 'impressionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
  differentialDiagnosisPredefined: {
    name: 'differentialDiagnosisPredefined',
    label: 'Differential Diagnosis',
    type: ConsultFieldTypeEnum.CHIP,
    options: assessmentOptions,
  },
  differentialDiagnosisFreeText: {
    name: 'differentialDiagnosisFreeText',
    associatedField: 'differentialDiagnosisPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
  medicalDecisionMakingHighLevelPredefined: {
    name: 'medicalDecisionMakingHighLevelPredefined',
    label: 'Medical Decision Making (High Level)',
    summaryLabel: 'Medical Decision Making',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'DisabledByCondition', label: 'disabled by condition' },
      { value: 'CannotDrive', label: 'cannot drive' },
      { value: 'NoLongerWorking', label: 'no longer working' },
      { value: 'Manage4PlusDiagnoses', label: 'manage 4+ diagnoses' },
      {
        value: 'NeurologicClearanceForProcedure',
        label: 'neurologic clearance for procedure',
      },
      {
        value: 'PatientHasNewProblemCondition',
        label: 'patient has new problem/condition',
      },
      { value: 'PatientIsNotStable', label: 'patient is not stable' },
    ],
  },
  medicalDecisionMakingModerateLevelPredefined: {
    name: 'medicalDecisionMakingModerateLevelPredefined',
    associatedField: 'medicalDecisionMakingHighLevelPredefined',
    label: 'Medical Decision Making (Moderate Level)',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'Manage2ChronicStableConditions',
        label: 'manage 2 chronic/stable conditions',
      },
      {
        value: 'ManageIllnessWithMildExacerbation',
        label: 'manage illness with a mild exacerbation',
      },
      {
        value: 'PatientOnlyPartiallyImprovingResponding',
        label: 'patient is only partially improving/responding',
      },
      {
        value: 'PatientHasPriorComplication',
        label: 'patient has prior complication',
      },
    ],
  },
};
