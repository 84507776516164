import React from 'react';
import styled from 'styled-components';

import colors from 'styles/colors';
import { FlexBox } from 'styles/layout';
import Button from 'shared/components/buttons/Button/Button';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import Drawer from 'shared/components/layout/Drawer/Drawer';

export const NavDrawer = styled((props) => (
  <Drawer classes={{ paper: 'paper' }} {...props} />
))`
  .paper {
    width: 180px;
    background: ${colors.primary};
  }
`;

export const CloseBtnWrapper = styled(FlexBox)`
  justify-content: flex-end;
`;

export const CloseDrawerBtn = styled(IconButton)`
  color: ${colors.white};
`;

export const DrawerMenuBtn = styled(Button)`
  border-radius: 0;
  color: ${colors.white};
  justify-content: flex-start;
`;
