import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import Switch from '@material-ui/core/Switch';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const ControlledSwitch = ({
  control,
  name,
  disabled = false,
  size = 'medium',
  required = false,
  color = 'primary',
  onChange,
  label,
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    render={(controllerProps) => (
      <>
        {!!label && (
          <InputLabel htmlFor={name} required={required} disabled={disabled}>
            {label}
          </InputLabel>
        )}
        <Switch
          checked={Boolean(controllerProps.value)}
          disabled={disabled}
          color={color}
          required={required}
          size={size}
          name={name}
          onChange={(_, value) => {
            controllerProps.onChange(value);
            if (onChange) onChange(value);
          }}
        />
      </>
    )}
  />
);

ControlledSwitch.propTypes = {
  control: PropTypes.shape({
    register: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ControlledSwitch;
