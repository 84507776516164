import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { buildFilterQueryString } from 'api/utils';
import Paths from 'navigation/paths';
import useInterval from 'shared/hooks/useInterval';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import ConsultRequestFilters from 'features/customer/components/ConsultRequestFilters/ConsultRequestFilters';
import ConsultRequestList from 'features/customer/components/ConsultRequestList/ConsultRequestList';
import useConsultRequestListState from 'features/customer/hooks/useConsultRequestListState';
import { consultRequestActions } from 'features/customer/store/slice';
import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { FlexBoxColumn } from 'styles/layout';

const REFRESH_INTERVAL = 60 * 1000; // 60 seconds

const stateFilterMap = {
  [ConsultRequestStatusEnum.OPEN]: [
    ConsultRequestStatusEnum.OPEN,
    ConsultRequestStatusEnum.ACCEPTED,
  ],
  [ConsultRequestStatusEnum.CLOSED]: [ConsultRequestStatusEnum.CLOSED],
};

const ConsultRequestListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, pageCount } = useSelector(
    ({ consultRequests }) => consultRequests,
  );
  const {
    page,
    filters,
    handlePageChange,
    handleFilterChange,
    sortBy,
    sortOrder,
  } = useConsultRequestListState();

  useFormState(
    {},
    {
      stateSlice: 'consultRequests',
      showAsSnackbar: true,
    },
  );

  const fetchConsultRequestList = useCallback(() => {
    const { state, ...moreFilters } = filters;
    dispatch(
      consultRequestActions.listConsultRequests({
        page,
        sortBy,
        sortOrder,
        filters: {
          ...moreFilters,
          states: stateFilterMap[state],
        },
      }),
    );
  }, [dispatch, filters, page, sortBy, sortOrder]);

  // Automatically fetch the consult request data every minute
  useInterval(() => {
    fetchConsultRequestList();
  }, REFRESH_INTERVAL);

  // Updates list on deletion
  usePerformActionOnSuccess(
    SuccessActionTypesEnum.CONSULT_REQUEST_DELETED,
    fetchConsultRequestList,
  );

  useEffect(() => {
    fetchConsultRequestList();

    history.replace(
      `${Paths.MyFacility}?page=${page}${buildFilterQueryString(filters)}`,
    );
  }, [fetchConsultRequestList, filters, history, page]);

  return (
    <FlexBoxColumn>
      <ConsultRequestFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onCreateNewConsultRequest={() =>
          history.push(`${Paths.MyFacility}/new-consult-request`)
        }
      />
      <FlexBoxColumn>
        <ConsultRequestList
          items={data}
          page={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default ConsultRequestListPage;
