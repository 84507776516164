import { withStyles } from '@material-ui/core/styles';
import { MenuItem as MaterialMenuItem } from '@material-ui/core';

import Button from 'shared/components/buttons/Button/Button';
import colors from 'styles/colors';

export const MenuItem = withStyles({
  selected: {
    backgroundColor: `${colors.optionSelected} !important`,
  },
})(MaterialMenuItem);

export const SelectAllButton = withStyles({
  root: {
    padding: 0,
  },
})(Button);
