import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FlexBox, FlexBoxWrap } from 'styles/layout';
import Select from 'shared/components/forms/Select/ControlledSelect';

import { FilterElement } from './ConsultFilters.style';
import ConsultServiceFilter from './ConsultServiceFilter';
import { consultTypes, serviceFilters } from './data';

const ConsultMainFilters = ({
  filters,
  control,
  watch,
  onFilter,
  onResetFilters,
}) => {
  const renderFilters = useMemo(
    () => serviceFilters(filters.service),
    [filters.service],
  );

  return (
    <FlexBox>
      <FlexBoxWrap>
        <FilterElement>
          <Select
            control={control}
            name="service"
            placeholder="Service"
            options={consultTypes}
            handleChange={({ target }) =>
              onResetFilters({ service: target.value })
            }
          />
        </FilterElement>
      </FlexBoxWrap>
      <FlexBoxWrap>
        {renderFilters &&
          renderFilters.map((filter) => (
            <FilterElement key={filter.name}>
              <ConsultServiceFilter
                filter={filter}
                onFilter={onFilter}
                control={control}
                watch={watch}
                consultType={filters.service}
              />
            </FilterElement>
          ))}
      </FlexBoxWrap>
    </FlexBox>
  );
};

ConsultMainFilters.propTypes = {
  filters: PropTypes.shape({
    service: PropTypes.string,
  }).isRequired,
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
};

export default ConsultMainFilters;
