import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import api from 'api';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import useFormState from 'shared/hooks/useFormState';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import ConsultLock from 'features/consults/components/ConsultLock/ConsultLock';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import SelectWithFilter from 'features/consults/components/tabFields/SelectWithFilter';
import { userRolesEnum } from 'utils/users';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { mapDoctorNames } from 'utils/mappers';
import { Box } from 'styles/layout';

import { leftPanels } from './data';
import schema from './validationSchema';

const SignatureClinic = () => {
  const { register, control, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isClosed,
    isEditable,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
  } = useConsultData(CONSULT_TABS.SIGNATURE, getValues);
  const [providers, setProviders] = useState([]);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    async function fetchUsers() {
      const { data } = await api.getUserNames({
        pageSize: MAX_PAGE_SIZE,
        filters: { roles: [userRolesEnum.CLINIC] },
      });
      setProviders(mapDoctorNames(data.users, 'value', 'label'));
    }

    fetchUsers();
  }, []);

  return (
    <GridWrapper>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <SelectWithFilter
            name="coSignatureProviderId"
            label="Provider (who requires co-signature)"
            control={control}
            data={providers}
            onChange={handleFieldChange}
            disabled={!isEditable}
          />
        </Box>
        <Box mb={1}>
          <b>If you are a provider who requires co-signature:</b>
          &nbsp;Select your name and name of supervising physician, then Save
          Draft.
          <Box>
            <b>Do not Save Lock Fax.</b>
          </Box>
          <Box>Once attending reviews chart, they will lock.</Box>
        </Box>
        {leftPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            onChange={handleFieldChange}
            getFieldError={getFieldError}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <SelectWithFilter
            control={control}
            name="assignToId"
            label="Provider"
            data={providers}
            required
            error={getFieldError('assignToId')}
            onChange={handleOwnerChange}
          />
        </Box>
        <ConsultLock
          consult={consult}
          consultMainType={consultMainType}
          disabled={!isEditable}
          checked={isClosed}
          onConsultLock={handleConsultLock}
        />
      </Grid>
    </GridWrapper>
  );
};

export default SignatureClinic;
