import styled from 'styled-components';
import { Chip as MaterialChip } from '@material-ui/core';
import { Tonality } from '@material-ui/icons';

import colors from 'styles/colors';
import { MultiChipStatesEnum } from './MultiChipStates.enum';

const chipBackground = (chipState) => {
  switch (chipState) {
    case MultiChipStatesEnum.YES:
      return colors.primary;

    case MultiChipStatesEnum.NO:
      return colors.notification;

    default:
      return colors.white;
  }
};

export const ChipContainer = styled.label`
  margin-right: 5px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const FormChip = styled(MaterialChip)`
  background: ${({ $state }) => `${chipBackground($state)} !important`};
  color: ${({ $state }) =>
    $state === MultiChipStatesEnum.UNKNOWN ? colors.dark : colors.white};
  ${({ $state }) =>
    $state === MultiChipStatesEnum.NO && 'text-decoration: line-through'};
  @media (hover: hover) {
    &:hover {
      ${({ $state }) =>
        $state === MultiChipStatesEnum.UNKNOWN &&
        `background-color: ${colors.secondary} !important;`}
    }
  }
`;

export const FormTonalityIcon = styled(Tonality)`
  color: ${({ $state }) =>
    $state === MultiChipStatesEnum.UNKNOWN ? colors.dark : colors.white};
`;
