import {
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';
import fields from './formFields';

export const leftPanels = [
  {
    bottomPanel: [
      mapToggleFieldOptions(fields.vitalsPredefined),
      fields.vitalsFreeText,
    ],
  },
  {
    bottomPanel: [fields.sbp],
  },
  {
    bottomPanel: [fields.dbp],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.mentalStatusPositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.mentalStatusNegativePredefined)],
    bottomPanel: [fields.mentalStatusFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.languagePositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.languageNegativePredefined)],
    bottomPanel: [fields.languageFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.cranialNervesPositivePredefined)],
    rightPanel: [
      mapToggleFieldOptions(fields.cranialNervesFacialDroop),
      mapToggleFieldOptions(fields.cranialNervesDecSensationFace),
      mapToggleFieldOptions(fields.cranialNervesGag),
      mapToggleFieldOptions(fields.cranialNervesPtosis),
      mapToggleFieldOptions(fields.cranialNervesTongueDeviation),
      mapToggleFieldOptions(fields.cranialNervesHemianopsia),
    ],
    bottomPanel: [fields.cranialNervesFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.motorPositivePredefined)],
    rightPanel: [
      mapToggleFieldOptions(fields.motorFacialDroop),
      mapToggleFieldOptions(fields.motorUpperExtremity),
      mapToggleFieldOptions(fields.motorLowerExtremity),
      mapToggleFieldOptions(fields.motorDrift),
    ],
    bottomPanel: [fields.motorFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.sensationPositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.sensationNegativePredefined)],
    bottomPanel: [fields.sensationFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.cerebellarPositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.cerebellarNegativePredefined)],
    bottomPanel: [fields.cerebellarFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.reflexesPositivePredefined)],
    rightPanel: [
      mapToggleFieldOptions(fields.reflexesAbnormalHypo),
      mapToggleFieldOptions(fields.reflexesBabinski),
      mapToggleFieldOptions(fields.reflexesWitdrawal),
    ],
    bottomPanel: [fields.reflexesFreeText],
  },
  {
    bottomPanel: [fields.additionalNeurologicExam],
  },
];

export const rightPanels = [
  {
    leftPanel: [
      mapChipFieldOptions(fields.constitutionalInteractivePredefined),
      mapChipFieldOptions(fields.constitutionalAnxious),
    ],
    rightPanel: [
      mapToggleFieldOptions(fields.constitutionalSleepy),
      mapToggleFieldOptions(fields.constitutionalIntoxicated),
      mapToggleFieldOptions(fields.constitutionalCombative),
    ],
    bottomPanel: [fields.constitutionalFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.eyesPositivePredefined)],
    rightPanel: [
      mapChipFieldOptions(fields.eyesNegativePredefined),
      mapToggleFieldOptions(fields.eyesNystagmus),
    ],
    bottomPanel: [fields.eyesFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.hentPositivePredefined)],
    rightPanel: [mapToggleFieldOptions(fields.hentEpistaxis)],
    bottomPanel: [fields.hentFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.respiratoryPositivePredefined)],
    rightPanel: [
      mapToggleFieldOptions(fields.respiratoryDecreasedBreathSounds),
      mapToggleFieldOptions(fields.respiratoryDistress),
    ],
    bottomPanel: [fields.respiratoryFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.cardiovascularPositivePredefined)],
    rightPanel: [
      mapToggleFieldOptions(fields.cardiovascularRhythmAbnormal),
      mapToggleFieldOptions(fields.cardiovascularRateAbnormal),
      mapChipFieldOptions(fields.cardiovascularMurmurPredefined),
      mapToggleFieldOptions(fields.cardiovascularPulsesAbnormal),
    ],
    bottomPanel: [fields.cardiovascularFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.gastrointestinalPositivePredefined)],
    rightPanel: [
      mapChipFieldOptions(fields.gastrointestinalPulsatileMassPredefined),
    ],
    bottomPanel: [fields.gastrointestinalFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.genitourinaryPositivePredefined)],
    bottomPanel: [fields.genitourinaryFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.skinPositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.skinNegativePredefined)],
    bottomPanel: [fields.skinFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.musculoskeletalPositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.musculoskeletalNegativePredefined)],
    bottomPanel: [fields.musculoskeletalFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.hemeLymphImmunePositivePredefined)],
    rightPanel: [mapChipFieldOptions(fields.hemeLymphImmuneNegativePredefined)],
    bottomPanel: [fields.hemeLymphImmuneFreeText],
  },
  {
    leftPanel: [mapChipFieldOptions(fields.psychiatricPositivePredefined)],
    bottomPanel: [fields.psychiatricFreeText],
  },
  {
    bottomPanel: [fields.additionalExamFindings],
  },
];
