import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import Paths from 'navigation/paths';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import { useTableState } from 'shared/hooks/useTableState';
import { consultsActions } from 'features/consults/store/slice';
import { FlexBoxColumn } from 'styles/layout';
import { US_DATE_FORMAT, TIME_FORMAT, formatDate } from 'utils/dates';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import DeleteMacro from './DeleteMacro';

const tableColumns = [
  {
    header: {
      id: sortFields.macros.name,
      value: 'Macro Name',
      isSortable: true,
    },
    dataKey: 'name',
  },
  {
    header: {
      id: sortFields.macros.createdAt,
      value: 'Created Date',
      isSortable: true,
    },
    formatValue: ({ createdAt }) =>
      formatDate(new Date(createdAt), `${US_DATE_FORMAT} - ${TIME_FORMAT}`),
  },
  {
    header: {
      id: sortFields.macros.type,
      value: 'Type',
      isSortable: true,
    },
    dataKey: 'type',
  },
  {
    header: {
      id: 'deleteMacro',
      value: '',
    },
    formatValue: (item) => <DeleteMacro macroId={item.id} />,
    width: '48px',
    align: 'center',
    empty: true,
  },
];

const MacrosListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { smDown } = useBreakpoints();
  const { data: macros, pageCount } = useSelector(
    ({ consults }) => consults.macros,
  );

  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.macros.name);

  useEffect(() => {
    dispatch(
      consultsActions.listMacros({
        page,
        sortBy,
        sortOrder,
      }),
    );
    history.replace(
      `${Paths.Macros}?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    );
  }, [dispatch, history, page, sortBy, sortOrder]);

  return (
    <MainWrapper sm={smDown}>
      <FlexBoxColumn mt={1.5}>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(macros, tableColumns)}
          fallbackMsg="No Macros found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
        />
      </FlexBoxColumn>
    </MainWrapper>
  );
};

export default MacrosListPage;
