import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import Sidebar from 'shared/components/layout/Sidebar/Sidebar';
import colors from 'styles/colors';
import AdminPaths from './paths';
import UsersPage from './pages/Users';
import ProvidersPage from './pages/Providers';
import HealthSystemsPage from './pages/Locations';
import NetworksPage from './pages/Networks';
import ConsultsPage from './pages/Consults';
import ConsultRequestsPage from './pages/ConsultRequests';

export const SidebarItem = styled(Link)`
  margin: 10px 0;
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ $isActive }) => ($isActive ? colors.white : colors.secondary)};

  ${({ $isActive }) => $isActive && `background: ${colors.secondary};`}

  &:hover {
    background: ${({ $isActive }) =>
      $isActive ? colors.secondary : colors.background};
  }
`;

const Admin = () => {
  const { pathname } = useLocation();
  const { viewAdminConsultTable } = useSelector(({ profile }) => profile.data);

  const menuItems = useMemo(
    () => [
      {
        text: 'Consults',
        path: AdminPaths.Consults,
        hide: !viewAdminConsultTable,
        component: ConsultsPage,
      },
      {
        text: 'Consult Requests',
        path: AdminPaths.ConsultRequests,
        component: ConsultRequestsPage,
      },
      { text: 'Users', path: AdminPaths.Users, component: UsersPage },
      {
        text: 'Providers',
        path: AdminPaths.Providers,
        component: ProvidersPage,
      },
      {
        text: 'Locations',
        path: AdminPaths.HealthSystems,
        component: HealthSystemsPage,
      },
      { text: 'Networks', path: AdminPaths.Networks, component: NetworksPage },
    ],
    [viewAdminConsultTable],
  );

  return (
    <>
      <Sidebar>
        {menuItems.map(
          ({ text, path, hide }) =>
            !hide && (
              <SidebarItem
                key={text}
                to={path}
                $isActive={pathname.includes(path)}
              >
                {text}
              </SidebarItem>
            ),
        )}
      </Sidebar>
      <MainWrapper>
        <Switch>
          {menuItems.map(
            ({ component, path, hide }) =>
              !hide && <Route key={path} path={path} component={component} />,
          )}
          <Route>
            <Redirect
              to={
                viewAdminConsultTable
                  ? AdminPaths.Consults
                  : AdminPaths.ConsultRequests
              }
            />
          </Route>
        </Switch>
      </MainWrapper>
    </>
  );
};

export default Admin;
