import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Box, FlexBox } from 'styles/layout';
import Select from 'shared/components/forms/Select/Select';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import useFormState from 'shared/hooks/useFormState';
import usePrevious from 'shared/hooks/usePrevious';
import useSelectedOptionState from 'shared/hooks/useSelectedOptionState';
import Autocomplete from 'shared/components/forms/Autocomplete/Autocomplete';
import { adminActions } from 'features/admin/store/slice';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { userRolesEnum, userRoles } from 'utils/users';

import schema from './filterSchema';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AutocompleteDropdown = styled(Autocomplete)`
  width: 200px;
`;

const fieldNames = {
  SYSTEM: 'healthSystem',
  FACILITY: 'facility',
};

const UserListFilter = ({ onFilter, defaultFilters }) => {
  const dispatch = useDispatch();
  const { control, register, getValues, setValue } = useFormState(schema);
  const { roles, healthSystemId, facilityId } = defaultFilters;
  const prevHealthSystemId = usePrevious(healthSystemId);
  const isCustomer = roles === userRolesEnum.CUSTOMER;
  const prevIsCustomer = usePrevious(isCustomer);

  const {
    systems: { data: healthSystems },
    facilities: { data: facilities },
  } = useSelector(({ admin }) => admin);
  const { setSelectedOptionValue } = useSelectedOptionState(
    getValues,
    setValue,
  );

  useEffect(() => {
    setSelectedOptionValue(healthSystemId, fieldNames.SYSTEM, healthSystems);
  }, [healthSystemId, healthSystems, setSelectedOptionValue]);

  useEffect(() => {
    setSelectedOptionValue(facilityId, fieldNames.FACILITY, facilities);
  }, [facilityId, facilities, setSelectedOptionValue]);

  useEffect(() => {
    if (isCustomer && isCustomer !== prevIsCustomer) {
      dispatch(adminActions.listHealthSystems({ pageSize: MAX_PAGE_SIZE }));
    }
  }, [dispatch, isCustomer, prevIsCustomer]);

  useEffect(() => {
    if (isCustomer && healthSystemId && healthSystemId !== prevHealthSystemId) {
      dispatch(
        adminActions.listFacilities({
          filters: { healthSystemIds: healthSystemId },
          pageSize: MAX_PAGE_SIZE,
        }),
      );
    }
  }, [dispatch, isCustomer, healthSystemId, prevHealthSystemId]);

  const handleRoleChange = (value) => {
    let filterChanges = { roles: value };

    // Reset health system & facility filters
    // if previous role was "Customer"
    if (isCustomer) {
      filterChanges = {
        ...filterChanges,
        healthSystemId: null,
        facilityId: null,
      };
    }

    onFilter(filterChanges);
  };

  const handleHealthSystemChange = (system) => {
    if (!system) {
      onFilter({
        healthSystemId: null,
        facilityId: null,
      });
      setValue(fieldNames.FACILITY, null);
    } else {
      onFilter({
        healthSystemId: system.id,
      });
    }
  };

  return (
    <Form>
      <FlexBox>
        <Box mr={1}>
          <Select
            defaultValue={defaultFilters.roles}
            inputRef={register}
            name="role"
            placeholder="All roles"
            options={userRoles}
            onChange={({ target }) => handleRoleChange(target.value)}
          />
        </Box>
        {isCustomer && (
          <>
            <Box mr={1}>
              <AutocompleteDropdown
                control={control}
                name={fieldNames.SYSTEM}
                placeholder="Health system"
                data={healthSystems}
                handleChange={handleHealthSystemChange}
              />
            </Box>
            <Box mr={1}>
              <AutocompleteDropdown
                control={control}
                name={fieldNames.FACILITY}
                placeholder="Facility"
                data={facilities}
                disabled={!healthSystemId}
                handleChange={(value) =>
                  onFilter({ facilityId: value?.id || null })
                }
              />
            </Box>
          </>
        )}
        <Box mr={1}>
          <SearchField
            name="query"
            defaultValue={defaultFilters.query}
            inputRef={register}
            onChange={(value) => onFilter({ query: value })}
          />
        </Box>
      </FlexBox>
    </Form>
  );
};

UserListFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  defaultFilters: PropTypes.shape({
    query: PropTypes.string,
    roles: PropTypes.string,
    healthSystemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default UserListFilter;
