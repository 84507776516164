import React from 'react';

import sortFields from 'api/sortFields';

import IntegrationIcon from 'shared/components/data/IntegrationIcon';
import ConsultFlag from 'features/consults/components/ConsultFlag/ConsultFlag';
import { NeuroConsultTypes } from 'features/consults/utils/constants';

import { getFieldLabelByValue } from 'features/consults/utils/formFields';
import { ConsultTypeEnum } from 'features/consults/utils/enums';
import { US_DATE_FORMAT, TIME_FORMAT, formatToUserTimezone } from 'utils/dates';
import { mapPhoneNumberToMask } from 'utils/mappers';

const EMPTY_COLUMN_VALUE = '--';

const showFirstLetter = (name) => (name ? name.substr(0, 1) : '');

const IntegrationColumn = {
  header: { id: 'integration', value: '' },
  formatValue: IntegrationIcon,
  width: '3.5rem',
};

export const consultsTableColumns = (consultType) => [
  {
    header: {
      id: sortFields.consults.color,
      value: '',
    },
    formatValue: (item) => (
      <ConsultFlag
        consultId={item?.id}
        consultType={consultType}
        initialFlag={item.colorName}
      />
    ),
    width: '15px',
    empty: true,
  },
  {
    header: {
      id: 'PatientInitials',
      value: '',
    },
    formatValue: ({ patientFirstName, patientLastName }) => {
      let initials = EMPTY_COLUMN_VALUE;

      if (patientFirstName || patientLastName) {
        initials = `${showFirstLetter(patientFirstName)}${showFirstLetter(
          patientLastName,
        )}`;
      }

      return initials;
    },
    width: '35px',
    align: 'center',
    empty: true,
  },
  {
    header: {
      id: sortFields.consults.facilityName,
      value: 'Facility',
      isSortable: true,
    },
    formatValue: (item) => item.facilityName || EMPTY_COLUMN_VALUE,
  },
  {
    header: {
      id: sortFields.consults.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
    width: '10%',
  },
  {
    header: {
      id: sortFields.consults.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
    width: '10%',
  },
  {
    header: {
      id: sortFields.consults.type,
      value: 'Type',
      isSortable: true,
    },
    formatValue: (item) => {
      switch (consultType) {
        case ConsultTypeEnum.NEURO:
          return getFieldLabelByValue(
            NeuroConsultTypes,
            item.neuroConsultTypeName,
          );
        case ConsultTypeEnum.EEG:
          return item.eegConsultTypeName;
        default:
          return EMPTY_COLUMN_VALUE;
      }
    },
  },
  {
    header: {
      id: sortFields.consults.phone,
      value: 'Phone',
      isSortable: true,
    },
    formatValue: (item) => mapPhoneNumberToMask(item.phone),
    width: '150px',
  },
  {
    header: {
      id: sortFields.consults.assignTo,
      value: 'Provider',
      isSortable: true,
    },
    formatValue: ({ assignToFirstName, assignToLastName }) =>
      `${assignToFirstName} ${assignToLastName}`,
    width: '15%',
  },
  {
    header: {
      id: sortFields.consults.createdAt,
      value: 'Created At',
      isSortable: true,
    },
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
  IntegrationColumn,
];

export const smallConsultsTableColumns = (consultType) =>
  consultsTableColumns(consultType).slice(0, 3).concat(IntegrationColumn);
