import { getTimezoneTimeString } from 'utils/dates';

export const TIMEZONES = {
  UTC: 'UTC',
  EASTERN: 'EasternStandardTimeUsCanada',
  CENTRAL: 'CentralStandardTimeUsCanada',
  MOUNTAIN: 'MountainStandardTimeUsCanada',
  US_MOUNTAIN: 'USMountainStandardTimeArizona',
  PACIFIC: 'PacificStandardTimeUsCanada',
  ALASKAN: 'AlaskanStandardTime',
  HAWAIIAN: 'HawaiianStandardTime',
};

export const TIMEZONE_OFFSETS = {
  [TIMEZONES.UTC]: '00:00',
  [TIMEZONES.EASTERN]: getTimezoneTimeString('US/Eastern'),
  [TIMEZONES.CENTRAL]: getTimezoneTimeString('US/Central'),
  [TIMEZONES.MOUNTAIN]: getTimezoneTimeString('US/Mountain'),
  [TIMEZONES.US_MOUNTAIN]: getTimezoneTimeString('US/Arizona'),
  [TIMEZONES.PACIFIC]: getTimezoneTimeString('US/Pacific'),
  [TIMEZONES.ALASKAN]: getTimezoneTimeString('US/Alaska'),
  [TIMEZONES.HAWAIIAN]: getTimezoneTimeString('US/Hawaii'),
};

export const TIMEZONE_LABELS = {
  [TIMEZONES.UTC]: 'UTC',
  [TIMEZONES.EASTERN]: 'Eastern Time',
  [TIMEZONES.CENTRAL]: 'Central Time',
  [TIMEZONES.MOUNTAIN]: 'Mountain Time',
  [TIMEZONES.US_MOUNTAIN]: 'Arizona',
  [TIMEZONES.PACIFIC]: 'Pacific Time',
  [TIMEZONES.ALASKAN]: 'Alaska',
  [TIMEZONES.HAWAIIAN]: 'Hawaii',
};

export const timezoneOptions = [
  {
    value: TIMEZONES.EASTERN,
    text: `Eastern Standard Time (GMT${TIMEZONE_OFFSETS[TIMEZONES.EASTERN]}) ${
      TIMEZONE_LABELS[TIMEZONES.EASTERN]
    } (US & Canada)`,
  },
  {
    value: TIMEZONES.CENTRAL,
    text: `Central Standard Time (GMT${TIMEZONE_OFFSETS[TIMEZONES.CENTRAL]}) ${
      TIMEZONE_LABELS[TIMEZONES.CENTRAL]
    } (US & Canada)`,
  },
  {
    value: TIMEZONES.MOUNTAIN,
    text: `Mountain Standard Time (GMT${
      TIMEZONE_OFFSETS[TIMEZONES.MOUNTAIN]
    }) ${TIMEZONE_LABELS[TIMEZONES.MOUNTAIN]} (US & Canada)`,
  },
  {
    value: TIMEZONES.US_MOUNTAIN,
    text: `US Mountain Standard Time (GMT${
      TIMEZONE_OFFSETS[TIMEZONES.US_MOUNTAIN]
    }) ${TIMEZONE_LABELS[TIMEZONES.US_MOUNTAIN]}`,
  },
  {
    value: TIMEZONES.PACIFIC,
    text: `Pacific Standard Time (GMT${TIMEZONE_OFFSETS[TIMEZONES.PACIFIC]}) ${
      TIMEZONE_LABELS[TIMEZONES.PACIFIC]
    } (US & Canada)`,
  },
  {
    value: TIMEZONES.ALASKAN,
    text: `Alaskan Standard Time (GMT${TIMEZONE_OFFSETS[TIMEZONES.ALASKAN]}) ${
      TIMEZONE_LABELS[TIMEZONES.ALASKAN]
    }`,
  },
  {
    value: TIMEZONES.HAWAIIAN,
    text: `Hawaiian Standard Time (GMT${
      TIMEZONE_OFFSETS[TIMEZONES.HAWAIIAN]
    }) ${TIMEZONE_LABELS[TIMEZONES.HAWAIIAN]}`,
  },
];
