import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

const CloseButton = ({ onClick, ...props }) => (
  <IconButton color="primary" onClick={onClick} {...props}>
    <Close />
  </IconButton>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
