import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_PAGE_SIZE } from 'utils/constants';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';

const NetworkFilter = ({ control, name, placeholder, onFilter }) => {
  const [networks, setNetworks] = useState([]);

  useEffect(() => {
    async function fetchNetworks() {
      try {
        const { data } = await api.getNetworkNames({
          pageSize: MAX_PAGE_SIZE,
        });
        setNetworks(
          data.networks.map((net) => ({ value: net.id, label: net.name })),
        );
      } catch {
        setNetworks([]);
      }
    }
    fetchNetworks();
  }, []);

  return (
    <SelectWithFilter
      control={control}
      name={name}
      placeholder={placeholder}
      data={networks}
      handleChange={(value) => onFilter({ [name]: value })}
    />
  );
};

NetworkFilter.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default NetworkFilter;
