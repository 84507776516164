import { Auth } from 'aws-amplify';

export const signIn = async (username, password) =>
  Auth.signIn(username, password);

export const signOut = async () => Auth.signOut();

export const getCurrentSession = async () => Auth.currentSession();

export const getCurrentUser = async () => Auth.currentAuthenticatedUser();

export const sendForgotPasswordCode = async (username) =>
  Auth.forgotPassword(username);

export const resetPassword = async (username, verificationCode, newPassword) =>
  Auth.forgotPasswordSubmit(username, verificationCode, newPassword);

export const setPassword = async (user, newPassword) =>
  Auth.completeNewPassword(user, newPassword);

export const signInWithGoogle = async () =>
  Auth.federatedSignIn({ provider: 'Google' });

export const changePassword = async (currentPassword, newPassword) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(currentUser, currentPassword, newPassword);
};

export const confirmSignIn = async (user, code, mfaType) =>
  Auth.confirmSignIn(user, code, mfaType);
