import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/components/buttons/Button/Button';
import { Box, FlexBoxColumnAlign } from 'styles/layout';

const ConsultUnmatch = ({ onUnlink, onCancel }) => (
  <FlexBoxColumnAlign style={{ padding: '3rem 10rem', textAlign: 'center' }}>
    <h3>
      Are you sure you want to un-reconcile this consult with this associated
      ADT Order?
    </h3>
    <h3>
      The associated Patient will enter the Orphaned ADT Orders list and will be
      available to reconcile with another Consult.
    </h3>
    <h3>
      The consult will need to be reconciled with the correct ADT Order before
      it is closed.
    </h3>
    <Box style={{ marginTop: '1rem' }}>
      <Button
        onClick={onUnlink}
        color="secondary"
        style={{ marginRight: '2rem' }}
        variant="contained"
      >
        Confirm
      </Button>
      <Button onClick={onCancel} color="secondary" variant="outlined">
        Cancel
      </Button>
    </Box>
  </FlexBoxColumnAlign>
);

ConsultUnmatch.propTypes = {
  onUnlink: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConsultUnmatch;
