import {
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/HPI/formFields';

export const leftPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.chiefComplaintPredefined),
      fields.chiefComplaintFreeText,
    ],
  },
  { elements: [mapToggleFieldOptions(fields.handedness)] },
  { elements: [fields.additionalHistory] },
  {
    elements: [
      mapChipFieldOptions(fields.durationConditionPredefined),
      fields.durationConditionFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.durationPeriodPredefined),
      fields.durationPeriodFreeText,
    ],
  },
];

export const rightPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.associatedSymptomsPredefined),
      fields.associatedSymptomsFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.qualityPredefined),
      fields.qualityFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.contextPreExistingConditionsPredefined),
      fields.contextPreExistingConditionsFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.severityPredefined),
      fields.severityFreeText,
    ],
  },
];
