import React from 'react';
import PropTypes from 'prop-types';

import FilteredSelect from 'shared/components/forms/SelectWithFilter';

const SelectWithFilter = ({ onChange, name, ...rest }) => (
  <FilteredSelect {...rest} name={name} handleChange={() => onChange(name)} />
);

SelectWithFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default SelectWithFilter;
