import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { consultsActions } from 'features/consults/store/slice';
import { ConsultMacroTypesEnum } from 'features/consults/utils/enums';
import { FlexBoxAlign, FlexBoxColumn } from 'styles/layout';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';
import MacrosList from './MacrosList';
import MacrosForm from './MacrosForm';

const ConsultMacros = ({ consultId, consultType, userId }) => {
  const dispatch = useDispatch();
  const { data: macros } = useSelector(({ consults }) => consults.macros);

  useEffect(() => {
    dispatch(
      consultsActions.listMacros({
        pageSize: MAX_PAGE_SIZE,
        filters: { type: ConsultMacroTypesEnum[consultType] },
      }),
    );
  }, [consultType, dispatch]);

  const applySelectedMacro = (macroId) => {
    dispatch(
      consultsActions.applyMacro({ consultId, consultMacroId: macroId }),
    );
  };

  const addNewMacro = ({ macroName }) => {
    dispatch(
      consultsActions.addNewMacro({
        consultId,
        name: macroName,
        type: ConsultMacroTypesEnum[consultType],
        createdAt: formatDate(new Date(), TIMESTAMP_FORMAT),
        createdById: userId,
      }),
    );
  };

  return (
    <FlexBoxColumn py={2}>
      <MacrosList items={macros} onSelect={applySelectedMacro} />
      <FlexBoxAlign>
        <MacrosForm onSubmit={addNewMacro} />
      </FlexBoxAlign>
    </FlexBoxColumn>
  );
};

ConsultMacros.propTypes = {
  consultId: PropTypes.number.isRequired,
  consultType: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

export default ConsultMacros;
