import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import fields from './formFields';

export const leftPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.additionalPredefined),
      fields.additionalFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.unableToObtainRosBecausePredefined),
      fields.unableToObtainRosBecauseFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.constitutionalPredefined),
      fields.constitutionalFreeText,
    ],
  },
  {
    elements: [mapChipFieldOptions(fields.eyesPredefined), fields.eyesFreeText],
  },
  {
    elements: [mapChipFieldOptions(fields.entPredefined), fields.entFreeText],
  },
  {
    elements: [
      mapChipFieldOptions(fields.respiratoryPredefined),
      fields.respiratoryFreeText,
    ],
  },
];

export const centerPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.cardiovascularPredefined),
      fields.cardiovascularFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.abdominalPainPredefined),
      fields.abdominalPainFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.genitourinaryPredefined),
      fields.genitourinaryFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.musculoskeletalPredefined),
      fields.musculoskeletalFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.integumentaryPredefined),
      fields.integumentaryFreeText,
    ],
  },
];

export const rightPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.neurologicSeeHPIPredefined),
      mapChipFieldOptions(fields.neurologicPredefined),
      fields.neurologicFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.psychiatricPredefined),
      fields.psychiatricFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.endocrinePredefined),
      fields.endocrineFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.hematologicPredefined),
      fields.hematologicFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.allergyPredefined),
      fields.allergyFreeText,
    ],
  },
];
