import { useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import { buildFilterQueryString } from 'api/utils';
import useFilterState from 'shared/hooks/useFilterState';
import { useTableState } from 'shared/hooks/useTableState';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import IntegrationIcon from 'shared/components/data/IntegrationIcon';
import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import {
  US_DATE_FORMAT,
  TIME_FORMAT,
  formatToUserTimezone,
  startOfMonthStr,
} from 'utils/dates';

const tableColumns = (service) => [
  {
    header: {
      id: sortFields.consults.facilityName,
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
    width: '35%',
  },
  {
    header: {
      id: sortFields.consults.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'patientFirstName',
  },
  {
    header: {
      id: sortFields.consults.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'patientLastName',
  },
  {
    header: {
      id: 'consultType',
      value: 'Service',
      isSortable: !service,
    },
    dataKey: 'consultType',
  },
  {
    header: {
      id: sortFields.consults.assignTo,
      value: 'Provider',
      isSortable: true,
    },
    formatValue: (item) => `${item.assignToFirstName} ${item.assignToLastName}`,
  },
  {
    header: {
      id: sortFields.consults.createdAt,
      value: 'Created At',
      isSortable: true,
    },
    width: '20%',
    formatValue: (item) =>
      formatToUserTimezone(
        new Date(item.createdAt),
        `${US_DATE_FORMAT} - ${TIME_FORMAT}`,
      ),
  },
  {
    header: {
      id: 'integration',
      value: '',
    },
    width: '3.5rem',
    formatValue: IntegrationIcon,
  },
];

const defaultFilterValues = (service) => ({
  // shared filters
  query: null,
  service,
  fromCreatedAt: service ? startOfMonthStr() : null,
  toCreatedAt: null,
  assignToUserId: null,

  // Neuro & EEG
  networkId: null,
  healthcareSystemId: null,
  facilityId: null,

  // Neuro
  neuroConsultTypes: [],
  patientLocationType: '',
  withQualityFlagSelected: '',
  neuroAttestationOfConsultCompletionTypes: [],
  planThrombolyticInterventionTypes: [],

  // EEG
  eegConsultType: '',
  eegReadConsultObservationType: '',
  eegAttestationOfEegCompletionTypes: [],
  withEegStatOptionsTypeSelected: '',
});

const useConsultsListState = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { queryParams } = useQueryParams();

  const {
    data: consults,
    pageCount,
    totalCount,
  } = useSelector(({ admin }) => admin.consults);
  const {
    page,
    setPage,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.consults.createdAt, 'desc');
  const { filters, handleFilter, handleResetFilters } = useFilterState(
    defaultFilterValues(queryParams.service),
    setPage,
  );

  const onResetFilters = useCallback(
    ({ service }) => handleResetFilters(defaultFilterValues(service)),
    [handleResetFilters],
  );

  useEffect(() => {
    const { service, ...moreFilters } = filters;

    dispatch(
      adminActions.listConsults({
        service,
        page,
        sortBy,
        sortOrder,
        filters: moreFilters,
      }),
    );
    history.replace(
      `${
        AdminPaths.Consults
      }?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}${buildFilterQueryString(
        filters,
      )}`,
    );
  }, [dispatch, history, page, sortBy, sortOrder, filters]);

  const serviceColumns = useMemo(
    () => tableColumns(filters.service),
    [filters.service],
  );

  const tableHeaders = useMemo(
    () => getTableHeaders(serviceColumns),
    [serviceColumns],
  );

  const tableData = useMemo(
    () => prepareTableData(consults, serviceColumns),
    [serviceColumns, consults],
  );

  const navigateToConsultDetails = useCallback(
    (consultId) => {
      const type = consults
        .find(({ id }) => id === consultId)
        ?.consultType?.toLowerCase();
      history.push(`${AdminPaths.Consults}/${type}/${consultId}`);
    },
    [consults, history],
  );

  return {
    page,
    pageCount,
    totalCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters: onResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsultDetails,
    tableHeaders,
    tableData,
  };
};

export default useConsultsListState;
