import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import api from 'api';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import useFormState from 'shared/hooks/useFormState';
import ConsultLock from 'features/consults/components/ConsultLock/ConsultLock';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import SelectWithFilter from 'features/consults/components/tabFields/SelectWithFilter';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import formFields from 'features/consults/shared/tabs/Signature/formFields';
import {
  qualityReviewPanel,
  qualityCommentPanel,
} from 'features/consults/shared/tabs/Signature/data';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { userRolesEnum } from 'utils/users';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { mapDoctorNames } from 'utils/mappers';
import { Box } from 'styles/layout';

import schema from './validationSchema';
import { leftPanels } from './data';

const SignatureNeuro = () => {
  const {
    register,
    control,
    getValues,
    getFieldError,
    setFormValues,
    clearErrors,
    trigger,
    watch,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isClosed,
    isEditable,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
    saveConsultChange,
    tabErrors,
  } = useConsultData(CONSULT_TABS.SIGNATURE, getValues);
  const [neuroUsers, setNeuroUsers] = useState([]);
  const currentAttestationValue = watch(
    formFields.attestationOfConsultCompletion.name,
  );
  const qualitySection = watch(formFields.qualityReview.name);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    clearErrors();
    if (tabErrors) trigger(tabErrors);
  }, [tabErrors, clearErrors, trigger]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await api.getUserNames({
        pageSize: MAX_PAGE_SIZE,
        filters: { roles: [userRolesEnum.NEURO] },
      });
      setNeuroUsers(mapDoctorNames(data.users, 'value', 'label'));
    };
    fetchUsers();
  }, []);

  const handleQualityChange = (fieldName) => {
    saveConsultChange({
      [fieldName]: getValues(fieldName),
      [formFields.qualityComment.name]: null,
    });
  };

  return (
    <GridWrapper>
      <Grid item sm={12} lg={6}>
        {leftPanels.map((section, index) => (
          <QuestionsPanel
            {...section}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            onChange={handleFieldChange}
            disabled={!isEditable}
            getFieldError={getFieldError}
          />
        ))}
      </Grid>
      <Grid item sm={12} lg={6}>
        <Box mb={1}>
          <SelectWithFilter
            control={control}
            name="assignToId"
            label="Neurologist"
            data={neuroUsers}
            required
            disabled={isClosed}
            onChange={handleOwnerChange}
          />
        </Box>
        <Box mb={1}>
          <SelectWithFilter
            control={control}
            name="backupNeurologistId"
            label="Are you providing backup for on-call provider? If so select on-call below:"
            data={neuroUsers}
            disabled={!isEditable}
            onChange={handleFieldChange}
          />
        </Box>
        <ConsultLock
          consult={consult}
          consultMainType={consultMainType}
          attestationValue={currentAttestationValue}
          disabled={!isEditable}
          checked={isClosed}
          onConsultLock={handleConsultLock}
        />
        <QuestionsPanel
          {...qualityReviewPanel}
          control={control}
          register={register}
          onChange={handleQualityChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
        />
        {qualitySection?.length > 0 && (
          <QuestionsPanel
            {...qualityCommentPanel}
            control={control}
            register={register}
            onChange={handleFieldChange}
            getFieldError={getFieldError}
            disabled={!isEditable}
          />
        )}
      </Grid>
    </GridWrapper>
  );
};

export default SignatureNeuro;
