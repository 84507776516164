import styled from 'styled-components';

import {
  FlexBoxColumn,
  FlexBoxColumnAlign,
  FullPageWrapperCenter,
} from 'styles/layout';
import backgroundPng from 'auth/assets/bsl-background.jpg';

export const PageWrapper = styled(FullPageWrapperCenter)`
  background: url(${backgroundPng}) no-repeat center;
  background-size: cover;
`;

export const FormWrapper = styled(FlexBoxColumnAlign)`
  width: 420px;
  padding: 30px 0;
`;

export const Form = styled(FlexBoxColumn)`
  width: 90%;
  margin: auto;
`;

export const Logo = styled.img`
  max-width: 300px;
  margin-bottom: 20px;
`;
