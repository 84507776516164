import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { appStateActions } from 'store/appStateSlice';
import api from 'api';
import sortFields from 'api/sortFields';
import { buildFilterQueryString } from 'api/utils';

import AddButton from 'navigation/components/AddButton';
import ExportButton from 'shared/components/buttons/ExportButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { useTableState } from 'shared/hooks/useTableState';
import useFilterState from 'shared/hooks/useFilterState';
import {
  FlexBox,
  FlexBoxColumn,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';

import { mapPhoneNumberToMask } from 'utils/mappers';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { titles } from 'utils/users';
import { downloadFile } from 'utils/helpers';

import UserListFilter from './UserListFilter';

const tableColumns = [
  {
    header: {
      id: sortFields.users.firstName,
      value: 'First Name',
      isSortable: true,
    },
    dataKey: 'firstName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.users.lastName,
      value: 'Last Name',
      isSortable: true,
    },
    dataKey: 'lastName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.users.title,
      value: sortFields.users.title,
      isSortable: true,
    },
    formatValue: (item) => titles[item.title] || '-',
    width: '75px',
  },
  {
    header: {
      id: sortFields.users.phoneNumber,
      value: 'Phone Number',
      isSortable: true,
    },
    formatValue: (item) => mapPhoneNumberToMask(item.phoneNumber),
    width: '160px',
  },
  {
    header: {
      id: sortFields.users.email,
      value: sortFields.users.email,
      isSortable: true,
    },
    dataKey: 'email',
  },
  {
    header: {
      id: sortFields.users.username,
      value: sortFields.users.username,
      isSortable: true,
    },
    dataKey: 'username',
  },
  {
    header: {
      id: sortFields.users.enabled,
      value: 'Active',
      isSortable: true,
    },
    formatValue: (item) => (item.enabled ? 'Yes' : 'No'),
    width: '75px',
    align: 'center',
  },
];

const defaultFilterValues = [
  'query',
  'roles',
  'healthSystemId',
  'facilityId',
].reduce((acc, key) => {
  acc[key] = null;
  return acc;
}, {});

const UserListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: users, pageCount } = useSelector(({ admin }) => admin.users);
  const { filters, handleFilter } = useFilterState(defaultFilterValues);
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.users.firstName);

  useEffect(() => {
    dispatch(adminActions.listUsers({ page, sortBy, sortOrder, filters }));
    history.replace(
      `${
        AdminPaths.Users
      }?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}${buildFilterQueryString(
        filters,
      )}`,
    );
  }, [dispatch, history, page, sortBy, sortOrder, filters]);

  const handleExport = async () => {
    try {
      dispatch(appStateActions.setLoading(true));

      const { data, headers } = await api.exportUsers({
        page,
        sortBy,
        sortOrder,
        filters,
      });

      const blob = new Blob([data], { type: headers['content-type'] });
      downloadFile(blob, headers);
    } catch {
      dispatch(
        appStateActions.showSnackbar({
          text:
            'An error occurred while downloading the file. Please, try again.',
          type: SnackbarTypeEnum.ERROR,
          duration: 5000,
        }),
      );
    } finally {
      dispatch(appStateActions.setLoading(false));
    }
  };

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter mt={1} mb={3}>
        <UserListFilter onFilter={handleFilter} defaultFilters={filters} />
        <AddButton to={AdminPaths.AddUser}>Add User</AddButton>
      </FlexBoxSpaceBetweenCenter>
      <FlexBox justifyContent="flex-end">
        <ExportButton color="secondary" onClick={handleExport}>
          Download CSV
        </ExportButton>
      </FlexBox>
      <FlexBoxColumn>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(users, tableColumns)}
          fallbackMsg="No users found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(rowId) => history.push(`${AdminPaths.Users}/${rowId}`)}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default UserListPage;
