import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

import { Autocomplete } from './SelectWithFilter.styles';

const getValueLabel = ({ value, data }) => {
  if (!value) return '';
  const currentOption = data.find((option) => option.value === value);
  return currentOption ? currentOption.label : '';
};

const SelectWithFilter = ({
  control,
  name,
  label,
  data,
  disabled,
  size = 'small',
  required = false,
  handleChange,
  error = null,
  groupBy,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={null}
    render={({ onChange, value, ...controlRest }) => (
      <Autocomplete
        {...controlRest}
        id={name}
        value={value}
        disabled={disabled}
        options={data}
        getOptionLabel={(option) => option.label || ''}
        getOptionSelected={(option) => option.value === value}
        size={size}
        onChange={(_, newValue) => {
          const changedValue = newValue ? newValue.value : null;
          onChange(changedValue);
          if (handleChange) handleChange(changedValue);
        }}
        groupBy={groupBy}
        renderInput={({ inputProps, ...autoCompleteRest }) => (
          <>
            {!!label && (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                error={error}
              >
                {label}
              </InputLabel>
            )}
            <TextField
              {...autoCompleteRest}
              {...props}
              inputProps={{
                ...inputProps,
                value: inputProps.value || getValueLabel({ value, data }),
              }}
              name={name}
              size={size}
              variant="outlined"
            />
            {error && (
              <ErrorMsg text={error.message} dense={size === 'small'} />
            )}
          </>
        )}
      />
    )}
  />
);

SelectWithFilter.propTypes = {
  control: PropTypes.shape({
    register: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.node,
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.shape({}),
  handleChange: PropTypes.func,
  groupBy: PropTypes.func,
};

export default SelectWithFilter;
