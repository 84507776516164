import React from 'react';
import PropTypes from 'prop-types';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import { Edit } from '@material-ui/icons';

const EditButton = ({ onClick }) => (
  <IconButton onClick={onClick} color="primary">
    <Edit />
  </IconButton>
);

EditButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EditButton;
