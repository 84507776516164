import * as yup from 'yup';

import formFields from 'features/consults/shared/tabs/Demographics/formFields';
import { isEmptyValue } from 'features/consults/utils/validators';
import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX } from 'utils/validators';

import { eegConsultTypeValue } from './data';

export default yup.object().shape({
  [formFields.firstName.name]: yup
    .string()
    .required(requiredString)
    .max(150, ({ max }) => `First name should not exceed ${max} characters`),
  [formFields.lastName.name]: yup
    .string()
    .required(requiredString)
    .max(150, ({ max }) => `Last name should not exceed ${max} characters`),
  [formFields.dateOfBirth.name]: yup
    .date()
    .nullable()
    .max(new Date(), 'Date of birth cannot be in the future')
    .typeError('Invalid date'),
  [formFields.gender.name]: yup.string(),
  facility: yup
    .number()
    .when('$hasMultipleFacilities', (hasMultipleFacilities, schema) =>
      hasMultipleFacilities
        ? schema.required(requiredString).typeError(requiredString)
        : schema,
    ),
  type: yup
    .string()
    .required(requiredString)
    .test('validSelection', requiredString, (value) => !isEmptyValue(value)),
  eegReadConsultType: yup.string().when('type', {
    is: eegConsultTypeValue,
    then: yup.string().required('This field is required.'),
  }),
  videoType: yup.string().when('type', {
    is: eegConsultTypeValue,
    then: yup.string().required('This field is required.'),
  }),
  patientLocation: yup
    .string()
    .required(requiredString)
    .test('validSelection', requiredString, (value) => !isEmptyValue(value)),
  providerRequestingConsult: yup
    .string()
    .max(256, ({ max }) => `Provider name should not exceed ${max} characters`),
  phoneNumber: yup
    .string()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .required(requiredString)
    .typeError(requiredString),
  neuroCameraName: yup
    .string()
    .max(255, ({ max }) => `Camera name should not exceed ${max} characters`),
  neuroDirectBeamInRequested: yup.array().of(yup.string()),
  eegTechName: yup
    .string()
    .max(
      255,
      ({ max }) => `Technician name should not exceed ${max} characters`,
    ),
  eegTechCallBackNumber: yup
    .string()
    .nullable()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  statReadRequested: yup.array().of(yup.string()),
  notes: yup
    .string()
    .max(20000, ({ max }) => `Notes should not exceed ${max} characters`),
});
