import React from 'react';
import { Grid } from '@material-ui/core';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useFormState from 'shared/hooks/useFormState';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import schema from './validationSchema';
import { leftPanels, rightPanels } from './data';

const PMHFHSH = () => {
  const { register, control, getFieldError, getValues, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultMainType,
    consultTabData,
    isEditable,
    handleFieldChange,
  } = useConsultData(CONSULT_TABS.PMH, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <GridWrapper>
      <Grid item xs={12} lg={6}>
        {leftPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={6}>
        {rightPanels(consultMainType).map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default PMHFHSH;
