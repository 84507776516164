import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

export const SLICE_PREFIX = 'schedule';

export const listScheduleShifts = createAsyncThunk(
  `${SLICE_PREFIX}/listScheduleShifts`,
  async ({ startDate, endDate, filters }, { rejectWithValue }) => {
    try {
      const { data } = await api.getShifts(startDate, endDate, filters);

      return data.shifts;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
