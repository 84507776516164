import Paths from 'navigation/paths';

export default {
  Users: `${Paths.Admin}/users`,
  AddUser: `${Paths.Admin}/users/add`,
  HealthSystems: `${Paths.Admin}/locations`,
  AddHealthSystem: `${Paths.Admin}/locations/add`,
  Networks: `${Paths.Admin}/networks`,
  AddNetwork: `${Paths.Admin}/networks/add`,
  Consults: `${Paths.Admin}/consults`,
  ConsultRequests: `${Paths.Admin}/consult-requests`,
  Providers: `${Paths.Admin}/providers`,
};
