import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

import Button from 'shared/components/buttons/Button/Button';

import {
  Box,
  FlexBoxColumnAlign,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import { formatDateString } from 'utils/dates';

import {
  TableCell,
  InfoLabel,
  matchIconStyle,
} from './ConsultIntegration.style';

const isMatch = (consultValue, orderValue) =>
  consultValue.toLowerCase() === orderValue.toLowerCase();

const formatDate = (date) =>
  date ? formatDateString(date.split('T')[0], 'MM-dd-yyyy') : '--';

const MatchedIcon = ({ matched }) => (
  <div style={matchIconStyle(matched)}>
    {matched ? (
      <CheckIcon style={{ fontSize: '2rem' }} />
    ) : (
      <ClearIcon style={{ fontSize: '2rem' }} />
    )}
  </div>
);

MatchedIcon.propTypes = {
  matched: PropTypes.bool,
};

const ConsultIntegrationMerge = ({
  consult,
  order,
  onMerge,
  onClose,
  closeStr = 'Cancel',
}) => {
  const rows = useMemo(() => {
    const {
      demographics: { firstName, lastName, dateOfBirth },
    } = consult;
    const { familyName, givenName, dob } = order;

    const consultDob = formatDate(dateOfBirth);
    const orderDob = formatDate(dob);
    const consultFirstName = firstName || '--';
    const orderFirstName = givenName || '--';
    const consultLastName = lastName || '--';
    const orderLastName = familyName || '--';

    return [
      [
        'First Name',
        consultFirstName,
        <MatchedIcon matched={isMatch(consultFirstName, orderFirstName)} />,
        orderFirstName,
      ],
      [
        'Last Name',
        consultLastName,
        <MatchedIcon matched={isMatch(consultLastName, orderLastName)} />,
        orderLastName,
      ],
      [
        'DOB',
        consultDob,
        <MatchedIcon matched={isMatch(consultDob, orderDob)} />,
        orderDob,
      ],
    ];
  }, [consult, order]);

  return (
    <Dialog open maxWidth="lg" onClose={onClose}>
      <DialogContent>
        <FlexBoxColumnAlign style={{ padding: '2rem 8rem' }}>
          <FlexBoxSpaceBetweenCenter width="100%" mb={2}>
            <InfoLabel>Patient MRN: {order.mrn}</InfoLabel>
            <InfoLabel>
              Patient Account Number: {order.patientAccountNumber}
            </InfoLabel>
          </FlexBoxSpaceBetweenCenter>
          <h4>
            Are you sure you want to reconcile this Consult with the associated
            ADT Order?
          </h4>
          <h4>
            This will update the information entered on the selected Consult to
            match the ADT Order
          </h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Consult</TableCell>
                <TableCell />
                <TableCell>ADT Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row[0]}>
                  {row.map((el) => (
                    <TableCell key={uuid()}>{el}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box style={{ marginBottom: '1rem' }}>
            <Button
              onClick={onMerge}
              color="secondary"
              style={{ marginRight: '1rem' }}
              variant="contained"
            >
              Merge
            </Button>
            <Button onClick={onClose} color="secondary" variant="outlined">
              {closeStr}
            </Button>
          </Box>
        </FlexBoxColumnAlign>
      </DialogContent>
    </Dialog>
  );
};

ConsultIntegrationMerge.propTypes = {
  consult: PropTypes.shape({
    demographics: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      dateOfBirth: PropTypes.string,
    }),
  }).isRequired,
  order: PropTypes.shape({
    familyName: PropTypes.string,
    givenName: PropTypes.string,
    dob: PropTypes.string,
    patientAccountNumber: PropTypes.string,
    mrn: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired,
  closeStr: PropTypes.string,
};

export default ConsultIntegrationMerge;
