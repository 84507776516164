import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { uploadProfilePhoto } from 'auth/amplify/storage';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import useProfilePhotoFetch from 'shared/hooks/useProfilePhotoFetch';
import { appStateActions } from 'store/appStateSlice';
import { isFile } from 'utils/helpers';

const useProfilePhotoUpload = (
  userId,
  { formFieldName, getValues, setFormValues, setValue } = {},
) => {
  const dispatch = useDispatch();
  const { imageUrl } = useProfilePhotoFetch(userId);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      setFormValues({
        ...getValues(),
        [formFieldName]: imageUrl,
      });
    }
  }, [formFieldName, getValues, imageUrl, setFormValues]);

  useEffect(() => {
    if (uploadError) {
      setValue(formFieldName, null);
      dispatch(
        appStateActions.showSnackbar({
          text:
            'An error occurred while uploading the file. Please, try again.',
          type: SnackbarTypeEnum.ERROR,
          duration: 5000,
        }),
      );
    }
  }, [dispatch, formFieldName, setValue, uploadError]);

  const uploadImage = async () => {
    const inputValue = getValues(formFieldName);

    if (isFile(inputValue)) {
      try {
        await uploadProfilePhoto(userId, inputValue);
        setUploadError(null);
      } catch (err) {
        setUploadError(err);
      }
    }
  };

  return {
    uploadImage,
  };
};

export default useProfilePhotoUpload;
