import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import styled from 'styled-components';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';

const DialogButtonsWrapper = styled((props) => (
  <DialogActions classes={{ spacing: 'spacing' }} {...props} />
))`
  &.spacing {
    > :not(:first-child) {
      margin-left: 24px;
    }
  }
`;

const BasicDialog = ({
  open,
  handleConfirm,
  handleCancel,
  title,
  description,
  confirmText = 'Submit',
  cancelText = 'Cancel',
}) => (
  <Dialog
    open={open}
    onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    {description && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogButtonsWrapper>
      {handleCancel && (
        <ActionButton
          type="button"
          variant="outlined"
          color="secondary"
          onClick={handleCancel}
        >
          {cancelText}
        </ActionButton>
      )}
      <ActionButton
        type="button"
        color="secondary"
        autoFocus
        onClick={handleConfirm}
      >
        {confirmText}
      </ActionButton>
    </DialogButtonsWrapper>
  </Dialog>
);

BasicDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};

export default BasicDialog;
