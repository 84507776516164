import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { appStateActions } from 'store/appStateSlice';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';

const duration = 5000;

const useFeedback = () => {
  const dispatch = useDispatch();

  const successDisplay = useCallback(
    (text) =>
      dispatch(
        appStateActions.showSnackbar({
          text,
          duration,
          type: SnackbarTypeEnum.SUCCESS,
        }),
      ),
    [dispatch],
  );

  const errorDisplay = useCallback(
    (text) =>
      dispatch(
        appStateActions.showSnackbar({
          text,
          duration,
          type: SnackbarTypeEnum.ERROR,
        }),
      ),
    [dispatch],
  );

  return {
    successDisplay,
    errorDisplay,
  };
};

export default useFeedback;
