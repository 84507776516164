import { ConsultTypeEnum } from 'features/consults/utils/enums';
import {
  filterOptionsByConsultType,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { sharedSections } from 'features/consults/shared/tabs/Plan/data';
import fields, {
  thrombolyticValues,
} from 'features/consults/shared/tabs/Plan/formFields';

export const thrombolyticPanel = {
  elements: [mapToggleFieldOptions(fields.thrombolyticInterventionPredefined)],
};

const sections = {
  additionalThrombolytic: {
    elements: [mapToggleFieldOptions(fields.dose)],
  },
  thrombolyticExclusion1: {
    elements: [
      mapChipFieldOptions(
        fields.thrombolyticExclusionLessThanThreeHourWindowPredefined,
      ),
    ],
  },
  thrombolyticExclusion2: {
    elements: [
      mapChipFieldOptions(
        fields.thrombolyticExclusionThreeToFourPointFiveHourWindowPredefined,
      ),
    ],
  },
  thrombolyticExclusion3: {
    elements: [
      mapToggleFieldOptions(fields.thrombolyticExclusionPredefined),
      fields.thrombolyticExclusionFreeText,
    ],
  },
  bloodPressureManagement: {
    elements: [
      mapChipFieldOptions(fields.bloodPressureManagementPredefined),
      fields.bloodPressureManagementFreeText,
    ],
  },
  targetBloodPressure: {
    elements: [
      mapToggleFieldOptions(fields.targetBloodPressureBelowPredefined),
      mapToggleFieldOptions(fields.targetBloodPressureAbovePredefined),
      mapToggleFieldOptions(fields.targetBloodPressureDiastolicPredefined),
      fields.targetBloodPressureFreeText,
    ],
  },
  IAExclusion: {
    elements: [
      mapChipFieldOptions(fields.iaExclusionPredefined),
      fields.iaExclusionFreeText,
    ],
  },
  possibleIACandidate: {
    elements: [
      mapChipFieldOptions(fields.possibleIACandidatePredefined),
      fields.possibleIACandidateFreeText,
    ],
  },
  intraArterialExclusion: {
    elements: [
      mapChipFieldOptions(fields.thrombolyticIntraarterialExclusionPredefined),
    ],
  },
  timeIVThrombolyticRecommended: {
    elements: [fields.timeIVThrombolyticRecommendedMountainTime],
  },
  timeThrombolyticBolus: {
    elements: [fields.timeThrombolyticBolusMountainTime],
  },
  timeThrombolyticInfusion: {
    elements: [fields.timeThrombolyticInfusionMountainTime],
  },
  imaging: {
    elements: [
      mapChipFieldOptions(fields.imagingUrgencySTATPredefined),
      fields.imagingUrgencySTATFreeText,
    ],
  },
  imaging2: {
    elements: [
      mapChipFieldOptions(fields.imagingUrgencyRoutinePredefined),
      fields.imagingUrgencyRoutineFreeText,
    ],
  },
  vteProphylaxis: {
    elements: [
      mapChipFieldOptions(fields.vteProphylaxisPredefined),
      fields.vteProphylaxisFreeText,
    ],
  },
  thrombolyticAdministration: {
    elements: [
      mapChipFieldOptions(
        fields.thrombolyticAdministrationRecommendationsPredefined,
      ),
      fields.thrombolyticAdministrationRecommendationsFreeText,
    ],
  },
  hemorrhageReversal: {
    elements: [
      mapChipFieldOptions(fields.hemorrhageReversalPredefined),
      fields.hemorrhageReversalFreeText,
    ],
  },
  other: {
    elements: [
      mapChipFieldOptions(
        filterOptionsByConsultType(
          fields.otherPredefined,
          ConsultTypeEnum.NEURO,
        ),
      ),
      fields.otherFreeText,
    ],
  },
  disposition: {
    elements: [mapToggleFieldOptions(fields.dispositionPredefined)],
  },
};

export const thrombolyticSections = {
  [thrombolyticValues.IVThrombolytic]: [
    sections.additionalThrombolytic,
    sections.IAExclusion,
    sections.intraArterialExclusion,
    sections.timeIVThrombolyticRecommended,
    sections.timeThrombolyticBolus,
    sections.timeThrombolyticInfusion,
  ],
  [thrombolyticValues.IAIntervention]: [
    sections.thrombolyticExclusion1,
    sections.thrombolyticExclusion2,
    sections.thrombolyticExclusion3,
  ],
  [thrombolyticValues.IVAndIAIntervention]: [
    sections.additionalThrombolytic,
    sections.timeIVThrombolyticRecommended,
    sections.timeThrombolyticBolus,
    sections.timeThrombolyticInfusion,
  ],
  [thrombolyticValues.NOTIVThrombolyticOrIAIntervention]: [
    sections.thrombolyticExclusion1,
    sections.thrombolyticExclusion2,
    sections.thrombolyticExclusion3,
    sections.IAExclusion,
    sections.intraArterialExclusion,
  ],
  [thrombolyticValues.PossibleIAcandidate]: [
    sections.thrombolyticExclusion1,
    sections.thrombolyticExclusion2,
    sections.thrombolyticExclusion3,
    sections.possibleIACandidate,
  ],
  [thrombolyticValues.IVThrombolyticPossibleIACandidate]: [
    sections.additionalThrombolytic,
    sections.possibleIACandidate,
    sections.timeIVThrombolyticRecommended,
    sections.timeThrombolyticBolus,
    sections.timeThrombolyticInfusion,
  ],
};

export const bloodPressurePanels = [
  sections.bloodPressureManagement,
  sections.targetBloodPressure,
];

export const rightPanels = [
  sharedSections.labsSection,
  sections.imaging,
  sections.imaging2,
  sharedSections.diagnosticTestSection,
  sharedSections.therapyEvaluationSection,
  sharedSections.medicationsSection,
  sections.vteProphylaxis,
  sections.thrombolyticAdministration,
  sections.hemorrhageReversal,
  sections.other,
  sharedSections.additionalRecommendationsSection,
  sections.disposition,
];
