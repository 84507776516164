import { createSlice } from '@reduxjs/toolkit';

import { logoutUser } from 'auth/store/actionCreators';
import { mapErrorResponse } from 'utils/mappers';
import { IntegrationStatusEnum } from 'features/consults/utils/enums';

import {
  SLICE_PREFIX,
  listHealthSystems,
  addHealthSystem,
  listNetworks,
  addNetwork,
  getNetwork,
  updateNetwork,
  getHealthSystemById,
  listUsers,
  addFacility,
  getFacilityById,
  updateFacility,
  listFacilities,
  updateHealthSystem,
  addUser,
  getUserById,
  updateUser,
  resetUserPassword,
  reInviteUser,
  listConsults,
  fetchConsult,
  updateAdminConsult,
  linkOrder,
  unlinkOrder,
} from './actionCreators';

const defaultTableState = { data: [], pageCount: 0 };

const initialState = {
  systems: defaultTableState,
  system: {},
  facilities: defaultTableState,
  facility: {},
  networks: defaultTableState,
  network: {},
  users: defaultTableState,
  user: {},
  consults: defaultTableState,
  consult: {},
  error: null,
};

/* eslint-disable no-param-reassign */
const adminStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, () => initialState)
      .addCase(listHealthSystems.fulfilled, (state, { payload }) => {
        state.systems = payload;
        state.system = {};
      })
      .addCase(getHealthSystemById.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.system = data;
      })
      .addCase(updateHealthSystem.fulfilled, (state) => {
        state.system = {};
      })
      .addCase(getFacilityById.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.facility = data;
      })
      .addCase(listFacilities.fulfilled, (state, { payload }) => {
        state.facilities = payload;
        state.facility = {};
      })
      .addCase(updateFacility.fulfilled, (state) => {
        state.facility = {};
      })
      .addCase(listNetworks.fulfilled, (state, { payload }) => {
        state.networks = payload;
      })
      .addCase(getNetwork.fulfilled, (state, { payload }) => {
        state.network = payload;
      })
      .addCase(listUsers.fulfilled, (state, { payload }) => {
        state.users = payload;
      })
      .addCase(getUserById.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(listConsults.fulfilled, (state, { payload }) => {
        state.consults = payload;
      })
      .addCase(fetchConsult.fulfilled, (state, { payload }) => {
        state.consult = payload;
      })
      .addCase(linkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          state.consult.integrationStatus = IntegrationStatusEnum.MATCHED;
        }
      })
      .addCase(unlinkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          state.consult.integrationStatus = IntegrationStatusEnum.UNMATCHED;
        }
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});
/* eslint-enable no-param-reassign */

const { reducer, actions } = adminStateSlice;

export { reducer as adminReducer };
export const adminActions = {
  ...actions,
  listHealthSystems,
  addHealthSystem,
  listNetworks,
  addNetwork,
  getNetwork,
  updateNetwork,
  getHealthSystemById,
  listUsers,
  addFacility,
  getFacilityById,
  updateFacility,
  listFacilities,
  updateHealthSystem,
  addUser,
  getUserById,
  updateUser,
  resetUserPassword,
  reInviteUser,
  listConsults,
  fetchConsult,
  updateAdminConsult,
  linkOrder,
  unlinkOrder,
};

export default adminStateSlice;
