import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';
import LockChart from 'features/consults/components/tabFields/LockChart';
import useSummaryTabState from 'features/consults/hooks/useSummaryTabState';
import useSummaryCopy from 'features/consults/hooks/useSummaryCopy';
import {
  consultCancelledValue,
  errorDuplicateValue,
} from 'features/consults/shared/tabs/Signature/formFields';
import { HiddenWrapper } from 'styles/layout';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';
import { getNamesWithTitle } from 'utils/helpers';

const ConsultLock = ({
  consult,
  consultMainType,
  attestationValue,
  disabled,
  checked,
  onConsultLock,
}) => {
  const [closedAt, setClosedAt] = useState(null);
  const {
    firstName,
    lastName,
    title: userTitle,
  } = useSelector(({ profile }) => profile.data);
  const { title, summarySections, facilityTimezone } = useSummaryTabState(
    consult,
    consultMainType,
  );
  const { summaryContainerRef } = useSummaryCopy();

  const dialogTitle = useMemo(() => {
    const warningText = [consultCancelledValue, errorDuplicateValue].includes(
      attestationValue,
    )
      ? 'Are you sure you would like to cancel this consult? This consult will be closed and the summary information will be discarded.'
      : 'Are you sure you would like to submit this consult?';

    return `${warningText} This cannot be undone.`;
  }, [attestationValue]);

  useEffect(() => {
    if (closedAt) {
      onConsultLock(
        summaryContainerRef.current.innerText,
        closedAt,
        attestationValue,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedAt]);

  return (
    <>
      <LockChart
        label="Lock Chart, esign, & fax"
        disabled={disabled}
        checked={checked}
        dialogTitle={dialogTitle}
        onChange={() => setClosedAt(formatDate(new Date(), TIMESTAMP_FORMAT))}
      />
      <HiddenWrapper>
        <SummaryNote
          ref={summaryContainerRef}
          title={title}
          summarySections={summarySections}
          facilityTimezone={facilityTimezone}
          isConsultClosed
          closedAt={closedAt}
          closedByName={getNamesWithTitle(firstName, lastName, userTitle)}
        />
      </HiddenWrapper>
    </>
  );
};

ConsultLock.propTypes = {
  consult: PropTypes.shape({}).isRequired,
  consultMainType: PropTypes.string.isRequired,
  attestationValue: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onConsultLock: PropTypes.func.isRequired,
};

export default ConsultLock;
