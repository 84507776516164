import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import InputField from 'shared/components/forms/InputField/InputField';

const PasswordField = ({
  inputRef,
  name = 'password',
  label = 'Password',
  autoComplete = 'on',
  error = null,
  disabled = false,
  required = false,
  fullWidth = true,
  helperText = null,
  ...props
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordField = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const renderPasswordIcon = () => (
    <InputAdornment position="end">
      <IconButton edge="end" disabled={disabled} onClick={togglePasswordField}>
        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <InputField
      inputRef={inputRef}
      name={name}
      id={name}
      label={label}
      type={isPasswordVisible ? 'text' : 'password'}
      autoComplete={autoComplete}
      endAdornment={renderPasswordIcon()}
      error={error}
      disabled={disabled}
      required={required}
      fullWidth={fullWidth}
      helperText={helperText}
      {...props}
    />
  );
};

PasswordField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
};

export default PasswordField;
