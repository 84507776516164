import * as yup from 'yup';

export default yup.object().shape({
  query: yup.string(),
  role: yup.string(),
  healthSystem: yup.object().shape({
    id: yup.number(),
    name: yup.string(),
  }),
  facility: yup.object().shape({
    id: yup.number(),
    name: yup.string(),
  }),
});
