import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import sortFields from 'api/sortFields';

import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { useTableState } from 'shared/hooks/useTableState';
import { getTableHeaders, prepareTableData } from 'utils/tables';

import { adminActions } from 'features/admin/store/slice';

const facilityTableColumns = [
  {
    header: {
      id: sortFields.facilities.name,
      value: 'Facility Name',
      isSortable: true,
    },
    dataKey: 'name',
    width: '15%',
  },
  {
    header: {
      id: sortFields.facilities.neuroNetwork,
      value: 'Neuro Network',
      isSortable: true,
    },
    dataKey: 'neuroNetworkName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.facilities.eegNetwork,
      value: 'EEG Network',
      isSortable: true,
    },
    dataKey: 'eegNetworkName',
    width: '15%',
  },
  {
    header: {
      id: sortFields.facilities.city,
      value: 'City',
      isSortable: true,
    },
    dataKey: 'locality',
  },
  {
    header: {
      id: sortFields.facilities.state,
      value: 'State',
      isSortable: true,
    },
    dataKey: 'region',
    width: '80px',
  },
  {
    header: {
      id: sortFields.facilities.emrName,
      value: 'EMR',
      isSortable: true,
    },
    dataKey: 'emrName',
    align: 'center',
  },
];

const FacilitiesTable = ({ networkId, healthSystemId, systemPath }) => {
  const itemId = healthSystemId || networkId;
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: facilities, pageCount } = useSelector(
    ({ admin }) => admin.facilities,
  );
  const { page, sortBy, sortOrder, handlePageChange, handleSortChange } =
    useTableState(sortFields.facilities.name);

  useEffect(() => {
    dispatch(
      adminActions.listFacilities({
        page,
        sortBy,
        sortOrder,
        filters: { networkId, healthSystemIds: healthSystemId },
      }),
    );
    history.replace(
      `${systemPath}/${itemId}?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortOrder]);

  const navigateToFacilityDetailsPage = (id) => {
    history.push(`${systemPath}/${itemId}/facility/${id}`);
  };

  return (
    <PaginatedTable
      headings={getTableHeaders(facilityTableColumns)}
      data={prepareTableData(facilities, facilityTableColumns)}
      fallbackMsg="No Facilities found"
      sortBy={sortBy}
      sortOrder={sortOrder}
      currentPage={page}
      totalPages={pageCount}
      onPageChange={handlePageChange}
      onSortChange={handleSortChange}
      onClickRow={navigateToFacilityDetailsPage}
    />
  );
};

FacilitiesTable.propTypes = {
  networkId: PropTypes.string,
  healthSystemId: PropTypes.string,
  systemPath: PropTypes.string.isRequired,
};

export default FacilitiesTable;
