import React from 'react';
import PropTypes from 'prop-types';

import LinkIcon from '@material-ui/icons/Link';

import { IntegrationStatusEnum } from 'features/consults/utils/enums';
import colors from 'styles/colors';

const IntegrationIcon = ({ integrationStatus }) =>
  !integrationStatus ||
  integrationStatus === IntegrationStatusEnum.NON_INTEGRATED ? null : (
    <LinkIcon
      style={{
        color:
          integrationStatus === IntegrationStatusEnum.MATCHED
            ? colors.success
            : colors.neutralDark,
      }}
    />
  );

IntegrationIcon.propTypes = {
  integrationStatus: PropTypes.string,
};

export default IntegrationIcon;
