import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { adminActions } from 'features/admin/store/slice';
import NetworkForm from 'features/admin/components/NetworkForm';
import AdminPaths from 'features/admin/paths';
import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';

const NetworkAddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.NETWORK_ADDED, () => {
    dispatch(appStateActions.hideSnackbar());
    history.replace(`${AdminPaths.Networks}/${successAction.payload.id}`, {
      navigateOnBack: AdminPaths.Networks,
    });
  });

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>New Network</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <NetworkForm
          onSubmit={(values) => dispatch(adminActions.addNetwork(values))}
          onCancel={() => history.goBack()}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default NetworkAddPage;
