import * as yup from 'yup';

import demographicsFields, {
  eegDemographicsFields,
  eEGTypeOptions,
} from 'features/consults/shared/tabs/Demographics/formFields';
import signatureFields from 'features/consults/shared/tabs/Signature/formFields';
import { NeuroConsultTypes } from 'features/consults/utils/constants';

export const neuroValidations = yup.object().shape({
  [demographicsFields.consultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      NeuroConsultTypes.map(({ value }) => value),
      'Required',
    ),
  [demographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [signatureFields.attestationOfConsultCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});

export const eegValidations = yup.object().shape({
  [eegDemographicsFields.consultType.name]: yup
    .string()
    .required('Required')
    .oneOf(
      eEGTypeOptions.map(({ value }) => value),
      'Required',
    ),
  [eegDemographicsFields.facility.name]: yup
    .string()
    .nullable()
    .required('Required'),
  [signatureFields.attestationOfEEGCompletion.name]: yup
    .string()
    .nullable()
    .required('Required'),
});
