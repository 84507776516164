import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const buildArray = (currentValue, newValue) => {
  if (Array.isArray(currentValue)) return [...currentValue, newValue];
  return [currentValue, newValue];
};

// eslint-disable-next-line import/prefer-default-export
export const useQueryParams = () => {
  const { search } = useLocation();

  // Extract all query parameter key-value pairs from the URL
  // and save them as an object
  const queryParams = useMemo(() => {
    let queryString = search;

    if (queryString.startsWith('?')) {
      queryString = queryString.substr(1);
    }

    return queryString.split('&').reduce((acc, kvp) => {
      const [key, value] = kvp.split('=');
      acc[key] = acc[key]
        ? buildArray(acc[key], value)
        : decodeURIComponent(value);

      return acc;
    }, {});
  }, [search]);

  return {
    queryString: search,
    queryParams,
  };
};
