import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

const frequencyOptions = [
  { value: 'BetaGreaterThan15Hz', label: 'beta (>15 Hz)' },
  {
    value: 'PosteriorDominantAlpha8_12Hz',
    label: 'posterior dominant alpha (8-12 Hz)',
  },
  {
    value: 'NonPosteriorDominantAlpha8_12Hz',
    label: 'non-posterior dominant alpha (8-12 Hz)',
  },
  { value: 'Theta4_7Hz', label: 'theta (4-7 Hz)' },
  { value: 'Delta2_3Hz', label: 'delta (2-3 Hz)' },
];

const frequencyDurationOptions = [
  { value: 'ContinuousGreaterThan90Percents', label: 'continuous (>90%)' },
  { value: 'Abundant50_89Percents', label: 'abundant (50-89%)' },
  { value: 'Frequent10_49Percents', label: 'frequent (10-49%)' },
  { value: 'Occasional1_9Percents', label: 'occasional (1-9%)' },
  { value: 'RareLessThan1Percents', label: 'rare (<1%)' },
];

export const impressionsSubtypeOptions = [
  {
    value: 'Beta',
    label: 'Excess Beta',
    description:
      'Excess beta is a non-specific finding but may be seen in the setting of Benzodiazepine or Barbiturate use',
  },
  {
    value: 'Breach',
    label: 'Breach',
    description:
      'Breach rhythm is expected, and considered normal, in the setting of a prior neurological procedure or skull defect.',
  },
  {
    value: 'BurstSuppression',
    label: 'Burst Suppression',
    description:
      'Burst suppression is a non-specific finding, which is associated with diffuse cerebral dysfunction. This can be seen in the setting of heavily sedating medication use, hypothermia, or following anoxic brain injury.',
  },
  {
    value: 'DiffuseSlowing',
    label: 'Diffuse Slowing',
    description:
      'Diffuse slowing is non-specific and may be seen in the setting of diffuse cerebral dysfunction; such as toxic/metabolic/infectious encephalopathy or heavily sedating medication use.',
  },
  {
    value: 'DiffuseSuppression',
    label: 'Diffuse Suppression',
    description:
      'Diffuse suppression is non-specific but is evidence of diffuse cerebral dysfunction and may be seen in the setting of diffuse anoxic brain injury, or heavily sedating medication use.',
  },
  {
    value: 'FocalAttenuation',
    label: 'Focal Attenuation',
    description:
      'Focal attenuation is a non-specific finding which may be seen in the setting of increased distance from scalp electrode to cortex. This can occur with scalp hematoma, subdural hemorrhages, or space occupying lesions.',
  },
  {
    value: 'FocalSharp',
    label: 'Focal Sharp',
    description:
      'Focal sharps may be evidence of an increased tendency for seizure arising from the *** region.',
  },
  {
    value: 'FocalSlowing',
    label: 'Focal Slowing',
    description:
      'Focal slowing may be evidence of a focal cerebral disturbance as seen in focal structural lesions, ischemia, and post-ictal slowing.',
  },
  {
    value: 'LPDs',
    label: 'LPDs',
    description:
      'Lateralized periodic discharge (LPDs) lie on the ictal-interictal continuum. LPDs are baseline to the ictal side of the continuum and are therefore associated with an increased risk of seizure.',
  },
  {
    value: 'LRDA',
    label: 'LRDA',
    description:
      'Lateralized rhythmic delta activity (LRDA). LRDA lies closer to the ictal side of the continuum and carries an increased risk of seizure.',
  },
  {
    value: 'GPDs',
    label: 'GPDs',
    description:
      'Generalized periodic discharges (GPDs) lie on the ictal-interictal continuum. This periodic pattern lies closer toward the interictal end of the continuum.  This is non-specific and may be an indication of diffuse cerebral dysfunction; as seen in encephalopathies.',
  },
  {
    value: 'GRDA',
    label: 'GRDA',
    description:
      'Generalized rhythmic delta activity (GRDA) lies on the ictal-interictal continuum. Given the lack of evolution or associated findings (such as fast or sharp activity) this pattern likely represents an interictal finding. This is non-specific and may be an indication of diffuse cerebral dysfunction; as seen in encephalopathies.',
  },
  {
    value: 'TIRDA',
    label: 'TIRDA',
    description:
      'Temporal intermittent rhythmic delta activity (TIRDA) lies on the ictal-interictal continuum. TIRDA lies closer to the ictal side of the continuum and is therefore associated with an increased risk of seizure.',
  },
  {
    value: 'Triphasics',
    label: 'Triphasics',
    addLabel: 'GPDs with triphasic like morphology.',
    description:
      'Generalized periodic discharges (GPDs) with triphasic like morphology lie on the ictal-interictal continuum. This periodic pattern lies closer toward the interictal end of the continuum. These are non-specific and may be seen in the setting of encephalopathy or with diffuse cerebral dysfunction.',
  },
];

export const impressionValues = {
  normal: 'Normal',
  abnormal: 'Abnormal',
};

export const impressionTextValue = (value) =>
  value === impressionValues.normal
    ? 'A normal EEG does not exclude nor support the diagnosis of epilepsy.'
    : '';

export const subImpressionDetails = (value) =>
  impressionsSubtypeOptions.find((el) => el.value === value);

const eegFields = {
  /*
  timeOfEEGHookUpAt: {
    name: 'timeOfEEGHookUpAt',
    label: 'Time of EEG Hook Up',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  */
  startTimeOfEEGReadAt: {
    name: 'startTimeOfEEGReadAt',
    label: 'Start Time of EEG Read',
    type: ConsultFieldTypeEnum.DATETIME,
    required: true,
  },
  stopTimeOfEEGReadAt: {
    name: 'stopTimeOfEEGReadAt',
    label: 'Stop Time of EEG Read',
    type: ConsultFieldTypeEnum.DATETIME,
    required: true,
  },
  duration: {
    name: 'duration',
    label: 'Duration',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
    disabled: true,
  },
  technicalDetailsPredefined: {
    name: 'technicalDetailsPredefined',
    label: 'Technical Details',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'StandardProtocol',
        label: 'standard protocol',
        summaryLabel:
          'The EEG electrodes were placed using the standard International 10-20 system of electrode placement. Video and an accessory EKG lead were used during the course of this study.',
      },
      {
        value: 'StandardProtocolNoVideo',
        label: 'standard protocol no video',
        summaryLabel:
          'The EEG electrodes were placed using the standard International 10-20 system of electrode placement. An accessory EKG lead was used during the course of this study.',
      },
      {
        value: 'SoftwareNihonKohden',
        label: 'software: Nihon Kohden',
        summaryLabel:
          'This study was recorded using the Nihon Kohden EEG software',
      },
      {
        value: 'SoftwareProfusion',
        label: 'software: Profusion',
        summaryLabel:
          'This study was recorded using the Profusion EEG software',
      },
      {
        value: 'SoftwareNatus',
        label: 'software: Natus',
        summaryLabel: 'This study was recorded using the Natus EEG software',
      },
      {
        value: 'SoftwareOther',
        label: 'software: other',
        summaryLabel: 'This study was recorded using EEG software',
      },
      {
        value: 'Ceribell',
        label: 'Ceribell',
        summaryLabel:
          'Limited Montage; 10 channel EEG on Ceribell device. Electrodes include: Fp1, F7, T3, T5, O1, Fp2, F8, T4, T6, and O2. Reformatted in a single bipolar montage over the temporal regions',
      },
    ],
  },
  technicalDetailsFreeText: {
    name: 'technicalDetailsFreeText',
    associatedField: 'technicalDetailsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  indicationPredefined: {
    name: 'indicationPredefined',
    label: 'Indication',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'AlteredMentalStatus', label: 'altered mental status' },
      {
        value: 'ConcernForStatusEpilepticus',
        label: 'status epilepticus',
        summaryLabel: 'concern for status epilepticus',
      },
      { value: 'AbnormalMovements', label: 'abnormal movements' },
      { value: 'Seizure', label: 'seizure' },
    ],
  },
  indicationFreeText: {
    name: 'indicationFreeText',
    associatedField: 'indicationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  additionalPatientHistory: {
    name: 'additionalPatientHistory',
    label: 'Additional patient history',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  photicStimulationPredefined: {
    name: 'photicStimulationPredefined',
    title: 'Photic Stimulation',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Performed', label: 'performed', summaryLabel: 'Performed' },
      { value: 'NotPerformed', label: 'NOT Performed' },
    ],
  },
  photicStimulationFreeText: {
    name: 'photicStimulationFreeText',
    associatedField: 'photicStimulationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  hyperventilationPredefined: {
    name: 'hyperventilationPredefined',
    title: 'Hyperventilation',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Performed', label: 'performed' },
      { value: 'NotPerformed', label: 'NOT performed' },
    ],
  },
  hyperventilationFreeText: {
    name: 'hyperventilationFreeText',
    associatedField: 'hyperventilationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  phasesCapturedPredefined: {
    name: 'phasesCapturedPredefined',
    label: 'Phases Captured',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Awake', label: 'awake' },
      { value: 'Drowsy', label: 'drowsy' },
      { value: 'Sleep', label: 'sleep' },
      { value: 'Unresponsive', label: 'unresponsive' },
    ],
  },
  phasesCapturedFreeText: {
    name: 'phasesCapturedFreeText',
    associatedField: 'phasesCapturedPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  symmetryPredefined: {
    name: 'symmetryPredefined',
    title: 'Symmetry',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Symmetric', label: 'symmetric' },
      {
        value: 'AsymmetricGreaterThan50PercentOfRecording',
        label: 'asymmetric > 50 % of recording',
      },
    ],
  },
  symmetryFreeText: {
    name: 'symmetryFreeText',
    associatedField: 'symmetryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  posteriorDominantRhythmPredefined: {
    name: 'posteriorDominantRhythmPredefined',
    title: 'Posterior Dominant Rhythm',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'PresentAttenuatesOnEyeOpening',
        label: 'present, attenuates on eye opening (specify frequency below)',
        summaryLabel: 'present, attenuates on eye opening',
      },
      { value: 'PoorlyDefined', label: 'poorly defined' },
      { value: 'Absent', label: 'absent' },
    ],
  },
  posteriorDominantRhythmFreeText: {
    name: 'posteriorDominantRhythmFreeText',
    associatedField: 'posteriorDominantRhythmPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  predominantFrequenciesFrequency: {
    name: 'predominantFrequenciesFrequency',
    title: 'Predominant Frequency',
    summaryLabel: 'Predominant Frequencies',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: frequencyOptions,
  },
  predominantFrequenciesFrequencyDuration: {
    name: 'predominantFrequenciesFrequencyDuration',
    associatedField: 'predominantFrequenciesFrequency',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: frequencyDurationOptions,
  },
  predominantFrequenciesFreeText: {
    name: 'predominantFrequenciesFreeText',
    associatedField: 'predominantFrequenciesFrequency',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  superimposedFrequenciesFrequency: {
    name: 'superimposedFrequenciesFrequency',
    title: 'Superimposed Frequency',
    summaryLabel: 'Superimposed Frequencies',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: frequencyOptions,
  },
  superimposedFrequenciesFrequencyDuration: {
    name: 'superimposedFrequenciesFrequencyDuration',
    associatedField: 'superimposedFrequenciesFrequency',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: frequencyDurationOptions,
  },
  superimposedFrequenciesFreeText: {
    name: 'superimposedFrequenciesFreeText',
    associatedField: 'superimposedFrequenciesFrequency',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  amplitudePredefined: {
    name: 'amplitudePredefined',
    title: 'Amplitude',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Normal', label: 'normal' },
      { value: 'Low', label: 'low' },
      { value: 'Suppressed', label: 'suppressed' },
    ],
  },
  amplitudeFreeText: {
    name: 'amplitudeFreeText',
    associatedField: 'amplitudePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  reactivityPredefined: {
    name: 'reactivityPredefined',
    title: 'Reactivity',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Yes', label: 'yes' },
      { value: 'No', label: 'no' },
      { value: 'Unclear', label: 'unclear' },
    ],
  },
  reactivityFreeText: {
    name: 'reactivityFreeText',
    associatedField: 'reactivityPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  variabilityPredefined: {
    name: 'variabilityPredefined',
    title: 'Variability',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Yes', label: 'yes' },
      { value: 'No', label: 'no' },
      { value: 'Unclear', label: 'unclear' },
    ],
  },
  variabilityFreeText: {
    name: 'variabilityFreeText',
    associatedField: 'variabilityPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  continuityPredefined: {
    name: 'continuityPredefined',
    title: 'Continuity',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Continuous', label: 'continuous' },
      { value: 'BriefAttenuation', label: 'brief attenuation' },
      { value: 'BurstSuppression', label: 'burst suppression' },
      { value: 'Suppressed', label: 'suppressed' },
    ],
  },
  continuityFreeText: {
    name: 'continuityFreeText',
    associatedField: 'continuityPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  ekgPredefined: {
    name: 'ekgPredefined',
    title: 'EKG',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'NSR', label: 'NSR' },
      { value: 'AtrialFibrillaiton', label: 'atrial fibrillation' },
      { value: 'Paced', label: 'paced' },
      { value: 'OccasionalPVCs', label: 'occasional PVCs' },
      { value: 'Irregular', label: 'irregular' },
      { value: 'Artificial', label: 'artifactual' },
    ],
  },
  ekgFreeText: {
    name: 'ekgFreeText',
    associatedField: 'ekgPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  stimulationPredefined: {
    name: 'stimulationPredefined',
    label: 'Stimulation',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'PhoticStimulationCausesAbnormalities',
        label: 'photic stimulation causes abnormalities',
      },
      {
        value: 'PhoticStimulationDoesNotCauseAbnormalities',
        label: 'photic stimulation does NOT cause abnormalities',
      },
      {
        value: 'HyperventilationCausesAbnormalities',
        label: 'hyperventilation causes abnormalities',
      },
      {
        value: 'HyperventilationDoesNotCauseAbnormalities',
        label: 'hyperventilation does NOT cause abnormalities',
      },
    ],
  },
  stimulationFreeText: {
    name: 'stimulationFreeText',
    associatedField: 'stimulationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  epileptiformAbnormalitiesPredefined: {
    name: 'epileptiformAbnormalitiesPredefined',
    label: 'Epileptiform Abnormalities',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NotPresent', label: 'NOT present' },
      { value: 'Present', label: 'present' },
      { value: 'Spikes', label: 'spikes' },
      { value: 'SharpWaves', label: 'sharp waves' },
    ],
  },
  epileptiformAbnormalitiesFreeText: {
    name: 'epileptiformAbnormalitiesFreeText',
    associatedField: 'epileptiformAbnormalitiesPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  focalSlowingPredefined: {
    name: 'focalSlowingPredefined',
    title: 'Focal Slowing',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Yes', label: 'yes' },
      { value: 'No', label: 'no' },
    ],
  },
  focalSlowingFreeText: {
    name: 'focalSlowingFreeText',
    associatedField: 'focalSlowingPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  seizurePredefined: {
    name: 'seizurePredefined',
    label: 'Seizure',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NotPresent', label: 'NOT present' },
      { value: 'Present', label: 'present' },
      { value: 'Generalized', label: 'generalized' },
      { value: 'Absence', label: 'absence' },
      { value: 'Myoclonic', label: 'myoclonic' },
      { value: 'Clonic', label: 'clonic' },
      { value: 'Tonic', label: 'tonic' },
      { value: 'Atonic', label: 'atonic' },
      { value: 'PostIctal', label: 'post-ictal' },
    ],
  },
  seizureFreeText: {
    name: 'seizureFreeText',
    associatedField: 'seizurePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  artifact: {
    name: 'artifact',
    label: 'Artifact',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  impressionsPredefined: {
    name: 'impressionsPredefined',
    title: 'Impression',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: impressionValues.normal, label: 'normal' },
      { value: impressionValues.abnormal, label: 'abnormal' },
    ],
  },
  impressionsSubtypePredefined: {
    name: 'impressionsSubtypePredefined',
    type: ConsultFieldTypeEnum.CHIP,
    options: impressionsSubtypeOptions,
    excludeFromSummary: true,
  },
  abnormalityOptionsFreeText: {
    name: 'abnormalityOptionsFreeText',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
  },
  impressionsClinicalCorrelationFreeText: {
    name: 'impressionsClinicalCorrelationFreeText',
    label: 'Clinical Correlation',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
  },
  additionalComments: {
    name: 'additionalComments',
    label: 'Additional Comments',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 3,
  },
};

export default eegFields;
