import { isEmptyValue } from 'features/consults/utils/validators';
import { PAGINATION_PAGE_SIZE } from 'utils/constants';

export const buildFilterQueryString = (filters) =>
  filters
    ? Object.keys(filters).reduce((res, key) => {
        const filterValue = filters[key];

        if (filterValue) {
          if (Array.isArray(filterValue)) {
            return filterValue.reduce(
              (acc, val) => `${acc}&${key}=${encodeURIComponent(val)}`,
              res,
            );
          }

          return `${res}&${key}=${encodeURIComponent(filterValue)}`;
        }

        return res;
      }, '')
    : '';

export const buildQueryString = ({
  page = 1,
  sortBy,
  sortOrder = 'asc',
  pageSize = PAGINATION_PAGE_SIZE,
  filters,
}) => {
  const pageIndex = page - 1;

  return `?PageIndex=${pageIndex}&OrderBy=${sortBy}&OrderDirection=${sortOrder}&PageSize=${pageSize}${buildFilterQueryString(
    filters,
  )}`;
};

const getPatchValue = (path, value) => {
  if (isEmptyValue(value)) {
    return {
      op: 'remove',
      path,
    };
  }
  return {
    op: 'replace',
    path,
    value,
  };
};

export const mapConsultFullChange = (values) =>
  Object.entries(values).map(([key, { tab, value }]) =>
    getPatchValue(`/${tab}/${key}`, value),
  );

export const mapConsultChanges = (tab, changes) =>
  Object.keys(changes).map((key) => {
    const path = `/${tab}/${key}`;
    const value = changes[key];
    return getPatchValue(path, value);
  });
