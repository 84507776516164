import React from 'react';
import { Grid } from '@material-ui/core';

import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useFormState from 'shared/hooks/useFormState';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { getInitialValueByFieldType } from 'features/consults/utils/formFields';
import { thrombolyticFieldName } from 'features/consults/shared/tabs/Plan/formFields';
import schema from './validationSchema';
import {
  thrombolyticSections,
  bloodPressurePanels,
  rightPanels,
  thrombolyticPanel,
} from './data';

const Plan = () => {
  const { control, register, getFieldError, getValues, setFormValues, watch } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    saveConsultChange,
    handleFieldChange,
  } = useConsultData(CONSULT_TABS.PLAN, getValues);

  const currentThrombolyticValue = watch(thrombolyticFieldName);
  const thrombolyticSection = thrombolyticSections[currentThrombolyticValue];

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  const handleThrombolyticChange = () => {
    const newThrombolyticValue = getValues(thrombolyticFieldName);

    // If no section was previously visible, do NOT reset anything
    if (!thrombolyticSection) {
      saveConsultChange({
        [thrombolyticFieldName]: newThrombolyticValue,
      });

      return;
    }

    // Get all fields from the previously visible section
    // and reset them to their initial values based on type
    const fieldsToReset = thrombolyticSection.flatMap((section) =>
      section.elements.map(({ name, type }) => ({ name, type })),
    );
    const resetValues = fieldsToReset.reduce((acc, field) => {
      acc[field.name] = getInitialValueByFieldType(field.type);

      return acc;
    }, {});

    saveConsultChange({
      [thrombolyticFieldName]: newThrombolyticValue,
      ...resetValues,
    });

    // Reset the form values of the section fields
    setFormValues({ ...getValues(), ...resetValues });
  };

  return (
    <GridWrapper>
      <Grid item sm={12} lg={6}>
        <QuestionsPanel
          control={control}
          register={register}
          onChange={handleThrombolyticChange}
          getFieldError={getFieldError}
          disabled={!isEditable}
          {...thrombolyticPanel}
        />
        {thrombolyticSection?.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            control={control}
            register={register}
            onChange={handleFieldChange}
            getFieldError={getFieldError}
            disabled={!isEditable}
          />
        ))}
        {bloodPressurePanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            control={control}
            register={register}
            onChange={handleFieldChange}
            getFieldError={getFieldError}
            disabled={!isEditable}
          />
        ))}
      </Grid>
      <Grid item sm={12} lg={6}>
        {rightPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            control={control}
            register={register}
            onChange={handleFieldChange}
            getFieldError={getFieldError}
            disabled={!isEditable}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default Plan;
