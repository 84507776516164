import {
  ConsultTypeEnum,
  FilterTypePropNames,
  ConsultStates,
} from 'features/consults/utils/enums';
import {
  NeuroConsultTypes,
  ConsultIntegrationOptions,
} from 'features/consults/utils/constants';
import {
  patientLocationOptions,
  eEGTypeOptions,
  observationOptions,
} from 'features/consults/shared/tabs/Demographics/formFields';
import {
  attestationOfNeuroOptions,
  attestationOfEEGOptions,
} from 'features/consults/shared/tabs/Signature/formFields';
import { thrombolyticOptions } from 'features/consults/shared/tabs/Plan/formFields';
import { mapStringIdToNumber, mapOptionsToSelect } from 'utils/mappers';

export const consultTypes = [
  { text: 'Neuro', value: ConsultTypeEnum.NEURO },
  { text: 'Clinic', value: ConsultTypeEnum.CLINIC },
  { text: 'EEG', value: ConsultTypeEnum.EEG },
];

const statusOptions = Object.values(ConsultStates).reduce(
  (res, value) => [...res, { text: value, value }],
  [],
);

export const mapAdminConsultFilters = ({
  query,
  assignToUserId,
  networkId,
  healthcareSystemId,
  facilityId,
  ...otherFilters
}) => ({
  ...otherFilters,
  query: query || '',
  assignToUserId: mapStringIdToNumber(assignToUserId),
  networkId: mapStringIdToNumber(networkId),
  healthcareSystemId: mapStringIdToNumber(healthcareSystemId),
  facilityId: mapStringIdToNumber(facilityId),
});

export const filterTypes = {
  DATETIME: 'datetime',
  SELECT: 'select',
  PROVIDER: 'provider',
  HEALTHSYSTEM: 'healthSystem',
  FACILITY: 'facility',
  NETWORK: 'network',
  FILTERED_SELECT: 'filterSelect',
  MULTISELECT: 'multiselect',
};

const yesNoOptions = [
  { value: 'true', text: 'Yes' },
  { value: 'false', text: 'No' },
];

const fromCreatedAtFilter = {
  filterType: filterTypes.DATETIME,
  name: 'fromCreatedAt',
  placeholder: 'From',
};

const toCreatedAtFilter = {
  filterType: filterTypes.DATETIME,
  name: 'toCreatedAt',
  placeholder: 'To',
};

const neuroConsultTypeFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'neuroConsultTypes',
  placeholder: 'Consult Type',
  options: mapOptionsToSelect(NeuroConsultTypes),
};

const neuroPatientLocationOptions = patientLocationOptions.filter(
  (option) => !option[FilterTypePropNames.EEG],
);

const patientLocationTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'patientLocationType',
  placeholder: 'Patient Location',
  options: mapOptionsToSelect(neuroPatientLocationOptions),
};

const withQualityFlagSelectedFilter = {
  filterType: filterTypes.SELECT,
  name: 'withQualityFlagSelected',
  placeholder: 'Quality Flag',
  options: yesNoOptions,
};

const providerFilter = {
  filterType: filterTypes.PROVIDER,
  name: 'assignToUserId',
  placeholder: 'Neurologist',
};

const healhSystemFilter = {
  filterType: filterTypes.HEALTHSYSTEM,
  name: 'healthcareSystemId',
  placeholder: 'Healthcare System',
};

const facilityFilter = {
  filterType: filterTypes.FACILITY,
  name: 'facilityId',
  placeholder: 'Facility',
  healthSystemKey: 'healthcareSystemId',
};

const networkFilter = {
  filterType: filterTypes.NETWORK,
  name: 'networkId',
  placeholder: 'Network',
};

const attestationNeuroFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'neuroAttestationOfConsultCompletionTypes',
  placeholder: 'Attestation',
  options: mapOptionsToSelect(attestationOfNeuroOptions),
};

const thrombolyticInterventionFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'planThrombolyticInterventionTypes',
  placeholder: 'Thrombolytic / Intervention',
  options: mapOptionsToSelect(thrombolyticOptions),
};

const eEGTypeFilter = {
  filterType: filterTypes.SELECT,
  name: 'eegConsultType',
  placeholder: 'EEG Type',
  options: mapOptionsToSelect(eEGTypeOptions),
};

const eEGReadConsultObservationFilter = {
  filterType: filterTypes.SELECT,
  name: 'eegReadConsultObservationType',
  placeholder: 'cEEG Type',
  options: mapOptionsToSelect(observationOptions),
};

const attestationEEGFilter = {
  filterType: filterTypes.MULTISELECT,
  name: 'eegAttestationOfEegCompletionTypes',
  placeholder: 'Attestation',
  options: mapOptionsToSelect(attestationOfEEGOptions),
};

const withEegStatField = {
  filterType: filterTypes.SELECT,
  name: 'withEegStatOptionsTypeSelected',
  placeholder: 'Stat Read',
  options: yesNoOptions,
};

const statusFilter = {
  filterType: filterTypes.SELECT,
  name: 'state',
  placeholder: 'Status',
  options: statusOptions,
};

const integrationStatusFilter = {
  filterType: filterTypes.SELECT,
  name: 'integrationStatusList',
  placeholder: 'Integration Status',
  options: ConsultIntegrationOptions,
};

const filterData = {
  [ConsultTypeEnum.NEURO]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    neuroConsultTypeFilter,
    patientLocationTypeFilter,
    withQualityFlagSelectedFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    attestationNeuroFilter,
    thrombolyticInterventionFilter,
    statusFilter,
    integrationStatusFilter,
  ],
  [ConsultTypeEnum.CLINIC]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    providerFilter,
    statusFilter,
  ],
  [ConsultTypeEnum.EEG]: [
    fromCreatedAtFilter,
    toCreatedAtFilter,
    eEGTypeFilter,
    eEGReadConsultObservationFilter,
    providerFilter,
    healhSystemFilter,
    facilityFilter,
    networkFilter,
    attestationEEGFilter,
    withEegStatField,
    statusFilter,
    integrationStatusFilter,
  ],
};

export const serviceFilters = (service) => filterData[service];
