import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { impressionValues } from './formFields';

export const leftPanels = [
  // { elements: [fields.timeOfEEGHookUpAt] },
  { elements: [fields.startTimeOfEEGReadAt] },
  { elements: [fields.stopTimeOfEEGReadAt] },
  { elements: [fields.duration] },
  {
    elements: [
      mapChipFieldOptions(fields.technicalDetailsPredefined),
      fields.technicalDetailsFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.indicationPredefined),
      fields.indicationFreeText,
    ],
  },
  {
    elements: [fields.additionalPatientHistory],
    mb: 3,
  },
  {
    title: 'Description',
    elements: [
      mapToggleFieldOptions(fields.photicStimulationPredefined),
      fields.photicStimulationFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.hyperventilationPredefined),
      fields.hyperventilationFreeText,
    ],
  },
];

export const centerPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.phasesCapturedPredefined),
      fields.phasesCapturedFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.symmetryPredefined),
      fields.symmetryFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.posteriorDominantRhythmPredefined),
      fields.posteriorDominantRhythmFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.predominantFrequenciesFrequency),
      mapToggleFieldOptions(fields.predominantFrequenciesFrequencyDuration),
      fields.predominantFrequenciesFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.superimposedFrequenciesFrequency),
      mapToggleFieldOptions(fields.superimposedFrequenciesFrequencyDuration),
      fields.superimposedFrequenciesFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.amplitudePredefined),
      fields.amplitudeFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.reactivityPredefined),
      fields.reactivityFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.variabilityPredefined),
      fields.variabilityFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.continuityPredefined),
      fields.continuityFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.ekgPredefined), fields.ekgFreeText],
  },
];

export const rightPanels = (impressionsValue) => [
  {
    title: 'Abnormalities',
    elements: [
      mapChipFieldOptions(fields.stimulationPredefined),
      fields.stimulationFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.epileptiformAbnormalitiesPredefined),
      fields.epileptiformAbnormalitiesFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.focalSlowingPredefined),
      fields.focalSlowingFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.seizurePredefined),
      fields.seizureFreeText,
    ],
  },
  {
    elements: [fields.artifact],
    mb: 3,
  },
  {
    title: 'Impression',
    elements:
      impressionsValue === impressionValues.abnormal
        ? [
            mapToggleFieldOptions(fields.impressionsPredefined),
            mapChipFieldOptions(fields.impressionsSubtypePredefined),
            fields.abnormalityOptionsFreeText,
          ]
        : [mapToggleFieldOptions(fields.impressionsPredefined)],
    mb: 3,
  },
  {
    title: 'Clinical Correlation',
    elements: [fields.impressionsClinicalCorrelationFreeText],
  },
  {
    elements: [fields.additionalComments],
  },
];
