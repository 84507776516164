import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';
import { generateGroupFields } from 'features/consults/utils/formFields';

const lateralityOptions = [
  { value: 'Right', label: 'right' },
  { value: 'Left', label: 'left' },
  { value: 'Bilateral', label: 'bilateral' },
];

const conductionOptions = [
  {
    value: 'Normal',
    label: 'CONDUCTION VELOCITY  nl',
    summaryLabel: 'conduction velocity was normal',
  },
  {
    value: 'BorderlineSlowed',
    label: 'borderline slow',
    summaryLabel: 'conduction velocity was borderline slowed',
  },
  {
    value: 'MildlySlowed',
    label: 'mild slow',
    summaryLabel: 'conduction velocity was mildly slowed',
  },
  {
    value: 'ModeratelySlowed',
    label: 'mod slow',
    summaryLabel: 'conduction velocity was moderately slowed',
  },
  {
    value: 'SeverelySlowed',
    label: 'sev slow',
    summaryLabel: 'conduction velocity was severely slowed',
  },
];

export const motorGroupFields = (index) => {
  const motorNerveName = `motorNerve${index}`;
  const motorLateralityName = `motorLaterality${index}`;
  const motorNormalName = `motorAllNormalPredefined${index}`;
  const motorAmplitudeName = `motorAmplitude${index}`;
  const motorLatencyName = `motorLatency${index}`;
  const motorVelocityName = `motorConductionVelocity${index}`;
  const motorWaveName = `motorFWave${index}`;
  const motorTextName = `motorFreeText${index}`;

  return {
    [motorNerveName]: {
      name: motorNerveName,
      title: 'Motor Location/Findings',
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        { value: 'Median', label: 'median' },
        { value: 'Ulnar', label: 'ulnar' },
        { value: 'Radial', label: 'radial' },
      ],
    },
    [motorLateralityName]: {
      name: motorLateralityName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: lateralityOptions,
    },
    [motorNormalName]: {
      name: motorNormalName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.CHIP,
      options: [
        {
          value: 'AmplitudeLatencyConductionVelocityFWaveAllNormal',
          label: 'ampl, latency, cond. vel., F-wave ALL nl',
          summaryLabel:
            'amplitude, latency, conduction velocity, F-wave all normal.',
        },
      ],
    },
    [motorAmplitudeName]: {
      name: motorAmplitudeName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        {
          value: 'Normal',
          label: 'AMPLITUDE nl',
          summaryLabel: 'amplitude was normal',
        },
        {
          value: 'MildlyDecreased',
          label: 'mild dec',
          summaryLabel: 'amplitude was mildly decreased',
        },
        {
          value: 'ModeratelyDecreased',
          label: 'mod dec',
          summaryLabel: 'amplitude was moderately decreased',
        },
        {
          value: 'SeverelyDecreased',
          label: 'sev dec',
          summaryLabel: 'amplitude was severely decreased',
        },
      ],
    },
    [motorLatencyName]: {
      name: motorLatencyName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        {
          value: 'Normal',
          label: 'LATENCY nl',
          summaryLabel: 'latency normal',
        },
        {
          value: 'BorderlineProlonged',
          label: 'borderline prolonged',
          summaryLabel: 'onset latency was borderline prolonged',
        },
        {
          value: 'MildlyProlonged',
          label: 'mild prolong',
          summaryLabel: 'onset latency was mildly prolonged',
        },
        {
          value: 'ModeratelyProlonged',
          label: 'mod prolong',
          summaryLabel: 'onset latency was moderately prolonged',
        },
        {
          value: 'SeverelyProlonged',
          label: 'sev prolong',
          summaryLabel: 'onset latency was severely prolonged',
        },
      ],
    },
    [motorVelocityName]: {
      name: motorVelocityName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: conductionOptions,
    },
    [motorWaveName]: {
      name: motorWaveName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        {
          value: 'Normal',
          label: 'F-WAVE nl',
          summaryLabel: 'F-wave was normal',
        },
        {
          value: 'BorderlineDelayed',
          label: 'borderline delay',
          summaryLabel: 'F-wave was borderline delayed',
        },
        {
          value: 'MildlyDelayed',
          label: 'mild delay',
          summaryLabel: 'F-wave was mildly delayed',
        },
        {
          value: 'ModeratelyDelayed',
          label: 'mod delay',
          summaryLabel: 'F-wave was moderately delayed',
        },
        {
          value: 'SeverelyDelayed',
          label: 'sev delay',
          summaryLabel: 'F-wave was severely delayed',
        },
      ],
    },
    [motorTextName]: {
      name: motorTextName,
      associatedField: motorNerveName,
      type: ConsultFieldTypeEnum.INPUT,
    },
  };
};

export const sensoryGroupFields = (index) => {
  const sensoryLocationName = `sensoryLocation${index}`;
  const sensoryLateralityName = `sensoryLaterality${index}`;
  const sensoryNormalName = `sensoryNormal${index}`;
  const sensoryVelocityName = `sensoryConductionVelocity${index}`;
  const sensoryLatencyName = `sensoryOnsetLatency${index}`;
  const sensoryTextName = `sensoryFreeText${index}`;

  return {
    [sensoryLocationName]: {
      name: sensoryLocationName,
      title: 'Sensory Location/Findings',
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        { value: 'Radial', label: 'radial' },
        { value: 'Median', label: 'median' },
        { value: 'Ulnar', label: 'ulnar' },
        { value: 'Sural', label: 'sural' },
        { value: 'SuperficialPeroneal', label: 'superficial peroneal' },
      ],
    },
    [sensoryLateralityName]: {
      name: sensoryLateralityName,
      associatedField: sensoryLocationName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: lateralityOptions,
    },
    [sensoryNormalName]: {
      name: sensoryNormalName,
      associatedField: sensoryLocationName,
      type: ConsultFieldTypeEnum.CHIP,
      options: [
        {
          value: 'ConductionVelocityAndOnsetLatencyNormal',
          label: 'cond vel, onset latency ALL nl',
          summaryLabel: 'conduction velocity and onset latency normal',
        },
      ],
    },
    [sensoryVelocityName]: {
      name: sensoryVelocityName,
      associatedField: sensoryLocationName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: conductionOptions,
    },
    [sensoryLatencyName]: {
      name: sensoryLatencyName,
      associatedField: sensoryLocationName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        {
          value: 'Normal',
          label: 'ONSET LATENCY nl',
          summaryLabel: 'onset latency normal',
        },
        {
          value: 'BorderlineProlonged',
          label: 'borderline prolong',
          summaryLabel: 'onset latency borderline prolonged',
        },
        {
          value: 'MildlyProlonged',
          label: 'mild prolong',
          summaryLabel: 'onset latency mildly prolonged',
        },
        {
          value: 'ModeratelyProlonged',
          label: 'mod prolong',
          summaryLabel: 'onset latency moderately prolonged',
        },
        {
          value: 'SeverelyProlonged',
          label: 'sev prolong',
          summaryLabel: 'onset latency severely prolonged',
        },
      ],
    },
    [sensoryTextName]: {
      name: sensoryTextName,
      associatedField: sensoryLocationName,
      type: ConsultFieldTypeEnum.INPUT,
    },
  };
};

const emgFields = {
  procedurePredefined: {
    name: 'procedurePredefined',
    label: 'EMG Procedure',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'StandardProtocol',
        label: 'standard protocol',
        summaryLabel:
          'Standard Protocol: Needle EMG was performed on select locations:',
      },
    ],
  },
  ...generateGroupFields(motorGroupFields),
  ...generateGroupFields(sensoryGroupFields),
  needleMyotomesPredefined: {
    name: 'needleMyotomesPredefined',
    title: 'Needle EMG',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'C5T1',
        label: 'C5-T1',
        summaryLabel: 'Needle EMG was performed in select muscles of C5-T1',
      },
      {
        value: 'L2S2',
        label: 'L2-S2',
        summaryLabel: 'Needle EMG was performed in select muscles of L2-S2',
      },
    ],
  },
  needleMyotomesFreeText: {
    name: 'needleMyotomesFreeText',
    associatedField: 'needleMyotomesPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  findingsSubheadPredefined: {
    name: 'findingsSubheadPredefined',
    summaryLabel: 'Needle EMG Findings',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'AllNormal',
        label: 'all nl',
        summaryLabel: 'all muscles studied were normal',
      },
      {
        value: 'AllOthersNormal',
        label: 'all others nl',
        summaryLabel: 'all remaining muscles studied were normal',
      },
    ],
  },
  findingsSubheadFreeText: {
    name: 'findingsSubheadFreeText',
    label: 'Needle EMG Findings',
    associatedField: 'findingsSubheadPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
  impressionLaterality: {
    name: 'impressionLaterality',
    title: 'Impression',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: lateralityOptions,
  },
  impressionPredefined: {
    name: 'impressionPredefined',
    associatedField: 'impressionLaterality',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'AxonalPredominantSensory',
        label: 'axonal-predominant sensory',
      },
      { value: 'AxonalPredominantMotor', label: 'axonal-predominant motor' },
      {
        value: 'CarpalTunnelSyndrome',
        label: 'CTS',
        summaryLabel: 'carpal tunnel syndrome',
      },
      { value: 'CervicalRadiculopathy', label: 'cervical radiculopathy' },
      { value: 'FocalNeuropathy', label: 'focal neuropathy' },
      {
        value: 'FocalNeuropathyAffecting',
        label: 'focal neuropathy affecting (specify below)',
        summaryLabel: 'focal neuropathy affecting',
      },
      { value: 'HereditaryNeuropathy', label: 'hereditary neuropathy' },
      { value: 'IdiopathicNeuropathy', label: 'idiopathic neuropathy' },
      { value: 'LumbarRadiculopathy', label: 'lumbar radiculopathy' },
      { value: 'Myopathy', label: 'myopathy' },
      { value: 'PeripheralNeuropathy', label: 'peripheral neuropathy' },
      { value: 'Plexopathy', label: 'plexopathy' },
      { value: 'Radiculopathy', label: 'radiculopathy' },
      {
        value: 'RadiculopathyAffecting',
        label: 'radiculopathy affecting (specify below)',
        summaryLabel: 'radiculopathy affecting',
      },
      {
        value: 'SensorymotorPolyneuropathy',
        label: 'sensorimotor polyneuropathy',
      },
      { value: 'Ulnar', label: 'ulnar' },
      { value: 'Medial', label: 'medial' },
      { value: 'Radial', label: 'radial' },
      { value: 'Sural', label: 'sural' },
      { value: 'SuperficialPeroneal', label: 'superficial peroneal' },
      { value: 'Peroneal', label: 'peroneal' },
      { value: 'Tibial', label: 'tibial' },
    ],
  },
  impressionClinicalCorrelationPredefined: {
    name: 'impressionClinicalCorrelationPredefined',
    associatedField: 'impressionLaterality',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'ClinicalCorrelationRecommended',
        label: 'clinical correlation recommended',
        summaryLabel: 'clinical correlation is recommended',
      },
    ],
  },
  impressionFreeText: {
    name: 'impressionFreeText',
    associatedField: 'impressionLaterality',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
  noEvidencePredefined: {
    name: 'noEvidencePredefined',
    label: 'Otherwise no evidence of',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'AxonalPredominantSensory',
        label: 'axonal-predominant sensory',
      },
      { value: 'AxonalPredominantMotor', label: 'axonal-predominant motor' },
      {
        value: 'CarpalTunnelSyndrome',
        label: 'CTS',
        summaryLabel: 'carpal tunnel syndrome',
      },
      { value: 'CervicalRadiculopathy', label: 'cervical radiculopathy' },
      { value: 'FocalNeuropathy', label: 'focal neuropathy' },
      { value: 'HereditaryNeuropathy', label: 'hereditary neuropathy' },
      { value: 'IdiopathicNeuropathy', label: 'idiopathic neuropathy' },
      { value: 'LumbarRadiculopathy', label: 'lumbar radiculopathy' },
      { value: 'Myopathy', label: 'myopathy' },
      { value: 'PeripheralNeuropathy', label: 'peripheral neuropathy' },
      { value: 'Plexopathy', label: 'plexopathy' },
      { value: 'Radiculopathy', label: 'radiculopathy' },
      {
        value: 'SensorimotorPolyneuropathy',
        label: 'sensorimotor polyneuropathy',
      },
    ],
  },
  noEvidenceFreeText: {
    name: 'noEvidenceFreeText',
    associatedField: 'noEvidencePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 2,
  },
};

export default emgFields;
