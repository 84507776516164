import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

const eegFields = {
  clinicalStatePredefined: {
    name: 'clinicalStatePredefined',
    label: 'Clinical State',
    type: ConsultFieldTypeEnum.CHIP,
    options: [{ value: 'StandardProtocol', label: 'standard protocol' }],
  },
  clinicalStateFreeText: {
    name: 'clinicalStateFreeText',
    associatedField: 'clinicalStatePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  photicStimulationPredefined: {
    name: 'photicStimulationPredefined',
    title: 'Photic Stimulation',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'One_25hz', label: '1-25hz' },
      { value: 'Three_33hz', label: '3-33hz' },
    ],
  },
  photicStimulationFreeText: {
    name: 'photicStimulationFreeText',
    associatedField: 'photicStimulationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  hyperventilationPredefined: {
    name: 'hyperventilationPredefined',
    title: 'Hyperventilation',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Performed', label: 'performed' },
      { value: 'NotPerformed', label: 'NOT performed' },
    ],
  },
  durationPredefined: {
    name: 'durationPredefined',
    title: 'Duration',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'TwentyFourHours', label: '24 hr' },
      { value: 'ExtendedGreaterThan61Min', label: 'extended > 61 min' },
      { value: 'ТhirtyMinutes', label: '30 min' },
      { value: 'TwentyMinutes', label: '20 min' },
    ],
  },
  durationFreeText: {
    name: 'durationFreeText',
    associatedField: 'durationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  phasesCapturedPredefined: {
    name: 'phasesCapturedPredefined',
    label: 'Phases Captured',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Awake', label: 'awake' },
      { value: 'Drowsy', label: 'drowsy' },
      { value: 'Sleep', label: 'sleep' },
    ],
  },
  phasesCapturedFreeText: {
    name: 'phasesCapturedFreeText',
    associatedField: 'phasesCapturedPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  ekgPredefined: {
    name: 'ekgPredefined',
    title: 'EKG',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'NSR', label: 'NSR' },
      { value: 'AtrialFibrillaiton', label: 'atrial fibrillation' },
      { value: 'Paced', label: 'paced' },
    ],
  },
  ekgFreeText: {
    name: 'ekgFreeText',
    associatedField: 'ekgPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  attenuationPredefined: {
    name: 'attenuationPredefined',
    label: 'Attenuation',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NormalWithEyeOpening', label: 'normal with eye opening' },
      {
        value: 'NormalWithEyeOpeningAndAlerting',
        label: 'normal with eye opening and alerting',
      },
      { value: 'MinimalWithEyeOpening', label: 'minimal with eye opening' },
      { value: 'Abnormal', label: 'abnormal' },
    ],
  },
  attenuationFreeText: {
    name: 'attenuationFreeText',
    associatedField: 'attenuationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  epileptiformAbnormalitiesPredefined: {
    name: 'epileptiformAbnormalitiesPredefined',
    label: 'Epileptiform Abnormalities',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NotPresent', label: 'NOT present' },
      { value: 'Present', label: 'present' },
      { value: 'Spikes', label: 'spikes' },
      { value: 'SharpWaves', label: 'sharp waves' },
    ],
  },
  epileptiformAbnormalitiesFreeText: {
    name: 'epileptiformAbnormalitiesFreeText',
    associatedField: 'epileptiformAbnormalitiesPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  seizurePredefined: {
    name: 'seizurePredefined',
    label: 'Seizure',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NotPresent', label: 'NOT present' },
      { value: 'Present', label: 'present' },
      { value: 'Generalized', label: 'generalized' },
      { value: 'Absence', label: 'absence' },
      { value: 'Myoclonic', label: 'myoclonic' },
      { value: 'Clonic', label: 'clonic' },
      { value: 'Tonic', label: 'tonic' },
      { value: 'Atonic', label: 'atonic' },
      { value: 'PostIctal', label: 'post-ictal' },
    ],
  },
  seizureFreeText: {
    name: 'seizureFreeText',
    associatedField: 'seizurePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  artifact: {
    name: 'artifact',
    label: 'Artifact',
    type: ConsultFieldTypeEnum.INPUT,
  },
  stimulationPredefined: {
    name: 'stimulationPredefined',
    title: 'Stimulation',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'PhoticStimulationCausesAbnormalities',
        label: 'photic stimulation causes abnormalities',
      },
      {
        value: 'PhoticStimulationDoesNotCauseAbnormalities',
        label: 'photic stimulation does NOT cause abnormalities',
      },
      {
        value: 'HyperventilationCausesAbnormalities',
        label: 'hyperventilation causes abnormalities',
      },
      {
        value: 'HyperventilationDoesNotCauseAbnormalities',
        label: 'hyperventilation does NOT cause abnormalities',
      },
    ],
  },
  stimulationFreeText: {
    name: 'stimulationFreeText',
    associatedField: 'stimulationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  impressionPredefined: {
    name: 'impressionPredefined',
    label: 'Impression',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NormalEEG', label: 'normal EEG' },
      { value: 'NoCorticalDysfunction', label: 'no cortical dysfxn' },
      {
        value: 'MildDiffuseEncephalopathy',
        label: 'mild diffuse encephalopathy',
      },
      { value: 'ModerateDiffuseEncephalopathy', label: 'mod diffuse enceph' },
      { value: 'SevereDiffuseEncephalopathy', label: 'severe diffuse enceph' },
      { value: 'NoSeizureActivity', label: 'NO seizure activity' },
      { value: 'SeizureActivity', label: 'seizure activity' },
      { value: 'SharpWaves', label: 'sharp waves' },
      { value: 'Spike', label: 'spike' },
      {
        value: 'NegEEGDoesNotRuleOutSeizure',
        label: 'caveat: neg EEG does not r/o  seizure',
      },
    ],
  },
  impressionFreeText: {
    name: 'impressionFreeText',
    associatedField: 'impressionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
};

export default eegFields;
