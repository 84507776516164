import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';

export const SLICE_PREFIX = 'consultRequests';

export const createNewConsultRequest = createAsyncThunk(
  `${SLICE_PREFIX}/createNewConsultRequest`,
  async (consultRequestData, { rejectWithValue }) => {
    try {
      const { data } = await api.createConsultRequest(consultRequestData);

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listConsultRequests = createAsyncThunk(
  `${SLICE_PREFIX}/listConsultRequests`,
  async ({ page, filters, sortBy, sortOrder }, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultRequests({
        page,
        sortBy,
        sortOrder,
        filters,
      });

      return {
        data: data.consults,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultRequestById = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultRequestById`,
  async (consultRequestId, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultRequestById(consultRequestId);

      return data.consultRequest;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const acceptConsultRequest = createAsyncThunk(
  `${SLICE_PREFIX}/acceptConsultRequest`,
  async (consultRequestId, { rejectWithValue }) => {
    try {
      const { data } = await api.acceptConsultRequest(consultRequestId);

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const declineConsultRequest = createAsyncThunk(
  `${SLICE_PREFIX}/declineConsultRequest`,
  async (consultRequestId, { rejectWithValue }) => {
    try {
      const { data } = await api.declineConsultRequest(consultRequestId);

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteConsultRequest = createAsyncThunk(
  `${SLICE_PREFIX}/deleteConsultRequest`,
  async (consultRequestId, { rejectWithValue }) => {
    try {
      const { data } = await api.deleteConsultRequest(consultRequestId);

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
