import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from '@material-ui/core';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const RadioGroup = ({
  control,
  name,
  label,
  error = null,
  required = false,
  options = [],
  disabled = false,
}) => (
  <FormControl required={required} error={!!error}>
    <InputLabel htmlFor={name} required={required} disabled={disabled}>
      {label}
    </InputLabel>
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ value, onChange }) => (
        <MaterialRadioGroup
          row
          name={name}
          id={name}
          value={value}
          onChange={onChange}
        >
          {options.map((opt) => (
            <FormControlLabel
              key={opt.value}
              value={opt.value}
              control={<Radio />}
              label={opt.label}
              disabled={disabled}
            />
          ))}
        </MaterialRadioGroup>
      )}
    />
    {error && <ErrorMsg text={error.message} dense />}
  </FormControl>
);

RadioGroup.propTypes = {
  control: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default RadioGroup;
