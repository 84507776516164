import {
  ConsultFieldTypeEnum,
  FilterTypePropNames,
} from 'features/consults/utils/enums';

const CTPreliminarilyOptions = [
  {
    value: 'PreliminarilyReviewedByMe',
    label: 'preliminarily reviewed by me, please refer to radiology',
    summaryLabel:
      'preliminarily reviewed by me, please refer to radiology read for official reading',
  },
  { value: 'PerRadiologistRead', label: 'per radiologist read' },
];

const dataFields = {
  glucosePredefined: {
    name: 'glucosePredefined',
    title: 'Glucose',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'WithinNormalLimits',
        label: 'WNL',
        summaryLabel: 'within normal limits',
      },
      { value: 'Hypoglycemia', label: 'hypoglycemia' },
      { value: 'Hyperglycemia', label: 'hyperglycemia' },
    ],
  },
  glucoseFreeText: {
    name: 'glucoseFreeText',
    associatedField: 'glucosePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  creatininePredefined: {
    name: 'creatininePredefined',
    title: 'Creatinine',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'WithinNormalLimits',
        label: 'WNL',
        summaryLabel: 'within normal limits',
      },
      { value: 'Elevated', label: 'elevated' },
    ],
  },
  creatinineFreeText: {
    name: 'creatinineFreeText',
    associatedField: 'creatininePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  inrPredefined: {
    name: 'inrPredefined',
    title: 'INR',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'WithinNormalLimits',
        label: 'WNL',
        summaryLabel: 'within normal limits',
      },
      { value: 'Therapeutinc', label: 'therapeutic' },
      { value: 'Elevated', label: 'elevated' },
      { value: 'Over', label: '> 1.7', summaryLabel: 'over 1.7' },
      { value: 'OverAnticoagulated', label: 'over anticoagulated' },
    ],
  },
  inrFreeText: {
    name: 'inrFreeText',
    associatedField: 'inrPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  otherLabs: {
    name: 'otherLabs',
    label: 'Other Labs',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  headCtPersonallyReadAt: {
    name: 'headCtPersonallyReadAt',
    label: 'Time Head CT personally read by me',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  headCtPredefined: {
    name: 'headCtPredefined',
    label: 'Head CT',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NoBleed', label: 'no bleed' },
      { value: 'EarlyIschemicChange', label: 'early ischemic change' },
      { value: 'McaSign', label: 'MCA sign' },
      { value: 'Hemorrhage', label: 'hemorrhage' },
      {
        value: 'PersonallyReviewedByMe',
        label: 'personally reviewed by me',
        [FilterTypePropNames.CLINIC]: true,
      },
      {
        value: 'PreliminarilyReviewedByMeReferToRadiologyRead',
        label: 'preliminarily reviewed by me, refer to radiology read',
        summaryLabel:
          'preliminarily reviewed by me, please refer to radiology read for official reading',
        [FilterTypePropNames.NEURO]: true,
      },
      { value: 'PerRadiologistRead', label: 'per radiologist read' },
      { value: 'PreliminaryRead', label: 'preliminary read' },
      { value: 'SubacuteIschemicStroke', label: 'subacute ischemic stroke' },
    ],
  },
  headCtFreeText: {
    name: 'headCtFreeText',
    associatedField: 'headCtPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  ctaHeadPredefined: {
    name: 'ctaHeadPredefined',
    title: 'CTA Head',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'NoLargeVesselOcclusion',
        label: 'no LVO',
        summaryLabel: 'no large vessel occlusion',
      },
      { value: 'McaOcclusion', label: 'MCA occlusion' },
      { value: 'Dissection', label: 'dissection' },
      { value: 'Aneurysm', label: 'aneurysm' },
      {
        value: 'ArteriovenousMalformation',
        label: 'AVM',
        summaryLabel: 'arteriovenous malformation',
      },
    ],
  },
  ctaHeadPreliminarilyPredefined: {
    name: 'ctaHeadPreliminarilyPredefined',
    associatedField: 'ctaHeadPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: CTPreliminarilyOptions,
  },
  ctaHeadFreeText: {
    name: 'ctaHeadFreeText',
    associatedField: 'ctaHeadPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  ctaNeckPredefined: {
    name: 'ctaNeckPredefined',
    title: 'CTA Neck',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'PatentVessels', label: 'patent vessels' },
      { value: 'Dissection', label: 'dissection' },
      { value: 'Occlusion', label: 'occlusion' },
    ],
  },
  ctaNeckPreliminarilyPredefined: {
    name: 'ctaNeckPreliminarilyPredefined',
    associatedField: 'ctaNeckPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: CTPreliminarilyOptions,
  },
  ctaNeckFreeText: {
    name: 'ctaNeckFreeText',
    associatedField: 'ctaNeckPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  ctPerfusionPredefined: {
    name: 'ctPerfusionPredefined',
    label: 'CT Perfusion',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'WNL', label: 'WNL' },
      { value: 'Mismatch', label: 'mismatch' },
      { value: 'MatchedDefect', label: 'matched defect' },
      { value: 'CoreInfarctSize', label: 'core infarct size' },
    ],
  },
  ctPerfusionPreliminarilyPredefined: {
    name: 'ctPerfusionPreliminarilyPredefined',
    associatedField: 'ctPerfusionPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: CTPreliminarilyOptions,
  },
  ctPerfusionFreeText: {
    name: 'ctPerfusionFreeText',
    associatedField: 'ctPerfusionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  mriPredefined: {
    name: 'mriPredefined',
    title: 'MRI',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'NoAcuteIschemia', label: 'no acute ischemia' },
      { value: 'AcuteIschemia', label: 'acute ischemia' },
      { value: 'SubacuteIschemia', label: 'subacute ischemia' },
      { value: 'Mass', label: 'mass' },
      { value: 'Hemorrhage', label: 'hemorrhage' },
    ],
  },
  mriPreliminarilyPredefined: {
    name: 'mriPreliminarilyPredefined',
    associatedField: 'mriPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: CTPreliminarilyOptions,
  },
  mriFreeText: {
    name: 'mriFreeText',
    associatedField: 'mriPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  otherImaging: {
    name: 'otherImaging',
    label: 'Other Imaging',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
};

export default dataFields;
