import { useState } from 'react';

import { useQueryParams } from 'shared/hooks/useQueryParams';

// eslint-disable-next-line import/prefer-default-export
export const useTableState = (initialSortBy, initialSortOrder = 'asc') => {
  const { queryParams } = useQueryParams();
  const {
    page: routePage,
    sortOrder: routeSortOrder,
    sortBy: routeSortBy,
  } = queryParams;

  const [page, setPage] = useState(routePage ? Number(routePage) : 1);
  const [sortOrder, setSortOrder] = useState(
    routeSortOrder || initialSortOrder,
  );
  const [sortBy, setSortBy] = useState(routeSortBy || initialSortBy);

  const handlePageChange = (_e, value) => setPage(value);

  const handleSortChange = (sortField, newSortOrder) => {
    setSortBy(sortField);
    setSortOrder(newSortOrder);
  };

  return {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
  };
};
