import { RESET_OPTION, CONSULT_TABS } from 'features/consults/utils/constants';
import { ConsultTypeEnum } from 'features/consults/utils/enums';

import demographicsFields from 'features/consults/shared/tabs/Demographics/formFields';
import signatureFields from 'features/consults/shared/tabs/Signature/formFields';

export const isEmptyValue = (value) => {
  const emptyValues = ['', RESET_OPTION.value, null, undefined];
  return emptyValues.includes(value);
};

const requiredConsultFields = {
  [ConsultTypeEnum.NEURO]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      demographicsFields.consultType,
      demographicsFields.neuroPatientLocation,
      demographicsFields.firstName,
      demographicsFields.lastName,
      demographicsFields.facility,
    ],
    [CONSULT_TABS.SIGNATURE]: [signatureFields.attestationOfConsultCompletion],
  },
  [ConsultTypeEnum.CLINIC]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      demographicsFields.firstName,
      demographicsFields.lastName,
    ],
  },
  [ConsultTypeEnum.EEG]: {
    [CONSULT_TABS.DEMOGRAPHICS]: [
      demographicsFields.firstName,
      demographicsFields.lastName,
      demographicsFields.facility,
      demographicsFields.consultType,
    ],
    [CONSULT_TABS.SIGNATURE]: [signatureFields.attestationOfEEGCompletion],
  },
};

const tabRequiredErrors = (consult, consultType, tabKey) => {
  if (!consult.id) return null;
  const fields = requiredConsultFields[consultType][tabKey];
  return (
    fields &&
    fields.reduce((res, { name }) => {
      if (isEmptyValue(consult[tabKey][name])) res.push(name);
      return res;
    }, [])
  );
};

export const consultErrors = (consult, consultType) => {
  const requiredTabs = requiredConsultFields[consultType];
  return Object.keys(requiredTabs).reduce((res, tabKey) => {
    const tabErrors = tabRequiredErrors(consult, consultType, tabKey);
    if (tabErrors && tabErrors.length) res[tabKey] = tabErrors;
    return res;
  }, {});
};
