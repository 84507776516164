import { useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  add,
  eachDayOfInterval,
  isToday,
  isSameDay,
  startOfWeek,
} from 'date-fns';

import api from 'api';
import { buildFilterQueryString } from 'api/utils';
import useRoles from 'auth/hooks/useRoles';
import Paths from 'navigation/paths';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import useFilterState from 'shared/hooks/useFilterState';
import {
  ConsultTypeEnum,
  ConsultNetworkTypesEnum,
} from 'features/consults/utils/enums';
import { LAST_CALENDAR_DAY_INDEX } from 'features/schedule/utils/constants';
import { ScheduleViewEnum } from 'features/schedule/utils/enums';
import { scheduleActions } from 'features/schedule/store/slice';
import { appStateActions } from 'store/appStateSlice';
import {
  BROWSER_DATE_FORMAT,
  TIME_FORMAT,
  formatDate,
  parseISO,
} from 'utils/dates';
import { downloadFile } from 'utils/helpers';
import colors from 'styles/colors';

const shiftColors = {
  [ConsultNetworkTypesEnum[ConsultTypeEnum.NEURO]]: colors.shiftNeuroBackground,
  [ConsultNetworkTypesEnum[ConsultTypeEnum.EEG]]: colors.shiftEegBackground,
};

const getScheduleFilters = (filters, userDetails) => {
  const { scheduleView, userId, ...restFilters } = filters;

  return {
    ...restFilters,
    userId: scheduleView === ScheduleViewEnum.MY ? userDetails.id : userId,
  };
};

const useCalendarData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { shifts } = useSelector(({ schedule }) => schedule);
  const userDetails = useSelector(({ profile }) => profile.data);
  const { isDoctor } = useRoles();

  const { filters, handleFilter } = useFilterState({
    scheduleView: isDoctor ? ScheduleViewEnum.MY : ScheduleViewEnum.ALL,
    facilityId: null,
    networks: null,
    networkType: null,
    userId: null,
  });

  const { calendarStartDate, calendarEndDate } = useMemo(() => {
    const firstDayOfWeek = startOfWeek(new Date(), { weekStartsOn: 1 }); // Week starts on Monday
    const lastCalendarDay = add(firstDayOfWeek, {
      days: LAST_CALENDAR_DAY_INDEX, // 4 weeks
    });

    return {
      calendarStartDate: formatDate(firstDayOfWeek, BROWSER_DATE_FORMAT),
      calendarEndDate: formatDate(lastCalendarDay, BROWSER_DATE_FORMAT),
    };
  }, []);

  useEffect(() => {
    if (
      (filters.scheduleView === ScheduleViewEnum.MY && userDetails.id) ||
      filters.scheduleView === ScheduleViewEnum.ALL
    ) {
      dispatch(
        scheduleActions.listScheduleShifts({
          startDate: calendarStartDate,
          endDate: calendarEndDate,
          filters: getScheduleFilters(filters, userDetails),
        }),
      );
      history.replace(
        `${Paths.Schedule}?${buildFilterQueryString(filters).substr(1)}`,
      );
    }
  }, [
    dispatch,
    history,
    calendarEndDate,
    calendarStartDate,
    filters,
    userDetails,
  ]);

  const calendarDays = useMemo(() => {
    const mappedShifts = shifts.map((shift) => {
      const {
        networkType,
        shiftAdminFacilityName,
        shiftAdminFacilityShortName,
        shiftAdminUserFirstName,
        shiftAdminUserLastName,
        shiftStart,
        shiftEnd,
      } = shift;
      const shiftStartDate = parseISO(shiftStart);
      const shiftEndDate = parseISO(shiftEnd);

      return {
        ...shift,
        network: shiftAdminFacilityShortName || shiftAdminFacilityName,
        provider: `${shiftAdminUserFirstName} ${shiftAdminUserLastName}`,
        shiftStart: shiftStartDate,
        shiftEnd: shiftEndDate,
        startTime: formatDate(shiftStartDate, TIME_FORMAT),
        endTime: formatDate(shiftEndDate, TIME_FORMAT),
        color: shiftColors[networkType],
      };
    });

    const timeInterval = eachDayOfInterval({
      start: parseISO(calendarStartDate),
      end: parseISO(calendarEndDate),
    });

    return timeInterval.map((date) => {
      const today = isToday(date);

      const formattedDate = today
        ? `Today - ${formatDate(date, 'MMM d')}`
        : formatDate(date, 'E MMM d');

      return {
        title: formattedDate,
        today,
        shifts: mappedShifts.filter((shift) =>
          isSameDay(date, shift.shiftStart),
        ),
      };
    });
  }, [shifts, calendarStartDate, calendarEndDate]);

  const hasShifts = useMemo(
    () => calendarDays.reduce((acc, day) => acc + day.shifts.length, 0) > 0,
    [calendarDays],
  );

  const handleExport = async (fileType) => {
    const exportFilters = {
      ...getScheduleFilters(filters, userDetails),
      timezoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      dispatch(appStateActions.setLoading(true));

      const { data, headers } = await api.exportShifts(
        calendarStartDate,
        calendarEndDate,
        exportFilters,
        fileType,
      );

      downloadFile(data, headers, fileType);
    } catch {
      dispatch(
        appStateActions.showSnackbar({
          text: 'An error occurred while downloading the file. Please, try again.',
          type: SnackbarTypeEnum.ERROR,
          duration: 5000,
        }),
      );
    } finally {
      dispatch(appStateActions.setLoading(false));
    }
  };

  return {
    calendarDays,
    hasShifts,
    filters,
    handleFilter,
    handleExport,
  };
};

export default useCalendarData;
