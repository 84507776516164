import React from 'react';

import { NoOption } from 'styles/icons';
import { ConsultStates, IntegrationStatusEnum } from './enums';

export const CONSULT_TABS = {
  DEMOGRAPHICS: 'demographics',
  HPI: 'hpi',
  SCORES: 'scores',
  EXAM: 'exam',
  ROS: 'ros',
  PMH: 'pmhfhsh',
  DATA: 'data',
  ASSESSMENT: 'assessment',
  PLAN: 'plan',
  SUMMARY: 'summary',
  SIGNATURE: 'signature',
  EEG: 'eeg',
  EMG: 'emg',
  BOTOX: 'botox',
};

export const RESET_OPTION = { value: 'x', content: <NoOption /> };
export const neuroAcuteStrokeLevel1Type =
  'AcuteStrokeZeroFourAndHalfHourStrokeLevel1';

export const NeuroConsultTypes = [
  {
    value: neuroAcuteStrokeLevel1Type,
    label: 'Acute Stroke Level 1 (0-4.5 hrs) ',
  },
  {
    value: 'AcuteStrokeFourAndHalfTwentyFourStrokeLevel2',
    label: 'Acute Stroke Level 2 (4.5-24 hrs)',
  },
  {
    value: 'GeneralNeurology',
    label: 'General Neurology',
  },
  {
    value: 'FollowUpPhoneCall',
    label: 'Follow-Up Phone Call',
  },
];

export const ConsultIntegrationOptions = [
  { value: IntegrationStatusEnum.NON_INTEGRATED, text: 'Non-Integrated' },
  { value: IntegrationStatusEnum.UNMATCHED, text: 'Unmatched' },
  { value: IntegrationStatusEnum.MATCHED, text: 'Matched' },
];

export const consultFilterStateLabels = [
  { value: ConsultStates.OPEN, text: ConsultStates.OPEN },
  { value: ConsultStates.CLOSED, text: ConsultStates.CLOSED },
  { value: ConsultStates.CANCELLED, text: ConsultStates.CANCELLED },
];

export const LAST24HOURS = 'last24Hours';
