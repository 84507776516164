import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Hidden } from '@material-ui/core';

import Sidebar from 'shared/components/layout/Sidebar/Sidebar';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import useFormState from 'shared/hooks/useFormState';
import NewConsultButton from 'features/consults/components/NewConsultButton/NewConsultButton';
import { smallConsultsTableColumns } from 'features/consults/components/ConsultsList/tableColumns';
import useConsultsListState from 'features/consults/hooks/useConsultsListState';
import useConsultData from 'features/consults/hooks/useConsultData';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { Box, FlexBoxColumn } from 'styles/layout';
import { getTableHeaders, prepareTableData } from 'utils/tables';

const ConsultSidebar = ({ consultType }) => {
  const {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsult,
  } = useConsultsListState(consultType);
  const { consult: selectedConsult } = useConsultData();
  const { register } = useFormState();

  const renderConsultsTable = useMemo(() => {
    const tableColumns = smallConsultsTableColumns(consultType);
    const data = consults.map((consult) => {
      if (consult.id === selectedConsult.id) {
        const { firstName, lastName, facilityName } = selectedConsult[
          CONSULT_TABS.DEMOGRAPHICS
        ];

        return {
          ...consult,
          patientFirstName: firstName,
          patientLastName: lastName,
          facilityName:
            facilityName !== undefined ? facilityName : consult.facilityName,
        };
      }

      return consult;
    });

    return (
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(data, tableColumns)}
        fallbackMsg="No consults found"
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={pageCount}
        selectedRowId={selectedConsult.id}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
        onClickRow={navigateToConsult}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consults, sortBy, sortOrder, page, pageCount, selectedConsult]);

  return (
    <Hidden smDown>
      <Sidebar>
        <FlexBoxColumn px={2.5}>
          <Box mb={4}>
            <NewConsultButton consultType={consultType} fullWidth />
          </Box>
          <Box mb={2.5}>
            <SearchField
              inputRef={register}
              name="query"
              defaultValue={filters.query}
              onChange={(value) => handleFilter({ query: value })}
            />
          </Box>
          {renderConsultsTable}
        </FlexBoxColumn>
      </Sidebar>
    </Hidden>
  );
};

ConsultSidebar.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultSidebar;
