import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';
import {
  Box,
  FlexBoxCenter,
  FlexBoxColumnSpaceBetween,
  FlexBoxColumnCenter,
} from 'styles/layout';

import {
  RequestDetails,
  ConsultRequestButton,
  ErrorMessage,
} from './ConsultRequestDetails.style';
import ConsultRequestTypeDetails from './ConsultRequestTypeDetails';

const ConsultRequestDetails = ({
  consultRequest,
  facilityName,
  error,
  onAccept,
  onDecline,
}) => {
  const renderConsultRequestDetails = useMemo(() => {
    if (error) {
      return <ErrorMessage>{error}</ErrorMessage>;
    }

    if (consultRequest.state === ConsultRequestStatusEnum.ACCEPTED) {
      return (
        <FlexBoxColumnCenter>
          <RequestDetails>Consult has already been accepted by</RequestDetails>
          <RequestDetails>{`${consultRequest.acceptedByFirstName} ${consultRequest.acceptedByLastName}`}</RequestDetails>
        </FlexBoxColumnCenter>
      );
    }

    return (
      <>
        {consultRequest.id && (
          <FlexBoxColumnSpaceBetween>
            <Box mb={5}>
              <ConsultRequestTypeDetails
                consultRequest={consultRequest}
                facilityName={facilityName}
              />
            </Box>
            <FlexBoxColumnCenter>
              <ConsultRequestButton
                type="button"
                color="secondary"
                size="large"
                fullWidth
                onClick={onAccept}
              >
                Accept
              </ConsultRequestButton>
              <ConsultRequestButton
                type="button"
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={onDecline}
              >
                Decline
              </ConsultRequestButton>
            </FlexBoxColumnCenter>
          </FlexBoxColumnSpaceBetween>
        )}
      </>
    );
  }, [consultRequest, error, facilityName, onAccept, onDecline]);

  return (
    <FlexBoxCenter px={1.5} py={10}>
      {renderConsultRequestDetails}
    </FlexBoxCenter>
  );
};

ConsultRequestDetails.propTypes = {
  consultRequest: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
    acceptedByFirstName: PropTypes.string,
    acceptedByLastName: PropTypes.string,
  }).isRequired,
  facilityName: PropTypes.string,
  error: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

export default ConsultRequestDetails;
