import { useHistory, useLocation } from 'react-router-dom';

const useBack = () => {
  const history = useHistory();
  const { state } = useLocation();

  const onBack = () => {
    if (state && state.navigateOnBack) {
      history.push(state.navigateOnBack);
      return;
    }

    history.goBack();
  };

  return { onBack };
};

export default useBack;
