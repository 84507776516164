import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import loGroupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import { FormControl, Select as MuiSelect } from '@material-ui/core';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { FlexBox } from 'styles/layout';

import { MenuItem, SelectAllButton } from './Select.styles';

const fixedOptions = ({ options, placeholder, multiple }) =>
  multiple ? options : [{ text: placeholder || '', value: '' }, ...options];

const renderNativeOptions = ({ options, placeholder, multiple }) =>
  fixedOptions({ options, placeholder, multiple }).map(({ text, value }) => (
    <option key={value} value={value}>
      {text}
    </option>
  ));

const renderOptions = ({ options, placeholder, multiple, groupBy }) => {
  if (!groupBy) {
    return fixedOptions({ options, placeholder, multiple }).map(
      ({ text, value }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ),
    );
  }
  const groupedOptions = loGroupBy(options, groupBy);
  return Object.entries(groupedOptions).reduce(
    (res, [key, groupOptions]) => [
      ...res,
      <MenuItem
        key={key}
        disabled
        style={{ fontWeight: 'bold', padding: '1rem .5rem .5rem 1rem' }}
      >
        {key}
      </MenuItem>,
      ...groupOptions.map(({ text, value }) => (
        <MenuItem value={value}>{text}</MenuItem>
      )),
    ],
    [],
  );
};

const Select = ({
  inputRef,
  name,
  label = null,
  size = 'small',
  error = null,
  required = false,
  fullWidth = true,
  placeholder = null,
  options,
  disabled,
  defaultValue,
  multiple = false,
  native = true,
  groupBy,
  hasSelectAll,
  onChange,
  value,
  ...props
}) => {
  const allValues = useMemo(
    () => sortBy(options, 'value')?.map((o) => o.value),
    [options],
  );

  const allValuesSelected = useMemo(() => {
    if (!(multiple && hasSelectAll)) return null;
    return isEqual(sortBy(value), allValues);
  }, [multiple, allValues, value, hasSelectAll]);

  const handleSelectAll = useCallback(
    (e) => {
      onChange({
        ...e,
        target: {
          name,
          value: allValuesSelected ? [] : allValues,
        },
      });
    },
    [allValues, allValuesSelected, name, onChange],
  );

  return (
    <>
      <FormControl
        variant="outlined"
        size={size}
        fullWidth={fullWidth}
        required={required}
        error={!!error}
      >
        {!!(label || hasSelectAll) && (
          <FlexBox
            alignItems="flex-end"
            justifyContent={label ? 'space-between' : 'flex-end'}
          >
            {!!label && (
              <InputLabel
                id={`${name}-label`}
                htmlFor={name}
                required={required}
                disabled={disabled}
              >
                {label}
              </InputLabel>
            )}
            {hasSelectAll && multiple && (
              <SelectAllButton disabled={disabled} onClick={handleSelectAll}>
                {`${allValuesSelected ? 'Unselect' : 'Select'} All`}
              </SelectAllButton>
            )}
          </FlexBox>
        )}
        <MuiSelect
          defaultValue={defaultValue}
          disabled={disabled}
          native={native}
          multiple={multiple}
          inputRef={inputRef}
          labelId={`${name}-label`}
          inputProps={{
            id: name,
            name,
          }}
          onChange={onChange}
          value={value}
          {...props}
        >
          {native
            ? renderNativeOptions({ options, placeholder, multiple })
            : renderOptions({ options, placeholder, multiple, groupBy })}
        </MuiSelect>
      </FormControl>
      {error && <ErrorMsg text={error.message} dense={size === 'small'} />}
    </>
  );
};

Select.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  size: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  native: PropTypes.bool,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  groupBy: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
  ).isRequired,
  groupLabels: PropTypes.shape({}),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  hasSelectAll: PropTypes.bool,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

export default Select;
