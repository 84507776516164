import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AddButton from 'navigation/components/AddButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { useTableState } from 'shared/hooks/useTableState';
import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';
import sortFields from 'api/sortFields';
import { getTableHeaders, prepareTableData } from 'utils/tables';

const tableColumns = [
  {
    header: {
      id: sortFields.networks.name,
      value: 'Network Name',
      isSortable: true,
    },
    dataKey: 'name',
    width: '80%',
  },
  {
    header: {
      id: sortFields.networks.facilityCount,
      value: 'Number of Facilities',
      isSortable: true,
    },
    dataKey: 'facilitiesCount',
    align: 'center',
  },
];

const NetworksListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: networks, pageCount } = useSelector(
    ({ admin }) => admin.networks,
  );
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.networks.name);

  useEffect(() => {
    dispatch(adminActions.listNetworks({ page, sortBy, sortOrder }));
    history.replace(
      `${AdminPaths.Networks}?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    );
  }, [dispatch, history, page, sortBy, sortOrder]);

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Networks</PageTitle>
        <AddButton to={AdminPaths.AddNetwork}>Add Network</AddButton>
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxColumn mt={3}>
        <PaginatedTable
          headings={getTableHeaders(tableColumns)}
          data={prepareTableData(networks, tableColumns)}
          fallbackMsg="No Networks found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={(rowId) =>
            history.push(`${AdminPaths.Networks}/${rowId}`)
          }
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default NetworksListPage;
