import {
  ConsultTypeEnum,
  ConsultNetworkTypesEnum,
} from 'features/consults/utils/enums';

export const LAST_CALENDAR_DAY_INDEX = 27;

export const serviceOptions = [
  { text: 'Neuro', value: ConsultNetworkTypesEnum[ConsultTypeEnum.NEURO] },
  { text: 'EEG', value: ConsultNetworkTypesEnum[ConsultTypeEnum.EEG] },
];
