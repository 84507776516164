import styled from 'styled-components';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import Autocomplete from 'shared/components/forms/Autocomplete/Autocomplete';

export const AutocompleteDropdown = styled(Autocomplete)`
  width: 200px;
`;

export const ExportButton = styled(ActionButton)`
  border-radius: 12px;
`;
