import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Box } from 'styles/layout';
import InputField from 'shared/components/forms/InputField/InputField';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import Select from 'shared/components/forms/Select/Select';
import Switch from 'shared/components/forms/Switch/Switch';

import listOfStates from 'utils/states';

const HealthSystemForm = ({
  register,
  control,
  getFieldError,
  disabled = false,
}) => (
  <Box mb={2}>
    <Grid container spacing={6}>
      <Grid container item xs={12} md={8} spacing={2}>
        <Grid item md={6} xs={12}>
          <Switch
            control={control}
            name="active"
            label="Active"
            disabled={disabled}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              inputRef={register}
              name="name"
              label="Healthcare System Name"
              required
              disabled={disabled}
              error={getFieldError('name')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              inputRef={register}
              name="siteCode"
              label="Site Code"
              disabled={disabled}
              error={getFieldError('siteCode')}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputField
              inputRef={register}
              name="contact.name"
              label="Contact Name"
              disabled={disabled}
              error={getFieldError('contact.name')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneNumberInput
              control={control}
              name="contact.phoneNumber"
              label="Contact Phone Number"
              disabled={disabled}
              error={getFieldError('contact.phoneNumber')}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputField
            inputRef={register}
            name="address.streetAddress"
            label="Address"
            disabled={disabled}
            error={getFieldError('address.streetAddress')}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <InputField
              inputRef={register}
              name="address.locality"
              label="City"
              disabled={disabled}
              error={getFieldError('address.locality')}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Select
              inputRef={register}
              name="address.region"
              label="State"
              disabled={disabled}
              error={getFieldError('address.region')}
              options={listOfStates}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputField
              inputRef={register}
              name="address.postalCode"
              label="Zip Code"
              disabled={disabled}
              inputProps={{ maxLength: 5 }}
              error={getFieldError('address.postalCode')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <InputField
          inputRef={register}
          name="note"
          multiline
          rows={6}
          label="BSL Healthcare System Note"
          disabled={disabled}
          error={getFieldError('note')}
        />
      </Grid>
    </Grid>
  </Box>
);

HealthSystemForm.propTypes = {
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  getFieldError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default HealthSystemForm;
