import React from 'react';
import PropTypes from 'prop-types';

import Select from 'shared/components/forms/Select/Select';

const SimpleSelect = ({ name, onChange, register, ...rest }) => (
  <Select
    {...rest}
    name={name}
    inputRef={register}
    onChange={() => onChange(name)}
  />
);

SimpleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default SimpleSelect;
