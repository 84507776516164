import React from 'react';
import flatten from 'lodash/flatten';
import { RESET_OPTION } from 'features/consults/utils/constants';
import {
  ConsultFieldTypeEnum,
  FilterByConsultTypePropsEnum,
} from 'features/consults/utils/enums';
import { MultiChipStatesEnum } from 'shared/components/forms/MultiChip/MultiChipStates.enum';
import {
  US_DATE_FORMAT,
  TIME_FORMAT,
  formatToSpecificTimezone,
  formatDateString,
} from 'utils/dates';
import { TIMEZONE_OFFSETS, TIMEZONE_LABELS } from 'utils/timezones';

const getChipSummaryValues = (options, selectedChips) => {
  // Loop over field options to preserve display order
  const chipValues = options.reduce((acc, option) => {
    const selectedChip = selectedChips?.find((chip) => chip === option.value);

    if (selectedChip) {
      const label = option.summaryLabel || option.label;
      return acc.concat(label);
    }

    return acc;
  }, []);

  return chipValues;
};

const getMultiChipSummaryValues = (options, selectedChips) => {
  const chipValues = options.reduce((acc, option) => {
    const selectedChip = selectedChips.find(
      (chip) => chip.label === option.value,
    );

    if (selectedChip) {
      let labelText = option.summaryLabel || option.label;

      // If option has multiple summary labels,
      // check the chip state and show either the positive, negative or default text
      if (option.summaryLabels) {
        const { positive, negative } = option.summaryLabels;

        if (selectedChip.state === MultiChipStatesEnum.YES) {
          labelText = positive || labelText;
        } else {
          labelText = negative || labelText;
        }

        return acc.concat(labelText);
      }

      if (selectedChip.state === MultiChipStatesEnum.NO) {
        const startsWithNegation = labelText.match(/^(not|non|no)\s/g);
        const startsWithNormal = labelText.match(/^(normal)/g);

        // If the option state is negative, check if the label starts with a negation (e.g. "no")
        // If it does, remove the negation
        if (startsWithNegation) {
          labelText = labelText.substr(startsWithNegation[0].length);
        } else if (startsWithNormal) {
          labelText = `ab${labelText}`;
        } else {
          labelText = `no ${labelText}`;
        }
      }

      return acc.concat(labelText);
    }

    return acc;
  }, []);

  return chipValues;
};

export const filterOptionsByConsultType = (field, consultType) => {
  const filterProps = FilterByConsultTypePropsEnum[consultType];
  const options = field.options.filter((option) =>
    filterProps.every((prop) => !option[prop]),
  );

  return {
    ...field,
    options,
  };
};

export const generateGroupFields = (generatorFunc, total = 6) =>
  Array.from({ length: total }).reduce(
    (acc, _, index) => ({
      ...acc,
      ...generatorFunc(index + 1),
    }),
    {},
  );

export const displaySummaryValue = (value, additionalValue) => {
  if (!additionalValue) {
    return value;
  }

  if (!value) {
    return additionalValue;
  }

  return [value, additionalValue];
};

export const mapFieldOptions = (options, fieldType) => {
  const labelFieldName =
    fieldType === ConsultFieldTypeEnum.TOGGLE ? 'content' : 'text';

  const fieldOptions = options.map(({ value, label, ...rest }) => ({
    value,
    [labelFieldName]: label,
    ...rest,
  }));

  if (fieldType === ConsultFieldTypeEnum.TOGGLE) {
    return [RESET_OPTION, ...fieldOptions];
  }

  return fieldOptions;
};

export const mapToggleFieldOptions = (toggleField) => ({
  ...toggleField,
  options: mapFieldOptions(toggleField.options, ConsultFieldTypeEnum.TOGGLE),
});

export const mapChipFieldOptions = (chipField) => ({
  ...chipField,
  options: mapFieldOptions(chipField.options, ConsultFieldTypeEnum.CHIP),
});

export const getFieldLabelByValue = (options, fieldValue) => {
  const option = options.find((item) => item.value === fieldValue);

  if (!option) {
    return null;
  }

  return option.summaryLabel || option.label;
};

export const getFieldSummaryLabel = (field, timezone) => {
  const { summaryLabel, label, title, type } = field;
  const labelText = summaryLabel || label || title;

  if (type === ConsultFieldTypeEnum.DATETIME) {
    return `${labelText} (${TIMEZONE_LABELS[timezone]}): `;
  }

  return labelText ? `${labelText}: ` : '';
};

export const getInitialValueByFieldType = (fieldType) => {
  switch (fieldType) {
    case ConsultFieldTypeEnum.CHIP:
    case ConsultFieldTypeEnum.MULTICHIP:
      return [];

    default:
      return null;
  }
};

export const getFieldSummaryValueForType = (field, timezone) => {
  const { type, value, additionalValue, options } = field;

  switch (type) {
    case ConsultFieldTypeEnum.INPUT:
    case ConsultFieldTypeEnum.PHONE:
    case ConsultFieldTypeEnum.DICTATION:
      return displaySummaryValue(value, additionalValue);

    case ConsultFieldTypeEnum.TOGGLE:
      return displaySummaryValue(
        getFieldLabelByValue(options, value),
        additionalValue,
      );

    case ConsultFieldTypeEnum.DATE:
      return formatDateString(value, US_DATE_FORMAT);

    case ConsultFieldTypeEnum.DATETIME:
      return formatToSpecificTimezone(
        new Date(value),
        `${US_DATE_FORMAT}, ${TIME_FORMAT}`,
        TIMEZONE_OFFSETS[timezone],
      );

    case ConsultFieldTypeEnum.CHIP:
      return displaySummaryValue(
        getChipSummaryValues(options, value),
        additionalValue,
      );

    case ConsultFieldTypeEnum.MULTICHIP:
      return displaySummaryValue(
        getMultiChipSummaryValues(options, value),
        additionalValue,
      );

    default:
      return null;
  }
};

export const renderFieldValue = (field, timezone) => {
  const fieldValue = getFieldSummaryValueForType(field, timezone);
  return Array.isArray(fieldValue) ? (
    <ul>
      {flatten(fieldValue).map((v) => (
        <li key={v}>{v}</li>
      ))}
    </ul>
  ) : (
    fieldValue
  );
};
