import React from 'react';
import styled from 'styled-components';
import { Badge } from '@material-ui/core';

import colors from 'styles/colors';
import Button from 'shared/components/buttons/Button/Button';

export const NavItem = styled.li`
  padding: ${({ theme }) => theme.link.padding};
`;

export const Link = styled(Button)`
  border-radius: 0;
  color: ${({ theme }) => theme.link.color};
  justify-content: ${({ theme }) => theme.link.justifyContent};

  /* https://styled-components.com/docs/api#transient-props */
  border-bottom: 3px solid
    ${({ $isActive }) => ($isActive ? colors.secondary : colors.transparent)};
`;

/*
  Based on the Material UI documentation on how to override deeper element styles
  using styled-components - https://material-ui.com/guides/interoperability/#deeper-elements-2
*/
export const NotificationBadge = styled((props) => (
  <Badge classes={{ badge: 'badge' }} {...props} />
))`
  .badge {
    top: 17px;
    right: -5px;
    background: ${colors.notification};
    color: ${colors.white};
  }
`;
