import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiSearchIcon from '@material-ui/icons/Search';

import colors from 'styles/colors';

export const SearchInput = withStyles({
  input: {
    padding: '.5rem',
  },
})(OutlinedInput);

export const SearchIcon = withStyles({
  root: {
    color: colors.neutralDark,
    marginLeft: '5px',
  },
})(MuiSearchIcon);

export const SearchTitle = styled.label`
  color: ${colors.primary};
  font-size: 0.8rem;
  font-weight: bold;
`;

export const InfoLabel = styled.label`
  color: ${colors.neutralDark};
  font-size: 1rem;
`;

export const TableCell = withStyles({
  root: {
    textAlign: 'center',
    border: '0',
    padding: '0.2rem',
  },
})(MuiTableCell);

export const matchIconStyle = (match) => ({
  display: 'inline-block',
  height: '2rem',
  width: '2rem',
  background: match ? colors.success : colors.error,
  borderRadius: '50%',
});
