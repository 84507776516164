import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from 'shared/components/buttons/Button/Button';

const DialogWrapper = ({
  children,
  onCancel,
  onConfirm,
  confirmTxt = 'Save',
  cancelTxt = 'Cancel',
  fullWidth = true,
  maxWidth = 'md',
  title,
}) => (
  <Dialog open fullWidth={fullWidth} maxWidth={maxWidth}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {onConfirm && (
        <Button color="secondary" variant="contained" onClick={onConfirm}>
          {confirmTxt}
        </Button>
      )}

      {onCancel && (
        <Button onClick={onCancel} color="secondary" variant="outlined">
          {cancelTxt}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

DialogWrapper.propTypes = {
  children: PropTypes.node,
  confirmTxt: PropTypes.string,
  cancelTxt: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
};

export default DialogWrapper;
