import Paths from 'navigation/paths';

export const ConsultTypeEnum = {
  NEURO: 'neuro',
  CLINIC: 'clinic',
  EEG: 'eeg',
};

export const ConsultTypeRoleNameEnum = {
  [ConsultTypeEnum.NEURO]: 'TeleNeurologist',
  [ConsultTypeEnum.CLINIC]: 'Clinic',
  [ConsultTypeEnum.EEG]: 'Eeg',
};

export const ConsultStates = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  CANCELLED: 'Cancelled',
};

export const ConsultFieldTypeEnum = {
  CHIP: 'chip',
  MULTICHIP: 'multichip',
  INPUT: 'input',
  TOGGLE: 'toggle',
  DATETIME: 'datetime',
  DATE: 'date',
  DICTATION: 'dictation',
  SELECT: 'select',
  PHONE: 'phone',
  AUTOCOMPLETE: 'autocomplete',
};

// Consult type value sent to BE
export const ConsultTypeRequestEnum = {
  [ConsultTypeEnum.NEURO]: 'NeuroConsult',
  [ConsultTypeEnum.CLINIC]: 'ClinicConsult',
  [ConsultTypeEnum.EEG]: 'EEGConsult',
};

export const ConsultPathsEnum = {
  [ConsultTypeEnum.NEURO]: Paths.Neuro,
  [ConsultTypeEnum.CLINIC]: Paths.Clinic,
  [ConsultTypeEnum.EEG]: Paths.Eeg,
};

export const ConsultNetworkTypesEnum = {
  [ConsultTypeEnum.NEURO]: 'NeuroNetwork',
  [ConsultTypeEnum.EEG]: 'EEGNetwork',
};

export const ConsultMacroTypesEnum = {
  [ConsultTypeEnum.NEURO]: 'Neuro',
  [ConsultTypeEnum.CLINIC]: 'Clinic',
  [ConsultTypeEnum.EEG]: 'EEG',
};

export const ConsultFilterEnum = {
  OPEN: 'open',
  ALL: 'all',
  MY_LAST_24: 'myLast24',
  LAST_24: 'last24',
  MY_CLOSED: 'myClosed',
  ALL_CLOSED: 'allClosed',
  ALL_OPEN: 'allOpen',
};

export const FilterTypePropNames = {
  NEURO: 'neuroOnly',
  CLINIC: 'clinicOnly',
  EEG: 'eegOnly',
};

export const FilterByConsultTypePropsEnum = {
  [ConsultTypeEnum.NEURO]: [
    FilterTypePropNames.CLINIC,
    FilterTypePropNames.EEG,
  ],
  [ConsultTypeEnum.CLINIC]: [
    FilterTypePropNames.NEURO,
    FilterTypePropNames.EEG,
  ],
  [ConsultTypeEnum.EEG]: [
    FilterTypePropNames.NEURO,
    FilterTypePropNames.CLINIC,
  ],
};

export const IntegrationStatusEnum = {
  NON_INTEGRATED: 'NonIntegrated',
  UNMATCHED: 'Unmatched',
  MATCHED: 'Matched',
};

export const OrderTypeEnum = {
  [ConsultTypeEnum.NEURO]: 'TELENEURO',
  [ConsultTypeEnum.EEG]: 'EEG',
};
