import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { adminActions } from 'features/admin/store/slice';
import { appStateActions } from 'store/appStateSlice';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import {
  IntegrationStatusEnum,
  ConsultStates,
} from 'features/consults/utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import IntegrationIcon from 'shared/components/data/IntegrationIcon';
import ConsultIntegrationMerge from 'features/consults/components/ConsultMenu/ConsultIntegration/ConsultIntegrationMerge';
import ConsultUnmatch from 'features/consults/components/ConsultMenu/ConsultIntegration/ConsultUnmatch';

import IntegrationOrderList from './IntegrationOrderList';

const modalEnums = {
  LIST: 'list',
  REVIEW: 'review',
  UNLINK: 'unlink',
};

const ConsultIntegration = ({ consult, summaryText }) => {
  const { id, integrationStatus } = consult;
  const [modal, setModal] = useState(null);
  const dispatch = useDispatch();

  const showSuccessFeedback = (text) => {
    dispatch(
      appStateActions.showSnackbar({
        text,
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  };

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADMIN_ORDER_LINKED, () => {
    setModal(null);
    dispatch(adminActions.fetchConsult(id));
    showSuccessFeedback('Linked successfully');
  });

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADMIN_ORDER_UNLINKED, () => {
    // TODO: is name update on unlink required anymore?
    /* sets the name of the patient to '' on unlink */
    if (consult[CONSULT_TABS.SIGNATURE]?.state === ConsultStates.OPEN) {
      dispatch(
        adminActions.updateAdminConsult({
          consultId: id,
          data: {
            firstName: {
              tab: CONSULT_TABS.DEMOGRAPHICS,
              value: '',
            },
          },
        }),
      );
    }
    setModal(null);
    showSuccessFeedback('Unlinked successfully');
    dispatch(adminActions.fetchConsult(id));
  });

  const handleButtonClick = useCallback(() => {
    switch (integrationStatus) {
      case IntegrationStatusEnum.MATCHED:
        setModal({ type: modalEnums.UNLINK });
        break;
      case IntegrationStatusEnum.UNMATCHED:
        setModal({ type: modalEnums.LIST });
        break;
      default:
        break;
    }
  }, [integrationStatus]);

  const handleLink = useCallback(() => {
    dispatch(
      adminActions.linkOrder({
        hl7OrderId: modal?.order?.id,
        consultId: consult.id,
        summary: summaryText,
      }),
    );
  }, [consult.id, dispatch, modal, summaryText]);

  const handleUnlink = useCallback(() => {
    dispatch(
      adminActions.unlinkOrder({
        consultId: consult.id,
      }),
    );
  }, [consult.id, dispatch]);

  const modalContent = useMemo(() => {
    switch (modal?.type) {
      case modalEnums.LIST:
        return (
          <IntegrationOrderList
            consult={consult}
            onClose={() => setModal(null)}
            onReview={(order) => setModal({ type: modalEnums.REVIEW, order })}
          />
        );
      case modalEnums.REVIEW:
        return (
          <ConsultIntegrationMerge
            consult={consult}
            order={modal.order}
            onMerge={handleLink}
            onClose={() => setModal({ type: modalEnums.LIST })}
            closeStr="Back"
          />
        );
      case modalEnums.UNLINK:
        return (
          <ConsultUnmatch
            onCancel={() => setModal(null)}
            onUnlink={handleUnlink}
          />
        );
      default:
        return null;
    }
  }, [modal, consult, handleLink, handleUnlink]);

  if (
    !id ||
    ![IntegrationStatusEnum.MATCHED, IntegrationStatusEnum.UNMATCHED].includes(
      integrationStatus,
    )
  )
    return null;

  return (
    <>
      <Dialog
        open={Boolean(modal)}
        maxWidth="lg"
        onClose={() => setModal(null)}
      >
        <DialogContent>{modalContent}</DialogContent>
      </Dialog>
      <IconButton onClick={handleButtonClick}>
        <IntegrationIcon integrationStatus={integrationStatus} />
      </IconButton>
    </>
  );
};

ConsultIntegration.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.number,
    integrationStatus: PropTypes.string,
  }),
  summaryText: PropTypes.string,
};

export default ConsultIntegration;
