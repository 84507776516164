import React from 'react';
import PropTypes from 'prop-types';

import { NeuroConsultTypes } from 'features/consults/utils/constants';
import { ConsultTypeEnum } from 'features/consults/utils/enums';
import { patientLocationOptions } from 'features/consults/shared/tabs/Demographics/formFields';

import { PatientName, RequestDetails } from './ConsultRequestDetails.style';

const ConsultNeuroExtraDetails = ({
  neuroConsultType,
  cameraName,
  directBeamInRequested,
}) => {
  const neuroTypeLabels = NeuroConsultTypes.reduce(
    (res, { value, label }) => ({ ...res, [value]: label }),
    {},
  );

  return (
    <>
      <RequestDetails>{`Consult Type: ${neuroTypeLabels[neuroConsultType]}`}</RequestDetails>
      <RequestDetails>{`Camera Name: ${cameraName || ''}`}</RequestDetails>
      {directBeamInRequested && (
        <RequestDetails>Direct Beam-In Requested</RequestDetails>
      )}
    </>
  );
};

ConsultNeuroExtraDetails.propTypes = {
  neuroConsultType: PropTypes.string.isRequired,
  cameraName: PropTypes.string,
  directBeamInRequested: PropTypes.bool.isRequired,
};

const getLocationLabel = (location) =>
  patientLocationOptions.find(({ value }) => value === location)?.label;

const ConsultRequestTypeDetails = ({
  consultRequest: {
    firstName,
    lastName,
    age,
    type,
    statReadRequested,
    eegReadConsultType,
    notes,
    patientLocation,
    ...rest
  },
  facilityName,
}) => (
  <>
    <PatientName>{`Patient: ${firstName} ${lastName}`}</PatientName>
    {age && <RequestDetails>{`Age: ${age}`}</RequestDetails>}
    <RequestDetails>{`Facility: ${facilityName}`}</RequestDetails>
    <RequestDetails>{`Consult type: ${type}`}</RequestDetails>
    {eegReadConsultType && (
      <RequestDetails>{`EEG Type: ${eegReadConsultType}`}</RequestDetails>
    )}
    {statReadRequested && <RequestDetails>STAT Read Requested</RequestDetails>}
    {type.toLowerCase() === ConsultTypeEnum.NEURO && (
      <ConsultNeuroExtraDetails {...rest} />
    )}
    {!!patientLocation && (
      <RequestDetails>{`Patient Location: ${getLocationLabel(
        patientLocation,
      )}`}</RequestDetails>
    )}
    {!!notes && <RequestDetails>{`Notes: ${notes}`}</RequestDetails>}
  </>
);

ConsultRequestTypeDetails.propTypes = {
  consultRequest: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    eegReadConsultType: PropTypes.string,
    age: PropTypes.number,
    type: PropTypes.string,
    statReadRequested: PropTypes.bool,
    notes: PropTypes.string,
    patientLocation: PropTypes.string,
  }).isRequired,
  facilityName: PropTypes.string,
};

export default ConsultRequestTypeDetails;
