import React from 'react';
import PropTypes from 'prop-types';

import useRoles from 'auth/hooks/useRoles';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import useFormState from 'shared/hooks/useFormState';
import { ScheduleViewEnum } from 'features/schedule/utils/enums';
import { Box, FlexBoxJustify } from 'styles/layout';

const styles = {
  sm: {
    Component: FlexBoxJustify,
    componentProps: { mt: 1.5, mb: 1 },
  },
  lg: {
    Component: Box,
    componentProps: { ml: 4 },
  },
};

const ScheduleViewToggle = ({
  defaultValue,
  handleChange,
  isMobile = false,
}) => {
  const { control } = useFormState();
  const { isDoctor } = useRoles();

  if (!isDoctor) {
    return null;
  }

  const { Component, componentProps } = isMobile ? styles.sm : styles.lg;

  return (
    <Component {...componentProps}>
      <ToggleButtonGroup
        control={control}
        buttons={[
          { value: ScheduleViewEnum.MY, content: 'My' },
          { value: ScheduleViewEnum.ALL, content: 'All' },
        ]}
        name="scheduleView"
        defaultValue={defaultValue}
        handleChange={handleChange}
      />
    </Component>
  );
};

ScheduleViewToggle.propTypes = {
  defaultValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default ScheduleViewToggle;
