import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, ClickAwayListener } from '@material-ui/core';

import { FlexBoxFull } from 'styles/layout';
import {
  DropdownIcon,
  DropdownMenu,
  SelectedOptionLabel,
  ConsultOption,
} from './ConsultQuickFilterSelect.style';

const ConsultQuickFilterSelect = ({
  anchorRef,
  selectedOption,
  expandedOptions,
  handleOptionChange,
}) => {
  const [showExpandedOptions, setShowExpandedOptions] = useState(false);

  const toggleExpandedMenu = (e) => {
    e.stopPropagation();
    setShowExpandedOptions(!showExpandedOptions);
  };

  const handleExpandedOptionChange = (e, value) => {
    e.stopPropagation();
    setShowExpandedOptions(false);
    handleOptionChange(value);
  };

  return (
    <>
      <FlexBoxFull>
        <SelectedOptionLabel>{selectedOption.label}</SelectedOptionLabel>
        <DropdownIcon onClick={toggleExpandedMenu} />
      </FlexBoxFull>
      {anchorRef && (
        <Popper
          open={showExpandedOptions}
          anchorEl={anchorRef.current}
          placement="bottom-end"
        >
          <ClickAwayListener onClickAway={() => setShowExpandedOptions(false)}>
            <DropdownMenu>
              {expandedOptions.map(({ value, label }) => (
                <ConsultOption
                  key={value}
                  onClick={(e) => handleExpandedOptionChange(e, value)}
                >
                  {label}
                </ConsultOption>
              ))}
            </DropdownMenu>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
};

ConsultQuickFilterSelect.propTypes = {
  anchorRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }),
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
  }).isRequired,
  expandedOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleOptionChange: PropTypes.func.isRequired,
};

export default ConsultQuickFilterSelect;
