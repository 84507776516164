import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import UserList from './UserList';
import UserAddPage from './UserAddPage';
import UserDetailsPage from './UserDetailsPage';

const Users = () => (
  <Switch>
    <Route exact path={AdminPaths.Users} component={UserList} />
    <Route exact path={AdminPaths.AddUser} component={UserAddPage} />
    <Route
      exact
      path={`${AdminPaths.Users}/:userId`}
      component={UserDetailsPage}
    />
  </Switch>
);

export default Users;
