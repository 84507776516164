import React from 'react';

import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useFormState from 'shared/hooks/useFormState';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';

import schema from './validationSchema';
import { impressionPanel } from './data';

const AssessmentNeuro = () => {
  const { register, control, getValues, getFieldError, setFormValues } =
    useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const { consult, consultTabData, isEditable, handleFieldChange } =
    useConsultData(CONSULT_TABS.ASSESSMENT, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return (
    <QuestionsPanel
      {...impressionPanel}
      control={control}
      register={register}
      onChange={handleFieldChange}
      getFieldError={getFieldError}
      disabled={!isEditable}
    />
  );
};

export default AssessmentNeuro;
