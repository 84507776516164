import * as yup from 'yup';

import { MAX_IMAGE_FILE_SIZE, requiredString } from 'utils/constants';
import { isFile } from 'utils/helpers';
import { PHONE_NUMBER_REGEX } from 'utils/validators';
import { userRolesEnum } from 'utils/users';

export const isPhoneRequired = (roles) =>
  !roles?.every(
    (role) =>
      role === userRolesEnum.ADMINISTRATOR || role === userRolesEnum.CUSTOMER,
  );

export default yup.object().shape({
  userImage: yup
    .mixed()
    .test(
      'fileSize',
      `The uploaded file exceeds the maximum allowed size of ${
        MAX_IMAGE_FILE_SIZE / 1024 ** 2
      }MB`,
      (value) => {
        if (isFile(value)) {
          return value.size <= MAX_IMAGE_FILE_SIZE;
        }

        return true;
      },
    ),
  enabled: yup.string().required(requiredString),
  firstName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `First Name should not exceed ${max} characters`),
  lastName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Last Name should not exceed ${max} characters`),
  title: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .required(requiredString)
    .email('Invalid Email')
    .max(2048, ({ max }) => `Email should not exceed ${max} characters`),
  roles: yup.array().of(yup.string()).required(requiredString),
  phoneNumber: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .when('roles', {
      is: (roles) => isPhoneRequired(roles),
      then: yup.string().required(requiredString).typeError(requiredString),
    }),
  shitAdminId: yup.string(),
  npi: yup.string(),
  autologout: yup.boolean(),
  inactivityLogOutThreshold: yup.number().when('autologout', {
    is: true,
    then: yup
      .number('Please enter a number')
      .positive('Please enter a number greater then zero')
      .integer('Please enter a integer value')
      .required('Required'),
  }),
});
