import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabContext } from '@material-ui/lab';
import { v4 as uuid } from 'uuid';

import { FlexBoxAlign, FlexBoxSpaceBetween } from 'styles/layout';
import CloseButton from 'shared/components/buttons/CloseButton/CloseButton';

import useBreakpoints from 'shared/hooks/useBreakpoints';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import neuroTabs from 'features/consults/pages/neuro/tabs';
import clinicTabs from 'features/consults/pages/clinic/tabs';
import eegTabs from 'features/consults/pages/eeg/tabs';
import { ConsultTypeEnum } from 'features/consults/utils/enums';

import { TabsContainer, TabHeader, TabContent, Locked } from './Tabs.style';
import IntegrationLink from './IntegrationLink';

const consultTabs = {
  [ConsultTypeEnum.NEURO]: neuroTabs,
  [ConsultTypeEnum.CLINIC]: clinicTabs,
  [ConsultTypeEnum.EEG]: eegTabs,
};

const getDefaultTab = (isClosed) =>
  isClosed ? CONSULT_TABS.SUMMARY : CONSULT_TABS.DEMOGRAPHICS;

const Tabs = ({
  error = null,
  isClosed = false,
  consultId,
  onClose,
  integrationStatus,
  consultType,
}) => {
  const [currentTab, setCurrentTab] = useState(getDefaultTab(isClosed));
  const { smDown } = useBreakpoints();

  useEffect(() => {
    setCurrentTab(getDefaultTab(isClosed));
  }, [consultId, isClosed]);

  const tabList = useMemo(() => consultTabs[consultType], [consultType]);

  const tabsData = useMemo(
    () =>
      isClosed && currentTab === CONSULT_TABS.SUMMARY
        ? [tabList[CONSULT_TABS.SUMMARY]]
        : Object.values(tabList),
    [tabList, isClosed, currentTab],
  );

  const tabsHeaders = useMemo(
    () =>
      tabsData.map(({ value, label }) => (
        <TabHeader
          key={uuid()}
          label={label}
          value={value}
          $selected={currentTab === value}
          $hasError={error && error[value]}
        />
      )),
    [currentTab, tabsData, error],
  );

  const tabsContent = useMemo(
    () =>
      tabsData.map(({ value, component: Component }) => (
        <TabContent key={uuid()} value={value}>
          <Component />
        </TabContent>
      )),
    [tabsData],
  );

  return (
    <TabContext value={currentTab}>
      <FlexBoxSpaceBetween style={{ alignItems: 'flex-start' }}>
        <TabsContainer
          $sm={smDown}
          onChange={(_e, newTab) => setCurrentTab(newTab)}
        >
          {tabsHeaders}
        </TabsContainer>
        <FlexBoxAlign>
          {isClosed && <Locked $sm={smDown} />}
          <IntegrationLink
            consultId={Number(consultId)}
            integrationStatus={integrationStatus}
            consultType={consultType}
            isClosed={isClosed}
          />
          {!smDown && <CloseButton onClick={onClose} />}
        </FlexBoxAlign>
      </FlexBoxSpaceBetween>

      {tabsContent}
    </TabContext>
  );
};

Tabs.propTypes = {
  error: PropTypes.shape({}),
  isClosed: PropTypes.bool,
  consultId: PropTypes.string,
  onClose: PropTypes.func,
  integrationStatus: PropTypes.string,
  consultType: PropTypes.string,
};

export default Tabs;
