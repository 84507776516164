import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import useFormState from 'shared/hooks/useFormState';

import ConsultMainFilters from './ConsultMainFilters';
import { mapAdminConsultFilters } from './data';

const ConsultListFilters = ({
  filters,
  onFilter,
  onResetFilters,
  children,
}) => {
  const { register, control, setFormValues, watch } = useFormState();

  useEffect(() => {
    setFormValues(mapAdminConsultFilters(filters));
  }, [filters, setFormValues]);

  return (
    <FlexBoxColumn>
      <ConsultMainFilters
        control={control}
        filters={filters}
        onFilter={onFilter}
        onResetFilters={onResetFilters}
        watch={watch}
      />
      <FlexBoxSpaceBetweenCenter mb={3} mt={5}>
        <SearchField
          name="query"
          inputRef={register}
          onChange={(query) => onFilter({ query })}
        />
        {children}
      </FlexBoxSpaceBetweenCenter>
    </FlexBoxColumn>
  );
};

ConsultListFilters.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  onFilter: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default ConsultListFilters;
