/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { DEFAULT_SNACKBAR_DURATION } from 'utils/constants';
import {
  setPermanentPassword,
  sendForgotUserPasswordCode,
  resetUserPassword,
} from 'auth/store/actionCreators';
import {
  addHealthSystem,
  updateHealthSystem,
  addNetwork,
  updateNetwork,
  addFacility,
  updateFacility,
  addUser,
  updateUser,
  resetUserPassword as adminResetUserPassword,
  reInviteUser as adminReInviteUser,
  updateAdminConsult,
  linkOrder as adminLinkOrder,
  unlinkOrder as adminUnlinkOrder,
} from 'features/admin/store/actionCreators';
import {
  UPDATE_CONSULT_ACTION,
  addNewConsult,
  getConsultById,
  copyConsult,
  addAddendum,
  addNewMacro,
  applyMacro,
  linkOrder,
  unlinkOrder,
} from 'features/consults/store/actionCreators';
import { updateProfile } from 'features/profile/store/actionCreators';
import {
  createNewConsultRequest,
  acceptConsultRequest,
  deleteConsultRequest,
} from 'features/customer/store/actionCreators';
import { SuccessActionTypesEnum } from './SuccessActionTypes.enum';

const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    loading: false,
    successAction: null,
    snackbar: {
      visible: false,
      text: '',
      type: SnackbarTypeEnum.SUCCESS,
      duration: DEFAULT_SNACKBAR_DURATION,
      closable: false,
      requireManualClose: false,
    },
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    showSnackbar: (state, { payload }) => {
      const duration =
        payload.duration !== undefined
          ? payload.duration
          : DEFAULT_SNACKBAR_DURATION;

      state.snackbar = {
        ...payload,
        duration,
        visible: true,
      };
    },
    hideSnackbar: (state) => {
      state.snackbar.visible = false;
    },
    removeSuccessAction: (state) => {
      state.successAction = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendForgotUserPasswordCode.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.VERIFICATION_CODE_SENT,
        };
      })
      .addCase(setPermanentPassword.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.PASSWORD_SET,
        };
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.PASSWORD_RESET,
        };
      })
      .addCase(addHealthSystem.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.HEALTH_SYSTEM_ADDED,
          payload,
        };
      })
      .addCase(updateHealthSystem.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.HEALTH_SYSTEM_UPDATED,
        };
      })
      .addCase(addNetwork.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.NETWORK_ADDED,
          payload,
        };
      })
      .addCase(updateNetwork.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.NETWORK_UPDATED,
        };
      })
      .addCase(addFacility.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.FACILITY_ADDED,
          payload,
        };
      })
      .addCase(updateFacility.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.FACILITY_UPDATED,
        };
      })
      .addCase(addUser.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.USER_ADDED,
          payload,
        };
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.USER_UPDATED,
          payload,
        };
      })
      .addCase(adminResetUserPassword.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ADMIN_USER_PASSWORD_RESET,
        };
      })
      .addCase(adminReInviteUser.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ADMIN_USER_REINVITED,
        };
      })
      .addCase(updateProfile.fulfilled, (state) => {
        state.successAction = {
          type: SuccessActionTypesEnum.PROFILE_UPDATED,
        };
      })
      .addCase(addNewConsult.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.CONSULT_CREATED,
          payload,
        };
      })
      .addCase(getConsultById.fulfilled, (state, { payload }) => {
        if (payload.forceReload) {
          state.successAction = {
            type: SuccessActionTypesEnum.FORCE_CONSULT_RELOAD,
            payload,
          };
        }
      })
      .addCase(copyConsult.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.CONSULT_COPIED,
          payload,
        };
      })
      .addCase(createNewConsultRequest.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.CONSULT_REQUEST_CREATED,
          payload,
        };
      })
      .addCase(acceptConsultRequest.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.CONSULT_REQUEST_ACCEPTED,
          payload,
        };
      })
      .addCase(deleteConsultRequest.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.CONSULT_REQUEST_DELETED,
          payload,
        };
      })
      .addCase(addAddendum.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ADDENDUM_ADDED,
          payload,
        };
      })
      .addCase(linkOrder.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ORDER_LINKED,
          payload,
        };
      })
      .addCase(unlinkOrder.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ORDER_UNLINKED,
          payload,
        };
      })
      .addCase(adminLinkOrder.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ADMIN_ORDER_LINKED,
          payload,
        };
      })
      .addCase(adminUnlinkOrder.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.ADMIN_ORDER_UNLINKED,
          payload,
        };
      })
      .addCase(addNewMacro.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.MACRO_CREATED,
          payload,
        };
      })
      .addCase(applyMacro.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.MACRO_APPLIED,
          payload,
        };
      })
      .addCase(updateAdminConsult.fulfilled, (state, { payload }) => {
        state.successAction = {
          type: SuccessActionTypesEnum.UPDATED_ADMIN_CONSULT,
          payload,
        };
      })
      .addMatcher(
        (action) =>
          action.type.endsWith('/pending') &&
          !action.type.startsWith(UPDATE_CONSULT_ACTION),
        (state) => {
          state.loading = true;
        },
      )
      .addMatcher(
        (action) =>
          action.type.endsWith('/fulfilled') ||
          action.type.endsWith('/rejected'),
        (state) => {
          state.loading = false;
        },
      );
  },
});

export const { reducer: appStateReducer, actions: appStateActions } =
  appStateSlice;

export default appStateSlice;
