import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControl, OutlinedInput, FormHelperText } from '@material-ui/core';

import { FlexBoxAlign, FlexBoxColumn } from 'styles/layout';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';

const TextInput = styled(OutlinedInput)`
  .MuiInputBase-inputMultiline {
    resize: vertical;
  }
`;

const InputField = ({
  inputRef,
  name,
  label = null,
  type = 'text',
  size = 'small',
  error = null,
  required = false,
  disabled = false,
  fullWidth = true,
  inlineLabel = false,
  helperText = null,
  ...props
}) => {
  const Container = inlineLabel ? FlexBoxAlign : FlexBoxColumn;

  return (
    <FormControl
      variant="outlined"
      size={size}
      fullWidth={fullWidth}
      required={required}
      error={!!error}
    >
      <Container>
        {!!label && (
          <InputLabel
            htmlFor={name}
            required={required}
            disabled={disabled}
            inline={inlineLabel}
            error={error}
          >
            {label}
          </InputLabel>
        )}
        <TextInput
          inputRef={inputRef}
          id={name}
          name={name}
          type={type}
          disabled={disabled}
          {...props}
        />
      </Container>
      {!!helperText && (
        <FormHelperText variant="outlined">{helperText}</FormHelperText>
      )}
      {error && <ErrorMsg text={error.message} dense={size === 'small'} />}
    </FormControl>
  );
};

InputField.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  type: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  helperText: PropTypes.string,
};

export default InputField;
