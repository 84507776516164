import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Mic, Stop } from '@material-ui/icons';

import { Box } from 'styles/layout';
import useConvertingSpeechToText from 'shared/hooks/useConvertingSpeechToText';
import Button from 'shared/components/buttons/Button/Button';

const BUTTON_STATES = {
  START: 'start',
  STOP: 'stop',
};

const DictationControls = ({ onChange, disabled }) => {
  const {
    hasBrowserSupport,
    transcript,
    resetTranscript,
    startRecording,
    stopRecording,
    interimTranscript,
    finalTranscript,
    listening,
  } = useConvertingSpeechToText();

  useEffect(() => {
    if (!finalTranscript || interimTranscript) return;
    onChange(finalTranscript);
    resetTranscript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interimTranscript, finalTranscript]);

  const handleStopRecording = useCallback(() => {
    if (listening) {
      stopRecording();
      resetTranscript();
    }
  }, [listening, stopRecording, resetTranscript]);

  useEffect(() => handleStopRecording, [handleStopRecording]);

  const buttonOptions = {
    [BUTTON_STATES.START]: {
      icon: <Mic />,
      action: BUTTON_STATES.START,
      handleClick: startRecording,
    },
    [BUTTON_STATES.STOP]: {
      icon: <Stop />,
      action: BUTTON_STATES.STOP,
      handleClick: handleStopRecording,
    },
  };

  const { icon, action, handleClick } =
    buttonOptions[listening ? BUTTON_STATES.STOP : BUTTON_STATES.START];

  return (
    hasBrowserSupport && (
      <Box>
        <Box mb={0.5}>{transcript}</Box>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClick}
          disabled={disabled}
        >
          {icon}
          {`${action} dictation`}
        </Button>
      </Box>
    )
  );
};

DictationControls.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DictationControls;
