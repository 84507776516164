import { ConsultTypeEnum } from 'features/consults/utils/enums';
import {
  filterOptionsByConsultType,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Data/formFields';

export const leftPanels = [
  {
    elements: [
      mapToggleFieldOptions(fields.glucosePredefined),
      fields.glucoseFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.creatininePredefined),
      fields.creatinineFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.inrPredefined), fields.inrFreeText],
  },
  {
    elements: [fields.otherLabs],
  },
];

export const rightPanels = [
  {
    elements: [
      mapChipFieldOptions(
        filterOptionsByConsultType(
          fields.headCtPredefined,
          ConsultTypeEnum.CLINIC,
        ),
      ),
      fields.headCtFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.ctaHeadPredefined),
      fields.ctaHeadFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.ctaNeckPredefined),
      fields.ctaNeckFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.ctPerfusionPredefined),
      fields.ctPerfusionFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.mriPredefined), fields.mriFreeText],
  },
  {
    elements: [fields.otherImaging],
  },
];
