import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import useFormState from 'shared/hooks/useFormState';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import SelectWithFilter from 'features/consults/components/tabFields/SelectWithFilter';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import useSharedHandlers from 'features/consults/hooks/useSharedHandlers';
import { eegDemographicsFields as formFields } from 'features/consults/shared/tabs/Demographics/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { Box } from 'styles/layout';
import schema from './validationSchema';
import {
  eEGTypePanel,
  leftPanels1,
  leftPanels2,
  leftPanels3,
  rightPanels,
} from './data';

const { consultType, firstName, lastName, dateOfBirth, facility } = formFields;

const Demographics = () => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setValue,
    setFormValues,
    watch,
    clearErrors,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isEditable,
    saveConsultChange,
    handleFieldChange,
    tabErrors,
  } = useConsultData(CONSULT_TABS.DEMOGRAPHICS, getValues);
  const {
    facilities,
    handleNameChange,
    handleFacilityChange,
    handleDateOfBirthChange,
  } = useSharedHandlers(
    consult.id,
    consultMainType,
    saveConsultChange,
    setValue,
    getValues,
  );

  const facilityValue = watch(facility.name);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    clearErrors();
    if (tabErrors) trigger(tabErrors);
  }, [tabErrors, clearErrors, trigger]);

  useEffect(() => {
    handleFacilityChange(facilityValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityValue]);

  return (
    <GridWrapper spacing={10}>
      <Grid item sm={12} lg={7}>
        <QuestionsPanel
          control={control}
          register={register}
          getFieldError={getFieldError}
          onChange={handleFieldChange}
          disabled={!isEditable}
          {...eEGTypePanel(watch(consultType.name))}
        />
        {leftPanels1.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
        <Box mb={1}>
          <TextInput
            register={register}
            name={firstName.name}
            label={firstName.label}
            required
            disabled={!isEditable}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            register={register}
            label={lastName.label}
            name={lastName.name}
            required
            disabled={!isEditable}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            error={getFieldError(dateOfBirth.name)}
            openTo="year"
            disableFuture
            disabled={!isEditable}
            onChange={handleDateOfBirthChange}
          />
        </Box>
        {leftPanels2.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
        <Box mb={1}>
          <SelectWithFilter
            control={control}
            name={facility.name}
            label={facility.label}
            data={facilities}
            error={getFieldError(facility.name)}
            required
            onChange={handleFieldChange}
            disabled={!isEditable}
          />
        </Box>
        {leftPanels3.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
      <Grid item sm={12} lg={5}>
        {rightPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default Demographics;
