import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { consultsActions } from 'features/consults/store/slice';
import {
  ConsultTypeEnum,
  ConsultFieldTypeEnum,
  ConsultStates,
} from 'features/consults/utils/enums';
import { consultErrors } from 'features/consults/utils/validators';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';

import {
  consultCancelledValue,
  errorDuplicateValue,
} from 'features/consults/shared/tabs/Signature/formFields';

const formatInput = (value, fieldType) => {
  if (!value) {
    return null;
  }

  switch (fieldType) {
    case 'number':
      return Number(value);

    case 'text':
    case ConsultFieldTypeEnum.DICTATION:
      return value.trim();

    case ConsultFieldTypeEnum.DATETIME:
      return formatDate(value, TIMESTAMP_FORMAT);

    default:
      return value;
  }
};

const useConsultData = (tabName, formFields) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { consult, consultLock, profileData } = useSelector(
    ({ consults, profile }) => ({
      consult: consults.currentConsult,
      consultLock: consults.consultLock,
      profileData: profile.data,
    }),
  );

  const consultTabData = useMemo(() => consult[tabName] || {}, [
    consult,
    tabName,
  ]);

  // Extract consult type from URL path
  const consultMainType = useMemo(() => {
    const typeRegex = new RegExp(
      `(${Object.values(ConsultTypeEnum).join('|')})`,
      'gi',
    );
    const match = pathname.match(typeRegex);

    return match ? match[0].toLowerCase() : null;
  }, [pathname]);

  const isClosed = useMemo(
    () =>
      [ConsultStates.CLOSED, ConsultStates.CANCELLED].includes(
        consult.signature?.state,
      ),
    [consult.signature],
  );

  const isEditable = useMemo(
    () => consult.signature?.assignToId === profileData.id && !isClosed,
    [profileData.id, consult.signature, isClosed],
  );

  const tabErrors = useMemo(
    () => consultLock.errors && consultLock.errors[tabName],
    [consultLock.errors, tabName],
  );

  useEffect(() => {
    if (!consultLock.requested) return;
    const lockErrors = consultErrors(consult, consultMainType);
    if (!isEqual(consultLock.errors, lockErrors)) {
      dispatch(consultsActions.setLockErrors(lockErrors));
    }
  }, [consult, consultLock, consultMainType, dispatch]);

  const saveConsultChange = useCallback(
    (changes) => {
      const payload = { consultId: consult.id, tab: tabName, changes };

      dispatch(consultsActions.saveConsultChanges(payload));
      dispatch(consultsActions.editConsult(payload));
    },
    [consult.id, dispatch, tabName],
  );

  const handleFieldChange = useCallback(
    (fieldName, type) => {
      const value = formFields(fieldName) || formFields()[fieldName];
      if (value !== undefined) {
        saveConsultChange({
          [fieldName]: formatInput(value, type),
        });
      }
    },
    [formFields, saveConsultChange],
  );

  const handleOwnerChange = useCallback(
    (fieldName) => {
      const ownerId = formFields(fieldName);

      if (!ownerId) return;

      dispatch(
        consultsActions.updateConsultOwner({
          consultId: consult.id,
          ownerId,
        }),
      );
    },
    [formFields, consult.id, dispatch],
  );

  const handleConsultLock = useCallback(
    (summary, lockAt, attestationValue) => {
      const lockErrors = consultErrors(consult, consultMainType);
      const lockAction = [consultCancelledValue, errorDuplicateValue].includes(
        attestationValue,
      )
        ? ConsultStates.CANCELLED
        : ConsultStates.CLOSED;

      if (lockAction === ConsultStates.CANCELLED || isEmpty(lockErrors)) {
        dispatch(
          consultsActions.lockConsult({
            consultId: consult.id,
            lockById: profileData.id,
            lockAt,
            lockAction,
            summary,
          }),
        );
      } else {
        dispatch(consultsActions.setLockErrors(lockErrors));
      }
    },
    [consult, consultMainType, profileData.id, dispatch],
  );

  return {
    consult,
    consultTabData,
    consultMainType,
    isClosed,
    isEditable,
    saveConsultChange,
    handleFieldChange,
    handleOwnerChange,
    handleConsultLock,
    tabErrors,
  };
};

export default useConsultData;
