import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import debounce from 'lodash/debounce';

import { Box } from 'styles/layout';
import useFormState from 'shared/hooks/useFormState';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import useSharedHandlers from 'features/consults/hooks/useSharedHandlers';
import { clinicDemographicsFields as formFields } from 'features/consults/shared/tabs/Demographics/formFields';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { formatToBrowserDate, isValidDate } from 'utils/dates';

import schema from './validationSchema';
import { leftPanels, rightPanels } from './data';

const { firstName, lastName, dateOfBirth, dateOfVisit } = formFields;

const DemographicsClinic = () => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setValue,
    setFormValues,
    clearErrors,
    trigger,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    consultMainType,
    isEditable,
    saveConsultChange,
    handleFieldChange,
    tabErrors,
  } = useConsultData(CONSULT_TABS.DEMOGRAPHICS, getValues);
  const { handleNameChange, handleDateOfBirthChange } = useSharedHandlers(
    consult.id,
    consultMainType,
    saveConsultChange,
    setValue,
    getValues,
  );

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    clearErrors();
    if (tabErrors) trigger(tabErrors);
  }, [tabErrors, clearErrors, trigger]);

  const handleDateOfVisitChange = debounce(() => {
    const visitDate = getValues('dateOfVisit');

    if (isValidDate(visitDate)) {
      saveConsultChange({
        dateOfVisit: formatToBrowserDate(visitDate),
      });
    }
  }, 500);

  return (
    <GridWrapper spacing={10}>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <TextInput
            register={register}
            name={firstName.name}
            label={firstName.label}
            required
            disabled={!isEditable}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <TextInput
            register={register}
            label={lastName.label}
            name={lastName.name}
            required
            disabled={!isEditable}
            getFieldError={getFieldError}
            onChange={handleNameChange}
          />
        </Box>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfBirth.name}
            label={dateOfBirth.label}
            error={getFieldError(dateOfBirth.name)}
            openTo="year"
            disableFuture
            onChange={handleDateOfBirthChange}
            disabled={!isEditable}
          />
        </Box>
        {leftPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box mb={1}>
          <DatePicker
            control={control}
            name={dateOfVisit.name}
            label={dateOfVisit.label}
            error={getFieldError(dateOfVisit.name)}
            onChange={handleDateOfVisitChange}
            disabled={!isEditable}
          />
        </Box>
        {rightPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
            {...section}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default DemographicsClinic;
