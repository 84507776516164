import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { LOCKED_ERROR_CODE } from 'utils/constants';
import { ConsultTypeRequestEnum } from 'features/consults/utils/enums';

export const SLICE_PREFIX = 'consults';
export const UPDATE_CONSULT_ACTION = `${SLICE_PREFIX}/editConsult`;

export const listConsults = createAsyncThunk(
  `${SLICE_PREFIX}/listConsults`,
  async (
    { consultType, page, sortBy, sortOrder, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getConsults(consultType, {
        page,
        sortBy,
        sortOrder,
        filters,
      });

      return {
        consultType,
        data: data.consults,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultById = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultById`,
  async ({ consultId, forceReload }, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultById(consultId);

      return { id: Number(consultId), ...data.consult, forceReload };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addNewConsult = createAsyncThunk(
  `${SLICE_PREFIX}/addNewConsult`,
  async (consultType, { rejectWithValue }) => {
    try {
      const { data } = await api.createConsult({
        type: ConsultTypeRequestEnum[consultType],
      });

      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const copyConsult = createAsyncThunk(
  `${SLICE_PREFIX}/copyConsult`,
  async (consultId, { rejectWithValue }) => {
    try {
      const { data } = await api.copyConsult(consultId);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editConsult = createAsyncThunk(
  UPDATE_CONSULT_ACTION,
  async ({ consultId, tab, changes, cb }, { rejectWithValue }) => {
    try {
      const { data } = await api.updateConsult(consultId, tab, changes);
      if (cb) cb();
      return data;
    } catch (err) {
      return rejectWithValue(
        err.response.status === LOCKED_ERROR_CODE
          ? { title: err.response.data }
          : err.response.data,
      );
    }
  },
);

export const updateConsultOwner = createAsyncThunk(
  `${SLICE_PREFIX}/updateConsultOwner`,
  async ({ consultId, ownerId }, { rejectWithValue }) => {
    try {
      await api.updateConsultOwner(consultId, ownerId);
      return ownerId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const lockConsult = createAsyncThunk(
  `${SLICE_PREFIX}/lockConsult`,
  async (
    { consultId, lockById, lockAt, lockAction, summary },
    { rejectWithValue },
  ) => {
    try {
      await api.lockConsult(consultId, { summary });

      return { lockById, lockAt, lockAction };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getConsultCount = createAsyncThunk(
  `${SLICE_PREFIX}/getConsultCount`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.getConsultCount();
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editConsultFlag = createAsyncThunk(
  `${SLICE_PREFIX}/editConsultFlag`,
  async ({ consultId, color }, { rejectWithValue }) => {
    try {
      const { data } = await api.setConsultFlag(consultId, { color });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addAddendum = createAsyncThunk(
  `${SLICE_PREFIX}/addAddendum`,
  async (
    { consultId, text, sendToFaxNumber, summary },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.addConsultAddendum(consultId, {
        text,
        sendToFaxNumber,
        summary,
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const listMacros = createAsyncThunk(
  `${SLICE_PREFIX}/listMacros`,
  async (
    { page, sortBy, sortOrder, pageSize, filters },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.getMacros({
        page,
        sortBy,
        sortOrder,
        pageSize,
        filters,
      });

      return {
        data: data.macros,
        pageCount: data.pageCount,
      };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addNewMacro = createAsyncThunk(
  `${SLICE_PREFIX}/addNewMacro`,
  async ({ consultId, name, ...rest }, { rejectWithValue }) => {
    try {
      const { data } = await api.createMacro({
        consultId,
        name,
      });
      return { id: data.id, name, ...rest };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const applyMacro = createAsyncThunk(
  `${SLICE_PREFIX}/applyMacro`,
  async ({ consultId, consultMacroId }, { rejectWithValue }) => {
    try {
      const { data } = await api.applyMacro({ consultId, consultMacroId });

      return { id: consultId, ...data.consult };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteMacro = createAsyncThunk(
  `${SLICE_PREFIX}/deleteMacro`,
  async (macroId, { rejectWithValue }) => {
    try {
      await api.deleteMacro(macroId);

      return macroId;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const linkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/linkOrder`,
  async (
    { hl7OrderId, consultId, consultType, summary },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await api.linkOrder({ hl7OrderId, consultId, summary });
      return { ...data, consultId, consultType };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const unlinkOrder = createAsyncThunk(
  `${SLICE_PREFIX}/unlinkOrder`,
  async ({ consultId, consultType }, { rejectWithValue }) => {
    try {
      const { data } = await api.unlinkOrder({ consultId });
      return { ...data, consultId, consultType };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
