import {
  ConsultTypeEnum,
  FilterTypePropNames,
} from 'features/consults/utils/enums';
import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from './formFields';

export const leftPanels = [
  {
    elements: [
      mapChipFieldOptions(fields.pastMedicalHistoryPredefined),
      fields.pastMedicalHistoryFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.pastSurgicalHistoryPredefined),
      fields.pastSurgicalHistoryFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.familyHistoryFirstDegreeRelativePredefined),
      fields.familyHistoryFirstDegreeRelativeFreeText,
    ],
  },
];

export const rightPanels = (consultType) => [
  {
    elements: [
      mapToggleFieldOptions(fields.socialHistorySmoking),
      mapToggleFieldOptions(fields.socialHistoryDrinking),
      mapChipFieldOptions(fields.socialHistoryDrugsPredefined),
      mapChipFieldOptions(fields.socialHistoryLivingPredefined),
      fields.socialHistoryLivingFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions({
        ...fields.medicationsPredefined,
        options:
          consultType === ConsultTypeEnum.CLINIC
            ? fields.medicationsPredefined.options.filter(
                (option) => !option[FilterTypePropNames.NEURO],
              )
            : fields.medicationsPredefined.options,
      }),
      fields.medicationsFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.allergiesPredefined),
      fields.allergiesFreeText,
    ],
  },
];
