import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  signIn,
  signOut,
  sendForgotPasswordCode,
  resetPassword,
  setPassword,
  signInWithGoogle,
  confirmSignIn,
} from 'auth/amplify/authentication';
import { mapUserToStoreFormat } from 'utils/mappers';

export const SLICE_PREFIX = 'auth';

let tempLoggedUser = null;

export const loginUser = createAsyncThunk(
  `${SLICE_PREFIX}/loginUser`,
  async ({ username, password }) => {
    const user = await signIn(username, password);
    if (user.challengeName) {
      tempLoggedUser = user;
    }
    return mapUserToStoreFormat(user, user.challengeName);
  },
);

export const loginUserConfirm = createAsyncThunk(
  `${SLICE_PREFIX}/loginUserConfirm`,
  async (code) => {
    const loggedUser = await confirmSignIn(
      tempLoggedUser,
      code,
      tempLoggedUser.challengeName,
    );
    return mapUserToStoreFormat(loggedUser);
  },
);

export const loginWithGoogle = createAsyncThunk(
  `${SLICE_PREFIX}/loginWithGoogle`,
  async () => {
    await signInWithGoogle();
  },
);

export const logoutUser = createAsyncThunk(
  `${SLICE_PREFIX}/logoutUser`,
  async () => {
    await signOut();
  },
);

export const sendForgotUserPasswordCode = createAsyncThunk(
  `${SLICE_PREFIX}/sendForgotUserPasswordCode`,
  async (username) => {
    await sendForgotPasswordCode(username);
  },
);

export const resetUserPassword = createAsyncThunk(
  `${SLICE_PREFIX}/resetUserPassword`,
  async ({ username, verificationCode, newPassword }) => {
    await resetPassword(username, verificationCode, newPassword);

    const user = await signIn(username, newPassword);
    if (user.challengeName) {
      tempLoggedUser = user;
      return mapUserToStoreFormat(user, user.challengeName);
    }
    return mapUserToStoreFormat(user);
  },
);

export const setPermanentPassword = createAsyncThunk(
  `${SLICE_PREFIX}/setPermanentPassword`,
  async (newPassword) => {
    const user = await setPassword(tempLoggedUser, newPassword);

    tempLoggedUser = null;

    return mapUserToStoreFormat(user);
  },
);
