import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import ConsultRequestList from './ConsultRequestList';
import ConsultRequestPage from './ConsultRequestPage';

const ConsultRequests = () => (
  <Switch>
    <Route
      exact
      path={AdminPaths.ConsultRequests}
      component={ConsultRequestList}
    />
    <Route
      exact
      path={`${AdminPaths.ConsultRequests}/:consultRequestId`}
      component={ConsultRequestPage}
    />
  </Switch>
);

export default ConsultRequests;
