import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userRoles } from 'auth/store/selectors';
import { AuthRoleEnum } from 'auth/utils/enums';

const doctorRoles = [AuthRoleEnum.NEURO, AuthRoleEnum.CLINIC, AuthRoleEnum.EEG];

const useRoles = () => {
  const roles = useSelector(userRoles);

  const isDoctor = useMemo(
    () => roles.some((role) => doctorRoles.includes(role)),
    [roles],
  );

  const isCustomerOnly = useMemo(
    () => roles.every((role) => role === AuthRoleEnum.CUSTOMER),
    [roles],
  );

  return {
    isDoctor,
    isCustomerOnly,
    doctorRoles,
  };
};

export default useRoles;
