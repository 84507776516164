import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@material-ui/core';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import BasicDialog from 'shared/components/feedback/BasicDialog';

const LockChart = ({
  label = null,
  size = 'medium',
  color = 'primary',
  dialogTitle,
  disabled,
  checked,
  onChange,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <Switch
        disabled={disabled}
        size={size}
        color={color}
        checked={checked}
        onChange={() => setDialogVisible(true)}
      />
      <BasicDialog
        title={dialogTitle}
        open={dialogVisible}
        handleConfirm={() => {
          setDialogVisible(false);
          onChange();
        }}
        handleCancel={() => setDialogVisible(false)}
      />
    </>
  );
};

LockChart.propTypes = {
  label: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  dialogTitle: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default LockChart;
