import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Paths from 'navigation/paths';
import NavBar from 'navigation/components/NavBar/NavBar';
import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';
import { isLoggedIn } from 'auth/store/selectors';
import usePrevious from 'shared/hooks/usePrevious';
import { FlexBoxFull, FullPageColumnWrapper } from 'styles/layout';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const loggedIn = useSelector(isLoggedIn);
  const prevLoggedIn = usePrevious(loggedIn);

  const userId = useSelector(({ profile }) => profile.data.id);
  const { canAccessRoute } = useRoleBasedRouting();

  // Redirect to the login screen if the user is NOT logged in
  const renderRoute = (routeProps) => {
    if (!loggedIn) {
      const {
        location: { pathname, search },
      } = routeProps;
      const redirectState = !prevLoggedIn
        ? { redirectTo: `${pathname}${search}` }
        : null;

      return (
        <Redirect
          to={{
            pathname: Paths.Login,
            state: redirectState,
          }}
        />
      );
    }

    if (!canAccessRoute(routeProps)) {
      return <Redirect to={Paths.Restricted} />;
    }

    if (!userId) return null;

    return (
      <FullPageColumnWrapper>
        <NavBar />
        <FlexBoxFull>
          <Component {...routeProps} />
        </FlexBoxFull>
      </FullPageColumnWrapper>
    );
  };

  return <Route {...rest} render={renderRoute} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default ProtectedRoute;
