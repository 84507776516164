import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import InputField from 'shared/components/forms/InputField/InputField';
import Autocomplete from 'shared/components/forms/Autocomplete/Autocomplete';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import RadioGroup from 'shared/components/forms/RadioGroup';
import Select from 'shared/components/forms/Select/Select';
import ControlledSelect from 'shared/components/forms/Select/ControlledSelect';
import Switch from 'shared/components/forms/Switch/Switch';

import api from 'api';
import listOfStates from 'utils/states';
import { timezoneOptions } from 'utils/timezones';
import useRequest from 'shared/hooks/useRequest';
import FACILITYFORM, {
  mobileImagingTypes,
  strokeCertificationOptions,
  hospitalSizeOptions,
} from './constants';

const {
  active,
  name,
  street,
  city,
  state,
  zipCode,
  timezone,
  phone,
  itPhone,
  callCenterPhone,
  fax,
  emrName,
  emrLink,
  coverage,
  directBeamIn,
  neuroNetwork,
  eegNetwork,
  radiology,
  radiologyLink,
  sharedSignout,
  sharedSignoutLink,
  camera,
  cameraLink,
  mobileImagings,
  note,
  isIntegrated,
  r1Integration,
  hL7FacilityCode,
  strokeCertification,
  hospitalSize,
  codeFromSystem,
  siteCode,
} = FACILITYFORM;

const FacilityForm = ({
  register,
  getFieldError,
  disabled = false,
  control,
  networks,
}) => {
  const { locationId } = useParams();
  const { doRequest: getSite, data: siteData } = useRequest(
    api.getHealthSystemById,
  );

  const isIntegratedValue = control.watchInternal(isIntegrated);
  const codeFromSystemValue = control.watchInternal(codeFromSystem);
  const { setValue } = control;

  useEffect(() => {
    getSite(locationId);
  }, [getSite, locationId]);

  useEffect(() => {
    if (
      !(
        siteData?.healthSystem?.siteCode &&
        codeFromSystemValue &&
        isIntegratedValue
      )
    )
      return;
    setValue(siteData.healthSystem.siteCode, siteData.siteCode);
  }, [siteData, codeFromSystemValue, setValue, isIntegratedValue]);

  return (
    <Grid container spacing={6}>
      <Grid container item xs={12} md={8} spacing={2}>
        <Grid item md={6} xs={12}>
          <Switch
            control={control}
            name={active}
            label="Active"
            disabled={disabled}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={name}
              label="Facility Name"
              required
              disabled={disabled}
              error={getFieldError(name)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Switch
              control={control}
              name={isIntegrated}
              label="Integrated Facility"
              disabled={disabled}
            />
          </Grid>
        </Grid>

        {isIntegratedValue && (
          <>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputField
                  inputRef={register}
                  name={hL7FacilityCode}
                  label="HL7 Facility Code"
                  required
                  disabled={disabled}
                  error={getFieldError(hL7FacilityCode)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Switch
                  control={control}
                  name={r1Integration}
                  label="R1 Integration"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputField
                  inputRef={register}
                  name={siteCode}
                  label="Site Code"
                  required
                  disabled={disabled || codeFromSystemValue}
                  error={getFieldError(siteCode)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Switch
                  control={control}
                  name={codeFromSystem}
                  label="Inherit from Parent"
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <InputField
            inputRef={register}
            name={street}
            label="Address"
            disabled={disabled}
            error={getFieldError(street)}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={4} xs={12}>
            <InputField
              inputRef={register}
              name={city}
              label="City"
              disabled={disabled}
              error={getFieldError(city)}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Select
              inputRef={register}
              name={state}
              label="State"
              disabled={disabled}
              error={getFieldError(state)}
              options={listOfStates}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <InputField
              inputRef={register}
              name={zipCode}
              label="Zip Code"
              disabled={disabled}
              inputProps={{ length: 5 }}
              error={getFieldError(zipCode)}
            />
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Select
            inputRef={register}
            name={timezone}
            label="Time Zone"
            disabled={disabled}
            error={getFieldError(timezone)}
            options={timezoneOptions}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              control={control}
              name={phone}
              label="ED Number"
              disabled={disabled}
              error={getFieldError(phone)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              control={control}
              name={itPhone}
              label="IT phone number"
              disabled={disabled}
              error={getFieldError(itPhone)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              control={control}
              name={callCenterPhone}
              label="Call Center Phone Number"
              disabled={disabled}
              error={getFieldError(callCenterPhone)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              control={control}
              name={fax}
              label="Fax Number"
              disabled={disabled}
              error={getFieldError(fax)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={emrName}
              label="EMR Name"
              disabled={disabled}
              error={getFieldError(emrName)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={emrLink}
              label="EMR Link"
              disabled={disabled}
              error={getFieldError(emrLink)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={radiology}
              label="Radiology"
              disabled={disabled}
              error={getFieldError(radiology)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={radiologyLink}
              label="Radiology Link"
              disabled={disabled}
              error={getFieldError(radiologyLink)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={sharedSignout}
              label="Shared Sign-Out"
              disabled={disabled}
              error={getFieldError(sharedSignout)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={sharedSignoutLink}
              label="Shared Sign-Out Link"
              disabled={disabled}
              error={getFieldError(sharedSignoutLink)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={camera}
              label="Camera"
              disabled={disabled}
              error={getFieldError(camera)}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              inputRef={register}
              name={cameraLink}
              label="Camera Link"
              disabled={disabled}
              error={getFieldError(cameraLink)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputField
            inputRef={register}
            name={coverage}
            label="Onsite Neuro Coverage"
            disabled={disabled}
            error={getFieldError(coverage)}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <RadioGroup
              control={control}
              name={directBeamIn}
              label="Direct Beam-In"
              options={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' },
              ]}
              error={getFieldError(directBeamIn)}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <ControlledSelect
              control={control}
              name={mobileImagings}
              label="Mobile Imaging"
              disabled={disabled}
              multiple
              error={getFieldError(mobileImagings)}
              options={mobileImagingTypes}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item md={6} xs={12}>
            <Select
              inputRef={register}
              name={strokeCertification}
              label="Stroke Certification"
              disabled={disabled}
              error={getFieldError(strokeCertification)}
              options={strokeCertificationOptions}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Select
              inputRef={register}
              name={hospitalSize}
              label="Hospital Size"
              disabled={disabled}
              error={getFieldError(hospitalSize)}
              options={hospitalSizeOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              control={control}
              name={neuroNetwork}
              disabled={disabled}
              label="Neuro Network"
              data={networks}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              control={control}
              name={eegNetwork}
              disabled={disabled}
              label="EEG Network"
              data={networks}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              inputRef={register}
              name={note}
              multiline
              rows={6}
              label="BSL Facility Note"
              disabled={disabled}
              error={getFieldError(note)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FacilityForm.propTypes = {
  register: PropTypes.func.isRequired,
  getFieldError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  control: PropTypes.shape({
    watchInternal: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
  networks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FacilityForm;
