import React from 'react';
import PropTypes from 'prop-types';

import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';

const DateTimeFilter = ({
  control,
  name,
  placeholder,
  handleFilter,
  ...moreProps
}) => {
  const handleChange = () => {
    const value = control.watchInternal(name);
    try {
      handleFilter({
        [name]: value ? formatDate(new Date(value), TIMESTAMP_FORMAT) : null,
      });
    } catch {
      handleFilter({ [name]: null });
    }
  };

  return (
    <DateTimeInput
      {...moreProps}
      control={control}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
};

DateTimeFilter.propTypes = {
  control: PropTypes.shape({
    watchInternal: PropTypes.func.isRequired,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default DateTimeFilter;
