import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import InputField from 'shared/components/forms/InputField/InputField';
import useFormState from 'shared/hooks/useFormState';
import { DEBOUNCE_TIMEOUT } from 'utils/constants';

const ConsultTypeNote = ({ onFetch, onCreate, onUpdate, onDelete, itemId }) => {
  const { register, setFormValues } = useFormState();
  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const note = await onFetch();
        setNoteId(note.id);
        setFormValues({ note: note.text });
      } catch {
        /* setting noteId to false will allow the input to be
         enabled but it will create a note instead of updating */
        setNoteId(false);
        setFormValues({ note: '' });
      }
    };
    if (itemId) fetchNotes();
    else setFormValues({ note: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormValues, itemId]);

  const updateNote = useMemo(
    () =>
      debounce((text) => {
        if (noteId) {
          if (text) onUpdate(text);
          else {
            const deleteNote = async () => {
              await onDelete();
              setNoteId(false);
            };
            deleteNote();
          }
        } else {
          const createNote = async () => {
            const newNoteId = await onCreate(text);
            setNoteId(newNoteId);
          };
          createNote();
        }
      }, DEBOUNCE_TIMEOUT),
    [noteId, onCreate, onUpdate, onDelete],
  );

  const onNoteChanged = useCallback(
    ({ target }) => updateNote(target.value),
    [updateNote],
  );

  return (
    <InputField
      inputRef={register}
      name="note"
      multiline
      rows={5}
      onChange={onNoteChanged}
      disabled={!itemId || noteId === null}
    />
  );
};

ConsultTypeNote.propTypes = {
  onFetch: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  itemId: PropTypes.number,
};

export default ConsultTypeNote;
