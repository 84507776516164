import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Toolbar } from '@material-ui/core';

import Button from 'shared/components/buttons/Button/Button';

export const HomeLink = styled(Link)`
  display: block;
  padding: 0 10px;
`;

export const HomeLogo = styled.img`
  max-width: 100%;
  width: 65px;
`;

export const LogoutBtn = styled(Button)`
  border-radius: 0;
`;

export const SmallScreenToolbar = styled(Toolbar)`
  padding: 0 10px;
`;
