import React from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumnCenter } from 'styles/layout';
import Table from 'shared/components/data/Table/Table';
import Pagination from 'shared/components/data/Pagination/Pagination';

const PaginatedTable = ({
  headings,
  data,
  fallbackMsg,
  sortBy,
  sortOrder,
  currentPage = 1,
  totalPages = 1,
  selectedRowId = null,
  onPageChange,
  onSortChange,
  onClickRow,
}) => (
  <FlexBoxColumnCenter>
    <Table
      headings={headings}
      data={data}
      fallbackMsg={fallbackMsg}
      sortBy={sortBy}
      sortOrder={sortOrder}
      selectedRowId={selectedRowId}
      onSortChange={onSortChange}
      onClickRow={onClickRow}
    />
    {data.length > 0 && (
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    )}
  </FlexBoxColumnCenter>
);

PaginatedTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fallbackMsg: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  selectedRowId: PropTypes.number,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  onClickRow: PropTypes.func,
};

export default PaginatedTable;
