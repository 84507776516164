import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userRoles } from 'auth/store/selectors';
import { RoutesForRoleEnum } from 'auth/utils/enums';
import Paths from 'navigation/paths';

const useRoleBasedRouting = () => {
  const roles = useSelector(userRoles);

  const homePage = useMemo(() => {
    const primaryRole = roles
      .map((role) => RoutesForRoleEnum[role])
      .sort((a, b) => a.priority - b.priority)[0];

    if (primaryRole) {
      return primaryRole.routes[0];
    }

    return Paths.Login;
  }, [roles]);

  const permittedRoutes = useMemo(
    () => [
      ...new Set([
        ...roles.flatMap((role) => RoutesForRoleEnum[role]?.routes),
        Paths.Schedule,
        Paths.Profile,
      ]),
    ],
    [roles],
  );

  const canAccessRoute = ({ location }) => {
    const [, mainRouteName] = location.pathname.split('/');

    return permittedRoutes.includes(`/${mainRouteName}`);
  };

  return {
    homePage,
    permittedRoutes,
    canAccessRoute,
  };
};

export default useRoleBasedRouting;
