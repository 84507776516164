import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { differenceInYears } from 'date-fns';
import debounce from 'lodash/debounce';

import api from 'api';
import { consultsActions } from 'features/consults/store/slice';
import {
  ConsultTypeEnum,
  ConsultNetworkTypesEnum,
} from 'features/consults/utils/enums';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { formatToBrowserDate } from 'utils/dates';
import { capitalizeFirstLetter } from 'utils/helpers';

const useSharedHandlers = (
  consultId,
  consultMainType,
  saveConsult,
  setFormValue,
  formFields,
) => {
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    if (consultMainType !== ConsultTypeEnum.CLINIC) {
      const fetchFacilities = async () => {
        const { data } = await api.getFacilityNames(
          ConsultNetworkTypesEnum[consultMainType],
          { pageSize: MAX_PAGE_SIZE },
        );
        setFacilities(
          data.facilities.map(({ id, name }) => ({
            value: id,
            label: name,
          })),
        );
      };

      fetchFacilities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNameChange = (fieldName) => {
    const value = formFields(fieldName);

    if (value !== undefined) {
      const formattedValue = value.trim();
      const propName = `patient${capitalizeFirstLetter(fieldName)}`;

      dispatch(
        consultsActions.updateConsultInList({
          id: consultId,
          consultType: consultMainType,
          propToUpdate: { [propName]: formattedValue },
        }),
      );
      saveConsult({
        [fieldName]: formattedValue,
      });
    }
  };

  const handleFacilityChange = useCallback(
    (facilityId) => {
      if (facilityId === undefined || !facilities.length) return;

      const facility = facilities.find((item) => item.value === facilityId);
      let facilityName = null;

      if (facility) {
        const [name] = facility.label.split(' - ');
        facilityName = name;
      }

      dispatch(
        consultsActions.updateConsultInList({
          id: consultId,
          consultType: consultMainType,
          propToUpdate: { facilityName },
        }),
      );
    },
    [consultId, consultMainType, dispatch, facilities],
  );

  const handleDateOfBirthChange = debounce(() => {
    const dob = formFields('dateOfBirth');

    if (dob) {
      const patientAge = differenceInYears(new Date(), dob);

      if (!Number.isNaN(patientAge)) {
        setFormValue('age', patientAge);
        saveConsult({
          dateOfBirth: formatToBrowserDate(dob),
          age: patientAge,
        });
      }
    } else {
      setFormValue('age', null);
      saveConsult({
        dateOfBirth: null,
        age: null,
      });
    }
  }, 1000);

  return {
    facilities,
    handleNameChange,
    handleFacilityChange,
    handleDateOfBirthChange,
  };
};

export default useSharedHandlers;
