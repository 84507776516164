import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import debounce from 'lodash/debounce';

import InputField from 'shared/components/forms/InputField/InputField';

const SearchIcon = styled(Search)`
  color: #95989a;
`;

const SearchField = ({
  inputRef,
  name = 'search',
  fullWidth = false,
  defaultValue = '',
  onChange,
  ...props
}) => {
  const handleChange = debounce((value) => {
    onChange(value);
  }, 500);

  return (
    <InputField
      inputRef={inputRef}
      name={name}
      type="search"
      placeholder="Search"
      fullWidth={fullWidth}
      defaultValue={defaultValue}
      endAdornment={(
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      )}
      onChange={(e) => handleChange(e.target.value)}
      {...props}
    />
  );
};

SearchField.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SearchField;
