import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

import Paths from 'navigation/paths';
import MenuList from 'navigation/components/MenuList/MenuList';
import { Box, FlexBoxColumn, FlexBoxColumnSpaceBetween } from 'styles/layout';
import {
  NavDrawer,
  CloseBtnWrapper,
  CloseDrawerBtn,
  DrawerMenuBtn,
} from './DrawerMenu.style';

const DrawerMenu = ({ isOpen, menuItems, showProfile, onClose, onLogout }) => (
  <NavDrawer isOpen={isOpen} onClose={onClose}>
    <FlexBoxColumnSpaceBetween height="90%">
      <FlexBoxColumn>
        <CloseBtnWrapper mb={1}>
          <CloseDrawerBtn onClick={onClose}>
            <Close />
          </CloseDrawerBtn>
        </CloseBtnWrapper>
        <MenuList items={menuItems} isMobile onNavigationChange={onClose} />
      </FlexBoxColumn>
      <FlexBoxColumn>
        {showProfile && (
          <Box mb={1.5}>
            <MenuList
              items={[{ name: 'Profile', path: Paths.Profile }]}
              isMobile
              onNavigationChange={onClose}
            />
          </Box>
        )}
        <Box pl={3.75}>
          <DrawerMenuBtn onClick={onLogout}>Logout</DrawerMenuBtn>
        </Box>
      </FlexBoxColumn>
    </FlexBoxColumnSpaceBetween>
  </NavDrawer>
);

DrawerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  showProfile: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default DrawerMenu;
