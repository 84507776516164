export default {
  transparent: 'transparent',
  white: '#fff',
  black: '#000',
  primary: '#1382CA',
  secondary: '#5BC9E1',
  neutral: '#F5F5F5',
  neutralDark: '#707070',
  dark: '#000000DE',
  dark2: '#303030',
  backgroundLight: '#F4F9FC',
  background: '#EDF9FC',
  error: '#B00020',
  success: '#80D684',
  warning: '#FFF836',
  warningDark: '#F8C44E',
  notification: '#FD3E64',
  optionSelected: '#E0E0E0',
  shiftNeuroBackground: '#D4D4FF',
  shiftEegBackground: '#C7F09E',
  placeholder: 'rgba(0, 0, 0, 0.4)',
};
