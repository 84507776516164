import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Signature/formFields';

// eslint-disable-next-line import/prefer-default-export
export const leftPanels = [
  {
    elements: [fields.attestationOfEEGCompletion],
  },
  {
    elements: [mapToggleFieldOptions(fields.eegTelemedicine)],
  },
  {
    elements: [mapToggleFieldOptions(fields.providerLocationPredefined)],
  },
  {
    elements: [fields.providerLocationFreeText],
  },
  {
    elements: [fields.patientLocation],
  },
  {
    elements: [fields.eegcptCode],
  },
];
