import { useCallback } from 'react';

const useSelectedOptionState = (getFormValues, setFormValue) => {
  const setSelectedOptionValue = useCallback(
    (selectedId, fieldName, collection) => {
      if (selectedId && !getFormValues(fieldName) && collection.length > 0) {
        const selectedOption = collection.find(
          (option) => option.id === Number(selectedId),
        );

        if (selectedOption) {
          setFormValue(fieldName, selectedOption);
        }
      }
    },
    [getFormValues, setFormValue],
  );

  return {
    setSelectedOptionValue,
  };
};

export default useSelectedOptionState;
