import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

const Wrapper = styled(Paper)`
  margin: 20px;
  padding: 8px 30px;
  width: ${({ width }) => width || '100%'};

  ${({ $sm }) =>
    $sm &&
    `
      margin: 20px 10px;
      padding: 8px 10px;
      overflow: hidden;
  `}
`;

const MainWrapper = ({ children, sm = false, ...props }) => (
  <Wrapper elevation={2} $sm={sm} {...props}>
    {children}
  </Wrapper>
);

MainWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  sm: PropTypes.bool,
};

export default MainWrapper;
