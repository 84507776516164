import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import NetworksListPage from './NetworksListPage';
import NetworkAddPage from './NetworkAddPage';
import NetworkDetailsPage from './NetworkDetailsPage';
import FacilityDetailsPage from '../Locations/Facilities/FacilityDetailsPage';

const Networks = () => (
  <Switch>
    <Route exact path={AdminPaths.Networks} component={NetworksListPage} />
    <Route exact path={AdminPaths.AddNetwork} component={NetworkAddPage} />
    <Route
      exact
      path={`${AdminPaths.Networks}/:networkId`}
      component={NetworkDetailsPage}
    />
    <Route
      exact
      path={`${AdminPaths.Networks}/:networkId/facility/:facilityId`}
      component={FacilityDetailsPage}
    />
  </Switch>
);

export default Networks;
