import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { appStateActions } from 'store/appStateSlice';
import { isValidDate } from 'utils/dates';

import useClearError from './useClearError';

const trimObjectValues = (obj) =>
  obj &&
  Object.entries(obj).reduce(
    (acc, [key, value]) => {
      if (typeof value !== 'object' || isValidDate(value)) {
        acc[key] = typeof value === 'string' ? value.trim() : value;
        return acc;
      }

      acc[key] = trimObjectValues(value);
      return acc;
    },
    Array.isArray(obj) ? [] : {},
  );

const useFormState = (
  validationSchema,
  serverErrorConfig = { stateSlice: 'appState', showAsSnackbar: false },
  context = null,
  defaultValues = {},
) => {
  const dispatch = useDispatch();
  const {
    register,
    getValues,
    handleSubmit,
    errors,
    formState,
    reset,
    ...rest
  } = useForm({
    resolver: yupResolver(validationSchema),
    context,
    defaultValues,
  });
  const { stateSlice, showAsSnackbar } = serverErrorConfig;

  const hasFormErrors = Object.keys(errors).length > 0;

  const { serverErrorMsg, visibleSnackBar } = useSelector((state) => ({
    serverErrorMsg: state[stateSlice].error,
    visibleSnackBar: state.appState.snackbar.visible,
  }));

  useClearError(hasFormErrors, stateSlice, showAsSnackbar, visibleSnackBar);

  useEffect(() => {
    if (showAsSnackbar && serverErrorMsg) {
      dispatch(
        appStateActions.showSnackbar({
          text: serverErrorMsg,
          type: SnackbarTypeEnum.ERROR,
          duration: null,
          closable: true,
          requireManualClose: true,
        }),
      );
    }
  }, [dispatch, formState.isDirty, serverErrorMsg, showAsSnackbar]);

  const getTrimmedValues = (fieldName = null) => {
    if (fieldName) {
      const fieldValue = getValues(fieldName);
      return fieldValue ? fieldValue.trim() : null;
    }
    return trimObjectValues(getValues());
  };

  const getFieldError = (fieldName) => {
    // Split field name by "." since fields can be nested
    const objectKeys = fieldName.split('.');
    let errorObj = { ...errors };

    // eslint-disable-next-line no-restricted-syntax
    for (const key of objectKeys) {
      const err = errorObj[key];
      if (!err) {
        return null;
      }

      if (Array.isArray(err)) {
        // message can be an array
        return { message: err.map(({ message }) => message) };
      }

      if (err.type) {
        // If the error has a "type" property
        // it means it's an error object returned by react-hook-form
        return err;
      }
      errorObj = { ...err };
    }

    return null;
  };

  const getServerError = () => {
    if (hasFormErrors) {
      return null;
    }

    return serverErrorMsg;
  };

  const canSubmitForm = formState.isDirty && !hasFormErrors;

  return {
    register,
    getValues,
    getTrimmedValues,
    handleSubmit,
    formState,
    getFieldError,
    hasFormErrors,
    canSubmitForm,
    serverError: getServerError(),
    setFormValues: reset,
    ...rest,
  };
};

export default useFormState;
