import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { Box, FlexBoxColumn } from 'styles/layout';

import { ButtonGroup, ToggleButton } from './ToggleButtonGroup.style';

const ToggleButtonGroup = forwardRef(
  (
    {
      buttons,
      control,
      name,
      title = '',
      defaultValue = '',
      required = false,
      exclusive = true,
      compact = false,
      handleChange,
      disabled = false,
      error = null,
      inlineLabel = false,
    },
    ref,
  ) => (
    <FlexBoxColumn>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={(controllerProps) => (
          <Box display={inlineLabel ? 'flex' : 'block'}>
            {!!title && (
              <Box mr={inlineLabel ? 2 : 0}>
                <InputLabel required={required} error={error}>
                  {title}
                </InputLabel>
              </Box>
            )}
            <ButtonGroup
              ref={ref}
              exclusive={exclusive}
              onChange={(_e, value) => {
                if (value !== controllerProps.value) {
                  controllerProps.onChange(value);
                  if (handleChange) handleChange(value);
                }
              }}
            >
              {buttons.map(({ value, content }) => (
                <ToggleButton
                  key={value}
                  value={value}
                  selected={value === controllerProps.value}
                  size="small"
                  $compact={compact}
                  disabled={disabled}
                >
                  {content}
                </ToggleButton>
              ))}
            </ButtonGroup>
            {error && <ErrorMsg text={error.message} dense />}
          </Box>
        )}
      />
    </FlexBoxColumn>
  ),
);

ToggleButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  exclusive: PropTypes.bool,
  compact: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  handleChange: PropTypes.func,
  error: PropTypes.shape({}),
};

export default ToggleButtonGroup;
