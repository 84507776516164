import { useState, useEffect } from 'react';

import { getProfilePhoto } from 'auth/amplify/storage';

const useProfilePhotoFetch = (userId) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const s3ImageUrl = await getProfilePhoto(userId);

        setImageUrl(s3ImageUrl);
      } catch {
        setImageUrl(null);
      }
    };

    if (userId) {
      fetchProfileImage();
    }
  }, [userId]);

  return {
    imageUrl,
  };
};

export default useProfilePhotoFetch;
