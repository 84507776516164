import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ConsultIcon from '@material-ui/icons/ZoomIn';

import api from 'api';
import { appStateActions } from 'store/appStateSlice';
import useRequest from 'shared/hooks/useRequest';
import useProfile from 'shared/hooks/useProfile';
import { useTableState } from 'shared/hooks/useTableState';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import AdminPaths from 'features/admin/paths';
import { FlexBoxColumn } from 'styles/layout';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import Button from 'shared/components/buttons/Button/Button';
import IconButton from 'shared/components/buttons/IconButton/IconButton';

import { formatISO, DEFAULT_FIELD_FORMAT } from 'utils/dates';

const getTableColumns = ({ viewAdminConsultTable }) => [
  {
    header: {
      id: 'FacilityName',
      value: 'Facility',
      isSortable: true,
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: 'ConsultType',
      value: 'Service',
      isSortable: true,
    },
    dataKey: 'typeDescrition',
  },
  {
    header: {
      id: 'CreatedAt',
      value: 'Created At',
      isSortable: true,
    },
    formatValue: ({ createdAt }) => formatISO(createdAt, DEFAULT_FIELD_FORMAT),
  },
  {
    header: {
      id: 'AcceptedAt',
      value: 'Accepted At',
      isSortable: true,
    },
    dataKey: 'acceptedAt',
    formatValue: ({ acceptedAt }) =>
      formatISO(acceptedAt, DEFAULT_FIELD_FORMAT),
  },
  {
    header: {
      id: 'ProviderFullName',
      value: 'Provider',
      isSortable: true,
    },
    formatValue: ({ providerFirstName, providerLastName }) =>
      `${providerFirstName || ''} ${providerLastName || ''}`,
  },
  {
    header: {
      id: 'ConsultRequestState',
      value: 'Consult',
      isSortable: true,
    },
    align: 'center',
    width: '100px',
    formatValue: ({ consultId, type }) =>
      consultId ? (
        <Button
          disabled={!viewAdminConsultTable}
          component={Link}
          to={`${AdminPaths.Consults}/${type.toLowerCase()}/${consultId}`}
        >
          {consultId}
        </Button>
      ) : (
        '-'
      ),
  },
  {
    header: {
      id: 'consult_url',
      value: '',
    },
    align: 'center',
    width: '80px',
    formatValue: ({ id }) => (
      <IconButton component={Link} to={`${AdminPaths.ConsultRequests}/${id}`}>
        <ConsultIcon />
      </IconButton>
    ),
  },
];

const ConsultRequestList = () => {
  const dispatch = useDispatch();
  const { viewAdminConsultTable } = useProfile();

  const {
    doRequest: getList,
    data: listData,
    isLoading,
  } = useRequest(api.getAdminConsultRequests);

  const { page, sortBy, sortOrder, handlePageChange, handleSortChange } =
    useTableState('CreatedAt', 'desc');

  const listFilters = useMemo(
    () => ({
      page,
      sortOrder,
      sortBy,
    }),
    [page, sortOrder, sortBy],
  );

  useEffect(() => {
    getList(listFilters);
  }, [getList, listFilters]);

  useEffect(() => {
    dispatch(appStateActions.setLoading(isLoading));
  }, [dispatch, isLoading]);

  const tableColumns = useMemo(
    () => getTableColumns({ viewAdminConsultTable }),
    [viewAdminConsultTable],
  );

  return (
    <FlexBoxColumn>
      <PageTitle>Consult Requests</PageTitle>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(listData?.consults, tableColumns)}
        fallbackMsg="No consult requests found"
        sortBy={sortBy}
        sortOrder={sortOrder}
        currentPage={page}
        totalPages={listData?.pageCount}
        onPageChange={handlePageChange}
        onSortChange={handleSortChange}
      />
    </FlexBoxColumn>
  );
};

export default ConsultRequestList;
