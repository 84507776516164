import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { Grid } from '@material-ui/core';

import api from 'api';
import useRequest from 'shared/hooks/useRequest';
import useFormState from 'shared/hooks/useFormState';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { userRolesEnum } from 'utils/users';
import { mapDoctorNames } from 'utils/mappers';

import Button from 'shared/components/buttons/Button/Button';
import Select from 'shared/components/forms/Select/ControlledSelect';
import InputField from 'shared/components/forms/InputField/InputField';

const schema = yup.object().shape({});

const ProviderForm = ({ initValues, onSubmit, onCancel }) => {
  const { doRequest: fetchUsers, data: userData } = useRequest(
    api.getUserNames,
  );
  const { doRequest: fetchSystems, data: systemData } = useRequest(
    api.getHealthSystemNames,
  );
  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getFacilities,
  );

  const {
    getFieldError,
    control,
    register,
    setFormValues,
    setValue,
    handleSubmit,
    watch,
  } = useFormState(schema);

  const healthSystem = watch('healthSystemId');

  useEffect(
    () => {
      fetchUsers({
        pageSize: MAX_PAGE_SIZE,
        filters: {
          roles: [userRolesEnum.NEURO, userRolesEnum.CLINIC, userRolesEnum.EEG],
        },
      });
      fetchSystems({ pageSize: MAX_PAGE_SIZE });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (healthSystem) {
      fetchFacilities({
        pageSize: MAX_PAGE_SIZE,
        filters: {
          healthSystemIds: healthSystem,
        },
      });
    }
  }, [healthSystem, fetchFacilities]);

  const providerOptions = useMemo(
    () => mapDoctorNames(userData?.users ?? [], 'value', 'text'),
    [userData],
  );

  const systemOptions = useMemo(
    () =>
      systemData?.healthSystems?.map((hs) => ({
        value: hs.id,
        text: hs.name,
      })) ?? [],
    [systemData],
  );

  const facilityOptions = useMemo(
    () =>
      facilityData?.facilities.map((f) => ({ value: f.id, text: f.name })) ??
      [],
    [facilityData],
  );

  useEffect(() => {
    if (initValues) setFormValues(initValues);
  }, [initValues, setFormValues]);

  const handleFormSubmit = ({
    providerId,
    healthSystemId,
    facilityId,
    ...moreValues
  }) =>
    onSubmit({
      ...moreValues,
      providerId: Number(providerId),
      healthSystemId: Number(healthSystemId),
      facilityId: facilityId ? Number(facilityId) : null,
    });

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Select
            placeholder="-- Provider --"
            control={control}
            name="providerId"
            label="Provider"
            error={getFieldError('providerId')}
            options={providerOptions}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            control={control}
            placeholder="-- Healthcare System --"
            name="healthSystemId"
            label="Healthcare System"
            error={getFieldError('healthSystemId')}
            options={systemOptions}
            handleChange={() => {
              setValue('facilityId', '');
            }}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            placeholder="-- Facility --"
            control={control}
            name="facilityId"
            label={
              <span>
                <span>Set Per Facility</span>
                &nbsp;&nbsp;
                <span style={{ color: 'gray' }}>(Uncommon)</span>
              </span>
            }
            error={getFieldError('facilityId')}
            disabled={!healthSystem}
            options={facilityOptions}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField
            placeholder="Mnemonic"
            inputRef={register}
            name="hospitalDocId"
            label="Mnemonic (ID for location)"
            error={getFieldError('hospitalDocId')}
            required
          />
        </Grid>
        <Grid item container xs={12} justifyContent="flex-end">
          <Button color="secondary" variant="contained" type="submit">
            Save
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            style={{ marginLeft: '1rem' }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

ProviderForm.propTypes = {
  initValues: PropTypes.shape({
    providerId: PropTypes.number,
  }),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProviderForm;
