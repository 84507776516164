import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

const rosFields = {
  additionalPredefined: {
    name: 'additionalPredefined',
    label: 'Additional',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'CompleteRosNeg',
        label: 'complete ros neg',
        summaryLabel: 'complete review of systems otherwise negative',
      },
    ],
  },
  additionalFreeText: {
    name: 'additionalFreeText',
    associatedField: 'additionalPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  unableToObtainRosBecausePredefined: {
    name: 'unableToObtainRosBecausePredefined',
    label: 'Unable to obtain ROS because',
    summaryLabel: 'Unable to obtain ROS',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'AlteredMentation', label: 'altered mentation' },
      { value: 'CriticalIllness', label: 'critical illness' },
      { value: 'Dementia', label: 'dementia' },
      { value: 'Intoxication', label: 'intoxication' },
      { value: 'NonVerbal', label: 'non-verbal' },
      { value: 'PoorComprehension', label: 'poor comprehension' },
      { value: 'Uncooperative', label: 'uncooperative' },
    ],
  },
  unableToObtainRosBecauseFreeText: {
    name: 'unableToObtainRosBecauseFreeText',
    associatedField: 'unableToObtainRosBecausePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  constitutionalPredefined: {
    name: 'constitutionalPredefined',
    label: 'Constitutional',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Chills', label: 'chills' },
      { value: 'Fatigue', label: 'fatigue' },
      { value: 'Fever', label: 'fever' },
      { value: 'Lethargy', label: 'lethargy' },
      { value: 'Malaise', label: 'malaise' },
    ],
  },
  constitutionalFreeText: {
    name: 'constitutionalFreeText',
    associatedField: 'constitutionalPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  eyesPredefined: {
    name: 'eyesPredefined',
    label: 'Eyes',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'BlurryVision', label: 'blurry vision' },
      { value: 'Diplopia', label: 'diplopia' },
      { value: 'Photophobia', label: 'photophobia' },
      { value: 'VisionChangesProblems', label: 'vision changes/problems' },
      { value: 'VisionLoss', label: 'vision loss' },
    ],
  },
  eyesFreeText: {
    name: 'eyesFreeText',
    associatedField: 'eyesPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  entPredefined: {
    name: 'entPredefined',
    label: 'ENT',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'DifficultySwallowing', label: 'difficulty swallowing' },
      { value: 'HearingLoss', label: 'hearing loss' },
      { value: 'HoarseVoice', label: 'hoarse voice' },
    ],
  },
  entFreeText: {
    name: 'entFreeText',
    associatedField: 'entPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  respiratoryPredefined: {
    name: 'respiratoryPredefined',
    label: 'Respiratory',
    summaryLabel: 'Pulmonary',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Cough', label: 'cough' },
      {
        value: 'Doe',
        label: 'doe',
        summaryLabels: {
          positive: 'dyspnea on exertion',
          negative: 'no dyspnea on exertion',
        },
      },
      {
        value: 'Sob',
        label: 'sob',
        summaryLabels: {
          positive: 'shortness of breath',
          negative: 'no shortness of breath',
        },
      },
    ],
  },
  respiratoryFreeText: {
    name: 'respiratoryFreeText',
    associatedField: 'respiratoryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  cardiovascularPredefined: {
    name: 'cardiovascularPredefined',
    label: 'Cardiovascular',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'ChestPain', label: 'chest pain' },
      { value: 'Edema', label: 'edema' },
      { value: 'Lightheadedness', label: 'lightheadedness' },
      { value: 'Palpitations', label: 'palpitations' },
      { value: 'Syncope', label: 'syncope' },
    ],
  },
  cardiovascularFreeText: {
    name: 'cardiovascularFreeText',
    associatedField: 'cardiovascularPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  abdominalPainPredefined: {
    name: 'abdominalPainPredefined',
    label: 'Abdominal Pain',
    summaryLabel: 'Gastrointestinal',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'AbdominalPain', label: 'abdominal pain' },
      { value: 'Anorexia', label: 'anorexia' },
      {
        value: 'Brbpr',
        label: 'brbpr',
        summaryLabels: {
          positive: 'bright red blood per rectum',
          negative: 'no bright red blood per rectum',
        },
      },
      { value: 'Nausea', label: 'nausea' },
      { value: 'Vomiting', label: 'vomiting' },
    ],
  },
  abdominalPainFreeText: {
    name: 'abdominalPainFreeText',
    associatedField: 'abdominalPainPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  genitourinaryPredefined: {
    name: 'genitourinaryPredefined',
    label: 'Genitourinary',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Dysuria', label: 'dysuria' },
      { value: 'Hematuria', label: 'hematuria' },
      {
        value: 'Pregnant',
        label: 'pregnant',
        summaryLabels: { negative: 'not pregnant' },
      },
      { value: 'UrinaryIncontinence', label: 'urinary incontinence' },
    ],
  },
  genitourinaryFreeText: {
    name: 'genitourinaryFreeText',
    associatedField: 'genitourinaryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  musculoskeletalPredefined: {
    name: 'musculoskeletalPredefined',
    label: 'Musculoskeletal',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'BackPain', label: 'back pain' },
      { value: 'JointPain', label: 'joint pain' },
      { value: 'Myalgia', label: 'myalgia' },
      { value: 'NeckPain', label: 'neck pain' },
    ],
  },
  musculoskeletalFreeText: {
    name: 'musculoskeletalFreeText',
    associatedField: 'musculoskeletalPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  integumentaryPredefined: {
    name: 'integumentaryPredefined',
    label: 'Integumentary',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Bruising', label: 'bruising' },
      { value: 'Jaundice', label: 'jaundice' },
      { value: 'Pallor', label: 'pallor' },
    ],
  },
  integumentaryFreeText: {
    name: 'integumentaryFreeText',
    associatedField: 'integumentaryPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  neurologicSeeHPIPredefined: {
    name: 'neurologicSeeHPIPredefined',
    label: 'Neurologic',
    type: ConsultFieldTypeEnum.CHIP,
    options: [{ value: 'SeeHPI', label: 'see HPI' }],
  },
  neurologicPredefined: {
    name: 'neurologicPredefined',
    associatedField: 'neurologicSeeHPIPredefined',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'BladderIncontinence', label: 'bladder incontinence' },
      { value: 'BowelIncontinence', label: 'bowel incontinence' },
      { value: 'GeneralizedWeakness', label: 'generalized weakness' },
      { value: 'Syncope', label: 'syncope' },
    ],
  },
  neurologicFreeText: {
    name: 'neurologicFreeText',
    associatedField: 'neurologicSeeHPIPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  psychiatricPredefined: {
    name: 'psychiatricPredefined',
    label: 'Psychiatric',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      {
        value: 'Anxious',
        label: 'anxious',
        summaryLabels: { negative: 'not anxious' },
      },
      { value: 'Confusion', label: 'confusion' },
      {
        value: 'Depressed',
        label: 'depressed',
        summaryLabels: { negative: 'not depressed' },
      },
      { value: 'Hallucinations', label: 'hallucinations' },
    ],
  },
  psychiatricFreeText: {
    name: 'psychiatricFreeText',
    associatedField: 'psychiatricPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  endocrinePredefined: {
    name: 'endocrinePredefined',
    label: 'Endocrine',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Polyuria', label: 'polyuria' },
      { value: 'Polydipsia', label: 'polydipsia' },
    ],
  },
  endocrineFreeText: {
    name: 'endocrineFreeText',
    associatedField: 'endocrinePredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  hematologicPredefined: {
    name: 'hematologicPredefined',
    label: 'Hematologic',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [
      { value: 'Lymphadenopathy', label: 'lymphadenopathy' },
      { value: 'Bruising', label: 'bruising' },
    ],
  },
  hematologicFreeText: {
    name: 'hematologicFreeText',
    associatedField: 'hematologicPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  allergyPredefined: {
    name: 'allergyPredefined',
    label: 'Allergy',
    type: ConsultFieldTypeEnum.MULTICHIP,
    options: [{ value: 'AllergicReaction', label: 'allergic reaction' }],
  },
  allergyFreeText: {
    name: 'allergyFreeText',
    associatedField: 'allergyPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
};

export default rosFields;
