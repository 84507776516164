import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from 'shared/components/buttons/Button/Button';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';
import useConsultData from 'features/consults/hooks/useConsultData';
import useSummaryTabState from 'features/consults/hooks/useSummaryTabState';
import useSummaryCopy from 'features/consults/hooks/useSummaryCopy';
import { eegType } from 'features/consults/shared/tabs/Demographics/formFields';
import { consultsActions } from 'features/consults/store/slice';
import { Box, FlexBoxColumn, FlexBoxSpaceBetween } from 'styles/layout';
import AddendumForm from './components/AddendumForm';
import ConsultCopyButton from './components/ConsultCopyButton';
import useAddendumState from './hooks/useAddendumState';
import { CopyIcon } from './Summary.style';

export const ConsultSummary = forwardRef(
  ({ consult, consultMainType, isClosed }, ref) => {
    const dispatch = useDispatch();

    const {
      title,
      summarySections,
      facilityTimezone,
      facilityFaxNumber,
      closedAt,
      closedByName,
    } = useSummaryTabState(consult, consultMainType);
    const { summaryContainerRef, copyNote } = useSummaryCopy();
    const {
      addenda,
      addendumFormVisible,
      addAddendum,
      showAddendumForm,
      hideAddendumForm,
    } = useAddendumState(consult.id, isClosed);

    const copyButtonType = useMemo(
      () =>
        [eegType.AEEG, eegType.CEEG, eegType.CERIBELL].includes(
          consult.demographics?.consultType,
        )
          ? consult.demographics.consultType
          : null,
      [consult.demographics],
    );

    const handleAddAddendum = (addendumData) => {
      const { addendum: text, faxNumber } = addendumData;
      addAddendum(text);

      dispatch(
        consultsActions.addAddendum({
          consultId: consult.id,
          text,
          sendToFaxNumber: faxNumber || null,
          summary: summaryContainerRef.current.innerText,
        }),
      );
    };

    if (addendumFormVisible) {
      return (
        <AddendumForm
          facilityFaxNumber={facilityFaxNumber}
          onSubmit={handleAddAddendum}
          onDismiss={hideAddendumForm}
        />
      );
    }

    return (
      <Box ref={ref}>
        <Button variant="contained" color="secondary" onClick={copyNote}>
          Copy
          <CopyIcon />
        </Button>
        {isClosed && (
          <FlexBoxColumn my={3}>
            <FlexBoxSpaceBetween>
              <ActionButton
                type="button"
                color="secondary"
                onClick={showAddendumForm}
              >
                Add Addendum/Resend Fax
              </ActionButton>
              {copyButtonType && (
                <ConsultCopyButton
                  consultId={consult.id}
                  consultMainType={consultMainType}
                  consultType={copyButtonType}
                />
              )}
            </FlexBoxSpaceBetween>
          </FlexBoxColumn>
        )}
        <SummaryNote
          ref={summaryContainerRef}
          title={title}
          summarySections={summarySections}
          addenda={addenda}
          facilityTimezone={facilityTimezone}
          isConsultClosed={isClosed}
          closedAt={closedAt}
          closedByName={closedByName}
        />
      </Box>
    );
  },
);

ConsultSummary.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.number,
    demographics: PropTypes.shape({
      consultType: PropTypes.string,
    }),
  }).isRequired,
  consultMainType: PropTypes.string.isRequired,
  isClosed: PropTypes.bool.isRequired,
};

const Summary = () => {
  const { consult, consultMainType, isClosed } = useConsultData();
  return (
    <ConsultSummary
      consult={consult}
      consultMainType={consultMainType}
      isClosed={isClosed}
    />
  );
};

export default Summary;
