import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MaterialSnackbar, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { SnackbarTypeEnum } from './SnackbarTypes.enum';

const Snackbar = ({
  isVisible,
  message,
  type,
  duration = null,
  closable = false,
  requireManualClose = false,
  onClose,
}) => (
  <MaterialSnackbar
    open={isVisible}
    autoHideDuration={duration}
    onClose={onClose}
    ClickAwayListenerProps={{
      // Prevent the snackbar from being closed by clicking outside of it
      mouseEvent: requireManualClose ? false : 'onClick',
      touchEvent: requireManualClose ? false : 'onTouchEnd',
    }}
  >
    <Alert severity={type} onClose={closable ? onClose : null}>
      {Array.isArray(message)
        ? message.map((msg) => <Box key={msg}>{msg}</Box>)
        : message}
    </Alert>
  </MaterialSnackbar>
);

Snackbar.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  type: PropTypes.oneOf(Object.values(SnackbarTypeEnum)).isRequired,
  duration: PropTypes.number,
  closable: PropTypes.bool,
  requireManualClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default Snackbar;
