import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultNetworkTypesEnum } from 'features/consults/utils/enums';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';

const FacilityFilter = ({
  control,
  name,
  watch,
  placeholder,
  onFilter,
  consultType,
  healthSystemKey,
}) => {
  const [facilities, setFacilities] = useState([]);

  const healthSystemId = watch(healthSystemKey);

  useEffect(() => {
    if (!healthSystemId) return;
    async function fetchFacilities() {
      try {
        const { data } = await api.getFacilityNames(
          ConsultNetworkTypesEnum[consultType],
          {
            pageSize: MAX_PAGE_SIZE,
            filters: { healthSystemIds: healthSystemId },
          },
        );
        setFacilities(
          data.facilities.map((fac) => ({ value: fac.id, label: fac.name })),
        );
      } catch {
        setFacilities([]);
      }
    }
    fetchFacilities();
  }, [consultType, healthSystemId]);

  return (
    <SelectWithFilter
      control={control}
      name={name}
      placeholder={placeholder}
      data={facilities}
      disabled={!healthSystemId}
      handleChange={(value) => onFilter({ [name]: value })}
    />
  );
};

FacilityFilter.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
  healthSystemKey: PropTypes.string.isRequired,
  watch: PropTypes.func.isRequired,
};

export default FacilityFilter;
