import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BackButton from 'navigation/components/BackButton/BackButton';
import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import EditButton from 'shared/components/buttons/EditButton';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import {
  FlexBoxColumnFull,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';
import useEditMode from 'features/admin/hooks/useEditMode';
import useRoles from 'auth/hooks/useRoles';
import ProfileForm from 'features/profile/components/ProfileForm';
import { profileActions } from 'features/profile/store/slice';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

const Profile = () => {
  const dispatch = useDispatch();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { smDown } = useBreakpoints();
  const profileData = useSelector(({ profile }) => profile.data);
  const { isCustomerOnly } = useRoles();

  usePerformActionOnSuccess(SuccessActionTypesEnum.PROFILE_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    disableEditMode();
  });

  return (
    (!isCustomerOnly || profileData?.viewAndEditSettings) && (
      <MainWrapper sm={smDown}>
        <FlexBoxSpaceBetween>
          <FlexBoxAlign>
            {!isEditMode && <BackButton />}
            <PageTitle>Profile</PageTitle>
          </FlexBoxAlign>
          <FlexBoxAlign>
            {!isEditMode && <EditButton onClick={enableEditMode} />}
          </FlexBoxAlign>
        </FlexBoxSpaceBetween>
        <FlexBoxColumnFull p={2}>
          <ProfileForm
            initValues={profileData}
            disabled={!isEditMode}
            onSubmit={(submitData) =>
              dispatch(profileActions.updateProfile(submitData))
            }
            onCancel={() => {
              disableEditMode();
              dispatch(appStateActions.hideSnackbar());
            }}
          />
        </FlexBoxColumnFull>
      </MainWrapper>
    )
  );
};

export default Profile;
