import * as yup from 'yup';

import { MAX_IMAGE_FILE_SIZE, requiredString } from 'utils/constants';
import { isFile } from 'utils/helpers';
import { PHONE_NUMBER_REGEX, PASSWORD_REGEX } from 'utils/validators';

export default yup.object().shape({
  userImage: yup
    .mixed()
    .test(
      'fileSize',
      `The uploaded file exceeds the maximum allowed size of ${
        MAX_IMAGE_FILE_SIZE / 1024 ** 2
      }MB`,
      (value) => {
        if (isFile(value)) {
          return value.size <= MAX_IMAGE_FILE_SIZE;
        }

        return true;
      },
    ),
  firstName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `First Name should not exceed ${max} characters`),
  lastName: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Last Name should not exceed ${max} characters`),
  title: yup.string().trim(),
  email: yup
    .string()
    .trim()
    .required(requiredString)
    .email('Invalid Email')
    .max(2048, ({ max }) => `Email should not exceed ${max} characters`),
  phoneNumber: yup
    .string()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number')
    .required(requiredString)
    .typeError(requiredString),
  newPassword: yup
    .string()
    .trim()
    .transform((value) => value || null)
    .nullable()
    .matches(PASSWORD_REGEX, 'Invalid password'),
  currentPassword: yup
    .string()
    .trim()
    .when('newPassword', {
      is: (value) => value && value.length > 0,
      then: yup
        .string()
        .required('Current Password is required when setting new password'),
      otherwise: yup
        .string()
        .trim()
        .transform((value) => value || null)
        .nullable()
        .matches(PASSWORD_REGEX, 'Invalid password'),
    }),
});
