import styled from 'styled-components';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';
import { Box, FlexBoxAlignWrap } from 'styles/layout';

export const NewConsultRequestButton = styled(ActionButton)`
  border-radius: 12px;
`;

export const AutocompleteDropdown = styled(SelectWithFilter)`
  width: 200px;
`;

export const FiltersWrapper = styled(FlexBoxAlignWrap)`
  max-width: calc(100vw - 600px);
`;

export const FilterBox = styled(Box)`
  margin: 4px 8px 4px 0;
`;
