import styled from 'styled-components';

import { Box, FlexBoxAlignWrap } from 'styles/layout';

export const FilterWrapper = styled(FlexBoxAlignWrap)`
  order: ${({ theme }) => theme.filterBox.order};
  flex-direction: ${({ theme }) => theme.filterBox.direction};
  max-width: ${({ theme }) => theme.filterBox.maxWidth};
`;

export const FilterElement = styled(Box)`
  margin-bottom: 8px;
  width: 220px;
  margin-right: ${({ theme }) => theme.filterElement.marginRight};
  width: ${({ theme }) => theme.filterElement.width};
`;

export const dateInputStyle = { minWidth: '220px' };
