import styled from 'styled-components';
import { MenuList, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { alpha } from '@material-ui/core/styles';

import colors from 'styles/colors';
import { justifyCenter, FlexBoxFull } from 'styles/layout';

export const DropdownIcon = styled(KeyboardArrowDown)`
  cursor: pointer;
`;

export const DropdownMenu = styled(MenuList)`
  width: 240px;
  background: ${colors.white};
  border: 1px solid ${colors.primary};
`;

export const SelectedOptionLabel = styled(FlexBoxFull)`
  ${justifyCenter}
`;

export const ConsultOption = styled(MenuItem)`
  text-transform: uppercase;
  color: ${colors.primary};
  margin: 5px 0;

  &:hover {
    background: ${alpha(colors.primary, 0.3)};
  }
`;
