import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { Box } from 'styles/layout';

const FormRow = ({ children, rowMargin = 3, itemSpacing = 2, ...props }) => (
  <Box mb={rowMargin}>
    <Grid container item spacing={itemSpacing} {...props}>
      {children}
    </Grid>
  </Box>
);

FormRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  rowMargin: PropTypes.number,
  itemSpacing: PropTypes.number,
};

export default FormRow;
