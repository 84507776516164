export const mobileImagingTypes = [
  { value: 'Rapid', text: 'Rapid' },
  { value: 'VizAl', text: 'Viz.AI' },
];

export const strokeCertificationOptions = [
  { value: 'CSC', text: 'CSC' },
  { value: 'TSC', text: 'TSC' },
  { value: 'PSC', text: 'PSC' },
  { value: 'ASR', text: 'ASR' },
  { value: 'None', text: 'None' },
];

export const hospitalSizeOptions = [
  { value: 'LessThen50Beds', text: '<50 beds' },
  { value: 'Between50And100Beds', text: '50-100 beds' },
  { value: 'Between100And500Beds', text: '100-500 beds' },
  { value: 'MoreThen500Beds', text: '>500 beds' },
];

const facilityFields = {
  active: 'active',
  name: 'name',
  street: 'address.streetAddress',
  city: 'address.locality',
  state: 'address.region',
  zipCode: 'address.postalCode',
  timezone: 'timezone',
  phone: 'edNumber',
  itPhone: 'itPhoneNumber',
  callCenterPhone: 'callCenterPhoneNumber',
  fax: 'faxNumber',
  emrName: 'emrName',
  emrLink: 'emrLink',
  coverage: 'onsiteNeuroCoverage',
  directBeamIn: 'directBeamIn',
  neuroNetwork: 'networks.NeuroNetwork',
  eegNetwork: 'networks.EEGNetwork',
  radiology: 'radiology',
  radiologyLink: 'radiologyLink',
  sharedSignout: 'sharedSignout',
  sharedSignoutLink: 'sharedSignoutLink',
  camera: 'camera',
  cameraLink: 'cameraLink',
  mobileImagings: 'mobileImagings',
  note: 'note',
  isIntegrated: 'isIntegrated',
  r1Integration: 'r1Integration',
  hL7FacilityCode: 'hL7FacilityCode',
  strokeCertification: 'strokeCertification',
  hospitalSize: 'hospitalSize',
  codeFromSystem: 'inheritSiteCodeFromSystem',
  siteCode: 'siteCode',
};

export default facilityFields;
