import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from './formFields';

// eslint-disable-next-line import/prefer-default-export
export const panels = [
  {
    elements: [
      mapChipFieldOptions(fields.clinicalStatePredefined),
      fields.clinicalStateFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.photicStimulationPredefined),
      fields.photicStimulationFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.hyperventilationPredefined)],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.durationPredefined),
      fields.durationFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.phasesCapturedPredefined),
      fields.phasesCapturedFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.ekgPredefined), fields.ekgFreeText],
  },
  {
    elements: [
      mapChipFieldOptions(fields.attenuationPredefined),
      fields.attenuationFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.epileptiformAbnormalitiesPredefined),
      fields.epileptiformAbnormalitiesFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.seizurePredefined),
      fields.seizureFreeText,
    ],
  },
  {
    elements: [fields.artifact],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.stimulationPredefined),
      fields.stimulationFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.impressionPredefined),
      fields.impressionFreeText,
    ],
  },
];
