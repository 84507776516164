import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import { FlexBoxWrap } from 'styles/layout';
import colors from 'styles/colors';

import Select from './Select';

const StyledChip = styled(Chip)`
  margin-bottom: 4px;
  margin-right: 4px;
`;

const StyledPlaceHolder = styled(Box)`
  color: ${colors.placeholder};
`;

const ControlledSelect = ({
  name,
  control,
  defaultValue,
  handleChange,
  placeholder,
  options = [],
  multiple,
  ...props
}) => {
  const valueDefault = useMemo(
    () => defaultValue || (multiple ? [] : ''),
    [defaultValue, multiple],
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={valueDefault}
      render={({ value, onBlur, onChange }) => (
        <Select
          name={name}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            if (handleChange) handleChange(e);
          }}
          value={options.length ? value : valueDefault}
          multiple={multiple}
          options={options}
          native={false}
          displayEmpty
          placeholder={placeholder}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          renderValue={() => {
            if ((multiple ? isEmpty(value) : !value) || isEmpty(options)) {
              return <StyledPlaceHolder>{placeholder}</StyledPlaceHolder>;
            }
            if (multiple) {
              const selectedOptions = options.filter((opt) =>
                value.includes(opt.value),
              );
              return (
                <FlexBoxWrap>
                  {selectedOptions?.map(({ text }) => (
                    <StyledChip key={text} label={text} />
                  ))}
                </FlexBoxWrap>
              );
            }
            return options?.find((opt) => opt.value === value)?.text;
          }}
          {...props}
        />
      )}
    />
  );
};

ControlledSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
  ).isRequired,
};

export default ControlledSelect;
