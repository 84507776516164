import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import { neuroAssessmentFields as fields } from 'features/consults/shared/tabs/Assessment/formFields';

// eslint-disable-next-line import/prefer-default-export
export const impressionPanel = {
  elements: [
    mapChipFieldOptions(fields.impressionPredefined),
    fields.impressionFreeText,
  ],
};
