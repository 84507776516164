import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';

import ConsultFilters from 'features/consults/components/ConsultFilters';
import ConsultTypeToggle from 'features/consults/components/ConsultQuickFilterToggle/ConsultQuickFilterToggle';
import NewConsultButton from 'features/consults/components/NewConsultButton/NewConsultButton';
import useConsultsListState from 'features/consults/hooks/useConsultsListState';
import useConsultQuickFilter from 'features/consults/hooks/useConsultQuickFilter';
import useFormState from 'shared/hooks/useFormState';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { FlexBoxColumn } from 'styles/layout';
import { getTableHeaders, prepareTableData } from 'utils/tables';

import {
  consultsTableColumns,
  smallConsultsTableColumns,
} from './tableColumns';
import {
  styleThemes,
  ContentWrapper,
  ActionsOuterWrapper,
  ActionsInnerWrapper,
  ConsultButtonWrapper,
} from './ConsultsList.style';

const ConsultsList = ({ consultType }) => {
  const {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsult,
  } = useConsultsListState(consultType);
  const { control } = useFormState();
  const {
    getQuickFilterOption,
    getQuickFilterValues,
    activeQuickFilter,
  } = useConsultQuickFilter(filters);

  const sm = useMediaQuery('(max-width:779px)');
  const theme = sm ? styleThemes.sm : styleThemes.lg;

  const tableColumns = sm
    ? smallConsultsTableColumns(consultType)
    : consultsTableColumns(consultType);

  return (
    <ThemeProvider theme={theme}>
      <ContentWrapper>
        <ActionsOuterWrapper mt={1} mb={3}>
          <ConsultFilters
            filters={filters}
            handleFilter={handleFilter}
            consultType={consultType}
            activeQuickFilter={activeQuickFilter}
          />
          <ActionsInnerWrapper>
            <ConsultButtonWrapper>
              <NewConsultButton consultType={consultType} />
            </ConsultButtonWrapper>
            <ConsultTypeToggle
              name="quickFilter"
              control={control}
              onChange={handleResetFilters}
              activeQuickFilter={activeQuickFilter}
              getQuickFilterOption={getQuickFilterOption}
              getQuickFilterValues={getQuickFilterValues}
            />
          </ActionsInnerWrapper>
        </ActionsOuterWrapper>
        <FlexBoxColumn>
          <PaginatedTable
            headings={getTableHeaders(tableColumns)}
            data={prepareTableData(consults, tableColumns)}
            fallbackMsg="No consults found"
            sortBy={sortBy}
            sortOrder={sortOrder}
            currentPage={page}
            totalPages={pageCount}
            onPageChange={handlePageChange}
            onSortChange={handleSortChange}
            onClickRow={navigateToConsult}
          />
        </FlexBoxColumn>
      </ContentWrapper>
    </ThemeProvider>
  );
};

ConsultsList.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultsList;
