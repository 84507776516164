import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';

import { Grid, InputAdornment } from '@material-ui/core';

import { Box } from 'styles/layout';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import Select from 'shared/components/forms/Select/Select';
import ControlledSelect from 'shared/components/forms/Select/ControlledSelect';
import Switch from 'shared/components/forms/Switch/Switch';
import RadioGroup from 'shared/components/forms/RadioGroup';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import ImageInput from 'shared/components/forms/ImageInput';
import { adminActions } from 'features/admin/store/slice';
import useFormState from 'shared/hooks/useFormState';
import usePrevious from 'shared/hooks/usePrevious';
import useProfilePhotoUpload from 'shared/hooks/useProfilePhotoUpload';
import listOfStates from 'utils/states';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { userRoles, userRolesEnum, userTitles } from 'utils/users';

import schema, { isPhoneRequired } from './validationSchema';
import { enabledOptions } from './utils';

const UserForm = ({
  onSubmit,
  onCancel,
  initValues = null,
  disabled = false,
}) => {
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
    getValues,
    setFormValues,
    watch,
    setValue,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });
  const { uploadImage } = useProfilePhotoUpload(initValues?.id, {
    formFieldName: 'userImage',
    getValues,
    setFormValues,
    setValue,
  });

  const previousValues = usePrevious(initValues);
  const currentHealthSystems = watch('healthSystemId');
  const autologout = watch('autologout');
  const currentRoles = watch('roles');
  const showFullNames = watch('showFullPatientNames');

  const {
    systems: { data: healthSystems },
    facilities: { data: facilities },
  } = useSelector(({ admin }) => admin);

  useEffect(() => {
    dispatch(adminActions.listHealthSystems({ pageSize: MAX_PAGE_SIZE }));
  }, [dispatch]);

  const isDoctor = useMemo(
    () =>
      intersection(currentRoles, [
        userRolesEnum.NEURO,
        userRolesEnum.CLINIC,
        userRolesEnum.EEG,
      ]).length > 0,
    [currentRoles],
  );

  useEffect(() => {
    if (!initValues) return;

    if (!isEqual(initValues, previousValues)) {
      setFormValues({
        userImage: getValues('userImage'),
        ...initValues,
      });
    }
  }, [getValues, initValues, previousValues, setFormValues]);

  useEffect(() => {
    if (!currentHealthSystems?.length) return;
    dispatch(
      adminActions.listFacilities({
        filters: { healthSystemIds: currentHealthSystems },
        pageSize: MAX_PAGE_SIZE,
      }),
    );
  }, [dispatch, currentHealthSystems]);

  const facilityOptions = useMemo(
    () =>
      currentHealthSystems?.length
        ? facilities.map(({ id, name, ...rest }) => ({
            ...rest,
            text: name,
            value: id,
          }))
        : [],
    [currentHealthSystems, facilities],
  );

  const handleHealthSystemChange = ({ target }) => {
    const currentSystems = target.value;

    if (currentSystems?.length < currentHealthSystems?.length) {
      const currentFacilities = getValues('facilities');
      const filteredFacilities = currentFacilities.filter((fId) => {
        const fac = facilities.find(({ id }) => id === fId);
        return fac && currentSystems.includes(fac.healthSystemId);
      });
      setValue('facilities', filteredFacilities);
    }
  };

  const submitValues = () => {
    const {
      userImage,
      enabled,
      title,
      healthSystemId,
      shiftAdminUserId,
      showAllConsultRequests,
      viewAndEditSettings,
      viewClosedConsultRequests,
      showFullPatientNames,
      numberOfCharactersToShowPatientNames,
      viewAdminConsultTable,
      inactivityLogOutThreshold,
      ...rest
    } = getTrimmedValues();

    if (initValues?.id) uploadImage();

    return {
      ...rest,
      title: title || null,
      shiftAdminUserId: shiftAdminUserId ? Number(shiftAdminUserId) : null,
      enabled: Boolean(Number(enabled)),
      showAllConsultRequests: Boolean(Number(showAllConsultRequests)),
      viewClosedConsultRequests: Boolean(Number(viewClosedConsultRequests)),
      viewAndEditSettings: Boolean(Number(viewAndEditSettings)),
      showFullPatientNames: Boolean(Number(showFullPatientNames)),
      viewAdminConsultTable: Boolean(Number(viewAdminConsultTable)),
      numberOfCharactersToShowPatientNames: Number(
        numberOfCharactersToShowPatientNames,
      ),
      inactivityLogOutThreshold: inactivityLogOutThreshold
        ? Number(inactivityLogOutThreshold)
        : null,
    };
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(() => onSubmit(submitValues()))}
      onCancel={() => {
        setFormValues();
        onCancel();
      }}
      isSubmitDisabled={!canSubmitForm}
      formDisabled={disabled}
    >
      {initValues?.id && (
        <Box mb={3}>
          <ImageInput
            control={control}
            name="userImage"
            disabled={disabled}
            error={getFieldError('userImage')}
          />
        </Box>
      )}
      <Box mb={3}>
        <RadioGroup
          control={control}
          name="enabled"
          required
          label="ACTIVE/INACTIVE"
          options={[
            { label: 'Active', value: '1' },
            { label: 'Inactive', value: '0' },
          ]}
          error={getFieldError('enabled')}
          disabled={disabled}
        />
      </Box>
      <Grid container spacing={10}>
        <Grid item xs={12} lg={7}>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <InputField
                    inputRef={register}
                    name="firstName"
                    label="First Name"
                    required
                    error={getFieldError('firstName')}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <InputField
                    inputRef={register}
                    name="lastName"
                    label="Last Name"
                    required
                    error={getFieldError('lastName')}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Select
                    inputRef={register}
                    name="title"
                    label="Title"
                    disabled={disabled}
                    options={userTitles}
                    error={getFieldError('title')}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <InputField
                    inputRef={register}
                    name="email"
                    label="Email"
                    required
                    error={getFieldError('email')}
                    disabled={disabled}
                    readOnly={initValues?.isEmailMutable === false}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <PhoneNumberInput
                    control={control}
                    name="phoneNumber"
                    label="Phone Number"
                    required={isPhoneRequired(currentRoles)}
                    disabled={disabled}
                    error={getFieldError('phoneNumber')}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <InputField
                    inputRef={register}
                    name="username"
                    label="Username"
                    required
                    disabled={disabled || Boolean(initValues?.username)}
                    error={getFieldError('username')}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  {isDoctor && (
                    <InputField
                      inputRef={register}
                      name="shiftAdminUserId"
                      label="ShiftAdmin Id"
                      type="number"
                      disabled={disabled}
                      error={getFieldError('shiftAdminUserId')}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box mb={3}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Switch
                    control={control}
                    name="autologout"
                    label="Enable Auto Logout"
                    disabled={disabled}
                    error={getFieldError('primaryLocation')}
                  />
                </Grid>
                {autologout && (
                  <Grid item xs={12} lg={6}>
                    <InputField
                      inputRef={register}
                      name="inactivityLogOutThreshold"
                      label="Logout After"
                      required
                      type="number"
                      disabled={disabled}
                      error={getFieldError('inactivityLogOutThreshold')}
                      endAdornment={
                        <InputAdornment position="end">min</InputAdornment>
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          {isDoctor && (
            <Grid item xs={12} md={12}>
              <Box mb={3}>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Select
                      inputRef={register}
                      name="primaryLocation"
                      label="Primary Location"
                      disabled={disabled}
                      options={listOfStates}
                      error={getFieldError('primaryLocation')}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputField
                      inputRef={register}
                      name="npi"
                      label="NPI"
                      required
                      disabled={disabled}
                      error={getFieldError('npi')}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} lg={4}>
          <Box mb={3}>
            <ControlledSelect
              name="roles"
              label="Role(s)"
              control={control}
              options={userRoles}
              multiple
              required
              disabled={disabled}
              error={getFieldError('roles')}
            />
          </Box>
          {currentRoles && currentRoles.includes(userRolesEnum.CUSTOMER) && (
            <Box>
              <Box mb={3}>
                <ControlledSelect
                  name="healthSystemId"
                  control={control}
                  label="Client Health System(s)"
                  options={healthSystems.map(({ id, name }) => ({
                    text: name,
                    value: id,
                  }))}
                  error={getFieldError('healthSystemId')}
                  required
                  multiple
                  disabled={disabled}
                  handleChange={handleHealthSystemChange}
                />
              </Box>
              <Box mb={3}>
                <ControlledSelect
                  name="facilities"
                  label="Client Facility(s)"
                  control={control}
                  options={facilityOptions}
                  required
                  hasSelectAll
                  groupBy="healthSystemName"
                  multiple
                  disabled={disabled || !facilityOptions?.length}
                  error={getFieldError('facilities')}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="showAllConsultRequests"
                  required
                  label="View All Consult Requests"
                  options={enabledOptions}
                  error={getFieldError('showAllConsultRequests')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="viewClosedConsultRequests"
                  required
                  label="View Closed Consult Requests"
                  options={enabledOptions}
                  error={getFieldError('viewClosedConsultRequests')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="viewAndEditSettings"
                  required
                  label="View and Edit Settings"
                  options={enabledOptions}
                  error={getFieldError('viewAndEditSettings')}
                  disabled={disabled}
                />
              </Box>
              <Box mb={3}>
                <RadioGroup
                  control={control}
                  name="showFullPatientNames"
                  required
                  label="Show Full Patient Names"
                  options={enabledOptions}
                  error={getFieldError('showFullPatientNames')}
                  disabled={disabled}
                />
              </Box>
              {showFullNames === '0' && (
                <Box mb={3}>
                  <InputField
                    inputRef={register}
                    type="number"
                    name="numberOfCharactersToShowPatientNames"
                    label="Show Characters of Patient Names"
                    required
                    error={getFieldError(
                      'numberOfCharactersToShowPatientNames',
                    )}
                    disabled={disabled}
                  />
                </Box>
              )}
            </Box>
          )}
          {currentRoles && currentRoles.includes(userRolesEnum.ADMINISTRATOR) && (
            <Box mb={3}>
              <RadioGroup
                control={control}
                name="viewAdminConsultTable"
                required
                label="View Admin Consult Table"
                options={enabledOptions}
                error={getFieldError('viewAdminConsultTable')}
                disabled={disabled}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default UserForm;
