import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import useFormState from 'shared/hooks/useFormState';
import SearchField from 'shared/components/forms/SearchField/SearchField';
import Select from 'shared/components/forms/Select/ControlledSelect';
import ProviderFilter from 'shared/components/data/ConsultFilters/ProviderFilter';
import HealthSystemFilter from 'shared/components/data/ConsultFilters/HealthSystemFilter';
import FacilityFilter from 'shared/components/data/ConsultFilters/FacilityFilter';
import NetworkFilter from 'shared/components/data/ConsultFilters/NetworkFilter';
import {
  consultFilterStateLabels,
  NeuroConsultTypes,
  ConsultIntegrationOptions,
} from 'features/consults/utils/constants';
import {
  ConsultFilterEnum,
  ConsultTypeEnum,
} from 'features/consults/utils/enums';
import { mapConsultFilters } from 'features/consults/utils/mappers';
import { mapOptionsToSelect } from 'utils/mappers';
import { eEGTypeOptions } from 'features/consults/shared/tabs/Demographics/formFields';

import DateTimeFilter from './DateTimeFilter';
import {
  FilterWrapper,
  FilterElement,
  dateInputStyle,
} from './ConsultFilters.style';

const typeFilterConfig = {
  [ConsultTypeEnum.NEURO]: {
    name: 'NeuroConsultType',
    options: NeuroConsultTypes,
  },
  [ConsultTypeEnum.EEG]: {
    name: 'EegConsultType',
    options: eEGTypeOptions,
  },
};

const ConsultFilters = ({
  filters,
  handleFilter,
  consultType,
  activeQuickFilter,
}) => {
  const { register, control, setFormValues, watch } = useFormState();

  const { name: typeFilterName, options: typeFilterOptions } = useMemo(
    () => typeFilterConfig[consultType] || {},
    [consultType],
  );

  useEffect(() => {
    setFormValues(mapConsultFilters(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <FilterWrapper>
      {activeQuickFilter !== ConsultFilterEnum.OPEN && (
        <>
          <FilterElement>
            <DateTimeFilter
              control={control}
              name="fromCreatedAt"
              placeholder="From"
              handleFilter={handleFilter}
              inputStyle={dateInputStyle}
            />
          </FilterElement>
          <FilterElement>
            <DateTimeFilter
              control={control}
              name="toCreatedAt"
              placeholder="To"
              handleFilter={handleFilter}
              inputStyle={dateInputStyle}
            />
          </FilterElement>
          {consultType !== ConsultTypeEnum.CLINIC && (
            <>
              <FilterElement>
                <HealthSystemFilter
                  control={control}
                  placeholder="Healthcare System"
                  name="healthcareSystemId"
                  onFilter={handleFilter}
                />
              </FilterElement>
              <FilterElement>
                <FacilityFilter
                  control={control}
                  placeholder="Facility"
                  name="facilityId"
                  onFilter={handleFilter}
                  healthSystemKey="healthcareSystemId"
                  watch={watch}
                  consultType={consultType}
                />
              </FilterElement>
              <FilterElement>
                <NetworkFilter
                  control={control}
                  placeholder="Network"
                  name="networkId"
                  onFilter={handleFilter}
                />
              </FilterElement>
            </>
          )}
          <FilterElement>
            <ProviderFilter
              control={control}
              placeholder="Provider"
              name="assignToUserId"
              consultType={consultType}
              onFilter={handleFilter}
            />
          </FilterElement>
          <FilterElement>
            <Select
              control={control}
              name="state"
              placeholder="Status"
              options={consultFilterStateLabels}
              handleChange={({ target }) =>
                handleFilter({ state: target.value })
              }
            />
          </FilterElement>
          {consultType !== ConsultTypeEnum.CLINIC && (
            <>
              <FilterElement>
                <Select
                  control={control}
                  name={typeFilterName}
                  placeholder="Type"
                  options={mapOptionsToSelect(typeFilterOptions)}
                  handleChange={({ target }) =>
                    handleFilter({ [target.name]: target.value })
                  }
                />
              </FilterElement>
              <FilterElement>
                <Select
                  control={control}
                  name="integrationStatusList"
                  placeholder="Integration Status"
                  options={ConsultIntegrationOptions}
                  handleChange={({ target }) =>
                    handleFilter({ [target.name]: target.value })
                  }
                />
              </FilterElement>
            </>
          )}
        </>
      )}
      <FilterElement>
        <SearchField
          inputRef={register}
          name="query"
          onChange={(value) => handleFilter({ query: value })}
        />
      </FilterElement>
    </FilterWrapper>
  );
};

ConsultFilters.propTypes = {
  filters: PropTypes.shape({
    query: PropTypes.string,
  }).isRequired,
  handleFilter: PropTypes.func.isRequired,
  consultType: PropTypes.string.isRequired,
  activeQuickFilter: PropTypes.string.isRequired,
};

export default ConsultFilters;
