import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

import IconButton from 'shared/components/buttons/IconButton/IconButton';

const BackButton = ({ onBack = null }) => {
  const history = useHistory();

  const handleBack = () => {
    if (onBack) {
      onBack();
      return;
    }

    history.goBack();
  };

  return (
    <IconButton color="inherit" onClick={handleBack}>
      <ArrowBack />
    </IconButton>
  );
};

BackButton.propTypes = {
  onBack: PropTypes.func,
};

export default BackButton;
