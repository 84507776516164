import React from 'react';
import { Switch, Route } from 'react-router-dom';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import RedirectToHome from 'navigation/components/RedirectToHome/RedirectToHome';
import Paths from 'navigation/paths';
import ConsultRequestListPage from './pages/ConsultRequestListPage/ConsultRequestListPage';
import NewConsultRequestPage from './pages/NewConsultRequestPage/NewConsultRequestPage';

const CustomerDashboard = () => {
  const { smDown } = useBreakpoints();

  return (
    <MainWrapper sm={smDown}>
      <Switch>
        <Route
          exact
          path={Paths.MyFacility}
          component={ConsultRequestListPage}
        />
        <Route
          exact
          path={`${Paths.MyFacility}/new-consult-request`}
          component={NewConsultRequestPage}
        />
        <Route>
          <RedirectToHome />
        </Route>
      </Switch>
    </MainWrapper>
  );
};

export default CustomerDashboard;
