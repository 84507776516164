import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import PasswordField from 'shared/components/forms/PasswordField/PasswordField';
import Select from 'shared/components/forms/Select/Select';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import ImageInput from 'shared/components/forms/ImageInput';
import useFormState from 'shared/hooks/useFormState';
import usePrevious from 'shared/hooks/usePrevious';
import useProfilePhotoUpload from 'shared/hooks/useProfilePhotoUpload';
import { userTitles } from 'utils/users';
import schema from './validationSchema';

const SectionTitle = styled.h4`
  margin-bottom: 5px;
`;

const ProfileForm = ({
  onSubmit,
  onCancel,
  initValues = null,
  disabled = false,
}) => {
  const {
    register,
    control,
    handleSubmit,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
    getValues,
    setFormValues,
    setValue,
  } = useFormState(schema, { stateSlice: 'profile', showAsSnackbar: true });
  const { uploadImage } = useProfilePhotoUpload(initValues.id, {
    formFieldName: 'userImage',
    getValues,
    setFormValues,
    setValue,
  });
  const previousValues = usePrevious(initValues);

  useEffect(() => {
    if (!initValues) return;

    if (!isEqual(initValues, previousValues)) {
      setFormValues({
        userImage: getValues('userImage'),
        ...initValues,
      });
    }
  }, [getValues, initValues, previousValues, setFormValues]);

  const submitValues = () => {
    const { userImage, title, ...rest } = getTrimmedValues();

    uploadImage();

    return {
      ...rest,
      title: title || null
    };
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(() => onSubmit(submitValues()))}
      onCancel={() => {
        setFormValues();
        onCancel();
      }}
      isSubmitDisabled={!canSubmitForm}
      formDisabled={disabled}
    >
      <Grid container item xs={12} lg={8} spacing={2}>
        <Grid item xs={12}>
          <ImageInput
            control={control}
            name="userImage"
            disabled={disabled}
            error={getFieldError('userImage')}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputField
            inputRef={register}
            name="firstName"
            label="First Name"
            required
            error={getFieldError('firstName')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <InputField
            inputRef={register}
            name="lastName"
            label="Last Name"
            required
            error={getFieldError('lastName')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Select
            inputRef={register()}
            name="title"
            label="Title"
            disabled={disabled}
            options={userTitles}
            error={getFieldError('title')}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputField
            inputRef={register}
            name="email"
            label="Email"
            required
            error={getFieldError('email')}
            disabled={disabled}
            readOnly={initValues?.isEmailMutable === false}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PhoneNumberInput
            control={control}
            name="phoneNumber"
            label="Phone Number"
            required
            disabled={disabled}
            error={getFieldError('phoneNumber')}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <InputField
            inputRef={register}
            name="username"
            label="Username"
            disabled
            error={getFieldError('username')}
          />
        </Grid>
        <Grid item xs={12}>
          <SectionTitle>Change Password</SectionTitle>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <PasswordField
                inputRef={register}
                name="currentPassword"
                label="Current Password"
                disabled={disabled}
                error={getFieldError('currentPassword')}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PasswordField
                inputRef={register}
                name="newPassword"
                label="New Password"
                disabled={disabled}
                error={getFieldError('newPassword')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default ProfileForm;
