import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Paths from 'navigation/paths';
import ConsultsList from 'features/consults/components/ConsultsList/ConsultsList';
import ConsultDetailsWrapper from 'features/consults/components/ConsultDetailsWrapper/ConsultDetailsWrapper';
import { ConsultTypeEnum } from 'features/consults/utils/enums';

const NeuroConsults = () => (
  <Switch>
    <Route
      exact
      path={Paths.Neuro}
      render={(routeProps) => (
        <ConsultsList consultType={ConsultTypeEnum.NEURO} {...routeProps} />
      )}
    />
    <Route
      exact
      path={`${Paths.Neuro}/:consultId`}
      render={(routeProps) => (
        <ConsultDetailsWrapper
          consultType={ConsultTypeEnum.NEURO}
          {...routeProps}
        />
      )}
    />
  </Switch>
);

export default NeuroConsults;
