/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { logoutUser } from 'auth/store/actionCreators';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import {
  ConsultStates,
  IntegrationStatusEnum,
} from 'features/consults/utils/enums';

import { mapErrorResponse } from 'utils/mappers';
import {
  SLICE_PREFIX,
  listConsults,
  getConsultById,
  getConsultCount,
  addNewConsult,
  copyConsult,
  editConsult,
  updateConsultOwner,
  lockConsult,
  editConsultFlag,
  addAddendum,
  listMacros,
  addNewMacro,
  applyMacro,
  deleteMacro,
  linkOrder,
  unlinkOrder,
} from './actionCreators';

const newConsultList = ({ consults, consultId, propToUpdate }) =>
  consults.map((item) => {
    if (item.id === consultId) {
      return {
        ...item,
        ...propToUpdate,
      };
    }

    return item;
  });

const initialState = {
  neuro: {
    data: [],
    pageCount: 0,
  },
  clinic: {
    data: [],
    pageCount: 0,
  },
  eeg: {
    data: [],
    pageCount: 0,
  },
  macros: {
    data: [],
    pageCount: 0,
  },
  consultLock: {
    requested: false,
    errors: null,
  },
  currentConsult: {},
  consultCount: {},
  error: null,
};

const consultsStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    clearConsult: (state) => {
      state.currentConsult = {};
      state.consultLock = initialState.consultLock;
    },
    updateConsultInList: (state, { payload }) => {
      const { id, consultType, propToUpdate } = payload;

      state[consultType].data = newConsultList({
        consults: state[consultType].data,
        consultId: id,
        propToUpdate,
      });
    },
    saveConsultChanges: (state, { payload }) => {
      const { tab, changes } = payload;

      state.currentConsult = {
        ...state.currentConsult,
        [tab]: {
          ...state.currentConsult[tab],
          ...changes,
        },
      };
    },
    setLockErrors: (state, { payload }) => {
      state.consultLock = { requested: true, errors: payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.fulfilled, () => initialState)
      .addCase(listConsults.fulfilled, (state, { payload }) => {
        const { consultType, ...rest } = payload;

        state[consultType] = rest;
      })
      .addCase(getConsultById.fulfilled, (state, { payload }) => {
        state.currentConsult = payload;
      })
      .addCase(updateConsultOwner.fulfilled, (state, { payload }) => {
        state.currentConsult[CONSULT_TABS.SIGNATURE].assignToId = payload;
      })
      .addCase(lockConsult.fulfilled, (state, { payload }) => {
        const { lockById, lockAt, lockAction } = payload;

        state.currentConsult[CONSULT_TABS.SIGNATURE].state = lockAction;
        if (lockAction === ConsultStates.CANCELLED) {
          state.currentConsult[CONSULT_TABS.SIGNATURE].cancelledById = lockById;
          state.currentConsult[CONSULT_TABS.SIGNATURE].cancelledAt = lockAt;
        } else {
          state.currentConsult[CONSULT_TABS.SIGNATURE].closedById = lockById;
          state.currentConsult[CONSULT_TABS.SIGNATURE].closedAt = lockAt;
        }
        state.consultLock = initialState.consultLock;
      })
      .addCase(getConsultCount.fulfilled, (state, { payload }) => {
        state.consultCount = payload;
      })
      .addCase(listMacros.fulfilled, (state, { payload }) => {
        state.macros = payload;
      })
      .addCase(addNewMacro.fulfilled, (state, { payload }) => {
        state.macros.data = state.macros.data
          .concat(payload)
          .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
          );
      })
      .addCase(applyMacro.fulfilled, (state, { payload }) => {
        state.currentConsult = payload;
      })
      .addCase(deleteMacro.fulfilled, (state, { payload }) => {
        state.macros.data = state.macros.data.filter(
          (macro) => macro.id !== payload,
        );
      })
      .addCase(linkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          const { consultId, consultType } = payload;
          state.currentConsult.integrationStatus =
            IntegrationStatusEnum.MATCHED;
          state[consultType].data = newConsultList({
            consults: state[consultType].data,
            consultId,
            propToUpdate: { integrationStatus: IntegrationStatusEnum.MATCHED },
          });
        }
      })
      .addCase(unlinkOrder.fulfilled, (state, { payload }) => {
        if (payload?.result) {
          const { consultId, consultType } = payload;
          state.currentConsult.integrationStatus =
            IntegrationStatusEnum.UNMATCHED;

          state[consultType].data = newConsultList({
            consults: state[consultType].data,
            consultId,
            propToUpdate: {
              integrationStatus: IntegrationStatusEnum.UNMATCHED,
            },
          });
        }
      })
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});

const { reducer, actions } = consultsStateSlice;

export { reducer as consultsReducer };
export const consultsActions = {
  ...actions,
  listConsults,
  getConsultById,
  getConsultCount,
  addNewConsult,
  editConsult,
  copyConsult,
  updateConsultOwner,
  lockConsult,
  editConsultFlag,
  addAddendum,
  listMacros,
  addNewMacro,
  applyMacro,
  deleteMacro,
  linkOrder,
  unlinkOrder,
};

export default consultsStateSlice;
