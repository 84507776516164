import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import {
  eegType,
  eegDemographicsFields as fields,
} from 'features/consults/shared/tabs/Demographics/formFields';

export const eEGTypePanel = (readValue) => {
  const eegTypeField = mapToggleFieldOptions(fields.consultType);

  return {
    elements: [eegType.CEEG, eegType.CERIBELL].includes(readValue)
      ? [
          eegTypeField,
          mapToggleFieldOptions(fields.observationType),
          mapToggleFieldOptions(fields.videoType),
        ]
      : [eegTypeField, mapToggleFieldOptions(fields.videoType)],
  };
};

export const leftPanels1 = [
  {
    elements: [mapToggleFieldOptions(fields.eegPatientLocation)],
  },
  {
    elements: [mapChipFieldOptions(fields.eegStatOptions)],
  },
];

export const leftPanels2 = [
  {
    elements: [fields.age],
  },
  {
    elements: [mapToggleFieldOptions(fields.gender)],
  },
];

export const leftPanels3 = [
  {
    elements: [fields.referringProvider],
  },
  {
    elements: [fields.callBackPhone],
  },
  {
    elements: [fields.referringTechnician],
  },
  {
    elements: [fields.techCallbackNumber],
  },
  {
    elements: [fields.notes],
  },
];

export const rightPanels = [
  {
    elements: [fields.initialPageAt],
  },
  {
    elements: [fields.returnCallAt],
  },
  {
    label: 'For cEEG reads, timestamps may be left blank unless stat requested',
  },
];
