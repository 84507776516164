import React from 'react';

import DemographicsHeader from 'features/consults/components/DemographicsHeader/DemographicsHeader';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import ROS from 'features/consults/shared/tabs/ROS';
import Exam from 'features/consults/shared/tabs/Exam';
import PMHFHSH from 'features/consults/shared/tabs/PMHFHSH';
import Summary from 'features/consults/shared/tabs/Summary';

import DemographicsClinic from './DemographicsClinic';
import EEG from './EEG';
import EMG from './EMG';
import Botox from './Botox';
import DataClinic from './DataClinic';
import AssessmentClinic from './AssessmentClinic';
import HPIClinic from './HPIClinic';
import PlanClinic from './PlanClinic';
import SignatureClinic from './SignatureClinic';

export default {
  demographics: {
    value: CONSULT_TABS.DEMOGRAPHICS,
    label: <DemographicsHeader />,
    component: () => <DemographicsClinic />,
  },
  hpi: {
    value: CONSULT_TABS.HPI,
    label: 'HPI',
    component: () => <HPIClinic />,
  },
  ros: {
    value: CONSULT_TABS.ROS,
    label: 'ROS',
    component: () => <ROS />,
  },
  pmh: {
    value: CONSULT_TABS.PMH,
    label: 'PMH-FH-SH',
    component: () => <PMHFHSH />,
  },
  exam: {
    value: CONSULT_TABS.EXAM,
    label: 'Exam',
    component: () => <Exam />,
  },
  eeg: {
    value: CONSULT_TABS.EEG,
    label: 'EEG',
    component: () => <EEG />,
  },
  emg: {
    value: CONSULT_TABS.EMG,
    label: 'EMG',
    component: () => <EMG />,
  },
  botox: {
    value: CONSULT_TABS.BOTOX,
    label: 'Botox',
    component: () => <Botox />,
  },
  data: {
    value: CONSULT_TABS.DATA,
    label: 'Data',
    component: () => <DataClinic />,
  },
  assessment: {
    value: CONSULT_TABS.ASSESSMENT,
    label: 'Assessment/MDM',
    component: () => <AssessmentClinic />,
  },
  plan: {
    value: CONSULT_TABS.PLAN,
    label: 'Plan',
    component: () => <PlanClinic />,
  },
  summary: {
    value: CONSULT_TABS.SUMMARY,
    label: 'Summary',
    component: () => <Summary />,
  },
  signature: {
    value: CONSULT_TABS.SIGNATURE,
    label: 'Signature',
    component: () => <SignatureClinic />,
  },
};
