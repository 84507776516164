import { ConsultTypeEnum } from 'features/consults/utils/enums';
import {
  filterOptionsByConsultType,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Data/formFields';

export const leftPanels = [
  {
    elements: [
      mapToggleFieldOptions(fields.glucosePredefined),
      fields.glucoseFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.creatininePredefined),
      fields.creatinineFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.inrPredefined), fields.inrFreeText],
  },
  {
    elements: [fields.otherLabs],
  },
];

export const rightPanels = [
  { elements: [fields.headCtPersonallyReadAt] },
  {
    elements: [
      mapChipFieldOptions(
        filterOptionsByConsultType(
          fields.headCtPredefined,
          ConsultTypeEnum.NEURO,
        ),
      ),
      fields.headCtFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.ctaHeadPredefined),
      mapToggleFieldOptions(fields.ctaHeadPreliminarilyPredefined),
      fields.ctaHeadFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.ctaNeckPredefined),
      mapToggleFieldOptions(fields.ctaNeckPreliminarilyPredefined),
      fields.ctaNeckFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.ctPerfusionPredefined),
      mapToggleFieldOptions(fields.ctPerfusionPreliminarilyPredefined),
      fields.ctPerfusionFreeText,
    ],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.mriPredefined),
      mapToggleFieldOptions(fields.mriPreliminarilyPredefined),
      fields.mriFreeText,
    ],
  },
  {
    elements: [fields.otherImaging],
  },
];
