import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import api from 'api';
import Paths from 'navigation/paths';
import BackButton from 'navigation/components/BackButton/BackButton';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import useFormState from 'shared/hooks/useFormState';
import useRequest from 'shared/hooks/useRequest';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';

import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import { consultRequestActions } from 'features/customer/store/slice';
import { neuroAcuteStrokeLevel1Type } from 'features/consults/utils/constants';
import { isEmptyValue } from 'features/consults/utils/validators';

import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { BROWSER_DATE_FORMAT, formatDate } from 'utils/dates';

import schema from './validationSchema';
import ConsultRequestForm from './ConsultRequestForm';

const NewConsultRequestPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { doRequest: fetchFacilities, data: facilityData } = useRequest(
    api.getCustomerFacilities,
  );

  const facilities = useMemo(
    () => facilityData?.facilities ?? [],
    [facilityData],
  );

  const { handleSubmit, canSubmitForm, getTrimmedValues, watch, ...formProps } =
    useFormState(schema, {
      stateSlice: 'consultRequests',
      showAsSnackbar: true,
    });
  const selectedConsultType = watch('type');

  useEffect(() => {
    fetchFacilities({ pageSize: MAX_PAGE_SIZE });
  }, [fetchFacilities]);

  const navigateToConsultRequestList = () => {
    history.push(Paths.MyFacility);
  };

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.CONSULT_REQUEST_CREATED,
    () => {
      dispatch(appStateActions.hideSnackbar());
      navigateToConsultRequestList();
    },
  );

  const createConsultRequest = () => {
    const {
      dateOfBirth,
      facility,
      neuroDirectBeamInRequested,
      statReadRequested,
      ...rest
    } = getTrimmedValues();

    const consultRequestData = Object.keys(rest).reduce((acc, key) => {
      const val = rest[key];

      return {
        ...acc,
        [key]: !isEmptyValue(val) ? val : null,
      };
    }, {});

    dispatch(
      consultRequestActions.createNewConsultRequest({
        dateOfBirth: dateOfBirth
          ? formatDate(dateOfBirth, BROWSER_DATE_FORMAT)
          : null,

        facilityId: facilities?.length === 1 ? facilities[0].id : facility,
        neuroDirectBeamInRequested:
          selectedConsultType === neuroAcuteStrokeLevel1Type
            ? neuroDirectBeamInRequested?.length > 0
            : false,
        statReadRequested: statReadRequested?.length > 0,
        ...consultRequestData,
      }),
    );
  };

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton onBack={navigateToConsultRequestList} />
        <PageTitle>New Consult</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull my={2} px={1.25}>
        <FormWrapper
          onSubmit={handleSubmit(createConsultRequest)}
          isSubmitDisabled={!canSubmitForm}
          onCancel={navigateToConsultRequestList}
        >
          <ConsultRequestForm
            formProps={{ ...formProps, watch }}
            facilities={facilities}
          />
        </FormWrapper>
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default NewConsultRequestPage;
