import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from 'auth/store/slice';
import { adminReducer } from 'features/admin/store/slice';
import { consultsReducer } from 'features/consults/store/slice';
import { consultRequestsReducer } from 'features/customer/store/slice';
import { profileReducer } from 'features/profile/store/slice';
import { scheduleReducer } from 'features/schedule/store/slice';
import { appStateReducer } from './appStateSlice';

const store = configureStore({
  reducer: {
    appState: appStateReducer,
    auth: authReducer,
    admin: adminReducer,
    consults: consultsReducer,
    consultRequests: consultRequestsReducer,
    profile: profileReducer,
    schedule: scheduleReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
