import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Delete } from '@material-ui/icons';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import BasicDialog from 'shared/components/feedback/BasicDialog';
import { consultsActions } from 'features/consults/store/slice';

const DeleteMacro = ({ macroId }) => {
  const dispatch = useDispatch();
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();

    setDialogVisible(true);
  };

  const handleDeleteConfirmation = () => {
    setDialogVisible(false);
    dispatch(consultsActions.deleteMacro(macroId));
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Delete color="primary" />
      </IconButton>
      <BasicDialog
        title="Are you sure you want to delete this Macro?"
        open={dialogVisible}
        confirmText="Confirm"
        handleConfirm={handleDeleteConfirmation}
        handleCancel={() => setDialogVisible(false)}
      />
    </>
  );
};

DeleteMacro.propTypes = {
  macroId: PropTypes.number.isRequired,
};

export default DeleteMacro;
