import { Storage } from 'aws-amplify';

const imageFilename = (userId) => `profile_photo_user_${userId}`;

export const getProfilePhoto = (userId) =>
  Storage.get(imageFilename(userId), {
    level: 'public',
    download: false,
    contentType: 'image/*',
  });

export const uploadProfilePhoto = (userId, file) =>
  Storage.put(imageFilename(userId), file, {
    contentType: file.type,
  });
