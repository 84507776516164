import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { adminActions } from 'features/admin/store/slice';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { FORCE_CHANGE_PASSWORD } from 'utils/users';

const UserActionButton = ({ userData: { id, userAccountStatus } }) => {
  const dispatch = useDispatch();

  const shouldInvite = useMemo(
    () => userAccountStatus === FORCE_CHANGE_PASSWORD,
    [userAccountStatus],
  );

  const onButtonClick = useCallback(
    () =>
      dispatch(
        (shouldInvite
          ? adminActions.reInviteUser
          : adminActions.resetUserPassword)(id),
      ),
    [id, shouldInvite, dispatch],
  );

  const showSucessSnackBar = useCallback(
    (action) =>
      dispatch(
        appStateActions.showSnackbar({
          text: `The user will receive an email with instructions to ${action} their password.`,
          type: SnackbarTypeEnum.SUCCESS,
          duration: 10000,
          closable: true,
        }),
      ),
    [dispatch],
  );

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADMIN_USER_REINVITED, () =>
    showSucessSnackBar('confirm'),
  );

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.ADMIN_USER_PASSWORD_RESET,
    () => showSucessSnackBar('reset'),
  );

  return (
    <ActionButton
      type="button"
      variant="outlined"
      color="primary"
      onClick={onButtonClick}
    >
      {shouldInvite ? 'REINVITE' : 'RESET PASSWORD'}
    </ActionButton>
  );
};

UserActionButton.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number,
    userAccountStatus: PropTypes.string,
  }).isRequired,
};

export default UserActionButton;
