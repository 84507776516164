import Paths from 'navigation/paths';

export const AuthRoleEnum = {
  NEURO: 'teleneurologist',
  CLINIC: 'clinic',
  EEG: 'eeg',
  ADMIN: 'administrator',
  CUSTOMER: 'customer',
};

export const RoutesForRoleEnum = {
  [AuthRoleEnum.NEURO]: {
    priority: 1,
    routes: [Paths.Neuro, Paths.Macros, Paths.ConsultRequestDetails],
  },
  [AuthRoleEnum.CLINIC]: {
    priority: 2,
    routes: [Paths.Clinic, Paths.Macros],
  },
  [AuthRoleEnum.EEG]: {
    priority: 3,
    routes: [Paths.Eeg, Paths.Macros, Paths.ConsultRequestDetails],
  },
  [AuthRoleEnum.ADMIN]: {
    priority: 4,
    routes: [Paths.Admin],
  },
  [AuthRoleEnum.CUSTOMER]: {
    priority: 5,
    routes: [Paths.MyFacility],
  },
};

export const AuthChallengeEnum = {
  NewPassword: 'NEW_PASSWORD_REQUIRED',
  SmsMfa: 'SMS_MFA',
};
