import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { mapDoctorNames } from 'utils/mappers';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultTypeRoleNameEnum } from 'features/consults/utils/enums';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';

const ProviderFilter = ({
  control,
  name,
  placeholder,
  consultType,
  onFilter,
}) => {
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    async function fetchProviders() {
      try {
        const { data } = await api.getUserNames({
          pageSize: MAX_PAGE_SIZE,
          filters: { roles: [ConsultTypeRoleNameEnum[consultType]] },
        });
        setProviders(mapDoctorNames(data.users, 'value', 'label'));
      } catch {
        setProviders([]);
      }
    }
    fetchProviders();
  }, [consultType]);

  return (
    <SelectWithFilter
      control={control}
      name={name}
      placeholder={placeholder}
      data={providers}
      handleChange={(value) => onFilter({ [name]: value })}
    />
  );
};

ProviderFilter.propTypes = {
  control: PropTypes.shape({}).isRequired,
  consultType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default ProviderFilter;
