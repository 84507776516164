import { css } from 'styled-components';
import { darken } from '@material-ui/core/styles';

import colors from 'styles/colors';

export const darkerNeutralColor = darken(colors.neutral, 0.05);

export const tableRowStyles = css`
  border: 1px solid ${darkerNeutralColor};
`;
