import { useState, useEffect, useMemo } from 'react';

import api from 'api';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { ConsultStates } from 'features/consults/utils/enums';
import {
  hasValue,
  getSummaryFields,
  getConsultSummaryData,
} from 'features/consults/shared/tabs/Summary/data';
import { getNamesWithTitle } from 'utils/helpers';
import { TIMEZONES } from 'utils/timezones';

const useSummaryTabState = (consult, consultMainType) => {
  const { id, ...tabs } = consult;
  const { title, sections } = getConsultSummaryData(consultMainType);

  const [facility, setFacility] = useState(null);
  const [closedByName, setClosedByName] = useState(null);
  const demographicsData = tabs[CONSULT_TABS.DEMOGRAPHICS];
  const signatureData = tabs[CONSULT_TABS.SIGNATURE];

  useEffect(() => {
    if (demographicsData?.facilityId) {
      const fetchFacility = async () => {
        const { data } = await api.getFacilityById(demographicsData.facilityId);
        setFacility(data?.facility);
      };
      fetchFacility();
    } else {
      setFacility(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographicsData]);

  useEffect(() => {
    if (signatureData?.state === ConsultStates.CLOSED) {
      const fetchProviderName = async () => {
        const { data } = await api.getUserNames({
          filters: { userId: signatureData.closedById },
        });
        const { firstName, lastName, title: userTitle } = data.users[0];

        setClosedByName(getNamesWithTitle(firstName, lastName, userTitle));
      };

      fetchProviderName();
    }
  }, [signatureData]);

  const summarySections = useMemo(
    () =>
      Object.entries(sections).reduce(
        (acc, [name, { title: sectionTitle, fields, tab }]) => {
          const tabData = tabs[tab || name];

          if (!tabData) return acc;

          // Filter fields without any values to show
          const summaryFields = getSummaryFields(
            fields,
            tabData,
            facility,
          ).filter(
            ({ value, additionalValue }) =>
              hasValue(value) || hasValue(additionalValue),
          );

          if (summaryFields.length === 0) return acc;

          return acc.concat({
            sectionTitle,
            summaryFields,
          });
        },
        [],
      ),
    [sections, tabs, facility],
  );

  return {
    title,
    summarySections,
    facilityTimezone: facility?.timezone || TIMEZONES.UTC,
    facilityFaxNumber: facility?.faxNumber,
    closedAt: signatureData?.closedAt,
    closedByName,
  };
};

export default useSummaryTabState;
