import styled from 'styled-components';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import colors from 'styles/colors';
import { Box } from 'styles/layout';

export const PatientName = styled.h1`
  font-size: 30px;
  margin: 0 0 25px 0;
  font-weight: 500;
`;

export const RequestDetails = styled(Box)`
  font-size: 20px;
  margin-bottom: 10px;
  color: ${colors.neutralDark};
`;

export const ConsultRequestButton = styled(ActionButton)`
  margin-top: 30px;
`;

export const ErrorMessage = styled(Box)`
  color: ${colors.error};
  font-size: 18px;
`;
