import * as yup from 'yup';

import { requiredString } from 'utils/constants';
/*
function transformEmails(value, originalValue) {
  if (this.isType(value) && value !== null) return value;

  return originalValue ? originalValue.split(/[\s,]+/) : [];
}
*/

export default yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(requiredString)
    .max(100, ({ max }) => `Network name should not exceed ${max} characters`),
  shiftAdminFacilityId: yup
    .string()
    .trim()
    .required(requiredString)
    .max(20, ({ max }) => `Network ID should not exceed ${max} characters`)
    .matches(/^[1-9]\d*$/, 'Invalid ID'),
  /*
  emailAddresses: yup
    .array()
    .transform(transformEmails)
    .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
  */
});
