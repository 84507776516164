import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX, ZIP_CODE_REGEX } from 'utils/validators';

export default yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(requiredString)
    .max(
      50,
      ({ max }) => `Healthcare system name should not exceed ${max} characters`,
    ),
  siteCode: yup.string().trim(),
  contact: yup.object().shape({
    name: yup
      .string()
      .trim()
      .max(
        200,
        ({ max }) => `Contact name should not exceed ${max} characters`,
      ),
    phoneNumber: yup
      .string()
      .nullable()
      .trim()
      .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  }),
  address: yup.object().shape({
    streetAddress: yup
      .string()
      .trim()
      .max(180, ({ max }) => `Address should not exceed ${max} characters`),
    locality: yup
      .string()
      .trim()
      .max(100, ({ max }) => `City should not exceed ${max} characters`),
    region: yup
      .string()
      .trim()
      .max(60, ({ max }) => `State should not exceed ${max} characters`),
    postalCode: yup
      .string()
      .trim()
      .max(5, ({ max }) => `Zip code should not exceed ${max} characters`)
      .matches(ZIP_CODE_REGEX, 'Invalid zip code'),
  }),
});
