import React, { useEffect, useCallback, useMemo } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';

import api from 'api';
import useBack from 'navigation/hooks/useBack';
import useRequest from 'shared/hooks/useRequest';
import useFeedback from 'shared/hooks/useFeedback';
import { useTableState } from 'shared/hooks/useTableState';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { PAGINATION_PAGE_SIZE } from 'utils/constants';

import AddButton from 'navigation/components/AddButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { FlexBox, FlexBoxColumn } from 'styles/layout';

import ProviderAdd from './ProviderAdd';
import ProviderDetails from './ProviderDetails';

const tableColumns = [
  {
    header: {
      id: 'provider',
      value: 'Provider',
    },
    dataKey: 'providerName',
  },
  {
    header: {
      id: 'healthSystem',
      value: 'Healthcare System',
    },
    dataKey: 'healthSystemName',
  },
  {
    header: {
      id: 'facility',
      value: 'Facility',
    },
    dataKey: 'facilityName',
  },
  {
    header: {
      id: 'hospitalDocId',
      value: 'Mnemonic',
    },
    dataKey: 'hospitalDocId',
  },
];

const Providers = () => {
  const { doRequest, data: listData } = useRequest(api.getProviders);
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const { onBack } = useBack();
  const { successDisplay, errorDisplay } = useFeedback();

  const { page, handlePageChange } = useTableState();

  const listItems = useMemo(
    () => listData?.providerAssociations || [],
    [listData],
  );

  const fecthList = useCallback(() => {
    doRequest({
      page,
      pageSize: PAGINATION_PAGE_SIZE,
    });
  }, [doRequest, page]);

  useEffect(fecthList, [fecthList]);

  const handleAdd = useCallback(
    (values) => {
      api
        .addProvider(values)
        .then(({ data }) => {
          history.replace(`${url}/${data.id}`);
          fecthList();
        })
        .catch(() => {
          errorDisplay('Error adding Provider');
        });
    },
    [errorDisplay, history, url, fecthList],
  );

  const handleUpdate = useCallback(
    (providerId, values) => {
      api
        .updateProvider(Number(providerId), values)
        .then(() => {
          successDisplay('Provider updated with success');
          fecthList();
        })
        .catch(() => {
          errorDisplay('Error updating Provider');
        });
    },
    [successDisplay, errorDisplay, fecthList],
  );

  return (
    <FlexBoxColumn>
      <Switch>
        <Route exact path={`${path}/add`}>
          <ProviderAdd onSubmit={handleAdd} onCancel={onBack} />
        </Route>
        <Route exact path={`${path}/:providerId`}>
          <ProviderDetails onSubmit={handleUpdate} onCancel={onBack} />
        </Route>
      </Switch>
      <FlexBox mt={2} justifyContent="flex-end">
        <AddButton to={`${url}/add`}>Add Provider</AddButton>
      </FlexBox>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(listItems, tableColumns)}
        fallbackMsg="No providers"
        currentPage={page}
        totalPages={listData?.pageCount}
        onPageChange={handlePageChange}
        onClickRow={(providerId) => history.push(`${url}/${providerId}`)}
      />
    </FlexBoxColumn>
  );
};

export default Providers;
