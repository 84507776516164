import styled from 'styled-components';
import { Box } from 'styles/layout';

export const FilterElement = styled(Box)`
  width: 220px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const dateInputStyle = { minWidth: '220px' };
