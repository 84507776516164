import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

const useConvertingSpeechToText = () => {
  const {
    transcript,
    resetTranscript,
    interimTranscript,
    finalTranscript,
    listening,
  } = useSpeechRecognition();

  const hasBrowserSupport = SpeechRecognition.browserSupportsSpeechRecognition();

  const startRecording = () =>
    SpeechRecognition.startListening({ continuous: true, language: 'en-US' });

  const stopRecording = SpeechRecognition.stopListening;

  return {
    hasBrowserSupport,
    startRecording,
    stopRecording,
    transcript,
    resetTranscript,
    interimTranscript,
    finalTranscript,
    listening,
  };
};

export default useConvertingSpeechToText;
