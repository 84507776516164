import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import { consultsActions } from 'features/consults/store/slice';
import { ConsultPathsEnum } from 'features/consults/utils/enums';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

const ConsultCopyButton = ({ consultId, consultMainType, consultType }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.CONSULT_COPIED, () => {
    history.push(
      `${ConsultPathsEnum[consultMainType]}/${successAction.payload.id}`,
    );
  });

  return (
    <ActionButton
      type="button"
      color="secondary"
      onClick={() => dispatch(consultsActions.copyConsult(consultId))}
    >
      + New Linked {consultType}
    </ActionButton>
  );
};

ConsultCopyButton.propTypes = {
  consultId: PropTypes.number.isRequired,
  consultMainType: PropTypes.string.isRequired,
  consultType: PropTypes.string.isRequired,
};

export default ConsultCopyButton;
