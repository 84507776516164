export const userRolesEnum = {
  ADMINISTRATOR: 'Administrator',
  CLINIC: 'Clinic',
  CUSTOMER: 'Customer',
  EEG: 'Eeg',
  NEURO: 'Teleneurologist',
};

export const userRoles = [
  { text: 'Administrator', value: userRolesEnum.ADMINISTRATOR },
  { text: 'Clinic', value: userRolesEnum.CLINIC },
  { text: 'Customer', value: userRolesEnum.CUSTOMER },
  { text: 'EEG', value: userRolesEnum.EEG },
  { text: 'TeleNeurologist', value: userRolesEnum.NEURO },
];

export const titles = {
  MD: 'MD',
  RN: 'RN',
  APN: 'APN',
  DO: 'DO',
  PaC: 'PA-C',
  NP: 'NP',
  MA: 'MA',
  Other: 'Other',
};

export const userTitles = [
  { text: titles.MD, value: 'MD' },
  { text: titles.RN, value: 'RN' },
  { text: titles.APN, value: 'APN' },
  { text: titles.DO, value: 'DO' },
  { text: titles.PaC, value: 'PaC' },
  { text: titles.NP, value: 'NP' },
  { text: titles.MA, value: 'MA' },
  { text: titles.Other, value: 'Other' },
];

export const FORCE_CHANGE_PASSWORD = 'ForceChangePassword';
