import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import HealthSystemForm from 'features/admin/components/HealthSystemForm/HealthSystemForm';
import schema from 'features/admin/components/HealthSystemForm/validationSchema';
import { adminActions } from 'features/admin/store/slice';
import AdminPaths from 'features/admin/paths';
import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';

const AddHealthSystemPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    control,
    handleSubmit,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.HEALTH_SYSTEM_ADDED, () => {
    dispatch(appStateActions.hideSnackbar());

    history.push(`${AdminPaths.HealthSystems}/${successAction.payload.id}`, {
      navigateOnBack: AdminPaths.HealthSystems,
    });
  });

  const addSystem = () => {
    dispatch(adminActions.addHealthSystem(getTrimmedValues()));
  };

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>New Healthcare System</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <FormWrapper
          onSubmit={handleSubmit(addSystem)}
          onCancel={() => history.goBack()}
          isSubmitDisabled={!canSubmitForm}
        >
          <HealthSystemForm
            register={register}
            control={control}
            getFieldError={getFieldError}
          />
        </FormWrapper>
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default AddHealthSystemPage;
