import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { clinicSignatureFields as fields } from 'features/consults/shared/tabs/Signature/formFields';

// eslint-disable-next-line import/prefer-default-export
export const leftPanels = [
  {
    elements: [
      mapToggleFieldOptions(fields.telemedicineOptions),
      mapChipFieldOptions(fields.patientConsent),
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.providerLocationPredefined)],
  },
  {
    elements: [fields.providerLocationFreeText],
  },
  {
    elements: [fields.patientLocation],
  },
  {
    label: fields.totalFaceToFaceTime.summaryLabel,
    elements: [fields.totalFaceToFaceTime],
  },
  {
    elements: [fields.additionalTelemedicineParticipants],
  },
];
