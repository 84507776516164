import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import useFormState from 'shared/hooks/useFormState';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Box, FlexBoxJustify } from 'styles/layout';
import { PASSWORD_REGEX } from 'utils/validators';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputField from 'shared/components/forms/InputField/InputField';
import PasswordField from 'shared/components/forms/PasswordField/PasswordField';
import AuthFormWrapper from 'auth/components/AuthFormWrapper/AuthFormWrapper';
import { authActions } from 'auth/store/slice';
import { loadingSelector } from 'store/selectors';
import { ResendCodeBtn } from './ResetPasswordPage.style';

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .matches(
      /^(?:[^\s]{1,99})$/i,
      'Please enter a valid username or email address.',
    ),
  code: yup.string().trim().required('Code is required'),
  newPassword: yup
    .string()
    .trim()
    .required('Password is required')
    .matches(PASSWORD_REGEX, 'Invalid password'),
});

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    getTrimmedValues,
    handleSubmit,
    getFieldError,
    canSubmitForm,
    serverError,
    setValue,
    watch,
  } = useFormState(schema, { stateSlice: 'auth' });

  const loading = useSelector(loadingSelector);
  const { queryParams } = useQueryParams();
  const usernameParam = queryParams.username;
  const user = watch('username');

  useEffect(() => {
    if (usernameParam) {
      setValue('username', usernameParam);
    }
  }, [setValue, usernameParam]);

  const resetPassword = () => {
    const { username, code, newPassword } = getTrimmedValues();

    dispatch(
      authActions.resetUserPassword({
        username,
        verificationCode: code,
        newPassword,
      }),
    );
  };

  const resendCode = () => {
    dispatch(authActions.sendForgotUserPasswordCode(user));
  };

  return (
    <AuthFormWrapper onSubmit={handleSubmit(resetPassword)}>
      <Box mb={1.5}>
        <InputField
          inputRef={register}
          name="username"
          label="Email Address or Username"
          size="medium"
          required
          error={getFieldError('username')}
        />
      </Box>
      <Box mb={1.5}>
        <InputField
          inputRef={register}
          name="code"
          label="Code"
          size="medium"
          autoComplete="off"
          required
          error={getFieldError('code')}
        />
      </Box>
      <Box mb={5.5}>
        <PasswordField
          inputRef={register}
          name="newPassword"
          label="New Password"
          size="medium"
          autoComplete="new-password"
          helperText="Password must be at least 8 characters long and contain uppercase, lowercase letters, numbers and special symbols"
          required
          error={getFieldError('newPassword')}
        />
        {!!serverError && <ErrorMsg text={serverError} mt={2} mb={0} />}
      </Box>
      <Box mb={2}>
        <ActionButton
          size="large"
          fullWidth
          disabled={!canSubmitForm || loading}
        >
          Set Password And Log In
        </ActionButton>
      </Box>
      <FlexBoxJustify mb={7.5}>
        <ResendCodeBtn onClick={resendCode} disabled={!user}>
          Resend Code
        </ResendCodeBtn>
      </FlexBoxJustify>
    </AuthFormWrapper>
  );
};

export default ResetPasswordPage;
