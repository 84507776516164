import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import { clinicAssessmentFields as fields } from 'features/consults/shared/tabs/Assessment/formFields';

// eslint-disable-next-line import/prefer-default-export
export const panels = [
  {
    elements: [
      mapChipFieldOptions(fields.impressionPredefined),
      fields.impressionFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.differentialDiagnosisPredefined),
      fields.differentialDiagnosisFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.medicalDecisionMakingHighLevelPredefined),
    ],
  },
  {
    elements: [
      mapChipFieldOptions(fields.medicalDecisionMakingModerateLevelPredefined),
    ],
  },
];
