import { ConsultTypeEnum } from 'features/consults/utils/enums';
import {
  filterOptionsByConsultType,
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import { sharedSections } from 'features/consults/shared/tabs/Plan/data';
import fields from 'features/consults/shared/tabs/Plan/formFields';

export const recommendationsPanel = {
  elements: [fields.recommendations],
};

export const rightPanels = [
  sharedSections.labsSection,
  {
    elements: [
      mapChipFieldOptions(fields.imagingPredefined),
      fields.imagingFreeText,
    ],
  },
  sharedSections.diagnosticTestSection,
  sharedSections.therapyEvaluationSection,
  {
    elements: [
      mapChipFieldOptions(
        filterOptionsByConsultType(
          fields.medicationsPredefined,
          ConsultTypeEnum.CLINIC,
        ),
      ),
      fields.medicationsFreeText,
    ],
  },
  {
    elements: [
      mapChipFieldOptions(
        filterOptionsByConsultType(
          fields.otherPredefined,
          ConsultTypeEnum.CLINIC,
        ),
      ),
      fields.otherFreeText,
    ],
  },
  sharedSections.additionalRecommendationsSection,
  {
    elements: [
      mapToggleFieldOptions(fields.followUpPredefined),
      fields.followUpFreeText,
    ],
  },
  {
    elements: [
      fields.timeSpentInDirectCareOfPatientMinutes,
      mapChipFieldOptions(
        fields.timeSpentInDirectCareOfPatientCounselingCoordinationPredefined,
      ),
    ],
  },
];
