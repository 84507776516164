import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import { buildFilterQueryString } from 'api/utils';
import useFilterState from 'shared/hooks/useFilterState';
import { useTableState } from 'shared/hooks/useTableState';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { consultsActions } from 'features/consults/store/slice';
import { getConsultsByType } from 'features/consults/store/selectors';
import { ConsultPathsEnum, ConsultStates } from 'features/consults/utils/enums';
import { mapConsultFilters } from 'features/consults/utils/mappers';

const useConsultsListState = (consultType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, state: routeState } = useLocation();

  const {
    consultData: { data: consults, pageCount },
    userId,
  } = useSelector(getConsultsByType(consultType));
  const { currentConsult } = useSelector((state) => state.consults);
  const consultState = currentConsult?.signature?.state;
  const consultAssignee = currentConsult?.signature?.assignToId;

  const { queryString, queryParams } = useQueryParams();
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
    setPage,
  } = useTableState(sortFields.consults.createdAt);

  const { filters, handleFilter, handleResetFilters } = useFilterState(
    {
      query: '',
      assignToUserId: queryParams.page ? null : String(userId),
      state: queryParams.page ? null : ConsultStates.OPEN,
      fromCreatedAt: null,
      toCreatedAt: null,
      networkId: null,
      healthcareSystemId: null,
      facilityId: null,
    },
    setPage,
    handleSortChange,
  );

  useEffect(() => {
    if (consultType) {
      const mappedFilters = mapConsultFilters(filters);
      dispatch(
        consultsActions.listConsults({
          consultType,
          page,
          sortBy,
          sortOrder,
          filters: mappedFilters,
        }),
      );
      history.replace(
        `${pathname}?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}${buildFilterQueryString(
          mappedFilters,
        )}`,
        routeState,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    sortBy,
    sortOrder,
    filters,
    currentConsult.id,
    consultState,
    consultAssignee,
  ]);

  const navigateToConsult = (consultId) => {
    history.push(`${ConsultPathsEnum[consultType]}/${consultId}${queryString}`);
  };

  return {
    consults,
    page,
    pageCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsult,
  };
};

export default useConsultsListState;
