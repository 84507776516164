import { US_DATE_FORMAT, TIME_FORMAT, formatDate } from 'utils/dates';
import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';

export const dateDisplay = (date) =>
  date && formatDate(new Date(date), `${US_DATE_FORMAT} - ${TIME_FORMAT}`);

export const getConsultRequestProgress = (consultRequestStatus) => {
  switch (consultRequestStatus) {
    case ConsultRequestStatusEnum.ACCEPTED:
      return 50;
    case ConsultRequestStatusEnum.CLOSED:
      return 100;
    default:
      return 0;
  }
};
