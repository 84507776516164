import React from 'react';
import PropTypes from 'prop-types';
import { Drawer as MaterialDrawer } from '@material-ui/core';

const Drawer = ({ children, isOpen, onClose, ...props }) => (
  <MaterialDrawer
    open={isOpen}
    onClose={onClose}
    ModalProps={{
      keepMounted: true, // Better open performance on mobile.
    }}
    {...props}
  >
    {children}
  </MaterialDrawer>
);

Drawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Drawer;
