import styled from 'styled-components';

/* Google button styles copied from here:
  https://github.com/anthonyjgrove/react-google-login/blob/master/src/google-login.js#L69
*/
export const GoogleButtonContainer = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 2px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;

  &:active {
    background-color: rgb(238, 238, 238);
  }
`;

export const GoogleLogo = styled.img`
  max-width: 18px;
  margin-right: 20px;
`;
