import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import FormRow from 'shared/components/forms/FormRow/FormRow';
import InputField from 'shared/components/forms/InputField/InputField';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import schema from './validationSchema';

const NetworkForm = ({
  onSubmit,
  onCancel,
  initValues = null,
  disabled = false,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
    setFormValues,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });

  useEffect(() => {
    if (initValues) {
      setFormValues(initValues);
    }
  }, [initValues, setFormValues]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.NETWORK_UPDATED, () => {
    // Set the current values as default
    // so any subsequent resets of the form work as expected
    setFormValues(getValues());
  });

  const submitFunc = () => {
    const {
      shiftAdminFacilityId,
      // emailAddresses,
      ...rest
    } = getTrimmedValues();
    const submitValues = {
      ...rest,
      shiftAdminFacilityId: Number(shiftAdminFacilityId),
      emailAddresses: [],
      /*
      emailAddresses: emailAddresses
        ? emailAddresses.split(',').map((email) => email.trim())
        : [],
      */
    };
    return onSubmit(submitValues);
  };

  const cancelFunc = () => {
    setFormValues(); // Reset form to initial state
    onCancel();
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit(submitFunc)}
      onCancel={cancelFunc}
      isSubmitDisabled={!canSubmitForm}
      formDisabled={disabled}
    >
      <FormRow xs={7} justify="space-between">
        <Grid item xs={6}>
          <InputField
            inputRef={register}
            name="name"
            label="Network Name"
            required
            error={getFieldError('name')}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <InputField
            inputRef={register}
            name="shiftAdminFacilityId"
            label="Network ID"
            required
            error={getFieldError('shiftAdminFacilityId')}
            disabled={disabled}
          />
        </Grid>
      </FormRow>
      {/*
      <FormRow xs={7}>
        <Grid item xs={12}>
          <InputField
            inputRef={register}
            name="emailAddresses"
            label="Schedule Update Email List"
            multiline
            rows={5}
            error={getFieldError('emailAddresses')}
            disabled={disabled}
          />
        </Grid>
      </FormRow>
    */}
    </FormWrapper>
  );
};

NetworkForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initValues: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default NetworkForm;
