import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

import Box from '@material-ui/core/Box';
import Button from 'shared/components/buttons/Button/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';

import api from 'api';
import useFormState from 'shared/hooks/useFormState';
import useRequest from 'shared/hooks/useRequest';

import demographicsFields, {
  eegDemographicsFields,
} from 'features/consults/shared/tabs/Demographics/formFields';
import signatureFields from 'features/consults/shared/tabs/Signature/formFields';
import { PanelField } from 'features/consults/components/tabFields/QuestionsPanel';
import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import { CONSULT_TABS } from 'features/consults/utils/constants';
import { ConsultTypeEnum } from 'features/consults/utils/enums';
import { MAX_PAGE_SIZE } from 'utils/constants';

import { neuroValidations, eegValidations } from './validations';

const Dialog = withStyles({
  paper: {
    padding: '1rem',
    borderRadius: '1rem',
  },
})(MuiDialog);

const DialogActions = withStyles({
  root: {
    justifyContent: 'center',
    '& button': {
      width: '7rem',
    },
  },
})(MuiDialogActions);

const EditConsultModal = ({
  consult,
  consultType,
  handleSave,
  handleClose,
}) => {
  const { doRequest: getFacilities, data: facilitiesData } = useRequest(
    api.getFacilities,
  );

  const formValidation = useMemo(() => {
    switch (consultType) {
      case ConsultTypeEnum.NEURO:
        return neuroValidations;
      case ConsultTypeEnum.EEG:
        return eegValidations;
      default:
        return {};
    }
  }, [consultType]);

  const {
    canSubmitForm,
    getFieldError,
    control,
    register,
    setFormValues,
    handleSubmit,
  } = useFormState(formValidation, {
    stateSlice: 'admin',
    showAsSnackbar: true,
  });

  useEffect(() => {
    getFacilities({
      pageSize: MAX_PAGE_SIZE,
    });
  }, [getFacilities]);

  const availableFacilities = useMemo(
    () =>
      facilitiesData?.facilities.map((fac) => ({
        value: fac.id,
        label: fac.name,
      })) ?? [],
    [facilitiesData],
  );

  const fields = useMemo(() => {
    switch (consultType) {
      case ConsultTypeEnum.NEURO:
        return [
          {
            ...mapToggleFieldOptions(demographicsFields.consultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...demographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...signatureFields.attestationOfConsultCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      case ConsultTypeEnum.EEG:
        return [
          {
            ...mapToggleFieldOptions(eegDemographicsFields.consultType),
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...eegDemographicsFields.facility,
            options: availableFacilities,
            tab: CONSULT_TABS.DEMOGRAPHICS,
          },
          {
            ...signatureFields.attestationOfEEGCompletion,
            tab: CONSULT_TABS.SIGNATURE,
          },
        ];
      default:
        return null;
    }
  }, [consultType, availableFacilities]);

  const onSubmit = useCallback(
    (formValues) => {
      const reducedData = fields.reduce(
        (res, { name, tab }) => ({
          ...res,
          [name]: {
            tab,
            value: formValues[name],
          },
        }),
        {},
      );
      handleSave(reducedData);
    },
    [fields, handleSave],
  );

  const initValues = useMemo(
    () =>
      fields?.reduce(
        (res, field) => ({
          ...res,
          [field.name]: consult[field.tab][field.name],
        }),
        {},
      ),
    [consult, fields],
  );

  useEffect(() => {
    setFormValues(initValues);
  }, [initValues, setFormValues]);

  return (
    <Dialog open onClose={handleClose} maxWidth="md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
            <span>
              This is a Closed Consult. The information entered here has likely
              already been added to the client EMR.
            </span>
            <br />
            <span>
              Any changes made here will NOT be automatically reflected on the
              Client EMR.
            </span>
          </Box>
          <Box>
            {fields?.map((field) => (
              <Box my={3} key={field.name}>
                <PanelField
                  {...omit(field, ['excludeFromSummary'])}
                  getFieldError={getFieldError}
                  control={control}
                  register={register}
                  onChange={() => {}}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={!canSubmitForm}
          >
            Save
          </Button>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

EditConsultModal.propTypes = {
  consult: PropTypes.shape({
    demographics: PropTypes.shape({}),
  }).isRequired,
  consultType: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EditConsultModal;
