import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useProfilePhotoFetch from 'shared/hooks/useProfilePhotoFetch';
import colors from 'styles/colors';
import { Box } from 'styles/layout';

export const ProviderPhotoContainer = styled(Box)`
  background: ${({ $imageUrl }) => `url(${$imageUrl}) no-repeat center`};
  background-color: ${colors.neutral};
  background-size: cover;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #ddd;
`;

const ProviderPhoto = ({ providerId }) => {
  const { imageUrl } = useProfilePhotoFetch(providerId);

  return <ProviderPhotoContainer ml={2.5} $imageUrl={imageUrl} />;
};

ProviderPhoto.propTypes = {
  providerId: PropTypes.number,
};

export default ProviderPhoto;
