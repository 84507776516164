import React from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import Paths from 'navigation/paths';

const ProfileButton = () => (
  <IconButton component={Link} to={Paths.Profile}>
    <AccountCircle color="primary" fontSize="large" />
  </IconButton>
);

export default ProfileButton;
