import React from 'react';
import { Redirect } from 'react-router-dom';

import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';

const RedirectToHome = () => {
  const { homePage } = useRoleBasedRouting();

  return <Redirect to={homePage} />;
};

export default RedirectToHome;
