import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import ConsultList from './ConsultList';
import ConsultPage from './ConsultPage';

const Consults = () => (
  <Switch>
    <Route exact path={AdminPaths.Consults} component={ConsultList} />
    <Route
      exact
      path={`${AdminPaths.Consults}/:consultType/:consultId`}
      component={ConsultPage}
    />
  </Switch>
);

export default Consults;
