import React from 'react';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import SchedulePage from './SchedulePage';

const Schedule = () => {
  const { smDown } = useBreakpoints();

  return (
    <MainWrapper sm={smDown}>
      <SchedulePage sm={smDown} />
    </MainWrapper>
  );
};

export default Schedule;
