import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as MaterialPagination } from '@material-ui/lab';
import styled from 'styled-components';

const PaginationWrapper = styled(MaterialPagination)`
  margin-bottom: 4px;
`;

const Pagination = ({
  currentPage = 1,
  totalPages = 1,
  siblingCount = 1,
  boundaryCount = 2,
  onPageChange,
}) => (
  <PaginationWrapper
    page={currentPage}
    count={totalPages}
    siblingCount={siblingCount}
    boundaryCount={boundaryCount}
    color="secondary"
    hideNextButton={totalPages === 1}
    hidePrevButton={totalPages === 1}
    onChange={onPageChange}
  />
);

Pagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  siblingCount: PropTypes.number,
  boundaryCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
