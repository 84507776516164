import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RedirectToHome from 'navigation/components/RedirectToHome/RedirectToHome';
import Paths from 'navigation/paths';
import { userSelector, isLoggedIn } from 'auth/store/selectors';
import { AuthChallengeEnum } from 'auth/utils/enums';

const PublicRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const loggedIn = useSelector(isLoggedIn);

  // Redirect to the home page if the user is logged in
  const renderRoute = (routeProps) => {
    if (user?.challengeName === AuthChallengeEnum.NewPassword) {
      return <Redirect to={Paths.ChangePassword} />;
    }
    if (user?.challengeName) {
      return <Redirect to={Paths.LoginConfirm} />;
    }

    if (loggedIn) {
      const {
        location: { state },
      } = routeProps;

      if (state?.redirectTo) {
        return <Redirect to={state.redirectTo} />;
      }
      return <RedirectToHome />;
    }
    return <Component {...routeProps} />;
  };

  return <Route {...rest} render={renderRoute} />;
};

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PublicRoute;
