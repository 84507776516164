import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';

import { ConsultTypeEnum } from 'features/consults/utils/enums';
import { TIMEZONE_LABELS, TIMEZONE_OFFSETS } from 'utils/timezones';

const mapLocation = (facility) => {
  const { streetAddress, locality } = facility.address;
  return streetAddress && locality
    ? `https://www.google.com/maps/search/?api=1&query=${streetAddress},${locality}`
    : null;
};

const phoneNumberUrl = (phoneNumber) => phoneNumber && `tel:${phoneNumber}`;

const timezoneLabel = (timezone) =>
  timezone && `${TIMEZONE_LABELS[timezone]} (${TIMEZONE_OFFSETS[timezone]})`;

const urlField = ({ value, label, url }) => ({
  value,
  label,
  url,
  blankUrl: true,
});

const mapField = (facility) =>
  urlField({
    label: 'Map',
    value: <LaunchIcon />,
    url: mapLocation(facility),
  });

const locationField = ({ address: { locality, region } }) => ({
  label: 'Location',
  value: `${locality ? locality.concat(', ') : ''} ${region || ''}`,
});

const timezoneField = ({ timezone }) => ({
  label: 'Timezone',
  value: timezoneLabel(timezone),
});

const phoneField = ({ number, label }) => ({
  label,
  value: number,
  url: phoneNumberUrl(number),
});

const mobileImagingsField = ({ mobileImagings }) => ({
  label: 'Mobile Imaging',
  value: mobileImagings && mobileImagings.join(', '),
});

const directBeamInField = ({ directBeamIn }) => ({
  label: 'Direct Beam-In',
  value: directBeamIn ? 'Yes' : 'No',
});

const neuroFacilityInfoMap = (facility) => [
  mapField(facility),
  locationField(facility),
  timezoneField(facility),
  phoneField({ number: facility.edNumber, label: 'ED Number' }),
  phoneField({ number: facility.itPhoneNumber, label: 'IT Number' }),
  phoneField({
    number: facility.callCenterPhoneNumber,
    label: 'Call Center Number',
  }),
  urlField({ label: 'EMR', value: facility.emrName, url: facility.emrLink }),
  urlField({
    label: 'Radiology',
    value: facility.radiology,
    url: facility.radiologyLink,
  }),
  urlField({
    label: 'Shared Sign-Out',
    value: facility.sharedSignout,
    url: facility.sharedSignoutLink,
  }),
  mobileImagingsField(facility),
  urlField({
    label: 'Camera',
    value: facility.camera,
    url: facility.cameraLink,
  }),
  { label: 'Onsite Neuro Coverage', value: facility.onsiteNeuroCoverage },
  directBeamInField(facility),
];

const eegFacilityInfoMap = (facility) => [
  mapField(facility),
  locationField(facility),
  timezoneField(facility),
];

// eslint-disable-next-line import/prefer-default-export
export const facilityInfoMap = (facility, consultType) => {
  switch (consultType) {
    case ConsultTypeEnum.NEURO:
      return neuroFacilityInfoMap(facility);
    case ConsultTypeEnum.EEG:
      return eegFacilityInfoMap(facility);
    default:
      return null;
  }
};
