import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX, ZIP_CODE_REGEX } from 'utils/validators';

import facilityFields from './constants';

export default yup.object().shape({
  [facilityFields.active]: yup.bool(),
  [facilityFields.name]: yup
    .string()
    .trim()
    .required(requiredString)
    .max(
      50,
      ({ max }) => `Healthcare system name should not exceed ${max} characters`,
    ),
  address: yup.object().shape({
    streetAddress: yup
      .string()
      .trim()
      .max(180, ({ max }) => `Address should not exceed ${max} characters`),
    locality: yup
      .string()
      .trim()
      .max(100, ({ max }) => `City should not exceed ${max} characters`),
    region: yup
      .string()
      .trim()
      .max(60, ({ max }) => `State should not exceed ${max} characters`),
    postalCode: yup
      .string()
      .trim()
      .max(5, ({ max }) => `Zip code should not exceed ${max} characters`)
      .matches(ZIP_CODE_REGEX, 'Invalid zip code'),
  }),
  [facilityFields.phone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.itPhone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.callCenterPhone]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid phone number'),
  [facilityFields.fax]: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid fax number'),
  [facilityFields.emrName]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`),
  [facilityFields.emrLink]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`)
    .url('Invalid url'),
  [facilityFields.radiology]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`),
  [facilityFields.radiologyLink]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`)
    .url('Invalid url'),
  [facilityFields.sharedSignout]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`),
  [facilityFields.sharedSignoutLink]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`)
    .url('Invalid url'),
  [facilityFields.camera]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`),
  [facilityFields.cameraLink]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`)
    .url('Invalid url'),
  [facilityFields.coverage]: yup
    .string()
    .trim()
    .max(180, ({ max }) => `Address should not exceed ${max} characters`),
  [facilityFields.codeFromSystem]: yup.bool(),
});
