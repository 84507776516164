import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { changePassword } from 'auth/amplify/authentication';

export const SLICE_PREFIX = 'profile';

export const getProfile = createAsyncThunk(
  `${SLICE_PREFIX}/getProfile`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { getUserDto },
      } = await api.getProfile();
      return getUserDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateProfile = createAsyncThunk(
  `${SLICE_PREFIX}/updateProfile`,
  async (
    {
      firstName,
      lastName,
      title,
      email,
      phoneNumber,
      currentPassword,
      newPassword,
    },
    { rejectWithValue },
  ) => {
    if (currentPassword && newPassword) {
      try {
        await changePassword(currentPassword, newPassword);
      } catch (err) {
        return rejectWithValue({ errors: { auth: [err.message] } });
      }
    }

    try {
      const {
        data: { getUserDto },
      } = await api.updateProfile({
        firstName,
        lastName,
        title,
        email,
        phoneNumber,
      });
      return getUserDto;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);
