import { mapChipFieldOptions } from 'features/consults/utils/formFields';
import fields from './formFields';

// eslint-disable-next-line import/prefer-default-export
export const sharedSections = {
  labsSection: {
    elements: [mapChipFieldOptions(fields.labsPredefined), fields.labsFreeText],
  },
  diagnosticTestSection: {
    elements: [
      mapChipFieldOptions(fields.diagnosticTestPredefined),
      fields.diagnosticTestFreeText,
    ],
  },
  therapyEvaluationSection: {
    elements: [
      mapChipFieldOptions(fields.therapyEvaluationPredefined),
      fields.therapyEvaluationFreeText,
    ],
  },
  medicationsSection: {
    elements: [
      mapChipFieldOptions(fields.medicationsPredefined),
      fields.medicationsFreeText,
    ],
  },
  additionalRecommendationsSection: {
    elements: [fields.additionalRecommendations],
  },
};
