import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';
import { generateGroupFields } from 'features/consults/utils/formFields';

export const muscleGroupFields = (index) => {
  const sideGroupName = `muscleGroup${index}Side`;
  const muscleGroupName = `muscleGroup${index}MuscleNamePredefined`;
  const muscleGroupFreeTextName = `muscleGroup${index}MuscleNameFreeText`;
  const multiplierName = `muscleGroup${index}Multiplier`;
  const unitName = `muscleGroup${index}UnitPredefined`;
  const unitFreeTextName = `muscleGroup${index}UnitFreeText`;

  return {
    [sideGroupName]: {
      name: sideGroupName,
      title: 'Muscle Group',
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        { value: 'Right', label: 'right' },
        { value: 'Left', label: 'left' },
        { value: 'Bilateral', label: 'bilateral' },
      ],
    },
    [muscleGroupName]: {
      name: muscleGroupName,
      associatedField: sideGroupName,
      type: ConsultFieldTypeEnum.CHIP,
      options: [
        { value: 'Face', label: '--Face--' },
        { value: 'Procerus', label: 'Procerus' },
        { value: 'Corrugator', label: 'Corrugator' },
        { value: 'OrbicularisOculi', label: 'Orbicularis oculi' },
        { value: 'OrbicularisOris', label: 'Orbicularis oris' },
        { value: 'Nasalis', label: 'Nasalis' },
        { value: 'Mentalis', label: 'Mentalis' },
        { value: 'Temporalis', label: 'Temporalis' },
        { value: 'Frontalis', label: 'Frontalis' },
        { value: 'Occipitalis', label: 'Occipitalis' },
        { value: 'Neck', label: '--Neck--', showOnNewLine: true },
        { value: 'Sternocleindomastoid', label: 'Sternocleindomastoid' },
        { value: 'LevatorScapulae', label: 'Levator scapulae' },
        { value: 'SpleniusCervicis', label: 'Splenius cervicis' },
        { value: 'SemispinalisCapitis', label: 'Semispinalis capitis' },
        { value: 'Trapezius', label: 'Trapezius' },
        { value: 'Scalenes', label: 'Scalenes' },
        { value: 'Platysma', label: 'Platysma' },
        { value: 'Trunk', label: '--Trunk--', showOnNewLine: true },
        { value: 'LatissimusDorsi', label: 'Latissimus Dorsi' },
        { value: 'Rhomboid', label: 'Rhomboid' },
        { value: 'Infraspinatus', label: 'Infraspinatus' },
        { value: 'Supraspinatus', label: 'Supraspinatus' },
        { value: 'ThoracicParaspinus', label: 'Thoracic paraspinus' },
        { value: 'TeresMajor', label: 'Teres major' },
        { value: 'TeresMinor', label: 'Teres minor' },
        { value: 'Pectoralis', label: 'Pectoralis' },
        { value: 'RectusAbdominus', label: 'Rectus abdominus' },
        { value: 'AbdominalObliques', label: 'Abdominal obliques' },
        { value: 'Arm', label: '--Arm--', showOnNewLine: true },
        { value: 'Deltoid', label: 'Deltoid' },
        { value: 'BicepsBrachii', label: 'Biceps brachii' },
        { value: 'Brachioradialis', label: 'Brachioradialis' },
        { value: 'PronatorTeres', label: 'Pronator teres' },
        { value: 'FlexorCarpiRadialis', label: 'Flexor carpi radialis' },
        { value: 'FlexorCarpiUlnaris', label: 'Flexor carpi ulnaris' },
        {
          value: 'FlexorDigitorumSuperficialis',
          label: 'Flexor digitorum superficialis',
        },
        {
          value: 'FlexorDigitorumProfundus',
          label: 'Flexor digitorum profundus',
        },
        { value: 'Lumbricals', label: 'Lumbricals' },
        { value: 'DorsalInterosseous', label: 'Dorsal interosseous' },
        { value: 'AdductorPollicis', label: 'Adductor pollicis' },
        { value: 'Leg', label: '--Leg--', showOnNewLine: true },
        { value: 'BicepsFemoris', label: 'Biceps femoris' },
        { value: 'Gastrocnemis', label: 'Gastrocnemis' },
        { value: 'TibialisAnterior', label: 'Tibialis anterior' },
        { value: 'TibialisPosterior', label: 'Tibialis posterior' },
        { value: 'FlexorDigitorum', label: 'Flexor digitorum' },
        { value: 'FlexorDigitorumBrevis', label: 'Flexor digitorum brevis' },
        { value: 'FlexorHallicusLongus', label: 'Flexor hallicus longus' },
        { value: 'AdductorHallucis', label: 'Adductor hallucis' },
        { value: 'ExtensorHallicus', label: 'Extensor hallicus' },
      ],
    },
    [muscleGroupFreeTextName]: {
      name: muscleGroupFreeTextName,
      associatedField: sideGroupName,
      type: ConsultFieldTypeEnum.INPUT,
    },
    [multiplierName]: {
      name: multiplierName,
      associatedField: sideGroupName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        { value: 'TimesOne', label: 'x1' },
        { value: 'TimesTwo', label: 'x2' },
        { value: 'TimesThree', label: 'x3' },
        { value: 'TimesFour', label: 'x4' },
        { value: 'TimesFive', label: 'x5' },
      ],
    },
    [unitName]: {
      name: unitName,
      associatedField: sideGroupName,
      type: ConsultFieldTypeEnum.TOGGLE,
      options: [
        { value: 'U5', label: '5 units' },
        { value: 'U10', label: '10 units' },
        { value: 'U15', label: '15 units' },
        { value: 'U20', label: '20 units' },
        { value: 'U25', label: '25 units' },
        { value: 'U30', label: '30 units' },
        { value: 'U40', label: '40 units' },
        { value: 'U50', label: '50 units' },
        { value: 'U60', label: '60 units' },
        { value: 'U70', label: '70 units' },
        { value: 'U80', label: '80 units' },
        { value: 'U90', label: '90 units' },
        { value: 'U100', label: '100 units' },
      ],
    },
    [unitFreeTextName]: {
      name: unitFreeTextName,
      associatedField: sideGroupName,
      type: ConsultFieldTypeEnum.INPUT,
    },
  };
};

const botoxFields = {
  consentPredefined: {
    name: 'consentPredefined',
    label: 'Consent',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'Consent',
        label: 'consent',
        summaryLabel:
          'Risks, benefits, and alternatives have been discussed with the patient; informed consent obtained',
      },
    ],
  },
  botulinumToxinRatioPredefined: {
    name: 'botulinumToxinRatioPredefined',
    title: 'Botulinum Toxin',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'OneToOne', label: '1:1' },
      { value: 'OneToFour', label: '1:4' },
    ],
  },
  botulinumToxinUnitPredefined: {
    name: 'botulinumToxinUnitPredefined',
    associatedField: 'botulinumToxinRatioPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'U100', label: '100 U' },
      { value: 'U200', label: '200 U' },
      { value: 'U300', label: '300 U' },
      { value: 'U400', label: '400 U' },
      { value: 'U500', label: '500 U' },
      { value: 'U600', label: '600 U' },
      { value: 'U700', label: '700 U' },
    ],
  },
  botulinumToxinRatioFreeText: {
    name: 'botulinumToxinRatioFreeText',
    associatedField: 'botulinumToxinRatioPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  emgGuidance: {
    name: 'emgGuidance',
    title: 'EMG Guidance',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Used', label: 'used' },
      { value: 'NotUsed', label: 'not used' },
    ],
  },
  ...generateGroupFields(muscleGroupFields),
  standardProtocolsPredefined: {
    name: 'standardProtocolsPredefined',
    label: 'Standard Protocols',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'StandardMigraineProtocol155U',
        label: 'standard migraine protocol 155 U',
        summaryLabel:
          'The following muscles were injected: Procerus 5 (5); Corrugator 5/ side (10); Frontalis 5x2/side (20); Temporalis 5x4/side (40); Occipitalis 5x3/side (30); Cervical Paraspinal Muscle Group 5x2/side (20); Trapezius 5x3/side (30); Total Dose: 155 Units',
      },
    ],
  },
  standardProtocolsTotalDoseUnits: {
    name: 'standardProtocolsTotalDoseUnits',
    label: 'Total Dose (units)',
    type: ConsultFieldTypeEnum.INPUT,
  },
  standardProtocolsWastedUnits: {
    name: 'standardProtocolsWastedUnits',
    label: 'Wasted (units)',
    type: ConsultFieldTypeEnum.INPUT,
  },
  complicationsPredefined: {
    name: 'complicationsPredefined',
    label: 'Complications',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'None', label: 'none' },
      { value: 'ToleratedProcedureWell', label: 'tolerated procedure well' },
    ],
  },
  complicationsFreeText: {
    name: 'complicationsFreeText',
    associatedField: 'complicationsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
};

export default botoxFields;
