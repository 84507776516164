import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import colors from 'styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const Autocomplete = withStyles({
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: colors.optionSelected,
    },
  },
})(MaterialAutocomplete);
