import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';
import BackButton from 'navigation/components/BackButton/BackButton';
import AddButton from 'navigation/components/AddButton';
import EditButton from 'shared/components/buttons/EditButton';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import HealthSystemForm from 'features/admin/components/HealthSystemForm/HealthSystemForm';
import schema from 'features/admin/components/HealthSystemForm/validationSchema';
import FacilitiesTable from 'features/admin/components/FacilitiesTable/FacilitiesTable';
import useEditMode from 'features/admin/hooks/useEditMode';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import {
  FlexBoxAlign,
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxSpaceBetween,
} from 'styles/layout';

const AddFacilityBtn = styled(AddButton)`
  align-self: flex-end;
  margin-bottom: 1rem;
`;

const HealthSystemDetailsPage = () => {
  const dispatch = useDispatch();
  const { system } = useSelector(({ admin }) => admin);
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { locationId } = useParams();
  const history = useHistory();

  const {
    register,
    control,
    getFieldError,
    setFormValues,
    handleSubmit,
    getTrimmedValues,
    canSubmitForm,
  } = useFormState(schema, {
    stateSlice: 'admin',
    showAsSnackbar: true,
  });

  useEffect(() => {
    if (!system.id) {
      dispatch(adminActions.getHealthSystemById(locationId));
    }
  }, [dispatch, locationId, system.id]);

  useEffect(() => {
    if (system) {
      setFormValues(system);
    }
  }, [system, setFormValues]);

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.HEALTH_SYSTEM_UPDATED,
    () => {
      dispatch(appStateActions.hideSnackbar());
      disableEditMode();
    },
  );

  const navigateToListHealthSystems = () => {
    history.push(AdminPaths.HealthSystems);
  };

  const editHealthSystem = () => {
    const values = getTrimmedValues();
    dispatch(
      adminActions.updateHealthSystem({ ...values, id: Number(locationId) }),
    );
  };

  const handleCancel = () => {
    setFormValues();
    disableEditMode();
  };

  const matchedSystem = system && Number(locationId) === system.id;

  return (
    <>
      <FlexBoxColumn height="100%">
        <FlexBoxSpaceBetween>
          <FlexBoxAlign>
            {!isEditMode && <BackButton onBack={navigateToListHealthSystems} />}
            <PageTitle>{matchedSystem && system.name}</PageTitle>
          </FlexBoxAlign>
          {!isEditMode && (
            <FlexBoxAlign>
              <EditButton onClick={enableEditMode} />
            </FlexBoxAlign>
          )}
        </FlexBoxSpaceBetween>
        <FlexBoxColumnFull p={2}>
          {matchedSystem && (
            <>
              <FormWrapper
                onSubmit={handleSubmit(editHealthSystem)}
                onCancel={handleCancel}
                isSubmitDisabled={!canSubmitForm}
                formDisabled={!isEditMode}
                buttonsPosition="left"
              >
                <HealthSystemForm
                  register={register}
                  control={control}
                  getFieldError={getFieldError}
                  disabled={!isEditMode}
                />
              </FormWrapper>
              <AddFacilityBtn
                to={`${AdminPaths.HealthSystems}/${locationId}/add-facility`}
              >
                Add Facility
              </AddFacilityBtn>
              <FacilitiesTable
                healthSystemId={locationId}
                systemPath={AdminPaths.HealthSystems}
              />
            </>
          )}
        </FlexBoxColumnFull>
      </FlexBoxColumn>
    </>
  );
};

export default HealthSystemDetailsPage;
