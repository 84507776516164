import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

export const consultCancelledValue = 'ConsultCancelled';
export const errorDuplicateValue = 'ErrorDuplicate';

export const attestationOfNeuroOptions = [
  { value: 'VideoConsultNoteInEmr', label: 'Video consult, note in EMR' },
  {
    value: 'VideoConsultTechDifficultyPastingNote',
    label: 'Video consult, technical difficulty pasting note',
  },
  {
    value: 'VideoConsultFaxOnlyFacility',
    label: 'Video consult, fax only facility',
  },
  { value: 'PhoneConsultNoteInEmr', label: 'Phone consult, note in EMR' },
  {
    value: 'PhoneConsultTechDifficultyPastingNote',
    label: 'Phone consult, technical difficulty pasting note',
  },
  {
    value: 'PhoneConsultFaxOnlyFacility',
    label: 'Phone consult, fax only facility',
  },
  { value: consultCancelledValue, label: 'Consult Cancelled' },
  { value: 'FollowUpPhoneCall', label: 'Follow-up phone call' },
  { value: errorDuplicateValue, label: 'Error, duplicate' },
];

export const attestationOfEEGOptions = [
  { value: 'EEGConsultNoteInEMR', label: 'EEG consult, note in EMR' },
  {
    value: 'EEGConsultTechnicalDifficultyPastingNote',
    label: 'EEG consult, technical difficulty pasting note',
  },
  {
    value: 'EEGConsultFaxOnlyFacility',
    label: 'EEG consult, fax only facility',
  },
  { value: consultCancelledValue, label: 'Consult Cancelled' },
  { value: 'FollowUpPhoneCall', label: 'Follow-up phone call' },
  { value: errorDuplicateValue, label: 'Error, duplicate' },
];

const telemedicineOptions = [
  {
    value: 'Telemedicine',
    label: 'telemedicine',
    summaryLabel:
      'Interactive 2 way audio and visual telecommunication technology was utilized during this visit',
  },
  { value: 'PhoneOnly', label: 'phone only' },
];

const ecptCodes = {
  95717: '2-12 hr no video',
  95718: '2-12 hr with video',
  95719: '12-26 hr no video',
  95720: '12-26 hr with video',
  95721: '36-60 hr no video',
  95722: '36-60 hr with video',
  95723: '60-84 hr no video',
  95724: '60-84 hr with video',
  95725: '>84 hr no video',
  95726: '>84 hr with video',
  95812: '41-60 minutes',
  95813: '61-119 minutes',
  95816: '20-40 minutes, awake and drowsy',
  95819: '20-40 minutes, awake and asleep',
  95822: '20-40 minutes, coma or sleep only',
};

const signatureFields = {
  attestationOfConsultCompletion: {
    name: 'attestationOfConsultCompletion',
    label: 'Attestation of consult completion',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    required: true,
    options: attestationOfNeuroOptions,
    excludeFromSummary: true,
  },
  attestationOfEEGCompletion: {
    name: 'attestationOfEEGCompletion',
    label: 'Attestation of EEG completion',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    required: true,
    options: attestationOfEEGOptions,
    excludeFromSummary: true,
  },
  telemedicine: {
    name: 'telemedicine',
    title: 'Telemedicine',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: telemedicineOptions,
  },
  eegTelemedicine: {
    name: 'eegTelemedicine',
    title: 'Telemedicine: remote EEG review',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'EEGReviewedRemotely', label: 'EEG reviewed remotely' },
      { value: 'PhoneOnly', label: 'phone only' },
    ],
  },
  telemedicineOptions: {
    name: 'telemedicineOptions',
    title: 'Telemedicine',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: telemedicineOptions,
  },
  patientConsent: {
    name: 'patientConsent',
    associatedField: 'telemedicineOptions',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'PtConsentedToNotUseFaceToFace',
        label: 'pt consented to not use face-to-face',
        summaryLabel: 'the patient consented to not using face-to-face service',
      },
    ],
  },
  providerLocationPredefined: {
    name: 'providerLocationPredefined',
    title: 'Provider Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Clinic', label: 'clinic' },
      { value: 'Hospital', label: 'hospital' },
      { value: 'Home', label: 'home' },
    ],
    excludeFromSummary: true,
  },
  providerLocationFreeText: {
    name: 'providerLocationFreeText',
    associatedField: 'providerLocationPredefined',
    label: 'Provider Location',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  patientLocation: {
    name: 'patientLocation',
    label: 'Patient Location',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  totalFaceToFaceTime: {
    name: 'totalFaceToFaceTime',
    summaryLabel:
      'Total face-to-face time spent in telemedicine encounter with patient and/or family and review of imaging (min)',
    type: ConsultFieldTypeEnum.INPUT,
    inputType: 'number',
    fullWidth: false,
  },
  criticalCareTime: {
    name: 'criticalCareTime',
    label: 'Critical Care time (min)',
    type: ConsultFieldTypeEnum.INPUT,
    inputType: 'number',
    inlineLabel: true,
  },
  billingCode: {
    name: 'billingCode',
    label: 'Billing Codes',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    options: [
      {
        value: 'G0425',
        label:
          'G0425 - Init Telehealth, problem focused hx, exam, & low complexity (or 30 min)',
      },
      {
        value: 'G0426',
        label:
          'G0426 - Init Telehealth consult, detailed hx, exam, & mod complexity (or 50 min)',
      },
      {
        value: 'G0427',
        label:
          'G0427 - Init Telehealth consult, comp hx, exam, & high complexity (or 70 min)',
      },
      {
        value: 'G0508',
        label:
          'G0508 - Init Telehealth crit care consult, typically 60 min comm w/ pt & providers via telehealth',
      },
      {
        value: 'G0406',
        label:
          'G0406 - Subsequent inpt consult. limited, typically 15 min spent comm w/ pt via telehealth',
      },
      {
        value: 'G0407',
        label:
          'G0407 - Subsequent inpt consult. intermediate, typically 25 min spent comm w/ pt via telehealth',
      },
      {
        value: 'G0408',
        label:
          'G0408 - Subsequent inpt consult. complex, typically 35 min spent comm w/ pt via telehealth',
      },
      {
        value: 'G0409',
        label:
          'G0409 - Subsequent telehealth crit care, typically 50 min spent comm w/ pt & providers via telehealth',
      },
      {
        value: 'A_99499TS',
        label: '99499TS - Brief beam in without patient assessment (no charge)',
      },
    ],
    excludeFromSummary: true,
  },
  eegcptCode: {
    name: 'eegcptCode',
    label: 'EEG CPT code',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
    options: Object.entries(ecptCodes).map(([code, label]) => ({
      label: `${code}: ${label}`,
      value: code,
    })),
    excludeFromSummary: true,
  },
  additionalTelemedicineParticipants: {
    name: 'additionalTelemedicineParticipants',
    label: 'Additional telemedicine participant(s)',
    type: ConsultFieldTypeEnum.INPUT,
  },
  qualityReview: {
    name: 'qualityReview',
    label: 'Quality Review',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'FlagForReview',
        label: 'flag for quality review',
      },
    ],
    excludeFromSummary: true,
  },
  qualityComment: {
    name: 'qualityComment',
    label: 'Quality Comments (Not part of medical record)',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
    excludeFromSummary: true,
  },
};

export const clinicSignatureFields = {
  ...signatureFields,
  providerLocationPredefined: {
    ...signatureFields.providerLocationPredefined,
    excludeFromSummary: false,
  },
  providerLocationFreeText: {
    ...signatureFields.providerLocationFreeText,
    excludeFromSummary: false,
  },
  patientLocation: {
    ...signatureFields.patientLocation,
    excludeFromSummary: false,
  },
  totalFaceToFaceTime: {
    ...signatureFields.totalFaceToFaceTime,
    excludeFromSummary: false,
  },
};

export default signatureFields;
