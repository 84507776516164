import { createSelector } from '@reduxjs/toolkit';

export const userSelector = ({ auth }) => auth.user;

export const isLoggedIn = createSelector(
  userSelector,
  (user) => !!(user && !user.challengeName),
);

export const userRoles = createSelector(userSelector, (user) =>
  user ? user.roles : [],
);
export const userMFA = createSelector(
  userSelector,
  (user) => user?.preferredMFA,
);
