import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import { NavItem, Link, NotificationBadge } from './NavLink.style';

const NavLink = ({
  text,
  to,
  isActive = false,
  badgeCount = null,
  onClick = null,
}) => {
  const renderLink = () => (
    <Link component={RouterLink} to={to} $isActive={isActive} onClick={onClick}>
      {text}
    </Link>
  );

  if (!badgeCount) {
    return <NavItem>{renderLink()}</NavItem>;
  }

  return (
    <NavItem>
      <NotificationBadge badgeContent={badgeCount}>
        {renderLink()}
      </NotificationBadge>
    </NavItem>
  );
};

NavLink.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  badgeCount: PropTypes.number,
  onClick: PropTypes.func,
};

export default NavLink;
