import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Paths from 'navigation/paths';
import ConsultsList from 'features/consults/components/ConsultsList/ConsultsList';
import ConsultDetailsWrapper from 'features/consults/components/ConsultDetailsWrapper/ConsultDetailsWrapper';
import { ConsultTypeEnum } from 'features/consults/utils/enums';

const EegConsults = () => (
  <Switch>
    <Route
      exact
      path={Paths.Eeg}
      render={(routeProps) => (
        <ConsultsList consultType={ConsultTypeEnum.EEG} {...routeProps} />
      )}
    />
    <Route
      exact
      path={`${Paths.Eeg}/:consultId`}
      render={(routeProps) => (
        <ConsultDetailsWrapper
          consultType={ConsultTypeEnum.EEG}
          {...routeProps}
        />
      )}
    />
  </Switch>
);

export default EegConsults;
