import { ConsultTypeEnum } from 'features/consults/utils/enums';
import {
  filterOptionsByConsultType,
  mapToggleFieldOptions,
  mapChipFieldOptions,
} from 'features/consults/utils/formFields';

import fields from 'features/consults/shared/tabs/Demographics/formFields';

export const leftPanels1 = [
  { elements: [mapToggleFieldOptions(fields.consultType)] },
  {
    elements: [
      mapToggleFieldOptions(
        filterOptionsByConsultType(
          fields.neuroPatientLocation,
          ConsultTypeEnum.NEURO,
        ),
      ),
    ],
  },
];

export const leftPanels2 = [
  { elements: [fields.age] },
  { elements: [mapToggleFieldOptions(fields.gender)] },
  { elements: [mapChipFieldOptions(fields.prehospitalBeamIn)] },
];

export const leftPanels3 = [
  { elements: [fields.referringProvider] },
  { elements: [fields.callBackPhone] },
  { elements: [fields.cameraName] },
  { elements: [fields.notes] },
];

export const rightPanels = [
  { elements: [fields.initialPageAt] },
  { elements: [fields.returnCallAt] },
];

export const phoneOnlyPanel = {
  elements: [mapChipFieldOptions(fields.phoneOnly)],
};

export const phoneOnlyFreetextPanel = {
  elements: [fields.phoneOnlyFreetext],
};
