import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_PAGE_SIZE } from 'utils/constants';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';

const HealthSystemFilter = ({ control, name, placeholder, onFilter }) => {
  const [healthSystems, setHealthSystems] = useState([]);

  useEffect(() => {
    async function fetchHealthSystems() {
      try {
        const {
          data: { healthSystems: systems },
        } = await api.getHealthSystemNames({ pageSize: MAX_PAGE_SIZE });
        setHealthSystems(
          systems.map((sys) => ({ value: sys.id, label: sys.name })),
        );
      } catch {
        setHealthSystems([]);
      }
    }
    fetchHealthSystems();
  }, []);

  return (
    <SelectWithFilter
      control={control}
      name={name}
      placeholder={placeholder}
      data={healthSystems}
      handleChange={(value) => onFilter({ [name]: value })}
    />
  );
};

HealthSystemFilter.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
};

export default HealthSystemFilter;
