import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Add } from '@material-ui/icons';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';

const Button = styled(ActionButton)`
  border-radius: 12px;
`;

const AddButton = ({ to, children, ...props }) => (
  <Button
    type="button"
    color="secondary"
    component={Link}
    to={to}
    icon={<Add />}
    {...props}
  >
    {children}
  </Button>
);

AddButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default AddButton;
