import { RESET_OPTION } from 'features/consults/utils/constants';
import { mapChipFieldOptions } from 'features/consults/utils/formFields';

import fields from './formFields';

export const telemedicineOptions = [
  RESET_OPTION,
  { value: 'Telemedicine', content: 'telemedicine' },
  { value: 'PhoneOnly', content: 'phone only' },
];

export const providerLocationOptions = [
  RESET_OPTION,
  { value: 'Clinic', content: 'clinic' },
  { value: 'Hospital', content: 'hospital' },
  { value: 'Home', content: 'home' },
];

export const qualityReviewPanel = {
  elements: [mapChipFieldOptions(fields.qualityReview)],
};

export const qualityCommentPanel = {
  elements: [fields.qualityComment],
};
