import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import sortBy from 'lodash/sortBy';

import api from 'api';
import useRoles from 'auth/hooks/useRoles';
import Select from 'shared/components/forms/Select/Select';
import useFormState from 'shared/hooks/useFormState';
import useSelectedOptionState from 'shared/hooks/useSelectedOptionState';
import { Box, FlexBoxAlign, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import { adminActions } from 'features/admin/store/slice';
import ScheduleViewToggle from 'features/schedule/components/ScheduleViewToggle/ScheduleViewToggle';
import useScheduleFilterOptions from 'features/schedule/hooks/useScheduleFilterOptions';
import { serviceOptions as defaultServiceOptions } from 'features/schedule/utils/constants';
import { ScheduleViewEnum } from 'features/schedule/utils/enums';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { mapDoctorNames } from 'utils/mappers';
import { AutocompleteDropdown, ExportButton } from './ScheduleFilters.style';

const ScheduleFilters = ({
  filters,
  disableExport = false,
  onFilterChange,
  onExport,
}) => {
  const dispatch = useDispatch();
  const { control, getFieldError, getValues, setValue, watch } = useFormState();
  const { data: facilities } = useSelector(({ admin }) => admin.facilities);

  const [networkOptions, setNetworkOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const { isCustomerOnly, doctorRoles } = useRoles();
  const { setSelectedOptionValue } = useSelectedOptionState(
    getValues,
    setValue,
  );
  const { serviceOptions } = useScheduleFilterOptions(facilities);
  const selectedFacility = watch('facility');
  const selectedNetwork = watch('network');

  useEffect(() => {
    dispatch(adminActions.listFacilities({ pageSize: MAX_PAGE_SIZE }));
  }, [dispatch]);

  useEffect(() => {
    if (!isCustomerOnly) {
      const fetchNetworks = async () => {
        const { data } = await api.getNetworkNames({
          pageSize: MAX_PAGE_SIZE,
        });

        setNetworkOptions(data.networks);
      };

      fetchNetworks();
    }
  }, [isCustomerOnly]);

  useEffect(() => {
    if (filters.scheduleView !== ScheduleViewEnum.MY) {
      const fetchDoctors = async () => {
        const { data } = await api.getUserNames({
          pageSize: MAX_PAGE_SIZE,
          filters: { roles: doctorRoles },
        });

        setUserOptions(mapDoctorNames(data.users));
      };

      fetchDoctors();
    }
  }, [doctorRoles, filters.scheduleView]);

  useEffect(() => {
    setSelectedOptionValue(filters.facilityId, 'facility', facilities);
  }, [filters.facilityId, facilities, setSelectedOptionValue]);

  useEffect(() => {
    setSelectedOptionValue(filters.networks, 'network', networkOptions);
  }, [filters.networks, networkOptions, setSelectedOptionValue]);

  useEffect(() => {
    setSelectedOptionValue(filters.userId, 'assignee', userOptions);
  }, [filters.userId, setSelectedOptionValue, userOptions]);

  const handleScheduleViewChange = (value) => {
    let filterValues = { scheduleView: value };

    if (value === ScheduleViewEnum.MY) {
      filterValues = {
        ...filterValues,
        userId: null,
      };
      setValue('assignee', null);
    }
    onFilterChange(filterValues);
  };

  return (
    <>
      <FlexBoxAlign flexWrap="wrap">
        {facilities.length > 1 && (
          <Box mr={1}>
            <AutocompleteDropdown
              control={control}
              name="facility"
              placeholder="Facility"
              data={sortBy(facilities, ['healthSystemId', 'name'])}
              disabled={!!selectedNetwork}
              handleChange={(facility) =>
                onFilterChange({ facilityId: facility?.id })
              }
              groupBy={(o) => o.healthSystemName}
            />
          </Box>
        )}
        {!isCustomerOnly && (
          <Box mr={1}>
            <AutocompleteDropdown
              control={control}
              name="network"
              placeholder="Network"
              data={networkOptions}
              disabled={!!selectedFacility}
              handleChange={(network) =>
                onFilterChange({ networks: network?.id })
              }
            />
          </Box>
        )}
        {serviceOptions.length > 1 && (
          <Box mr={1}>
            <Select
              name="service"
              placeholder="All services"
              control={control}
              options={serviceOptions}
              error={getFieldError('service')}
              defaultValue={filters.networkType}
              onChange={({ target }) =>
                onFilterChange({ networkType: target.value })
              }
            />
          </Box>
        )}
        {filters.scheduleView !== ScheduleViewEnum.MY && (
          <Box mr={1}>
            <AutocompleteDropdown
              control={control}
              name="assignee"
              placeholder="Assignee"
              data={userOptions}
              handleChange={(user) => onFilterChange({ userId: user?.id })}
            />
          </Box>
        )}
      </FlexBoxAlign>
      <FlexBoxSpaceBetweenCenter>
        {isCustomerOnly && (
          <FlexBoxAlign>
            <Box mr={1}>
              <ExportButton
                color="secondary"
                disabled={disableExport}
                onClick={() => onExport('csv')}
              >
                Export as CSV
              </ExportButton>
            </Box>
            <ExportButton
              color="secondary"
              disabled={disableExport}
              onClick={() => onExport('xlsx')}
            >
              Export as XLS
            </ExportButton>
          </FlexBoxAlign>
        )}
        <ScheduleViewToggle
          defaultValue={filters.scheduleView}
          handleChange={handleScheduleViewChange}
        />
      </FlexBoxSpaceBetweenCenter>
    </>
  );
};

ScheduleFilters.propTypes = {
  filters: PropTypes.shape({
    scheduleView: PropTypes.oneOf([ScheduleViewEnum.MY, ScheduleViewEnum.ALL])
      .isRequired,
    facilityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    networks: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    networkType: PropTypes.oneOf([
      ...defaultServiceOptions.map((option) => option.value),
      '',
    ]),
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  disableExport: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default ScheduleFilters;
