import styled from 'styled-components';

import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import { Box, FlexBoxSpaceBetween } from 'styles/layout';

const ResponsiveContainer = styled(MainWrapper)`
  width: auto;
`;

export const OuterWrapper = styled(FlexBoxSpaceBetween)`
  width: ${({ $sm }) => ($sm ? '100%' : 'calc(100% - 260px)')};
`;

export const ConsultWrapper = styled(Box)`
  width: ${({ $notesOpen, $sm }) =>
    $notesOpen && !$sm ? 'calc(100% - 320px)' : '100%'};
`;

export const DetailsWrapper = styled(ResponsiveContainer)`
  position: relative;
  min-height: 875px;
`;

export const MacrosWrapper = styled(ResponsiveContainer)`
  margin-top: 0;
`;
