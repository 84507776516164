import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import debounce from 'lodash/debounce';

import api from 'api';
import useRequest from 'shared/hooks/useRequest';
import useSummaryCopy from 'features/consults/hooks/useSummaryCopy';
import useSummaryTabState from 'features/consults/hooks/useSummaryTabState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { consultsActions } from 'features/consults/store/slice';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { PAGINATION_PAGE_SIZE, DEBOUNCE_TIMEOUT } from 'utils/constants';
import {
  IntegrationStatusEnum,
  OrderTypeEnum,
} from 'features/consults/utils/enums';
import { formatDate, US_DATE_FORMAT_SMALL } from 'utils/dates';
import {
  Box,
  FlexBoxSpaceBetweenCenter,
  FlexBoxJustify,
  HiddenWrapper,
} from 'styles/layout';

import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import Button from 'shared/components/buttons/Button/Button';
import SummaryNote from 'features/consults/components/SummaryNote/SummaryNote';

import {
  SearchInput,
  SearchIcon,
  SearchTitle,
} from './ConsultIntegration.style';
import ConsultIntegrationMerge from './ConsultIntegrationMerge';

const formatedDate = (date) => formatDate(new Date(date), US_DATE_FORMAT_SMALL);

const tableColumns = [
  {
    header: {
      id: 'dtOfTrnsaction',
      value: 'DOS',
    },
    formatValue: ({ dtOfTrnsaction }) => formatedDate(dtOfTrnsaction),
  },
  {
    header: {
      id: 'givenName',
      value: 'First',
    },
    dataKey: 'givenName',
  },
  {
    header: {
      id: 'familyName',
      value: 'Last',
    },
    dataKey: 'familyName',
  },
  {
    header: {
      id: 'dob',
      value: 'DOB',
    },
    formatValue: ({ dob }) => formatedDate(dob),
  },
];

const ConsultIntegration = ({ consult, consultType }) => {
  const { doRequest: getOrders, data: orderData } = useRequest(api.getOrders);
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showMerge, setShowMerge] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);
  const dispatch = useDispatch();
  const { title, summarySections, facilityTimezone, closedAt, closedByName } =
    useSummaryTabState(consult, consultType);
  const { summaryContainerRef } = useSummaryCopy();

  useEffect(() => {
    getOrders({
      PageIndex: page - 1,
      PageSize: PAGINATION_PAGE_SIZE,
      ConsultId: consult?.id,
      Query: searchTxt,
      Type: OrderTypeEnum[consultType],
    });
  }, [getOrders, page, consult, consultType, searchTxt]);

  const onClickRow = useCallback(
    (id) => {
      if (selectedRow?.id === id) {
        setSelectedRow(null);
        return;
      }
      const row = find(orderData?.hl7Orders, { id });
      if (row) setSelectedRow(row);
    },
    [orderData, selectedRow],
  );

  const onMerge = () => {
    dispatch(
      consultsActions.linkOrder({
        hl7OrderId: selectedRow?.id,
        consultId: consult.id,
        consultType,
        summary: summaryContainerRef.current?.innerText,
      }),
    );
  };

  usePerformActionOnSuccess(SuccessActionTypesEnum.ORDER_LINKED, () => {
    dispatch(
      consultsActions.getConsultById({
        consultId: consult.id,
        forceReload: true,
      }),
    );
    setShowMerge(false);
    setSelectedRow(null);
    dispatch(
      appStateActions.showSnackbar({
        text: 'Linked successfully',
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  });

  if (consult?.integrationStatus === IntegrationStatusEnum.MATCHED) {
    return (
      <FlexBoxJustify>
        <h3>Matched</h3>
      </FlexBoxJustify>
    );
  }

  return (
    <Box style={{ width: '27rem' }}>
      <SearchTitle>Unmatched ADT Orders</SearchTitle>
      <FlexBoxSpaceBetweenCenter>
        <SearchInput
          fullWidth
          type="search"
          onChange={debounce(
            ({ target }) => setSearchTxt(target.value),
            DEBOUNCE_TIMEOUT,
          )}
        />
        <SearchIcon />
      </FlexBoxSpaceBetweenCenter>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(orderData?.hl7Orders, tableColumns)}
        fallbackMsg="No orders to show"
        selectedRowId={selectedRow?.id}
        onClickRow={onClickRow}
        currentPage={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        totalPages={orderData?.pageCount}
      />
      <FlexBoxJustify mt={1}>
        <Button
          disabled={!selectedRow}
          variant="contained"
          color="secondary"
          onClick={() => setShowMerge(true)}
        >
          Review Order & Consult
        </Button>
      </FlexBoxJustify>
      {showMerge && (
        <ConsultIntegrationMerge
          consult={consult}
          order={selectedRow}
          onClose={() => setShowMerge(false)}
          onMerge={onMerge}
        />
      )}
      <HiddenWrapper>
        <SummaryNote
          ref={summaryContainerRef}
          title={title}
          summarySections={summarySections}
          facilityTimezone={facilityTimezone}
          isConsultClosed={Boolean(closedAt)}
          closedByName={closedByName}
        />
      </HiddenWrapper>
    </Box>
  );
};

ConsultIntegration.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.number,
    integrationStatus: PropTypes.string,
    demographics: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  consultType: PropTypes.string,
};

export default ConsultIntegration;
