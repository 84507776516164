import React from 'react';

import logoNoType from 'auth/assets/bsl-logo-no-type.svg';
import {
  PageWrapper,
  ContentWrapper,
  Logo,
  Paragraph,
} from './RestrictedPage.style';

const RestrictedPage = () => (
  <PageWrapper>
    <ContentWrapper>
      <Logo src={logoNoType} alt="Blue Sky Link Logo" />
      <Paragraph>403 Error</Paragraph>
      <Paragraph>You do not have access to this page.</Paragraph>
      <Paragraph>
        If you think you have seen this message in error, please contact a Blue
        Sky administrator.
      </Paragraph>
    </ContentWrapper>
  </PageWrapper>
);

export default RestrictedPage;
