import { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { appStateActions } from 'store/appStateSlice';

const useSummaryCopy = () => {
  const dispatch = useDispatch();
  const summaryContainerRef = useRef(null);

  const copyNote = () => {
    const elem = summaryContainerRef.current;
    const selection = window.getSelection();
    const range = document.createRange();

    selection.removeAllRanges();
    range.selectNodeContents(elem);
    selection.addRange(range);

    try {
      document.execCommand('copy');
      selection.removeAllRanges();
      dispatch(
        appStateActions.showSnackbar({
          text: 'Copied successfully',
          type: SnackbarTypeEnum.SUCCESS,
          duration: 5000,
        }),
      );
    } catch {
      // Show snackbar message as fallback
      dispatch(
        appStateActions.showSnackbar({
          text: 'Press Ctrl + C to copy note to clipboard',
          type: SnackbarTypeEnum.WARNING,
          duration: 5000,
        }),
      );
    }
  };

  return {
    summaryContainerRef,
    copyNote,
  };
};

export default useSummaryCopy;
