import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import ChipList from 'shared/components/forms/Chip/ChipList';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import InputField from 'shared/components/forms/InputField/InputField';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import { Box } from 'styles/layout';

import { mapFacilityOptions } from 'utils/mappers';
import { getFacilityNetworks } from 'utils/networks';
import { neuroAcuteStrokeLevel1Type } from 'features/consults/utils/constants';
import { FilterTypePropNames } from 'features/consults/utils/enums';
import {
  eEGTypeOptions,
  videoOptions,
} from 'features/consults/shared/tabs/Demographics/formFields';

import {
  eegConsultTypeValue,
  genderOptions,
  patientLocationOptions,
  consultTypeOptions,
} from './data';

const FlexGridColumn = styled(Grid)`
  flex-direction: column;
`;

const ConsultRequestForm = ({
  formProps: { control, register, setValue, getFieldError, watch },
  facilities,
  disabled,
}) => {
  const selectedConsultType = watch('type');

  const locationOptions = useMemo(
    () =>
      selectedConsultType !== eegConsultTypeValue
        ? patientLocationOptions.filter(
            (option) => !option[FilterTypePropNames.EEG],
          )
        : patientLocationOptions,
    [selectedConsultType],
  );

  const typeOptions = useMemo(() => {
    const availableNetworks = getFacilityNetworks(facilities);

    return consultTypeOptions.reduce((acc, consultType) => {
      if (availableNetworks.has(consultType.network)) {
        return acc.concat(consultType);
      }

      return acc;
    }, []);
  }, [facilities]);

  useEffect(() => {
    // Automatically select EEG if it is the only option
    if (
      typeOptions.length === 1 &&
      typeOptions[0].value === eegConsultTypeValue
    ) {
      setValue('type', eegConsultTypeValue);
    }
  }, [setValue, typeOptions]);

  return (
    <GridWrapper spacing={10}>
      <FlexGridColumn container item sm={12} lg={5}>
        <Box mb={3}>
          <InputField
            inputRef={register}
            name="firstName"
            label="First Name"
            required
            error={getFieldError('firstName')}
            disabled={disabled}
          />
        </Box>
        <Box mb={3}>
          <InputField
            inputRef={register}
            name="lastName"
            label="Last Name"
            required
            error={getFieldError('lastName')}
            disabled={disabled}
          />
        </Box>
        <Box mb={3}>
          <DatePicker
            control={control}
            name="dateOfBirth"
            label="Date of Birth"
            error={getFieldError('dateOfBirth')}
            openTo="year"
            disableFuture
            disabled={disabled}
          />
        </Box>
        <Box mb={3}>
          <ToggleButtonGroup
            control={control}
            name="gender"
            title="Gender"
            compact
            buttons={genderOptions}
            disabled={disabled}
          />
        </Box>
        {facilities?.length > 1 && (
          <Box mb={3}>
            <SelectWithFilter
              control={control}
              name="facility"
              label="Facility"
              data={mapFacilityOptions(facilities)}
              error={getFieldError('facility')}
              required
              groupBy={(o) => o.healthSystemName}
              disabled={disabled}
            />
          </Box>
        )}
        <Box mb={1}>
          <ToggleButtonGroup
            control={control}
            name="type"
            title="Consult Type"
            compact
            required
            buttons={typeOptions}
            handleChange={() => setValue('eegReadConsultType', null)}
            error={getFieldError('type')}
            disabled={disabled}
          />
        </Box>
        {selectedConsultType === eegConsultTypeValue && (
          <>
            <Box mb={1}>
              <ToggleButtonGroup
                control={control}
                name="eegReadConsultType"
                title="Type of EEG Read"
                compact
                required
                buttons={eEGTypeOptions.map((opt) => ({
                  value: opt.value,
                  content: opt.label,
                }))}
                error={getFieldError('eegReadConsultType')}
                disabled={disabled}
              />
            </Box>
            <Box mb={3}>
              <ToggleButtonGroup
                control={control}
                name="videoType"
                compact
                required
                buttons={videoOptions.map((v) => ({
                  ...v,
                  content: v.label,
                }))}
                error={getFieldError('videoType')}
              />
            </Box>
          </>
        )}
        <Box mb={3}>
          <ToggleButtonGroup
            control={control}
            name="patientLocation"
            title="Patient Location"
            compact
            required
            buttons={locationOptions}
            error={getFieldError('patientLocation')}
            disabled={disabled}
          />
        </Box>
      </FlexGridColumn>
      <FlexGridColumn container item sm={12} lg={5}>
        <Box mb={3}>
          <InputField
            inputRef={register}
            name="providerRequestingConsult"
            label="Provider Requesting Consult"
            error={getFieldError('providerRequestingConsult')}
            disabled={disabled}
          />
        </Box>
        <Box mb={3}>
          <PhoneNumberInput
            control={control}
            name="phoneNumber"
            label="Phone"
            required
            error={getFieldError('phoneNumber')}
            disabled={disabled}
          />
        </Box>
        {selectedConsultType !== eegConsultTypeValue ? (
          <>
            <Box mb={3}>
              <InputField
                inputRef={register}
                name="neuroCameraName"
                label="Camera Name"
                error={getFieldError('neuroCameraName')}
                disabled={disabled}
              />
            </Box>
            {selectedConsultType === neuroAcuteStrokeLevel1Type && (
              <Box mb={3}>
                <ChipList
                  control={control}
                  name="neuroDirectBeamInRequested"
                  options={[
                    {
                      value: 'directBeamIn',
                      text: 'Direct Beam-in Requested',
                    },
                  ]}
                  disabled={disabled}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            <Box mb={3}>
              <InputField
                // Added because this field is somehow being confused with neuroCameraName
                key="eegTechName"
                inputRef={register}
                name="eegTechName"
                label="Referring Technician"
                error={getFieldError('eegTechName')}
                disabled={disabled}
              />
            </Box>
            <Box mb={3}>
              <PhoneNumberInput
                control={control}
                name="eegTechCallBackNumber"
                label="Tech Callback Number"
                error={getFieldError('eegTechCallBackNumber')}
                disabled={disabled}
              />
            </Box>
            <Box mb={3}>
              <ChipList
                control={control}
                name="statReadRequested"
                options={[
                  {
                    value: 'statReadRequested',
                    text: 'STAT Read Requested',
                  },
                ]}
                disabled={disabled}
              />
            </Box>
          </>
        )}
        <Box mb={3}>
          <InputField
            inputRef={register}
            name="notes"
            label="Notes"
            error={getFieldError('notes')}
            multiline
            rows={8}
            disabled={disabled}
          />
        </Box>
      </FlexGridColumn>
    </GridWrapper>
  );
};

ConsultRequestForm.propTypes = {
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.shape({}).isRequired,
    setValue: PropTypes.func.isRequired,
    watch: PropTypes.func.isRequired,
    getFieldError: PropTypes.func.isRequired,
  }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
};

export default ConsultRequestForm;
