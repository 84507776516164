import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import RedirectToHome from 'navigation/components/RedirectToHome/RedirectToHome';
import Paths from 'navigation/paths';
import AuthFormWrapper from 'auth/components/AuthFormWrapper/AuthFormWrapper';
import { userSelector, isLoggedIn } from 'auth/store/selectors';
import { authActions } from 'auth/store/slice';
import useFormState from 'shared/hooks/useFormState';
import { requiredString } from 'utils/constants';

import { Box } from 'styles/layout';
import InputField from 'shared/components/forms/InputField/InputField';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';

const schema = yup.object().shape({
  code: yup.string().trim().required(requiredString),
});

const LoginConfirmPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const loggedIn = useSelector(isLoggedIn);

  const { register, handleSubmit, getFieldError, serverError } = useFormState(
    schema,
    { stateSlice: 'auth' },
  );

  const onToken = ({ code }) => {
    dispatch(authActions.loginUserConfirm(code));
  };

  const authLabel = useMemo(() => {
    if (!user?.challengeParam) return null;
    switch (user?.challengeParam?.CODE_DELIVERY_DELIVERY_MEDIUM) {
      case 'SMS':
        return `A security code was sent by SMS to the phone number on file: ${user.challengeParam.CODE_DELIVERY_DESTINATION}`;
      default:
        return 'Please enter you confirmation code';
    }
  }, [user]);

  if (loggedIn) {
    return <RedirectToHome />;
  }

  if (!authLabel) return <Redirect to={Paths.Login} />;

  return (
    <AuthFormWrapper onSubmit={handleSubmit(onToken)}>
      <Box mb={2}>
        <InputField
          inputRef={register}
          name="code"
          label={authLabel}
          size="medium"
          required
          autoComplete="off"
          error={getFieldError('code')}
        />
        {!!serverError && <ErrorMsg text={serverError} mt={2} mb={0} />}
      </Box>
      <Box mb={2}>
        <ActionButton size="large" fullWidth>
          Confirm Login
        </ActionButton>
      </Box>
    </AuthFormWrapper>
  );
};

export default LoginConfirmPage;
