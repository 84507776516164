import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';

import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { Autocomplete as MaterialAutocomplete } from 'shared/components/forms/SelectWithFilter/SelectWithFilter.styles';

const showOptions = (options, selected) => {
  if (selected && !options.find((option) => option.id === selected.id)) {
    return [selected, ...options];
  }

  return options;
};

const Autocomplete = ({
  control,
  name,
  label = null,
  data,
  disabled,
  size = 'small',
  placeholder = null,
  required = false,
  handleChange,
  handleInputChange,
  groupBy,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={null}
    render={(controllerProps) => (
      <MaterialAutocomplete
        {...controllerProps}
        id={name}
        disabled={disabled}
        options={showOptions(data, controllerProps.value)}
        getOptionLabel={(option) => option?.name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        size={size}
        onChange={(_, value) => {
          controllerProps.onChange(value);
          if (handleChange) handleChange(value);
        }}
        onInputChange={(_, value) => {
          if (handleInputChange) handleInputChange(value);
        }}
        groupBy={groupBy}
        renderInput={(autocompleteProps) => (
          <>
            {!!label && (
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
              >
                {label}
              </InputLabel>
            )}
            <TextField
              {...autocompleteProps}
              {...props}
              name={name}
              size={size}
              placeholder={placeholder}
              variant="outlined"
            />
          </>
        )}
      />
    )}
  />
);

Autocomplete.propTypes = {
  control: PropTypes.shape({
    register: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  groupBy: PropTypes.func,
};

export default Autocomplete;
