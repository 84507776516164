import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AddButton from 'navigation/components/AddButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import { useTableState } from 'shared/hooks/useTableState';
import { FlexBoxColumn, FlexBoxSpaceBetweenCenter } from 'styles/layout';
import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';
import sortFields from 'api/sortFields';
import { getTableHeaders, prepareTableData } from 'utils/tables';

const healthSystemTableColumns = [
  {
    header: {
      id: sortFields.healthSystems.name,
      value: 'Healthcare System Name',
      isSortable: true,
    },
    dataKey: 'name',
    width: '80%',
  },
  {
    header: {
      id: sortFields.healthSystems.facilityCount,
      value: 'Number of Facilities',
      isSortable: true,
    },
    dataKey: 'facilitiesCount',
    align: 'center',
  },
];

const HealthSystemsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: healthSystems, pageCount } = useSelector(
    ({ admin }) => admin.systems,
  );
  const {
    page,
    sortBy,
    sortOrder,
    handlePageChange,
    handleSortChange,
  } = useTableState(sortFields.healthSystems.name);

  useEffect(() => {
    dispatch(adminActions.listHealthSystems({ page, sortBy, sortOrder }));
    history.replace(
      `${AdminPaths.HealthSystems}?page=${page}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortOrder]);

  const navigateToHealthSystemDetailsPage = (id) => {
    history.push(`${AdminPaths.HealthSystems}/${id}`);
  };

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Healthcare Systems</PageTitle>
        <AddButton to={AdminPaths.AddHealthSystem}>Add System</AddButton>
      </FlexBoxSpaceBetweenCenter>
      <FlexBoxColumn mt={3}>
        <PaginatedTable
          headings={getTableHeaders(healthSystemTableColumns)}
          data={prepareTableData(healthSystems, healthSystemTableColumns)}
          fallbackMsg="No Healthcare systems found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={navigateToHealthSystemDetailsPage}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default HealthSystemsPage;
