import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import BackButton from 'navigation/components/BackButton/BackButton';
import useBack from 'navigation/hooks/useBack';
import {
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';

import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import EditButton from 'shared/components/buttons/EditButton';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';

import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import NetworkForm from 'features/admin/components/NetworkForm';
import FacilitiesTable from 'features/admin/components/FacilitiesTable/FacilitiesTable';
import useEditMode from 'features/admin/hooks/useEditMode';
import AdminPaths from 'features/admin/paths';
import { adminActions } from 'features/admin/store/slice';

const NetworkDetailsPage = () => {
  const dispatch = useDispatch();
  const { networkId } = useParams();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { onBack } = useBack();
  const { data: network } = useSelector(({ admin }) => admin.network);

  useEffect(() => {
    dispatch(adminActions.getNetwork(networkId));
  }, [dispatch, networkId]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.NETWORK_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    disableEditMode();
  });

  const onNetworkUpdate = (values) => {
    dispatch(adminActions.updateNetwork({ ...values, id: network.id }));
  };

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={onBack} />}
          <PageTitle>Network Details</PageTitle>
        </FlexBoxAlign>
        {!isEditMode && (
          <FlexBoxAlign>
            <EditButton onClick={enableEditMode} />
          </FlexBoxAlign>
        )}
      </FlexBoxSpaceBetween>
      <FlexBoxColumnFull p={2}>
        {network && Number(networkId) === network.id && (
          <NetworkForm
            disabled={!isEditMode}
            onSubmit={onNetworkUpdate}
            onCancel={disableEditMode}
            initValues={network}
          />
        )}
        <FacilitiesTable
          networkId={networkId}
          systemPath={AdminPaths.Networks}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default NetworkDetailsPage;
