import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import { ConsultFilterEnum } from 'features/consults/utils/enums';

import ConsultQuickFilterSelect from './ConsultQuickFilterSelect';

const expandedFilters = [
  ConsultFilterEnum.ALL,
  ConsultFilterEnum.ALL_OPEN,
  ConsultFilterEnum.ALL_CLOSED,
  ConsultFilterEnum.MY_CLOSED,
  ConsultFilterEnum.LAST_24,
  ConsultFilterEnum.MY_LAST_24,
];

const ConsultQuickFilterToggle = ({
  name,
  control,
  onChange,
  getQuickFilterOption,
  getQuickFilterValues,
  activeQuickFilter,
}) => {
  const openOption = getQuickFilterOption(ConsultFilterEnum.OPEN);
  const expandedOptions = expandedFilters.map(getQuickFilterOption);
  const [selectedOption, setSelectedOption] = useState(
    getQuickFilterOption(ConsultFilterEnum.ALL),
  );
  const anchorRef = useRef(null);

  useEffect(() => {
    if (activeQuickFilter && expandedFilters.includes(activeQuickFilter)) {
      setSelectedOption(getQuickFilterOption(activeQuickFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuickFilter]);

  useEffect(() => {
    control.setValue(name, activeQuickFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuickFilter]);

  const handleChange = (value) => {
    const quickFilterValues = getQuickFilterValues(value);
    onChange(quickFilterValues);
  };

  const handleOptionChange = (value) => {
    control.setValue(name, value);
    handleChange(value);
  };

  return (
    <ToggleButtonGroup
      ref={anchorRef}
      buttons={[
        { value: openOption.value, content: openOption.label },
        {
          value: selectedOption.value,
          content: (
            <ConsultQuickFilterSelect
              anchorRef={anchorRef}
              selectedOption={selectedOption}
              expandedOptions={expandedOptions}
              handleOptionChange={handleOptionChange}
            />
          ),
        },
      ]}
      control={control}
      name="quickFilter"
      handleChange={handleChange}
    />
  );
};

ConsultQuickFilterToggle.propTypes = {
  control: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  getQuickFilterOption: PropTypes.func.isRequired,
  getQuickFilterValues: PropTypes.func.isRequired,
  activeQuickFilter: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ConsultQuickFilterToggle;
