export const PHONE_MASK_FORMAT = '999-999-9999';

export const mapInputValue = (phoneNumber) => {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber.substr(2); // Remove country code
};

export const mapOutputValue = (phoneNumber) => {
  const onlyDigits = phoneNumber.replace(/\D/g, '');

  return onlyDigits ? `+1${onlyDigits}` : null;
};
