import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Add } from '@material-ui/icons';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { consultsActions } from 'features/consults/store/slice';
import { ConsultPathsEnum } from 'features/consults/utils/enums';

const Button = styled(ActionButton)`
  border-radius: 12px;
`;

const NewConsultButton = ({ consultType, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.CONSULT_CREATED, () => {
    history.push(
      `${ConsultPathsEnum[consultType]}/${successAction.payload.id}`,
    );
  });

  const createNewConsult = () => {
    dispatch(consultsActions.addNewConsult(consultType));
  };

  return (
    <Button
      type="button"
      color="secondary"
      onClick={createNewConsult}
      icon={<Add />}
      {...props}
    >
      {`New ${consultType}`}
    </Button>
  );
};

NewConsultButton.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default NewConsultButton;
