import { NeuroConsultTypes } from 'features/consults/utils/constants';
import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';
import { mapFieldOptions } from 'features/consults/utils/formFields';
import {
  patientLocationOptions as demographicsPatientLocationOptions,
  genderOptions as demographicsGenderOptions,
} from 'features/consults/shared/tabs/Demographics/formFields';
import { NETWORK_NAMES } from 'utils/networks';

const neuroConsultTypeOptions = NeuroConsultTypes.map((consultType) => ({
  ...consultType,
  network: NETWORK_NAMES.NEURO,
}));

export const eegConsultTypeValue = 'Eeg';

export const genderOptions = mapFieldOptions(
  demographicsGenderOptions,
  ConsultFieldTypeEnum.TOGGLE,
);

export const patientLocationOptions = mapFieldOptions(
  demographicsPatientLocationOptions,
  ConsultFieldTypeEnum.TOGGLE,
);

export const consultTypeOptions = [
  ...mapFieldOptions(neuroConsultTypeOptions, ConsultFieldTypeEnum.TOGGLE),
  { value: eegConsultTypeValue, content: 'EEG', network: NETWORK_NAMES.EEG },
];
