import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { formatSummaryTimestamp } from 'features/consults/utils/dates';
import {
  getFieldSummaryLabel,
  renderFieldValue,
} from 'features/consults/utils/formFields';
import { Box } from 'styles/layout';
import {
  fontStyle,
  pageTileStyle,
  sectionHeaderStyle,
  fieldRowStyle,
  lineBreakStyle,
} from './SummaryNote.style';
import AddendumList from './AddendumList';

const SummaryNote = forwardRef(
  (
    {
      title,
      summarySections,
      addenda = [],
      facilityTimezone,
      isConsultClosed,
      closedAt,
      closedByName,
    },
    ref,
  ) => {
    const showSignedAtTime = isConsultClosed && closedAt && closedByName;

    const renderTabData = useMemo(
      () =>
        summarySections.map(({ sectionTitle, summaryFields }) => (
          <Box key={sectionTitle} mb={3}>
            <h3 style={sectionHeaderStyle}>{`# ${sectionTitle}`}</h3>
            {summaryFields.map((field) => (
              <Box style={fieldRowStyle} key={field.name} mb={1}>
                <b>{getFieldSummaryLabel(field, facilityTimezone)}</b>
                {renderFieldValue(field, facilityTimezone)}
                <Box style={lineBreakStyle}>{'\r'}</Box>
              </Box>
            ))}
            <Box style={lineBreakStyle}>{'\r'}</Box>
          </Box>
        )),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [summarySections],
    );

    const renderAddenda = useMemo(() => {
      if (addenda.length === 0) {
        return null;
      }

      return (
        <AddendumList addenda={addenda} facilityTimezone={facilityTimezone} />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addenda]);

    return (
      <Box ref={ref}>
        <Box style={fontStyle}>
          {isConsultClosed && renderAddenda}
          <h1 style={pageTileStyle}>
            {`Blue Sky ${title} Note`}
            <Box style={lineBreakStyle}>{'\r'}</Box>
          </h1>
          {renderTabData}
          {showSignedAtTime && (
            <Box fontSize="14px">
              {`Electronically signed at ${formatSummaryTimestamp(
                closedAt,
                facilityTimezone,
              )} by ${closedByName}`}
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);

SummaryNote.propTypes = {
  title: PropTypes.string.isRequired,
  summarySections: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addenda: PropTypes.arrayOf(PropTypes.shape({})),
  facilityTimezone: PropTypes.string.isRequired,
  isConsultClosed: PropTypes.bool,
  closedAt: PropTypes.string,
  closedByName: PropTypes.string,
};

export default SummaryNote;
