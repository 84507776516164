import React, { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask-3.0';
import { Controller } from 'react-hook-form';
import { AccessTime } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import DatePicker from 'react-datepicker';
import debounce from 'lodash/debounce';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';
import { FlexBoxAlign, FlexBoxWrap } from 'styles/layout';
import { DEFAULT_FIELD_FORMAT, TIME_FORMAT, isValidDate } from 'utils/dates';
import { TIMEZONE_LABELS, TIMEZONE_OFFSETS } from 'utils/timezones';

import 'react-datepicker/dist/react-datepicker.css';

const EMPTY_TIME_MASK = '__/__/____ __:__';
const INPUT_MASK = '99/99/9999 99:99';

const DateTimeTextInput = forwardRef(
  ({ onChange, inputStyle, ...rest }, ref) => (
    <InputMask
      {...rest}
      ref={ref}
      mask={INPUT_MASK}
      onChange={(e) => {
        const { target } = e;
        onChange({
          ...e,
          target: {
            ...target,
            value: target.value === EMPTY_TIME_MASK ? '' : target.value,
          },
        });
      }}
    >
      <TextField variant="outlined" size="small" style={inputStyle} />
    </InputMask>
  ),
);

DateTimeTextInput.propTypes = {
  onChange: PropTypes.func,
  inputStyle: PropTypes.shape({}),
};

const selectedDate = (date, timezone) => {
  if (!date) return null;

  return timezone
    ? utcToZonedTime(date, TIMEZONE_OFFSETS[timezone])
    : new Date(date);
};

const DateTimeInput = ({
  control,
  name,
  label,
  required = false,
  disabled = false,
  error = null,
  format = DEFAULT_FIELD_FORMAT,
  placeholder = null,
  disableFuture = false,
  onChange,
  timezone,
  inputStyle = {},
}) => {
  const inputPlaceholder = placeholder || format.toLowerCase();

  const updateTimestamp = debounce(() => {
    onChange(name, ConsultFieldTypeEnum.DATETIME);
  }, 1000);

  const handleDateTimeChange = (date) => {
    if (isValidDate(date)) {
      updateTimestamp();
    }
  };

  const timezoneLabelExtra = useMemo(
    () => (timezone ? ` (${TIMEZONE_LABELS[timezone]})` : ''),
    [timezone],
  );

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ value, onChange: onControllerChange }) => (
        <FlexBoxWrap>
          {label && (
            <FlexBoxAlign>
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                inline
              >
                {`${label}${timezoneLabelExtra}`}
              </InputLabel>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  onControllerChange(new Date());
                  updateTimestamp();
                }}
              >
                <AccessTime />
              </IconButton>
            </FlexBoxAlign>
          )}
          <FlexBoxAlign>
            <DatePicker
              selected={selectedDate(value, timezone)}
              dateFormat={format}
              timeIntervals={1}
              customInput={
                <DateTimeTextInput inputStyle={inputStyle} error={error} />
              }
              isClearable
              timeFormat={TIME_FORMAT}
              placeholderText={inputPlaceholder}
              showTimeSelect
              onChange={(date) => {
                const newDate =
                  timezone && date
                    ? zonedTimeToUtc(date, TIMEZONE_OFFSETS[timezone])
                    : date;
                onControllerChange(newDate);
                handleDateTimeChange(newDate);
              }}
              maxDate={disableFuture ? new Date() : null}
            />
          </FlexBoxAlign>
        </FlexBoxWrap>
      )}
    />
  );
};

DateTimeInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  format: PropTypes.string,
  placeholder: PropTypes.string,
  timezone: PropTypes.string,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  inputStyle: PropTypes.shape({}),
};

export default DateTimeInput;
