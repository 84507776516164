export const getTableHeaders = (columns) =>
  columns.map((column) => column.header);

// Transform table data into the expected format
export const prepareTableData = (data, columns) =>
  data
    ? data.map((item) => ({
        id: item.id,
        cells: columns.map(
          ({ dataKey, width, align, formatValue, ...props }) => ({
            value: formatValue ? formatValue(item) : item[dataKey],
            width,
            align,
            ...props,
          }),
        ),
      }))
    : [];
