import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import api from 'api';
import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';
import { userRoles } from 'auth/store/selectors';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { successActionSelector } from 'store/selectors';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { ConsultNetworkTypesEnum } from 'features/consults/utils/enums';
import { consultRequestActions } from 'features/customer/store/slice';
import { AllowedProviderRolesEnum } from 'features/customer/utils/enums';

const useConsultRequestData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { consultType, consultRequestId } = useParams();
  const roles = useSelector(userRoles);
  const { homePage } = useRoleBasedRouting();
  const { currentConsultRequest: consultRequest, error } = useSelector(
    ({ consultRequests }) => consultRequests,
  );
  const successAction = useSelector(successActionSelector);
  const [facilityName, setFacilityName] = useState(null);

  const canAccessRequest = useMemo(
    () => roles.includes(AllowedProviderRolesEnum[consultType]),
    [consultType, roles],
  );

  useEffect(() => {
    if (canAccessRequest && consultRequestId) {
      dispatch(consultRequestActions.getConsultRequestById(consultRequestId));
    }
  }, [canAccessRequest, consultRequestId, dispatch]);

  useEffect(() => {
    if (consultRequest.facilityId) {
      const fetchFacility = async () => {
        const { data } = await api.getFacilityNames(
          ConsultNetworkTypesEnum[consultType],
          {
            filters: { facilityId: consultRequest.facilityId },
          },
        );

        if (data.facilities.length > 0) {
          setFacilityName(data.facilities[0].name);
        }
      };

      fetchFacility();
    }
  }, [consultRequest.facilityId, consultType]);

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.CONSULT_REQUEST_ACCEPTED,
    () => {
      history.push(`/${consultType}/${successAction.payload.consultId}`, {
        navigateOnBack: `/${consultType}`,
      });
    },
  );

  const handleRequestAccept = useCallback(() => {
    dispatch(consultRequestActions.acceptConsultRequest(consultRequestId));
  }, [consultRequestId, dispatch]);

  const handleRequestDecline = useCallback(() => {
    dispatch(consultRequestActions.declineConsultRequest(consultRequestId));
    history.replace(homePage);
  }, [consultRequestId, dispatch, history, homePage]);

  return {
    consultRequest,
    consultType,
    facilityName,
    canAccessRequest,
    handleRequestAccept,
    handleRequestDecline,
    error,
  };
};

export default useConsultRequestData;
