import React from 'react';
import styled from 'styled-components';

import { Tab } from '@material-ui/core';
import { TabList, TabPanel } from '@material-ui/lab';
import LockIcon from '@material-ui/icons/Lock';

import colors from 'styles/colors';

export const TabsContainer = styled((props) => (
  <TabList
    classes={{ flexContainer: 'container', indicator: 'indicator' }}
    {...props}
  />
))`
  .container {
    flex-wrap: wrap;
  }

  .indicator {
    background-color: ${colors.transparent};
  }
`;

export const TabHeader = styled(Tab)`
  min-width: 80px;
  border-bottom: 3px solid
    ${({ $selected }) => ($selected ? colors.secondary : colors.transparent)};

  ${({ $hasError }) => $hasError && `border: 3px solid ${colors.error}`}
`;

export const TabContent = styled(TabPanel)`
  padding: 20px 10px;
`;

export const Locked = styled(LockIcon)`
  margin: 10px 0;
  color: ${colors.neutralDark};
`;
