import { useState } from 'react';

import { useQueryParams } from 'shared/hooks/useQueryParams';

const renderArray = (queryValue, resValue) => {
  if (Array.isArray(queryValue)) return [...resValue, ...queryValue];
  return [...resValue, queryValue];
};

const useFilterState = (
  defaultFilters = {},
  setPage = null,
  handleSortChange = null,
) => {
  const { queryParams } = useQueryParams();
  const [filters, setFilters] = useState(
    Object.entries(defaultFilters).reduce(
      (res, [key, defaultvalue]) =>
        (Object.prototype.hasOwnProperty.call(queryParams, key) && {
          ...res,
          [key]: Array.isArray(defaultvalue)
            ? renderArray(queryParams[key], res[key])
            : queryParams[key],
        }) ||
        res,
      defaultFilters,
    ),
  );

  const handleFilter = (value) => {
    setFilters({ ...filters, ...value });
    if (setPage) setPage(1);
  };

  const handleResetFilters = (value) => {
    const { orderOverride, ...newFilters } = value;
    setFilters(newFilters);
    if (setPage) setPage(1);
    if (orderOverride && handleSortChange) {
      handleSortChange(orderOverride.sortBy, orderOverride.sortOrder);
    }
  };

  return {
    filters,
    handleFilter,
    handleResetFilters,
  };
};

export default useFilterState;
