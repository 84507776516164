import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useBreakpoints from 'shared/hooks/useBreakpoints';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import Tabs from 'features/consults/components/Tabs/Tabs';
import ConsultMenu from 'features/consults/components/ConsultMenu';
import ConsultSidebar from 'features/consults/components/ConsultSidebar/ConsultSidebar';
import ConsultMacros from 'features/consults/components/ConsultMacros/ConsultMacros';
import { consultsActions } from 'features/consults/store/slice';
import {
  ConsultTypeEnum,
  ConsultPathsEnum,
  ConsultStates,
} from 'features/consults/utils/enums';
import {
  OuterWrapper,
  ConsultWrapper,
  DetailsWrapper,
  MacrosWrapper,
} from './ConsultDetailsWrapper.style';

const ConsultDetailsWrapper = ({ consultType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { consultId } = useParams();
  const [menuOpen, setMenuOpen] = useState(false);
  const { smDown } = useBreakpoints();
  const { queryString } = useQueryParams();
  const { id: userId } = useSelector(({ profile }) => profile.data);
  const { consult, errors } = useSelector(
    ({ consults: { consultLock, currentConsult } }) => ({
      errors: consultLock.errors,
      consult: currentConsult,
    }),
  );
  const consultState = consult.signature?.state;
  const consultAssignee = consult.signature?.assignToId;

  const isClosed = useMemo(
    () => consultState === ConsultStates.CLOSED,
    [consultState],
  );

  const canApplyMacros = useMemo(
    () => !isClosed && userId === consultAssignee,
    [consultAssignee, isClosed, userId],
  );

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      // Clear consult data when navigating to a different page
      dispatch(consultsActions.clearConsult());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(consultsActions.getConsultById({ consultId }));
  }, [consultId, dispatch]);

  return (
    <>
      <ConsultSidebar consultType={consultType} />
      {consult.id && (
        <OuterWrapper $sm={smDown}>
          <ConsultWrapper $sm={smDown} $notesOpen={menuOpen}>
            <DetailsWrapper sm={smDown}>
              <Tabs
                isClosed={isClosed}
                error={errors}
                consultId={consultId}
                consultType={consultType}
                onClose={() =>
                  history.push(`${ConsultPathsEnum[consultType]}${queryString}`)
                }
                integrationStatus={consult?.integrationStatus}
              />
            </DetailsWrapper>
            {canApplyMacros && (
              <MacrosWrapper sm={smDown}>
                <ConsultMacros
                  consultId={consult.id}
                  consultType={consultType}
                  userId={userId}
                />
              </MacrosWrapper>
            )}
          </ConsultWrapper>
          {consultType !== ConsultTypeEnum.CLINIC && (
            <ConsultMenu
              isOpen={menuOpen}
              sm={smDown}
              onToggle={() => setMenuOpen(!menuOpen)}
              consult={consult}
              consultType={consultType}
            />
          )}
        </OuterWrapper>
      )}
    </>
  );
};

ConsultDetailsWrapper.propTypes = {
  consultType: PropTypes.string.isRequired,
};

export default ConsultDetailsWrapper;
