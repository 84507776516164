import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import Paths from 'navigation/paths';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import useFormState from 'shared/hooks/useFormState';
import { Box } from 'styles/layout';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { PASSWORD_REGEX } from 'utils/validators';
import { requiredString } from 'utils/constants';
import { authActions } from 'auth/store/slice';
import { userSelector } from 'auth/store/selectors';
import { loadingSelector } from 'store/selectors';

import AuthFormWrapper from 'auth/components/AuthFormWrapper/AuthFormWrapper';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import BasicDialog from 'shared/components/feedback/BasicDialog';
import PasswordField from 'shared/components/forms/PasswordField/PasswordField';

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .trim()
    .required(requiredString)
    .matches(PASSWORD_REGEX, 'Invalid password'),
  confirmNewPassword: yup
    .string()
    .trim()
    .required(requiredString)
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const [shouldLogout, setLogout] = useState(false);
  const {
    register,
    getTrimmedValues,
    handleSubmit,
    getFieldError,
    canSubmitForm,
    serverError,
  } = useFormState(schema, { stateSlice: 'auth' });

  const user = useSelector(userSelector);
  const loading = useSelector(loadingSelector);

  const setPermanentPassword = () => {
    const { newPassword } = getTrimmedValues();
    dispatch(authActions.setPermanentPassword(newPassword));
  };

  usePerformActionOnSuccess(SuccessActionTypesEnum.PASSWORD_SET, () => {
    setLogout(true);
  });

  if (!user) {
    return <Redirect to={Paths.Login} />;
  }

  return (
    <AuthFormWrapper onSubmit={handleSubmit(setPermanentPassword)}>
      <BasicDialog
        title="Please login with your new password"
        open={shouldLogout}
        confirmText="OK"
        handleConfirm={() => {
          dispatch(authActions.logoutUser());
        }}
      />
      <Box mb={1.5}>
        <PasswordField
          inputRef={register}
          name="newPassword"
          label="New Password"
          size="medium"
          autoComplete="new-password"
          helperText="Password must be at least 8 characters long and contain uppercase, lowercase letters, numbers and special symbols"
          required
          error={getFieldError('newPassword')}
        />
      </Box>
      <Box mb={5.5}>
        <PasswordField
          inputRef={register}
          name="confirmNewPassword"
          label="Confirm New Password"
          size="medium"
          autoComplete="new-password"
          required
          error={getFieldError('confirmNewPassword')}
        />
        {!!serverError && <ErrorMsg text={serverError} mt={2} mb={0} />}
      </Box>
      <Box mb={2}>
        <ActionButton
          size="large"
          fullWidth
          disabled={!canSubmitForm || loading}
        >
          Set Password And Log In
        </ActionButton>
      </Box>
    </AuthFormWrapper>
  );
};

export default ChangePasswordPage;
