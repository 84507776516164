import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentSession, getCurrentUser } from 'auth/amplify/authentication';
import { addHubListener, removeHubListener } from 'auth/amplify/listeners';
import { authActions } from 'auth/store/slice';
import { appStateActions } from 'store/appStateSlice';
import { mapUserToStoreFormat } from 'utils/mappers';

const useAuthEvents = () => {
  const dispatch = useDispatch();
  const [isSessionChecked, setSessionChecked] = useState(false);

  useEffect(() => {
    const authListener = ({ payload: { event, data } }) => {
      switch (event) {
        // Example - https://docs.amplify.aws/lib/auth/social/q/platform/js#full-samples
        case 'cognitoHostedUI_failure': {
          const { message } = data;
          const errorMsg = message.replace(/\+/g, ' ');

          if (errorMsg.toLowerCase().includes('already found')) {
            // Retry login as described here - https://stackoverflow.com/a/60673251
            dispatch(appStateActions.setLoading(true));
            dispatch(authActions.loginWithGoogle());
          } else {
            // Strip Cognito error text appended to message
            const cognitoErrorText = 'failed with error';
            const messageStartPos = errorMsg
              .toLowerCase()
              .lastIndexOf(cognitoErrorText);
            const messageText = errorMsg.slice(
              messageStartPos + cognitoErrorText.length + 1,
              -2, // Remove trailing whitespace and dot
            );
            dispatch(authActions.setAuthError(messageText));
          }

          break;
        }

        default:
          break;
      }
    };

    addHubListener('auth', authListener);

    return () => {
      removeHubListener('auth', authListener);
    };
  }, [dispatch]);

  useEffect(() => {
    async function isUserAuthenticated() {
      try {
        await getCurrentSession(); // Refresh tokens if expired
        const user = await getCurrentUser(); // Get current user if logged in

        dispatch(authActions.persistUserSession(mapUserToStoreFormat(user)));
      } catch {
        return;
      } finally {
        setSessionChecked(true);
      }
    }

    isUserAuthenticated();
  }, [dispatch]);

  return { isSessionChecked };
};

export default useAuthEvents;
