import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { IntegrationStatusEnum } from 'features/consults/utils/enums';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { consultsActions } from 'features/consults/store/slice';
import { appStateActions } from 'store/appStateSlice';

import IconButton from 'shared/components/buttons/IconButton/IconButton';
import IntegrationIcon from 'shared/components/data/IntegrationIcon';
import ConsultUnmatch from 'features/consults/components/ConsultMenu/ConsultIntegration/ConsultUnmatch';

const IntegrationLink = ({
  consultId,
  integrationStatus,
  consultType,
  isClosed,
}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  usePerformActionOnSuccess(SuccessActionTypesEnum.ORDER_UNLINKED, () => {
    if (!isClosed) {
      dispatch(
        consultsActions.editConsult({
          consultId,
          tab: CONSULT_TABS.DEMOGRAPHICS,
          changes: {
            firstName: '',
          },
          cb: () =>
            dispatch(
              consultsActions.getConsultById({
                consultId,
                forceReload: true,
              }),
            ),
        }),
      );
    }

    setShowModal(false);
    dispatch(
      appStateActions.showSnackbar({
        text: 'Unlinked successfully',
        type: SnackbarTypeEnum.SUCCESS,
        duration: 5000,
      }),
    );
  });

  return (
    <>
      <Dialog
        open={showModal}
        maxWidth="md"
        onClose={() => setShowModal(false)}
      >
        <DialogContent>
          <ConsultUnmatch
            onUnlink={() =>
              dispatch(consultsActions.unlinkOrder({ consultId, consultType }))
            }
            onCancel={() => setShowModal(false)}
          />
        </DialogContent>
      </Dialog>
      <IconButton
        disabled={
          integrationStatus !== IntegrationStatusEnum.MATCHED || isClosed
        }
        onClick={() => setShowModal(true)}
      >
        <IntegrationIcon integrationStatus={integrationStatus} />
      </IconButton>
    </>
  );
};

IntegrationLink.propTypes = {
  consultId: PropTypes.number,
  integrationStatus: PropTypes.string,
  consultType: PropTypes.string,
  isClosed: PropTypes.bool,
};

export default IntegrationLink;
