import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

// Use the breakpoints coming from the Material theme
const useBreakpoints = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    smDown,
  };
};

export default useBreakpoints;
