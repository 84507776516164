import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import { Box } from 'styles/layout';
import MultiChip from './MultiChip';
import { MultiChipStatesEnum } from './MultiChipStates.enum';

const MultiChipList = ({ control, name, options, onChange, disabled }) => {
  const getCurrentChipState = (selectedValues, chipValue) => {
    const chip = selectedValues.find((item) => item.label === chipValue);

    return chip ? chip.state : MultiChipStatesEnum.UNKNOWN;
  };

  const handleValuesChange = (selectedValues, chipValue, newState) => {
    // If chip state is UNKNOWN, remove it from list
    if (newState === MultiChipStatesEnum.UNKNOWN) {
      return selectedValues.filter((item) => item.label !== chipValue);
    }

    // If chip is in list, update its state
    if (selectedValues.find((item) => item.label === chipValue)) {
      return selectedValues.map((item) => {
        if (item.label === chipValue) {
          return {
            ...item,
            state: newState,
          };
        }

        return item;
      });
    }

    // If chip is NOT in list, add it
    return selectedValues.concat({
      label: chipValue,
      state: newState,
    });
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ value: selectedValues, onChange: controllerOnChange }) =>
        options.map(({ value, text }) => (
          <Box key={value} mt={0.5}>
            <MultiChip
              text={text}
              currentState={getCurrentChipState(selectedValues, value)}
              onChange={(newState) => {
                const updatedValues = handleValuesChange(
                  selectedValues,
                  value,
                  newState,
                );

                controllerOnChange(updatedValues);
                onChange();
              }}
              disabled={disabled}
            />
          </Box>
        ))
      }
    />
  );
};

MultiChipList.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiChipList;
