import { useMemo } from 'react';

import useRoles from 'auth/hooks/useRoles';
import {
  ConsultTypeEnum,
  ConsultNetworkTypesEnum,
} from 'features/consults/utils/enums';
import { serviceOptions as defaultServiceOptions } from 'features/schedule/utils/constants';
import { NETWORK_NAMES, getFacilityNetworks } from 'utils/networks';

const getServiceOptionByType = (type) =>
  defaultServiceOptions.find(
    (option) => option.value === ConsultNetworkTypesEnum[type],
  );

const networkServices = {
  [NETWORK_NAMES.NEURO]: getServiceOptionByType(ConsultTypeEnum.NEURO),
  [NETWORK_NAMES.EEG]: getServiceOptionByType(ConsultTypeEnum.EEG),
};

const useScheduleFilterOptions = (facilities) => {
  const { isCustomerOnly } = useRoles();

  const services = useMemo(() => {
    if (!isCustomerOnly) {
      return defaultServiceOptions;
    }

    const availableNetworks = getFacilityNetworks(facilities);

    return Object.entries(networkServices).reduce(
      (acc, [networkName, service]) => {
        if (availableNetworks.has(networkName)) {
          return acc.concat(service);
        }

        return acc;
      },
      [],
    );
  }, [facilities, isCustomerOnly]);

  return {
    serviceOptions: services,
  };
};

export default useScheduleFilterOptions;
