import sortFields from 'api/sortFields';
import useFilterState from 'shared/hooks/useFilterState';
import { useTableState } from 'shared/hooks/useTableState';
import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';

const useConsultRequestListState = () => {
  const { page, handlePageChange, sortBy, sortOrder } = useTableState(
    sortFields.consultRequests.createdAt,
    'desc',
  );
  const { filters, handleFilter } = useFilterState({
    facilityId: null,
    query: null,
    state: ConsultRequestStatusEnum.OPEN,
  });

  return {
    page,
    filters,
    handlePageChange,
    sortBy,
    sortOrder,
    handleFilterChange: handleFilter,
  };
};

export default useConsultRequestListState;
