import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxSpaceBetweenCenter } from 'styles/layout';
import colors from 'styles/colors';

import { facilityInfoMap } from './data';

const FacilityInfoLabel = styled.span`
  color: ${colors.neutralDark};
  font-weight: 500;
  font-size: 12px;
`;

const FacilityInfoUrl = styled.a`
  color: ${colors.primary};
  text-decoration: underline;
  font-size: 12px;
`;

const FacilityInfoValue = styled.span`
  color: ${colors.neutralDark};
  font-size: 12px;
`;

const FacilityInfo = ({ consultFacility, consultType }) => {
  if (!consultFacility)
    return <FacilityInfoLabel>No facility</FacilityInfoLabel>;
  const displayItems = facilityInfoMap(consultFacility, consultType);
  return (
    displayItems &&
    displayItems.map(({ label, value, url, blankUrl }) => (
      <FlexBoxSpaceBetweenCenter key={label} mb={1}>
        <FacilityInfoLabel>{label}</FacilityInfoLabel>
        {url ? (
          <FacilityInfoUrl
            href={url}
            rel="noreferrer"
            target={blankUrl && '_blank'}
          >
            {value || 'Open url'}
          </FacilityInfoUrl>
        ) : (
          <FacilityInfoValue>{value}</FacilityInfoValue>
        )}
      </FlexBoxSpaceBetweenCenter>
    ))
  );
};

FacilityInfo.propTypes = {
  consultType: PropTypes.string,
  consultFacility: PropTypes.shape({}),
};

export default FacilityInfo;
