import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import api from 'api';
import { TIMESTAMP_FORMAT, formatDate } from 'utils/dates';

const useAddendumState = (consultId, isConsultClosed) => {
  const user = useSelector(({ profile }) => profile.data);
  const [addenda, setAddenda] = useState([]);
  const [addendumFormVisible, setAddendumFormVisible] = useState(false);

  useEffect(() => {
    const fetchAddenda = async () => {
      const { data } = await api.getConsultAddenda(consultId);
      setAddenda(data.addenda);
    };

    if (isConsultClosed) {
      fetchAddenda();
    }
  }, [consultId, isConsultClosed]);

  const showAddendumForm = () => setAddendumFormVisible(true);

  const hideAddendumForm = () => setAddendumFormVisible(false);

  const addAddendum = (addendumText) => {
    setAddenda([
      {
        text: addendumText,
        addedAt: formatDate(new Date(), TIMESTAMP_FORMAT),
        addedByFirstName: user.firstName,
        addedByLastName: user.lastName,
        addedByTitle: user.title,
      },
      ...addenda,
    ]);
    hideAddendumForm();
  };

  return {
    addenda,
    addendumFormVisible,
    addAddendum,
    showAddendumForm,
    hideAddendumForm,
  };
};

export default useAddendumState;
