import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box } from 'styles/layout';
import colors from 'styles/colors';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import DictationInput from 'shared/components/forms/DictationInput';
import DatePicker from 'shared/components/forms/DatePicker/DatePicker';
import SingleSelect from 'features/consults/components/tabFields/SingleSelect/SingleSelect';
import MultiSelect from 'features/consults/components/tabFields/MultiSelect/MultiSelect';
import ThreeStateMultiSelect from 'features/consults/components/tabFields/ThreeStateMultiSelect/ThreeStateMultiSelect';
import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import PhoneNumber from 'features/consults/components/tabFields/PhoneNumber/PhoneNumber';
import TextInput from 'features/consults/components/tabFields/TextInput/TextInput';
import SimpleSelect from 'features/consults/components/tabFields/SimpleSelect';
import SelectWithFilter from 'features/consults/components/tabFields/SelectWithFilter';
import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';

const SectionHeader = styled(InputLabel)`
  color: ${colors.neutralDark};
  font-weight: 600;
`;

export const PanelField = ({
  name,
  type,
  options,
  register,
  control,
  onChange,
  getFieldError,
  inputType = 'text',
  ...rest
}) => {
  switch (type) {
    case ConsultFieldTypeEnum.INPUT:
      return (
        <TextInput
          {...rest}
          type={inputType}
          name={name}
          register={register}
          onChange={onChange}
          getFieldError={getFieldError}
        />
      );
    case ConsultFieldTypeEnum.MULTICHIP:
      return (
        <ThreeStateMultiSelect
          {...rest}
          name={name}
          options={options}
          control={control}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.CHIP:
      return (
        <MultiSelect
          {...rest}
          name={name}
          options={options}
          control={control}
          onChange={onChange}
          error={getFieldError(name)}
        />
      );
    case ConsultFieldTypeEnum.TOGGLE:
      return (
        <SingleSelect
          {...rest}
          name={name}
          control={control}
          buttons={options}
          compact
          onChange={onChange}
          error={getFieldError(name)}
        />
      );
    case ConsultFieldTypeEnum.DATETIME:
      return (
        <DateTimeInput
          {...rest}
          name={name}
          control={control}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.DATE:
      return (
        <DatePicker
          {...rest}
          name={name}
          control={control}
          error={getFieldError(name)}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.DICTATION:
      return (
        <DictationInput
          {...rest}
          name={name}
          control={control}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.PHONE:
      return (
        <PhoneNumber
          {...rest}
          control={control}
          name={name}
          getFieldError={getFieldError}
          onChange={onChange}
        />
      );
    case ConsultFieldTypeEnum.SELECT:
      return (
        <SimpleSelect
          {...rest}
          register={register}
          name={name}
          options={options}
          onChange={onChange}
          error={getFieldError(name)}
        />
      );
    case ConsultFieldTypeEnum.AUTOCOMPLETE:
      return (
        <SelectWithFilter
          {...rest}
          control={control}
          name={name}
          data={options}
          onChange={onChange}
          error={getFieldError(name)}
        />
      );
    default:
      return null;
  }
};

PanelField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  getFieldError: PropTypes.func,
  inputType: PropTypes.string,
};

const QuestionsPanel = ({
  label = null,
  required = false,
  elements = [],
  title = null,
  mb = 1,
  ...rest
}) => (
  <Box mb={mb}>
    {!!title && (
      <Box mb={2}>
        <SectionHeader>{title}</SectionHeader>
      </Box>
    )}
    {!!label && <InputLabel required={required}>{label}</InputLabel>}
    {elements.map(
      ({
        associatedField,
        summaryLabel,
        excludeFromSummary,
        ...fieldProps
      }) => (
        <Box key={fieldProps.name} mb={0.5}>
          <PanelField {...rest} {...fieldProps} />
        </Box>
      ),
    )}
  </Box>
);

QuestionsPanel.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  mb: PropTypes.number,
  required: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.shape({})),
  register: PropTypes.func.isRequired,
  control: PropTypes.shape({}).isRequired,
};

export default QuestionsPanel;
