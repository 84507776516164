import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

import api from 'api';
import { dateDiffFormatted, parseISO } from 'utils/dates';
import GridWrapper from 'shared/components/layout/GridWrapper/GridWrapper';
import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useFormState from 'shared/hooks/useFormState';
import usePrevious from 'shared/hooks/usePrevious';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import { TIMEZONES } from 'utils/timezones';
import schema from './validationSchema';
import { leftPanels, centerPanels, rightPanels } from './data';
import fields, {
  impressionTextValue,
  subImpressionDetails,
} from './formFields';

const fixedDate = (date) => {
  if (typeof date === 'string') return parseISO(date);
  return date;
};

const formatAddText = (newText, currentText) =>
  currentText ? `${currentText}\n\n${newText}` : newText;

const EEG = () => {
  const [facilityTimezone, setFacilityTimezone] = useState(null);
  const {
    control,
    register,
    getValues,
    getFieldError,
    setFormValues,
    setValue,
    watch,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    handleFieldChange,
    saveConsultChange,
  } = useConsultData(CONSULT_TABS.EEG, getValues);
  const [impressionAdded, setImpressionAdded] = useState([]);

  const {
    startTimeOfEEGReadAt,
    stopTimeOfEEGReadAt,
    duration,
    impressionsPredefined,
    impressionsSubtypePredefined,
    impressionsClinicalCorrelationFreeText,
    abnormalityOptionsFreeText,
  } = fields;
  const eegStartTime = watch(startTimeOfEEGReadAt.name);
  const eegEndTime = watch(stopTimeOfEEGReadAt.name);
  const impressions = watch(impressionsPredefined.name);
  const impressionSubtype = watch(impressionsSubtypePredefined.name);
  const prevImpressionSubtype = usePrevious(impressionSubtype);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  useEffect(() => {
    if (consult.id) {
      setImpressionAdded(consultTabData[impressionsSubtypePredefined.name]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consult.id]);

  useEffect(() => {
    if (consult?.demographics?.facilityId) {
      const fetchFacility = async () => {
        const { data } = await api.getFacilityById(
          consult.demographics.facilityId,
        );
        const timezone = data?.facility?.timezone || TIMEZONES.UTC;
        setFacilityTimezone(timezone);
      };
      fetchFacility();
    }
  }, [consult.demographics]);

  useEffect(() => {
    const startTime = fixedDate(eegStartTime);
    const stopTime = fixedDate(eegEndTime);
    const currentDuration = getValues(duration.name);

    if (currentDuration && (!startTime || !stopTime)) {
      setValue(duration.name, null);
      saveConsultChange({ [duration.name]: null });
      return;
    }

    const timeDiff = dateDiffFormatted(startTime, stopTime);

    if (timeDiff && timeDiff !== currentDuration) {
      setValue(duration.name, timeDiff);
      saveConsultChange({ [duration.name]: timeDiff });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eegStartTime, eegEndTime]);

  const handleImpressionChange = (fieldName) => {
    const value = getValues(fieldName);
    const text = impressionTextValue(value);

    setImpressionAdded([]);

    setValue(impressionsClinicalCorrelationFreeText.name, text);
    setValue(impressionsSubtypePredefined.name, []);
    setValue(abnormalityOptionsFreeText.name, '');

    saveConsultChange({
      [impressionsClinicalCorrelationFreeText.name]: text,
      [fieldName]: value,
      [abnormalityOptionsFreeText.name]: '',
      [impressionsSubtypePredefined.name]: [],
    });
  };

  const handleSubImpressionChange = (fieldName) => {
    const currentValues = getValues(fieldName);
    const prevValues = prevImpressionSubtype || consultTabData[fieldName];

    if (currentValues.length < prevValues.length) {
      saveConsultChange({ [fieldName]: currentValues });
      return;
    }

    const newImpression = currentValues.find(
      (val) => !prevValues.includes(val),
    );

    if (!newImpression || impressionAdded.includes(newImpression)) return;

    setImpressionAdded([...impressionAdded, newImpression]);

    const abnormalValueField = abnormalityOptionsFreeText.name;
    const abnormalDescriptionsField =
      impressionsClinicalCorrelationFreeText.name;

    const impressionDetails = subImpressionDetails(newImpression);

    const abnormalValuesText = formatAddText(
      impressionDetails.addLabel || impressionDetails.label,
      getValues(abnormalValueField),
    );
    const abnormalDescriptionsText = formatAddText(
      impressionDetails.description,
      getValues(abnormalDescriptionsField),
    );

    setValue(abnormalValueField, abnormalValuesText);
    setValue(abnormalDescriptionsField, abnormalDescriptionsText);

    saveConsultChange({
      [fieldName]: currentValues,
      [abnormalValueField]: abnormalValuesText,
      [abnormalDescriptionsField]: abnormalDescriptionsText,
    });
  };

  const handleFieldChangeOverride = (name, type) => {
    switch (name) {
      case impressionsPredefined.name:
        handleImpressionChange(name);
        break;
      case impressionsSubtypePredefined.name:
        handleSubImpressionChange(name);
        break;
      default:
        handleFieldChange(name, type);
    }
  };

  return (
    <GridWrapper>
      <Grid item xs={12} lg={4}>
        {leftPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            timezone={facilityTimezone}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={4}>
        {centerPanels.map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChange}
            disabled={!isEditable}
          />
        ))}
      </Grid>
      <Grid item xs={12} lg={4}>
        {rightPanels(impressions).map((section, index) => (
          <QuestionsPanel
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            {...section}
            control={control}
            register={register}
            getFieldError={getFieldError}
            onChange={handleFieldChangeOverride}
            disabled={!isEditable}
          />
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default EEG;
