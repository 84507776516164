import * as yup from 'yup';

import signatureFields from 'features/consults/shared/tabs/Signature/formFields';
import { requiredString } from 'utils/constants';

export default yup.object().shape({
  [signatureFields.attestationOfEEGCompletion
    .name]: yup
    .string()
    .trim()
    .required(requiredString)
    .typeError(requiredString),
});
