import React from 'react';
import PropTypes from 'prop-types';

import ProviderFilter from 'shared/components/data/ConsultFilters/ProviderFilter';
import HealthSystemFilter from 'shared/components/data/ConsultFilters/HealthSystemFilter';
import FacilityFilter from 'shared/components/data/ConsultFilters/FacilityFilter';
import NetworkFilter from 'shared/components/data/ConsultFilters/NetworkFilter';
import DateTimeFilter from 'features/consults/components/ConsultFilters/DateTimeFilter';
import ControlledSelect from 'shared/components/forms/Select/ControlledSelect';
import SelectWithFilter from 'shared/components/forms/SelectWithFilter';

import { filterTypes } from './data';
import { dateInputStyle } from './ConsultFilters.style';

const ConsultServiceFilter = ({
  filter: { filterType, name, placeholder, options, ...filterProps },
  control,
  onFilter,
  consultType,
  watch,
}) => {
  switch (filterType) {
    case filterTypes.DATETIME:
      return (
        <DateTimeFilter
          control={control}
          name={name}
          placeholder={placeholder}
          handleFilter={onFilter}
          inputStyle={dateInputStyle}
        />
      );
    case filterTypes.SELECT:
      return (
        <ControlledSelect
          control={control}
          name={name}
          placeholder={placeholder}
          options={options}
          handleChange={({ target }) => onFilter({ [name]: target.value })}
        />
      );
    case filterTypes.MULTISELECT:
      return (
        <ControlledSelect
          control={control}
          name={name}
          multiple
          placeholder={placeholder}
          options={options}
          handleChange={({ target }) => onFilter({ [name]: target.value })}
        />
      );
    case filterTypes.FILTERED_SELECT:
      return (
        <SelectWithFilter
          control={control}
          name={name}
          placeholder={placeholder}
          data={options}
          handleChange={(value) => onFilter({ [name]: value })}
        />
      );
    case filterTypes.PROVIDER:
      return (
        <ProviderFilter
          control={control}
          placeholder={placeholder}
          name={name}
          consultType={consultType}
          onFilter={onFilter}
        />
      );
    case filterTypes.HEALTHSYSTEM:
      return (
        <HealthSystemFilter
          control={control}
          placeholder={placeholder}
          name={name}
          onFilter={onFilter}
        />
      );
    case filterTypes.FACILITY:
      return (
        <FacilityFilter
          control={control}
          placeholder={placeholder}
          name={name}
          onFilter={onFilter}
          consultType={consultType}
          watch={watch}
          {...filterProps}
        />
      );
    case filterTypes.NETWORK:
      return (
        <NetworkFilter
          control={control}
          placeholder={placeholder}
          name={name}
          onFilter={onFilter}
        />
      );
    default:
      return null;
  }
};

ConsultServiceFilter.propTypes = {
  control: PropTypes.shape({}).isRequired,
  watch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  consultType: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    filterType: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default ConsultServiceFilter;
