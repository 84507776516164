import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import api from 'api';

import useRequest from 'shared/hooks/useRequest';
import useFormState from 'shared/hooks/useFormState';
import useBack from 'navigation/hooks/useBack';
import BackButton from 'navigation/components/BackButton/BackButton';

import { MAX_PAGE_SIZE } from 'utils/constants';
import { ConsultTypeEnum } from 'features/consults/utils/enums';
import ConsultRequestForm from 'features/customer/pages/NewConsultRequestPage/ConsultRequestForm';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';

import { FlexBoxColumn, FlexBoxAlign } from 'styles/layout';

const getRequestType = (consultRequest) => {
  switch (consultRequest.type.toLowerCase()) {
    case ConsultTypeEnum.NEURO:
      return consultRequest.neuroConsultType;
    case ConsultTypeEnum.EEG:
      return 'Eeg';
    default:
      return null;
  }
};

const ConsultRequestPage = () => {
  const { doRequest, data } = useRequest(api.getConsultRequestById);
  const { doRequest: getFacilities, data: facilitiesData } = useRequest(
    api.getFacilities,
  );
  const { consultRequestId } = useParams();
  const { onBack } = useBack();

  const formProps = useFormState(
    {},
    {
      stateSlice: 'consultRequests',
      showAsSnackbar: true,
    },
  );

  useEffect(() => doRequest(consultRequestId), [doRequest, consultRequestId]);

  useEffect(() => getFacilities({ pageSize: MAX_PAGE_SIZE }), [getFacilities]);

  const facilities = useMemo(
    () => facilitiesData?.facilities ?? [],
    [facilitiesData],
  );

  useEffect(
    () => {
      if (!data) return;
      const { consultRequest } = data;
      formProps.setFormValues({
        ...consultRequest,
        facility: consultRequest.facilityId,
        type: getRequestType(consultRequest),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton onBack={onBack} />
        <PageTitle>Consult Request Details</PageTitle>
      </FlexBoxAlign>
      <ConsultRequestForm
        disabled
        formProps={formProps}
        facilities={facilities}
      />
    </FlexBoxColumn>
  );
};

export default ConsultRequestPage;
