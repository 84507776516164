import React from 'react';
import PropTypes from 'prop-types';

import DialogWrapper from 'shared/components/layout/DialogWrapper';

import ProviderForm from './ProviderForm';

const ProviderAdd = ({ onSubmit, onCancel }) => (
  <DialogWrapper title="New Provider">
    <ProviderForm onCancel={onCancel} onSubmit={onSubmit} />
  </DialogWrapper>
);

ProviderAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ProviderAdd;
