import { createSlice } from '@reduxjs/toolkit';

import { mapErrorResponse } from 'utils/mappers';
import {
  SLICE_PREFIX,
  loginUser,
  loginUserConfirm,
  loginWithGoogle,
  logoutUser,
  sendForgotUserPasswordCode,
  resetUserPassword,
  setPermanentPassword,
} from './actionCreators';

const initialState = {
  user: null,
  error: null,
};

/* eslint-disable no-param-reassign */
const setUser = (state, { payload }) => {
  state.user = payload;
};

const authStateSlice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    persistUserSession: setUser,
    setAuthError: (state, { payload }) => {
      state.error = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, setUser)
      .addCase(setPermanentPassword.fulfilled, setUser)
      .addCase(resetUserPassword.fulfilled, setUser)
      .addCase(loginUserConfirm.fulfilled, setUser)
      .addCase(logoutUser.fulfilled, () => initialState)
      .addMatcher(
        (action) =>
          action.type.startsWith(`${SLICE_PREFIX}/`) &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = mapErrorResponse(action);
        },
      );
  },
});
/* eslint-enable no-param-reassign */

const { reducer, actions } = authStateSlice;

export { reducer as authReducer };
export const authActions = {
  ...actions,
  loginUser,
  loginUserConfirm,
  loginWithGoogle,
  logoutUser,
  sendForgotUserPasswordCode,
  resetUserPassword,
  setPermanentPassword,
};

export default authStateSlice;
