import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';

import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import InputField from 'shared/components/forms/InputField/InputField';
import PhoneNumberInput from 'shared/components/forms/PhoneNumberInput/PhoneNumberInput';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import colors from 'styles/colors';
import { Box } from 'styles/layout';
import { requiredString } from 'utils/constants';
import { PHONE_NUMBER_REGEX } from 'utils/validators';

const schema = yup.object().shape({
  addendum: yup.string().trim().required(requiredString),
  faxNumber: yup
    .string()
    .nullable()
    .trim()
    .matches(PHONE_NUMBER_REGEX, 'Invalid fax number'),
});

const HelperTextParagraph = styled(Box)`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.neutralDark};
`;

const AddendumForm = ({ facilityFaxNumber, onSubmit, onDismiss }) => {
  const dispatch = useDispatch();
  const {
    control,
    register,
    canSubmitForm,
    getFieldError,
    getTrimmedValues,
    handleSubmit,
    setValue,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });

  const helperText = useMemo(
    () =>
      facilityFaxNumber ? (
        <>
          <HelperTextParagraph>
            This facility has a fax number. Adding Addendum will resend Clinical
            Note with Addendum attached.
          </HelperTextParagraph>
          <HelperTextParagraph>
            You may change the number provided to resend somewhere else.
          </HelperTextParagraph>
        </>
      ) : (
        <>
          <HelperTextParagraph>
            This facility does not have a fax number assigned.
          </HelperTextParagraph>
          <HelperTextParagraph>
            You may add a number to resend the Addendum and Summary.
          </HelperTextParagraph>
        </>
      ),
    [facilityFaxNumber],
  );

  useEffect(() => {
    if (facilityFaxNumber) {
      setValue('faxNumber', facilityFaxNumber);
    }
  }, [facilityFaxNumber, setValue]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.ADDENDUM_ADDED, () => {
    dispatch(appStateActions.hideSnackbar());

    onDismiss();
  });

  return (
    <Box height="100%">
      <FormWrapper
        onSubmit={handleSubmit(() => onSubmit(getTrimmedValues()))}
        isSubmitDisabled={!canSubmitForm}
        onCancel={onDismiss}
      >
        <Box mb={3}>
          <InputField
            inputRef={register}
            name="addendum"
            label="Addendum"
            required
            error={getFieldError('addendum')}
            multiline
            rows={10}
          />
        </Box>
        <Box mb={3}>
          <PhoneNumberInput
            control={control}
            name="faxNumber"
            label="Fax Number"
            fullWidth={false}
            error={getFieldError('faxNumber')}
          />
          <Box mt={1.25} mb={3}>
            {helperText}
          </Box>
        </Box>
      </FormWrapper>
    </Box>
  );
};

AddendumForm.propTypes = {
  facilityFaxNumber: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default AddendumForm;
