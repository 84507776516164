import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { successActionSelector } from 'store/selectors';
import BackButton from 'navigation/components/BackButton/BackButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { FlexBoxColumn, FlexBoxColumnFull, FlexBoxAlign } from 'styles/layout';
import { adminActions } from 'features/admin/store/slice';
import AdminPaths from 'features/admin/paths';
import UserForm from 'features/admin/components/UserForm';

const UserAddPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const successAction = useSelector(successActionSelector);

  usePerformActionOnSuccess(SuccessActionTypesEnum.USER_ADDED, () => {
    history.push(`${AdminPaths.Users}/${successAction.payload.userId}`, {
      navigateOnBack: AdminPaths.Users,
    });
  });

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxAlign>
        <BackButton />
        <PageTitle>Create New User</PageTitle>
      </FlexBoxAlign>
      <FlexBoxColumnFull p={2}>
        <UserForm
          initValues={{ autologout: false }}
          onSubmit={(values) => dispatch(adminActions.addUser(values))}
          onCancel={() => history.goBack()}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default UserAddPage;
