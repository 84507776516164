import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from 'styles/colors';

const Title = styled.h1`
  font-size: 19px;
  font-weight: 500;
  text-transform: capitalize;
  color: ${colors.black};
  margin: 0;
  padding: 12px 0;
`;

const PageTitle = ({ children }) => <Title>{children}</Title>;

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
export default PageTitle;
