import { CONSULT_TABS } from 'features/consults/utils/constants';
import demographicsFields from 'features/consults/shared/tabs/Demographics/formFields';
import dataFields from 'features/consults/shared/tabs/Data/formFields';
import scoreFields from 'features/consults/pages/neuro/tabs/ScoresNeuro/formFields';
import planFields, {
  discussedWReferringProviderValue,
} from 'features/consults/shared/tabs/Plan/formFields';

// eslint-disable-next-line import/prefer-default-export
export const neuroTodoList = [
  {
    text: 'Callback',
    isComplete: (consult) =>
      consult[CONSULT_TABS.DEMOGRAPHICS][demographicsFields.returnCallAt.name],
  },
  {
    text: 'Reviewed Imaging',
    isComplete: (consult) =>
      consult[CONSULT_TABS.DATA][dataFields.headCtPersonallyReadAt.name] ||
      consult[CONSULT_TABS.DATA][dataFields.headCtPredefined.name].length,
  },
  {
    text: 'Camera Eval Complete',
    isComplete: (consult) =>
      consult[CONSULT_TABS.SCORES][scoreFields.nihssExamTimeAt.name],
  },
  {
    text: 'Recs Given to Doc',
    isComplete: (consult) =>
      consult[CONSULT_TABS.PLAN][planFields.otherPredefined.name]?.includes(
        discussedWReferringProviderValue,
      ),
  },
  {
    text: 'Note in Target EMR ',
    // TODO: implement backend persistence once is done
    isComplete: () => false,
  },
];
