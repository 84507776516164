import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { OutlinedInput } from '@material-ui/core';

import api from 'api';
import { ConsultTypeEnum } from 'features/consults/utils/enums';
import { Box } from 'styles/layout';

import ToDoList from './ToDoList';
import FacilityInfo from './FacilityInfo';
import SystemNote from './SystemNote';
import FacilityNote from './FacilityNote';

import { MenuHeading } from '../ConsultMenu.style';

const ConsultInfo = ({ consult, consultType, consultFacility }) => {
  const [consultSystem, setConsultSystem] = useState(null);

  useEffect(() => {
    async function fetchSystem() {
      try {
        const {
          data: { healthSystem },
        } = await api.getHealthSystemById(consultFacility.healthSystemId);

        setConsultSystem(healthSystem);
      } catch {
        setConsultSystem(null);
      }
    }
    if (consultFacility?.healthSystemId) fetchSystem();
    else setConsultSystem(null);
  }, [consultFacility]);

  return (
    <>
      {consultType === ConsultTypeEnum.NEURO && (
        <Box mb={3}>
          <MenuHeading>To Do</MenuHeading>
          <ToDoList consult={consult} consultType={consultType} />
        </Box>
      )}
      <Box mb={3}>
        <MenuHeading>Facility Info</MenuHeading>
        <FacilityInfo
          consultFacility={consultFacility}
          consultType={consultType}
        />
      </Box>
      <Box mb={3}>
        <MenuHeading>My System Notes</MenuHeading>
        <SystemNote
          consultType={consultType}
          healthSystemId={consultFacility?.healthSystemId}
        />
      </Box>
      <Box mb={3}>
        <MenuHeading>My Facility Notes</MenuHeading>
        <FacilityNote
          consultType={consultType}
          facilityId={consultFacility?.id}
        />
      </Box>
      <Box mb={3}>
        <MenuHeading>BSL System Notes</MenuHeading>
        <OutlinedInput
          value={consultSystem?.note ?? ''}
          fullWidth
          multiline
          disabled
          rows={3}
        />
      </Box>
      <Box>
        <MenuHeading>BSL Facility Notes</MenuHeading>
        <OutlinedInput
          value={consultFacility?.note ?? ''}
          fullWidth
          multiline
          disabled
          rows={3}
        />
      </Box>
    </>
  );
};

ConsultInfo.propTypes = {
  consult: PropTypes.shape({}).isRequired,
  consultType: PropTypes.string.isRequired,
  consultFacility: PropTypes.shape({
    id: PropTypes.number,
    healthSystemId: PropTypes.number,
    note: PropTypes.string,
  }),
};

export default ConsultInfo;
