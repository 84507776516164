import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

import { FlexBoxColumn } from 'styles/layout';

const Sidebar = ({ children }) => (
  <Paper elevation={2} square>
    <FlexBoxColumn width={260} pt={5}>
      {children}
    </FlexBoxColumn>
  </Paper>
);

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Sidebar;
