import React from 'react';
import PropTypes from 'prop-types';

import api from 'api';
import { ConsultTypeRequestEnum } from 'features/consults/utils/enums';

import ConsultTypeNote from './ConsultTypeNote';

const SystemNote = ({ healthSystemId, consultType }) => {
  const type = ConsultTypeRequestEnum[consultType];

  const handleNoteFetch = async () => {
    const { data } = await api.getHealthSystemNote({ healthSystemId, type });
    return data.healthSystemNote;
  };

  const handleNoteCreate = async (text) => {
    const { data } = await api.createHealthSystemNote({
      healthSystemId,
      type,
      text,
    });
    return data.id;
  };

  const handleNoteUpdate = async (text) =>
    api.updateHealthSystemNote({ healthSystemId, type, text });

  const handleNoteDelete = async () =>
    api.deleteHealthSystemNote({ healthSystemId, type });

  return (
    <ConsultTypeNote
      onFetch={handleNoteFetch}
      onCreate={handleNoteCreate}
      onUpdate={handleNoteUpdate}
      onDelete={handleNoteDelete}
      itemId={healthSystemId}
    />
  );
};

SystemNote.propTypes = {
  consultType: PropTypes.string.isRequired,
  healthSystemId: PropTypes.number,
};

export default SystemNote;
