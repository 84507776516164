import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputLabel from 'shared/components/forms/InputLabel/InputLabel';
import { FlexBoxAlignWrap } from 'styles/layout';
import { US_DATE_FORMAT, formatDateString } from 'utils/dates';

const Picker = styled(KeyboardDatePicker)`
  /* Override input field padding */
  > div {
    padding-right: 0;
  }
`;

const mapInputValue = (value) => {
  if (typeof value === 'string') {
    return formatDateString(value);
  }

  return value;
};

const DatePicker = ({
  control,
  name,
  label,
  required = false,
  disabled = false,
  error = null,
  format = US_DATE_FORMAT,
  placeholder = null,
  openTo = 'date',
  disableFuture = false,
  onChange,
}) => {
  const inputPlaceholder = placeholder || format.toLowerCase();
  const maxDate = disableFuture ? new Date() : undefined;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={null}
      render={({ value, onChange: onControllerChange }) => {
        const inputValue = mapInputValue(value);
        return (
          <>
            <FlexBoxAlignWrap>
              <InputLabel
                htmlFor={name}
                required={required}
                disabled={disabled}
                error={!!error}
                inline
              >
                {label}
              </InputLabel>
              <Picker
                variant="inline"
                inputVariant="outlined"
                size="small"
                required={required}
                disabled={disabled}
                format={format}
                placeholder={inputPlaceholder}
                value={inputValue}
                openTo={openTo}
                disableFuture={disableFuture}
                maxDate={maxDate}
                error={!!error}
                helperText={null}
                onChange={(date) => {
                  onControllerChange(date);
                  if (onChange) onChange();
                }}
              />
            </FlexBoxAlignWrap>
            {error && <ErrorMsg text={error.message} dense />}
          </>
        );
      }}
    />
  );
};

DatePicker.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  format: PropTypes.string,
  placeholder: PropTypes.string,
  openTo: PropTypes.string,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func,
};

export default DatePicker;
