import axios from 'axios';

import { getCurrentSession } from 'auth/amplify/authentication';
import {
  buildQueryString,
  buildFilterQueryString,
  mapConsultChanges,
  mapConsultFullChange,
} from './utils';
import sortFields from './sortFields';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 150000,
});

// Add access token to every API request
api.interceptors.request.use(async (config) => {
  try {
    const session = await getCurrentSession();
    const { accessToken } = session;

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${accessToken.jwtToken}`,
      },
    };
  } catch (_e) {
    // If the session is invalid, do not add an Authorization header
    return config;
  }
});

const apiConfig = {
  getUsers({ sortBy = sortFields.users.firstName, ...listOptions }) {
    return api.get(`users${buildQueryString({ sortBy, ...listOptions })}`);
  },
  exportUsers(params) {
    return api.get(`users/export${buildQueryString(params)}`);
  },
  getUserNames({ sortBy = sortFields.users.firstName, ...listOptions }) {
    return api.get(
      `users/names${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  createUser(user) {
    return api.post('users', user);
  },
  getUser(id) {
    return api.get(`users/${id}`);
  },
  updateUser(user) {
    return api.put('users', user);
  },
  resetUserPassword(id) {
    return api.post(`users/${id}/reset-password`);
  },
  reInviteUser(id) {
    return api.post(`users/${id}/re-invite`);
  },
  getProfile() {
    return api.get('user');
  },
  updateProfile(profile) {
    return api.put('user', profile);
  },
  getProviders({ sortBy = sortFields.users.firstName, ...listOptions }) {
    return api.get(
      `provider-association${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  getProvider(id) {
    return api.get(`provider-association/${id}`);
  },
  addProvider(params) {
    return api.post('provider-association', params);
  },
  updateProvider(id, values) {
    return api.put('provider-association', {
      id,
      ...values,
    });
  },
  getHealthSystems({ sortBy = sortFields.healthSystems.name, ...listOptions }) {
    return api.get(
      `health-systems${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  getHealthSystemNames({
    sortBy = sortFields.healthSystems.name,
    ...listOptions
  }) {
    return api.get(
      `health-systems/names${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  getHealthSystemById(id) {
    return api.get(`health-systems/${id}`);
  },
  createHealthSystem(system) {
    return api.post('health-systems', system);
  },
  updateHealthSystem(system) {
    return api.put('health-systems', system);
  },
  getNetworks({ sortBy = sortFields.networks.name, ...listOptions }) {
    return api.get(`networks${buildQueryString({ sortBy, ...listOptions })}`);
  },
  getNetworkNames({ sortBy = sortFields.networks.name, ...listOptions }) {
    return api.get(
      `networks/names${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  createNetwork(network) {
    return api.post('networks', network);
  },
  getNetwork(networkId) {
    return api.get(`networks/${networkId}`);
  },
  updateNetwork(network) {
    return api.put('networks', network);
  },
  createFacility({ locationId, facility }) {
    return api.post(`health-systems/${locationId}/facilities`, facility);
  },
  getFacilityById(id) {
    return api.get(`facilities/${id}`);
  },
  getCustomerFacilities({ sortBy = sortFields.facilities.name, ...params }) {
    return api.get(
      `facilities/me${buildQueryString({
        sortBy,
        ...params,
      })}`,
    );
  },
  updateFacility(facility) {
    return api.put('facilities', facility);
  },
  getFacilities({ sortBy = sortFields.facilities.name, ...listOptions }) {
    return api.get(
      `facilities${buildQueryString({
        sortBy,
        ...listOptions,
      })}`,
    );
  },
  getFacilityNames(
    networkType,
    { filters, sortBy = sortFields.facilities.name, ...listOptions },
  ) {
    return api.get(
      `facilities/names${buildQueryString({
        ...listOptions,
        sortBy,
        filters: { networkType, ...filters },
      })}`,
    );
  },
  getConsults(
    type,
    { sortBy = sortFields.consults.createdAt, ...listOptions },
  ) {
    const endpoint = type ? `consults/${type}` : 'consults-with-facilities';
    return api.get(
      `${endpoint}${buildQueryString({ sortBy, ...listOptions })}`,
    );
  },
  exportConsults(
    type,
    { sortBy = sortFields.consults.createdAt, ...listOptions },
  ) {
    return api.get(
      `consults/${type}/export${buildQueryString({ sortBy, ...listOptions })}`,
      { responseType: 'blob' },
    );
  },
  getConsultCount() {
    return api.get(`consults/count`);
  },
  getConsultById(id) {
    return api.get(`consults/${id}`);
  },
  createConsult(data) {
    return api.post('consults', data);
  },
  copyConsult(id) {
    return api.post(`consults/${id}/copy`);
  },
  setConsultFlag(id, data) {
    return api.post(`consults/${id}/setcolor`, data);
  },
  updateFullConsult(id, values) {
    const data = mapConsultFullChange(values);
    return api.patch(`consults/${id}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  updateConsult(id, tab, changes) {
    const data = mapConsultChanges(tab, changes);
    return api.patch(`consults/${id}`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  updateConsultSummary({ consultId, summary }) {
    return api.put(`consults/summary`, { consultId, summary });
  },
  updateConsultOwner(id, ownerId) {
    const data = [
      { op: 'replace', path: '/signature/assignToId', value: ownerId },
    ];
    return api.patch(`consults/${id}/owner`, data, {
      headers: { 'Content-Type': 'application/json-patch+json' },
    });
  },
  lockConsult(id, data) {
    return api.post(`consults/${id}/lock`, data);
  },
  getConsultAddenda(id) {
    return api.get(`consult-addenda/${id}`);
  },
  addConsultAddendum(id, data) {
    return api.post(`consults/${id}/consult-addendum`, data);
  },
  getShifts(startDate, endDate, filters = {}) {
    return api.get(
      `shifts?StartDate=${startDate}&EndDate=${endDate}${buildFilterQueryString(
        filters,
      )}`,
    );
  },
  exportShifts(startDate, endDate, filters = {}, fileType = 'csv') {
    return api.get(
      `shifts/export?StartDate=${startDate}&EndDate=${endDate}${buildFilterQueryString(
        filters,
      )}&FileType=${fileType}`,
      { responseType: 'blob' },
    );
  },
  getConsultRequests(params) {
    return api.get(`consult-requests${buildQueryString(params)}`);
  },
  getAdminConsultRequests(params) {
    return api.get(`consult-requests-admin${buildQueryString(params)}`);
  },
  getConsultRequestById(id) {
    return api.get(`consult-requests/${id}`);
  },
  createConsultRequest(data) {
    return api.post(`consult-requests`, data);
  },
  acceptConsultRequest(id) {
    return api.post(`consult-requests/${id}/accept`, {});
  },
  declineConsultRequest(id) {
    return api.post(`consult-requests/${id}/decline`, {});
  },
  deleteConsultRequest(id) {
    return api.delete(`consult-requests/${id}`);
  },
  getHealthSystemNote(data) {
    return api.get(`health-system-note?${buildFilterQueryString(data)}`);
  },
  updateHealthSystemNote(data) {
    return api.put(`health-system-note`, data);
  },
  deleteHealthSystemNote(data) {
    return api.delete(`health-system-note?${buildFilterQueryString(data)}`);
  },
  createHealthSystemNote({ healthSystemId, ...rest }) {
    return api.post(
      `health-systems/${healthSystemId}/health-system-notes`,
      rest,
    );
  },
  getFacilityNote(data) {
    return api.get(`facility-note?${buildFilterQueryString(data)}`);
  },
  updateFacilityNote(data) {
    return api.put(`facility-note`, data);
  },
  deleteFacilityNote(data) {
    return api.delete(`facility-note?${buildFilterQueryString(data)}`);
  },
  createFacilityNote({ facilityId, ...rest }) {
    return api.post(`facilities/${facilityId}/facility-notes`, rest);
  },
  getMacros({ sortBy = sortFields.macros.name, ...listOptions }) {
    return api.get(
      `consult-macros${buildQueryString({
        sortBy,
        ...listOptions,
      })}`,
    );
  },
  createMacro(data) {
    return api.post(`consult-macros`, data);
  },
  applyMacro(data) {
    return api.put(`consults`, data);
  },
  deleteMacro(macroId) {
    return api.delete(`consult-macros/${macroId}`);
  },
  getOrders(params) {
    return api.get(`order?${buildFilterQueryString(params)}`);
  },
  getAdminOrders(params) {
    return api.get(`order/consult?${buildFilterQueryString(params)}`);
  },
  linkOrder({ consultId, hl7OrderId, summary }) {
    return api.post('order/link', { consultId, hl7OrderId, summary });
  },
  unlinkOrder({ consultId }) {
    return api.post('order/unlink', { consultId });
  },
};

export default apiConfig;
