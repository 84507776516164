import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';

import { Box } from 'styles/layout';

const AlertBox = styled(Alert)`
  padding: ${({ $dense }) => ($dense ? '0' : '6px')} 16px;
`;

const ErrorMsg = ({ text, dense = false, ...props }) => (
  <Box my={1} {...props}>
    <AlertBox $dense={dense} severity="error">
      {Array.isArray(text)
        ? text.map((msg) => <Box key={msg}>{msg}</Box>)
        : text}
    </AlertBox>
  </Box>
);

ErrorMsg.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  dense: PropTypes.bool,
};

export default ErrorMsg;
