import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import LoginPage from 'auth/pages/LoginPage/LoginPage';
import LoginConfirmPage from 'auth/pages/LoginConfirmPage/LoginConfirmPage';
import ForgotPasswordPage from 'auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from 'auth/pages/ResetPasswordPage/ResetPasswordPage';
import ChangePasswordPage from 'auth/pages/ChangePasswordPage/ChangePasswordPage';
import RestrictedPage from 'auth/pages/RestrictedPage/RestrictedPage';
import Consults from 'features/consults';
import Macros from 'features/consults/pages/macros/MacrosListPage';
import CustomerDashboard from 'features/customer';
import ConsultRequestDetailsPage from 'features/customer/pages/ConsultRequestDetailsPage/ConsultRequestDetailsPage';
// import Messages from 'features/messages';
import Schedule from 'features/schedule';
import Admin from 'features/admin';
import Profile from 'features/profile';
import Paths from './paths';
import PublicRoute from './components/PublicRoute/PublicRoute';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

const AppRoutes = () => (
  <Router>
    <Switch>
      <PublicRoute exact path={Paths.Login} component={LoginPage} />
      <PublicRoute
        exact
        path={Paths.ForgotPassword}
        component={ForgotPasswordPage}
      />
      <PublicRoute
        exact
        path={Paths.ResetPassword}
        component={ResetPasswordPage}
      />
      <Route exact path={Paths.ChangePassword} component={ChangePasswordPage} />
      <Route exact path={Paths.LoginConfirm} component={LoginConfirmPage} />
      <Route exact path={Paths.Restricted} component={RestrictedPage} />
      <ProtectedRoute path={Paths.Neuro} component={Consults} />
      <ProtectedRoute path={Paths.Clinic} component={Consults} />
      <ProtectedRoute path={Paths.Eeg} component={Consults} />
      <ProtectedRoute path={Paths.MyFacility} component={CustomerDashboard} />
      <ProtectedRoute
        path={`${Paths.ConsultRequestDetails}/:consultType/:consultRequestId`}
        component={ConsultRequestDetailsPage}
      />
      {/* <ProtectedRoute path={Paths.Messages} component={Messages} /> */}
      <ProtectedRoute path={Paths.Macros} component={Macros} />
      <ProtectedRoute path={Paths.Admin} component={Admin} />
      <ProtectedRoute path={Paths.Schedule} component={Schedule} />
      <ProtectedRoute path={Paths.Profile} component={Profile} />
      <Route>
        <Redirect to={Paths.Login} />
      </Route>
    </Switch>
  </Router>
);

export default AppRoutes;
