import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LockIcon from '@material-ui/icons/Lock';
import EditIcon from '@material-ui/icons/Edit';

import { ConsultSummary } from 'features/consults/shared/tabs/Summary';
import { adminActions } from 'features/admin/store/slice';
import { ConsultStates, ConsultTypeEnum } from 'features/consults/utils/enums';
import useBack from 'navigation/hooks/useBack';
import BackButton from 'navigation/components/BackButton/BackButton';

import api from 'api';
import useRequest from 'shared/hooks/useRequest';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import {
  FlexBoxColumn,
  FlexBoxAlign,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';

import EditConsultModal from './EditConsultModal';
import ConsultIntegration from './ConsultIntegration';

const ConsultPage = () => {
  const { onBack } = useBack();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const { consultId, consultType } = useParams();
  const consult = useSelector(({ admin }) => admin.consult);
  const summaryRef = useRef(null);
  const { doRequest: updateSummary } = useRequest(api.updateConsultSummary);

  const fetchConsult = useCallback(
    () => dispatch(adminActions.fetchConsult(consultId)),
    [consultId, dispatch],
  );

  const handleSave = useCallback(
    (data) => {
      dispatch(
        adminActions.updateAdminConsult({
          consultId,
          data: {
            ...data,
            facilityId: {
              tab: data.facilityId.tab,
              value: Number(data.facilityId.value),
            },
          },
        }),
      );
      updateSummary({
        consultId: Number(consultId),
        summary: summaryRef.current?.lastChild.innerText,
      });
    },
    [consultId, dispatch, updateSummary],
  );

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.UPDATED_ADMIN_CONSULT,
    () => {
      fetchConsult();
      setEditing(false);
    },
  );

  useEffect(fetchConsult, [fetchConsult]);

  const isClosed = useMemo(
    () => consult?.signature?.state === ConsultStates.CLOSED,
    [consult],
  );
  const isEditable = useMemo(
    () =>
      isClosed &&
      [ConsultTypeEnum.NEURO, ConsultTypeEnum.EEG].includes(consultType),
    [consultType, isClosed],
  );

  const renderModal = useMemo(() => {
    if (!editing) return null;
    return (
      <EditConsultModal
        consult={consult}
        consultType={consultType}
        handleSave={handleSave}
        handleClose={() => setEditing(false)}
      />
    );
  }, [consult, consultType, editing, handleSave]);

  return (
    <FlexBoxColumn height="100%">
      {renderModal}
      <FlexBoxSpaceBetweenCenter>
        <FlexBoxAlign>
          <BackButton onBack={onBack} />
          <PageTitle>Consult Details</PageTitle>
        </FlexBoxAlign>
        <FlexBoxAlign>
          <ConsultIntegration
            consult={{ ...consult, id: Number(consultId) }}
            consultMainType={consultType}
            isClosed={isClosed}
            summaryText={summaryRef.current?.lastChild.innerText}
          />
          {isClosed && <LockIcon />}
          {isEditable && (
            <IconButton color="secondary" onClick={() => setEditing(true)}>
              <EditIcon />
            </IconButton>
          )}
        </FlexBoxAlign>
      </FlexBoxSpaceBetweenCenter>
      <ConsultSummary
        ref={summaryRef}
        consult={{ ...consult, id: Number(consultId) }}
        consultMainType={consultType}
        isClosed={isClosed}
      />
    </FlexBoxColumn>
  );
};

export default ConsultPage;
