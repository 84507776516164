import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask-3.0';
import { Controller } from 'react-hook-form';
import Link from '@material-ui/core/Link';

import InputField from 'shared/components/forms/InputField/InputField';

import { PHONE_MASK_FORMAT, mapInputValue, mapOutputValue } from './utils';

const PhoneNumberInput = ({
  control,
  name,
  label,
  disabled = false,
  error = null,
  required = false,
  onChange,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue=""
    render={({ onChange: onControllerChange, value }) => {
      const inputValue = mapInputValue(value);

      return (
        <InputMask
          mask={PHONE_MASK_FORMAT}
          disabled={disabled}
          value={inputValue}
          onChange={({ target }) => {
            const newValue = mapOutputValue(target.value);
            onControllerChange(newValue);
            if (onChange) onChange(newValue);
          }}
        >
          <InputField
            {...props}
            name={name}
            required={required}
            label={
              inputValue && label ? (
                <Link href={`tel:${inputValue}`}>{label}</Link>
              ) : (
                label
              )
            }
            error={error}
          />
        </InputMask>
      );
    }}
  />
);

PhoneNumberInput.propTypes = {
  control: PropTypes.shape({
    register: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  }),
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default PhoneNumberInput;
