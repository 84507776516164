import React from 'react';

import QuestionsPanel from 'features/consults/components/tabFields/QuestionsPanel';
import useFormState from 'shared/hooks/useFormState';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS } from 'features/consults/utils/constants';
import schema from './validationSchema';
import { panels } from './data';

const Botox = () => {
  const {
    control,
    register,
    getFieldError,
    getValues,
    setFormValues,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    handleFieldChange,
  } = useConsultData(CONSULT_TABS.BOTOX, getValues);

  useConsultInitialFormState(consult.id, consultTabData, setFormValues);

  return panels.map((section, index) => (
    <QuestionsPanel
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      control={control}
      register={register}
      onChange={handleFieldChange}
      getFieldError={getFieldError}
      disabled={!isEditable}
      {...section}
    />
  ));
};

export default Botox;
