import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';

import BackButton from 'navigation/components/BackButton/BackButton';
import useBack from 'navigation/hooks/useBack';

import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';

import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import EditButton from 'shared/components/buttons/EditButton';

import { adminActions } from 'features/admin/store/slice';
import UserForm from 'features/admin/components/UserForm';
import UserActionButton from 'features/admin/components/UserForm/UserActionButton';
import useEditMode from 'features/admin/hooks/useEditMode';
import { capitalizeFirstLetter } from 'utils/helpers';

const mapDataToForm = ({
  facilities,
  enabled,
  roles,
  showAllConsultRequests,
  viewClosedConsultRequests,
  viewAndEditSettings,
  showFullPatientNames,
  viewAdminConsultTable,
  inactivityLogOutThreshold,
  ...rest
}) => ({
  ...rest,
  enabled: enabled ? '1' : '0',
  autologout: Boolean(inactivityLogOutThreshold),
  inactivityLogOutThreshold,
  showAllConsultRequests: showAllConsultRequests ? '1' : '0',
  viewClosedConsultRequests: viewClosedConsultRequests ? '1' : '0',
  viewAndEditSettings: viewAndEditSettings ? '1' : '0',
  showFullPatientNames: showFullPatientNames ? '1' : '0',
  viewAdminConsultTable: viewAdminConsultTable ? '1' : '0',
  healthSystemId:
    facilities?.reduce(
      (res, f) =>
        res.includes(f.healthSystemId) ? res : [...res, f.healthSystemId],
      [],
    ) || [],
  facilities: facilities?.map(({ facilityId }) => facilityId),
  roles: roles?.map((role) => capitalizeFirstLetter(role)),
});

const UserDetailsPage = () => {
  const { userData, authUser } = useSelector(({ admin, auth }) => ({
    userData: admin.user,
    authUser: auth.user,
  }));
  const dispatch = useDispatch();
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { onBack } = useBack();
  const { userId } = useParams();

  const selfDetails = userData.username === authUser.username;

  usePerformActionOnSuccess(SuccessActionTypesEnum.USER_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    disableEditMode();
  });

  useEffect(() => {
    dispatch(adminActions.getUserById(userId));
  }, [dispatch, userId]);

  const onUserUpdate = (data) => {
    dispatch(
      adminActions.updateUser({
        id: Number(userId),
        ...data,
      }),
    );
  };

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={onBack} />}
          <PageTitle>User Details</PageTitle>
        </FlexBoxAlign>
        <FlexBoxAlign>
          {!selfDetails && userData && userData.enabled && (
            <UserActionButton userData={userData} />
          )}
          {!isEditMode && !selfDetails && (
            <EditButton onClick={enableEditMode} />
          )}
        </FlexBoxAlign>
      </FlexBoxSpaceBetween>
      <FlexBoxColumnFull p={2}>
        <UserForm
          initValues={mapDataToForm(userData)}
          disabled={!isEditMode}
          onSubmit={onUserUpdate}
          onCancel={disableEditMode}
        />
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default UserDetailsPage;
