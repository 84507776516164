export const NETWORK_NAMES = {
  NEURO: 'neuroNetworkName',
  EEG: 'eegNetworkName',
};

export const getFacilityNetworks = (facilities) =>
  new Set(
    facilities.reduce(
      (networksAcc, facility) =>
        networksAcc.concat(
          Object.values(NETWORK_NAMES).reduce((acc, networkName) => {
            if (facility[networkName] && !acc.includes(networkName)) {
              return acc.concat(networkName);
            }

            return acc;
          }, []),
        ),
      [],
    ),
  );
