import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useBack from 'navigation/hooks/useBack';
import EditButton from 'shared/components/buttons/EditButton';
import { adminActions } from 'features/admin/store/slice';
import BackButton from 'navigation/components/BackButton/BackButton';
import FacilityForm from 'features/admin/components/FacilityForm/FacilityForm';
import schema from 'features/admin/components/FacilityForm/validationSchema';
import useEditMode from 'features/admin/hooks/useEditMode';
import FormWrapper from 'shared/components/forms/FormWrapper/FormWrapper';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { mapFacilityToApiFormat, mapFacilityToForm } from 'utils/mappers';
import {
  FlexBoxAlign,
  FlexBoxColumn,
  FlexBoxColumnFull,
  FlexBoxSpaceBetween,
} from 'styles/layout';

const FacilityDetailsPage = () => {
  const { isEditMode, enableEditMode, disableEditMode } = useEditMode();
  const { facility } = useSelector(({ admin }) => admin);
  const { data: networks } = useSelector(({ admin }) => admin.networks);
  const dispatch = useDispatch();
  const { onBack } = useBack();
  const { facilityId } = useParams();
  const {
    register,
    getValues,
    getTrimmedValues,
    canSubmitForm,
    getFieldError,
    control,
    setFormValues,
    handleSubmit,
  } = useFormState(schema, { stateSlice: 'admin', showAsSnackbar: true });

  useEffect(() => {
    dispatch(adminActions.listNetworks({ pageSize: MAX_PAGE_SIZE }));
  }, [dispatch]);

  useEffect(() => {
    if (!facility.id) {
      dispatch(adminActions.getFacilityById(facilityId));
    }
  }, [dispatch, facilityId, facility.id]);

  useEffect(() => {
    if (facility.id && Object.keys(networks).length) {
      const facilityForm = mapFacilityToForm(facility, networks);
      setFormValues(facilityForm);
    }
  }, [facility, setFormValues, networks]);

  usePerformActionOnSuccess(SuccessActionTypesEnum.FACILITY_UPDATED, () => {
    dispatch(appStateActions.hideSnackbar());
    disableEditMode();
    setFormValues(getValues());
  });

  const matchedFacility = facility && Number(facilityId) === facility.id;

  const editFacility = () => {
    const facilityForm = getTrimmedValues();
    const data = mapFacilityToApiFormat(facilityForm);
    dispatch(
      adminActions.updateFacility({ ...data.facility, id: facility.id }),
    );
  };

  const handleCancel = () => {
    setFormValues();
    disableEditMode();
  };

  return (
    <FlexBoxColumn height="100%">
      <FlexBoxSpaceBetween>
        <FlexBoxAlign>
          {!isEditMode && <BackButton onBack={onBack} />}
          <PageTitle>{matchedFacility && facility.name}</PageTitle>
        </FlexBoxAlign>
        {!isEditMode && (
          <FlexBoxAlign>
            <EditButton onClick={enableEditMode} />
          </FlexBoxAlign>
        )}
      </FlexBoxSpaceBetween>
      <FlexBoxColumnFull p={2}>
        {matchedFacility && (
          <FormWrapper
            onSubmit={handleSubmit(editFacility)}
            isSubmitDisabled={!canSubmitForm}
            formDisabled={!isEditMode}
            onCancel={handleCancel}
          >
            <FacilityForm
              networks={networks}
              control={control}
              register={register}
              getFieldError={getFieldError}
              disabled={!isEditMode}
            />
          </FormWrapper>
        )}
      </FlexBoxColumnFull>
    </FlexBoxColumn>
  );
};

export default FacilityDetailsPage;
