import React from 'react';
import { Switch, Route } from 'react-router-dom';

import AdminPaths from 'features/admin/paths';
import HealthSystemsListPage from './HealthSystems/HealthSystemsListPage';
import AddHealthSystemPage from './HealthSystems/AddHealthSystemPage';
import HealthSystemDetailsPage from './HealthSystems/HealthSystemDetailsPage';
import AddFacilityPage from './Facilities/AddFacilityPage';
import FacilityDetailsPage from './Facilities/FacilityDetailsPage';

const HealthSystems = () => (
  <Switch>
    <Route
      exact
      path={AdminPaths.HealthSystems}
      component={HealthSystemsListPage}
    />
    <Route
      exact
      path={AdminPaths.AddHealthSystem}
      component={AddHealthSystemPage}
    />
    <Route
      exact
      path={`${AdminPaths.HealthSystems}/:locationId`}
      component={HealthSystemDetailsPage}
    />
    <Route
      exact
      path={`${AdminPaths.HealthSystems}/:locationId/add-facility`}
      component={AddFacilityPage}
    />
    <Route
      exact
      path={`${AdminPaths.HealthSystems}/:locationId/facility/:facilityId`}
      component={FacilityDetailsPage}
    />
  </Switch>
);

export default HealthSystems;
