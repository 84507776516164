import { AuthRoleEnum } from 'auth/utils/enums';
import { ConsultTypeEnum } from 'features/consults/utils/enums';

export const ConsultRequestStatusEnum = {
  OPEN: 'Open',
  ACCEPTED: 'Accepted',
  CLOSED: 'Closed',
};

export const AllowedProviderRolesEnum = {
  [ConsultTypeEnum.NEURO]: AuthRoleEnum.NEURO,
  [ConsultTypeEnum.EEG]: AuthRoleEnum.EEG,
};

export const ConsultRequestPagingStatusEnum = {
  InitialPageSent: 'Initial Page Sent',
  SecondPageSent: 'Second Page Sent',
  ThirdPageSent: 'Third Page Sent',
  BackupPageSent: 'Backup Page Sent to PAOC',
  CallPaoc: '15 Minutes Elapsed, Call PAOC',
};
