import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  StylesProvider,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import store from 'store';
import { GlobalStyles } from 'styles/global';
import theme from 'styles/theme';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <GlobalStyles />
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </MaterialThemeProvider>
      </StylesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
