import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import styled from 'styled-components';

import Button from 'shared/components/buttons/Button/Button';
import ToggleButtonGroup from 'shared/components/forms/ToggleButtonGroup/ToggleButtonGroup';
import InputField from 'shared/components/forms/InputField/InputField';
import useFormState from 'shared/hooks/useFormState';
import DateTimeInput from 'features/consults/components/tabFields/DateTimeInput';
import useConsultData from 'features/consults/hooks/useConsultData';
import useConsultInitialFormState from 'features/consults/hooks/useConsultInitialFormState';
import { CONSULT_TABS, RESET_OPTION } from 'features/consults/utils/constants';

import { Box } from 'styles/layout';
import schema from './validationSchema';
import { GROUPS, GROUP_OPTIONS, GROUP_SCORE_LABELS } from './data';
import fields from './formFields';

const WrapIcon = styled.span`
  margin-left: -5px;
  margin-right: 5px;
  display: flex;
`;

const SectionHeader = styled.p`
  margin-bottom: 5px;
`;

const getOptionsForField = (groupFields, fieldName) => {
  const field = groupFields.find((item) => item.name === fieldName);

  return field?.options || [];
};

const getFieldDataForGroup = (group) => {
  const options = GROUP_OPTIONS[group];

  return {
    options,
    totalFieldName: `${group}Total`,
    fieldNames: options.map((option) => option.name),
  };
};

const getValueToPositive = (value) => {
  if (value === null) return '';
  return Number(value) ? 'Positive' : 'Negative';
};

const ScoresNeuro = () => {
  const {
    control,
    register,
    getFieldError,
    setValue,
    getValues,
    setFormValues,
    watch,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });
  const {
    consult,
    consultTabData,
    isEditable,
    saveConsultChange,
    handleFieldChange,
  } = useConsultData(CONSULT_TABS.SCORES, getValues);

  const onSetFormValues = (values) => {
    setFormValues({
      ...values,
      vanTotal: getValueToPositive(values.vanTotal),
    });
  };

  useConsultInitialFormState(consult.id, consultTabData, onSetFormValues);

  const calculateTotal = useCallback(
    (value, fieldName, group) => {
      const { options, totalFieldName, fieldNames } =
        getFieldDataForGroup(group);
      const formFields = fieldNames.map((name) => ({
        name,
        value: getValues(name),
      }));

      const total = formFields.reduce((acc, field) => {
        if (field.value && field.value !== RESET_OPTION.value) {
          const fieldOptions = getOptionsForField(options, field.name);
          const selectedOption = fieldOptions.find(
            (item) => item.value === field.value,
          );

          return acc + (selectedOption?.severity || 0);
        }

        return acc;
      }, 0);

      setValue(totalFieldName, total);
      saveConsultChange({
        [fieldName]: value,
        [totalFieldName]: total,
      });
    },
    [getValues, setValue, saveConsultChange],
  );

  const setNihssTo0 = () => {
    const { options, totalFieldName, fieldNames } = getFieldDataForGroup(
      GROUPS.NIHSS,
    );
    const updatedFields = {};

    fieldNames.forEach((name) => {
      const value = getValues(name);

      if (!value || value === RESET_OPTION.value) {
        const fieldOptions = getOptionsForField(options, name);

        if (fieldOptions.length > 0) {
          const newValue = fieldOptions[1].value;

          // Set field value to option corresponding to 0
          setValue(name, newValue);
          updatedFields[name] = newValue;
        }
      }
    });

    if (getValues(totalFieldName) === '') {
      setValue(totalFieldName, 0);
    }

    saveConsultChange({
      ...updatedFields,
      [totalFieldName]: Number(getValues(totalFieldName)),
    });
  };

  const getVanResult = () => {
    const armWeakness = fields.vanArmWeakness.name;
    const vanVisualDisturbance = fields.vanVisualDisturbance.name;
    const vanAphasia = fields.vanAphasia.name;
    const vanNeglect = fields.vanNeglect.name;
    const currentResults = watch([
      armWeakness,
      vanVisualDisturbance,
      vanAphasia,
      vanNeglect,
    ]);
    const armWeaknessValue = currentResults[armWeakness];
    if (!armWeaknessValue || armWeaknessValue === 'x') return null;
    if (armWeaknessValue === 'No') return 0;
    const result = [vanVisualDisturbance, vanAphasia, vanNeglect].reduce(
      (res, key) => {
        const currentValue = currentResults[key];
        if (!currentValue || currentValue === 'x') return res || null;
        if (!res) return currentValue === 'Yes';
        return res;
      },
      null,
    );
    if (result === null) return null;
    return result ? 1 : 0;
  };

  const calculatePositiveVan = (value, fieldName, group) => {
    const { totalFieldName } = getFieldDataForGroup(group);
    const result = getVanResult();
    setValue(totalFieldName, getValueToPositive(result));
    saveConsultChange({
      [fieldName]: value,
      [totalFieldName]: result,
    });
  };

  const renderOptionsButtonGroup = (group, updateFunc) => {
    const { options, totalFieldName } = getFieldDataForGroup(group);

    return (
      <>
        {options.map((field, index) => (
          <Grid key={field.name} item xs={12}>
            <Box
              mb={
                [GROUPS.ICH, GROUPS.VAN].includes(group) &&
                index < options.length - 1
                  ? 0
                  : 1
              }
            >
              <ToggleButtonGroup
                control={control}
                name={field.name}
                title={field.title}
                buttons={field.options}
                inlineLabel={group === GROUPS.VAN}
                compact
                handleChange={(value) => updateFunc(value, field.name, group)}
                disabled={!isEditable}
              />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box mb={3}>
            <InputField
              inputRef={register}
              type="text"
              name={totalFieldName}
              label={`${GROUP_SCORE_LABELS[group]}`}
              inlineLabel
              error={getFieldError(totalFieldName)}
              disabled
            />
          </Box>
        </Grid>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item sm={12} lg={7}>
        <Grid item xs={12}>
          <Box mb={3}>
            <DateTimeInput
              control={control}
              name={fields.nihssExamTimeAt.name}
              label={fields.nihssExamTimeAt.label}
              onChange={handleFieldChange}
              disabled={!isEditable}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mb={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={setNihssTo0}
              disabled={!isEditable}
            >
              <WrapIcon>
                <Star fontSize="small" />
              </WrapIcon>
              Set NIHSS to 0
            </Button>
          </Box>
        </Grid>
        {renderOptionsButtonGroup(GROUPS.NIHSS, calculateTotal)}
      </Grid>
      <Grid item sm={12} lg={5}>
        {renderOptionsButtonGroup(GROUPS.MRS, calculateTotal)}
        <SectionHeader>VAN Screening</SectionHeader>
        {renderOptionsButtonGroup(GROUPS.VAN, calculatePositiveVan)}
        {renderOptionsButtonGroup(GROUPS.ICH, calculateTotal)}
        {renderOptionsButtonGroup(GROUPS.HUNT, calculateTotal)}
      </Grid>
    </Grid>
  );
};

export default ScoresNeuro;
