import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';

import { AppBar, Toolbar, Hidden, useMediaQuery } from '@material-ui/core';
import { Menu, ArrowBack } from '@material-ui/icons';

import useRoles from 'auth/hooks/useRoles';
import useRoleBasedRouting from 'auth/hooks/useRoleBasedRouting';
import { authActions } from 'auth/store/slice';
import { consultsActions } from 'features/consults/store/slice';
import {
  FlexBoxCenter,
  FlexBoxAlign,
  FlexBoxSpaceBetween,
} from 'styles/layout';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import Paths from 'navigation/paths';
import useBack from 'navigation/hooks/useBack';
import bsnLogo from 'navigation/assets/home-logo.svg';
import DrawerMenu from 'navigation/components/DrawerMenu/DrawerMenu';
import ProfileButton from 'navigation/components/ProfileButton';
import MenuList from 'navigation/components/MenuList/MenuList';
import {
  HomeLink,
  HomeLogo,
  LogoutBtn,
  SmallScreenToolbar,
} from './NavBar.style';

const navItems = (notificationCount) => [
  {
    name: 'Neuro',
    path: Paths.Neuro,
    notifications: notificationCount.neuroCounter,
  },
  {
    name: 'Clinic',
    path: Paths.Clinic,
    notifications: notificationCount.clinicCounter,
  },
  { name: 'EEG', path: Paths.Eeg, notifications: notificationCount.eegCounter },
  { name: 'Macros', path: Paths.Macros },
  { name: 'Admin', path: Paths.Admin },
  { name: 'My Facility', path: Paths.MyFacility },
  // { name: 'Messages', path: Paths.Messages }, Hide page temporarily
  { name: 'Schedule', path: Paths.Schedule },
];

const consultPaths = [`${Paths.Neuro}/`, `${Paths.Clinic}/`, `${Paths.Eeg}/`];

const NavBar = () => {
  const dispatch = useDispatch();
  const idleRef = useRef(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const lg = useMediaQuery('(min-width:1050px)');
  const { smDown } = useBreakpoints();
  const { onBack } = useBack();
  const { pathname } = useLocation();
  const { isDoctor, isCustomerOnly } = useRoles();
  const { homePage, permittedRoutes } = useRoleBasedRouting();
  const { consultCount, currentConsult } = useSelector(
    (state) => state.consults,
  );
  const {
    data: { viewAndEditSettings, inactivityLogOutThreshold },
  } = useSelector((state) => state.profile);

  const consultState = currentConsult?.signature?.state;
  const consultAssignee = currentConsult?.signature?.assignToId;

  useEffect(() => {
    if (isDoctor) {
      dispatch(consultsActions.getConsultCount());
    }
  }, [dispatch, isDoctor, currentConsult.id, consultState, consultAssignee]);

  const navLinks = useMemo(
    () =>
      navItems(consultCount).filter((item) =>
        permittedRoutes.includes(item.path),
      ),
    [permittedRoutes, consultCount],
  );

  const handleLogout = useCallback(
    () => dispatch(authActions.logoutUser()),
    [dispatch],
  );

  const renderMenuButton = () => {
    const showBackButton =
      smDown && consultPaths.some((path) => pathname.includes(path));

    return (
      <IconButton
        color="primary"
        onClick={() => (showBackButton ? onBack() : setDrawerOpen(true))}
      >
        {showBackButton ? <ArrowBack /> : <Menu />}
      </IconButton>
    );
  };

  return (
    <>
      {inactivityLogOutThreshold && (
        <IdleTimer
          ref={idleRef}
          timeout={inactivityLogOutThreshold * 60000}
          onIdle={handleLogout}
          debounce={250}
        />
      )}
      <Hidden mdUp>
        <AppBar position="static" color="inherit" elevation={3}>
          <SmallScreenToolbar variant="dense">
            {renderMenuButton()}
          </SmallScreenToolbar>
        </AppBar>
        <DrawerMenu
          isOpen={isDrawerOpen}
          menuItems={navLinks}
          onClose={() => setDrawerOpen(false)}
          onLogout={handleLogout}
          showProfile={!isCustomerOnly || viewAndEditSettings}
        />
      </Hidden>
      <Hidden smDown>
        <AppBar position="static" color="inherit" elevation={3}>
          <Toolbar>
            <FlexBoxSpaceBetween width="100%">
              <FlexBoxAlign>
                <FlexBoxCenter width={lg ? 210 : 'auto'}>
                  <HomeLink to={homePage}>
                    <HomeLogo src={bsnLogo} alt="Blue Sky Link Logo" />
                  </HomeLink>
                </FlexBoxCenter>
                <MenuList items={navLinks} />
              </FlexBoxAlign>
              <FlexBoxAlign>
                {(!isCustomerOnly || viewAndEditSettings) && (
                  <FlexBoxCenter>
                    <ProfileButton />
                  </FlexBoxCenter>
                )}
                <LogoutBtn onClick={handleLogout}>Logout</LogoutBtn>
              </FlexBoxAlign>
            </FlexBoxSpaceBetween>
          </Toolbar>
        </AppBar>
      </Hidden>
    </>
  );
};

export default NavBar;
