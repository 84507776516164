import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import api from 'api';

import Drawer from 'shared/components/layout/Drawer/Drawer';

import { FlexBoxSpaceBetweenCenter } from 'styles/layout';

import ConsultMenuToggle, { tabsEnum } from './ConsultMenuToggle';

import ConsultInfo from './ConsultInfo';
import ConsultIntegration from './ConsultIntegration';

import {
  NotesWrapper,
  NotesContent,
  SmToggleWrapper,
  ToggleIconWrapper,
  ExpandedNotesWrapper,
  CloseButton,
} from './ConsultMenu.style';

const ConsultMenu = ({
  isOpen = false,
  sm = false,
  onToggle,
  consult,
  consultType,
}) => {
  const [tab, setTab] = useState(null);
  const facilityId = consult.demographics?.facilityId;
  const [consultFacility, setConsultFacility] = useState(null);

  const onTabClick = useCallback(
    (type) => {
      setTab(type);
      if (!isOpen) onToggle();
    },
    [isOpen, onToggle],
  );

  const onClose = useCallback(() => {
    setTab(null);
    onToggle();
  }, [onToggle]);

  useEffect(() => {
    async function fetchFacility() {
      try {
        const {
          data: { facility },
        } = await api.getFacilityById(facilityId);
        setConsultFacility(facility);
      } catch {
        setConsultFacility(null);
      }
    }
    if (facilityId) fetchFacility();
    else setConsultFacility(null);
  }, [facilityId]);

  const isIntegrated = useMemo(
    () => consultFacility?.isIntegrated,
    [consultFacility],
  );

  const isConsultOpen = useMemo(
    () => consult.signature?.state === 'Open',
    [consult.signature],
  );

  useEffect(() => {
    if (!(isIntegrated && isConsultOpen) && isOpen) setTab(tabsEnum.INFO);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntegrated, isConsultOpen]);

  const renderTabButtons = useMemo(
    () => (
      <>
        <ConsultMenuToggle
          onTabClick={() => onTabClick(tabsEnum.INFO)}
          type={tabsEnum.INFO}
          disabled={tabsEnum.INFO === tab}
        />
        {isIntegrated && isConsultOpen && (
          <ConsultMenuToggle
            onTabClick={() => onTabClick(tabsEnum.INTEGRATION)}
            type={tabsEnum.INTEGRATION}
            disabled={tabsEnum.INTEGRATION === tab}
          />
        )}
      </>
    ),
    [tab, onTabClick, isIntegrated, isConsultOpen],
  );

  const renderTab = useMemo(() => {
    switch (tab) {
      case tabsEnum.INFO:
        return (
          <ConsultInfo
            consult={consult}
            consultType={consultType}
            consultFacility={consultFacility}
          />
        );
      case tabsEnum.INTEGRATION:
        return (
          <ConsultIntegration consult={consult} consultType={consultType} />
        );
      default:
        return null;
    }
  }, [tab, consult, consultType, consultFacility]);

  const renderNotesMenu = () => {
    const menu = (
      <NotesContent $isOpen={isOpen}>
        <FlexBoxSpaceBetweenCenter>
          {isOpen && <CloseButton onClick={onClose} />}
          <ToggleIconWrapper $isOpen={isOpen}>
            {renderTabButtons}
          </ToggleIconWrapper>
        </FlexBoxSpaceBetweenCenter>
        {isOpen && <ExpandedNotesWrapper>{renderTab}</ExpandedNotesWrapper>}
      </NotesContent>
    );

    if (sm) {
      return (
        <Drawer anchor="right" isOpen={isOpen} onClose={onToggle}>
          {menu}
        </Drawer>
      );
    }

    return <NotesWrapper elevation={2}>{menu}</NotesWrapper>;
  };

  return (
    <>
      {sm && <SmToggleWrapper>{renderTabButtons}</SmToggleWrapper>}
      {renderNotesMenu()}
    </>
  );
};

ConsultMenu.propTypes = {
  isOpen: PropTypes.bool,
  sm: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  consult: PropTypes.shape({
    id: PropTypes.number,
    demographics: PropTypes.shape({
      facilityId: PropTypes.number,
    }),
    signature: PropTypes.shape({
      state: PropTypes.string,
    }),
  }).isRequired,
  consultType: PropTypes.string.isRequired,
};

export default ConsultMenu;
