import { NeuroConsultTypes } from 'features/consults/utils/constants';
import {
  ConsultFieldTypeEnum,
  FilterTypePropNames,
} from 'features/consults/utils/enums';

export const eegType = {
  ROUTINE: 'Routine',
  PROLONGED: 'Prolonged',
  CEEG: 'CEEG',
  AEEG: 'AEEG',
  CERIBELL: 'Ceribell',
};

export const eEGTypeOptions = [
  { value: eegType.ROUTINE, label: 'Routine EEG' },
  { value: eegType.PROLONGED, label: 'Prolonged EEG' },
  { value: eegType.CEEG, label: 'Continuous EEG' },
  { value: eegType.AEEG, label: 'Ambulatory EEG' },
  { value: eegType.CERIBELL, label: 'Ceribell' },
];

export const patientLocationOptions = [
  { value: 'EmergencyRoom', label: 'Emergency Room' },
  { value: 'Inpatient', label: 'Inpatient' },
  { value: 'Outpatient', label: 'Outpatient', [FilterTypePropNames.EEG]: true },
];

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'NonBinary', label: 'Non-binary' },
];

export const observationOptions = [
  { value: 'HookUp', label: 'hook-up' },
  { value: 'ObservationDay', label: 'subsequent day' },
  { value: 'DisconnectedEndClip', label: 'disconnected/end clip' },
];

export const videoOptions = [
  { value: 'Video', label: 'video' },
  { value: 'WithoutVideo', label: 'without video' },
];

const demographicsFields = {
  consultType: {
    name: 'consultType',
    title: 'Consult Type',
    type: ConsultFieldTypeEnum.TOGGLE,
    required: true,
    options: NeuroConsultTypes,
  },
  observationType: {
    name: 'observationType',
    associatedField: 'consultType',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: observationOptions,
  },
  videoType: {
    name: 'videoType',
    associatedField: 'consultType',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: videoOptions,
  },
  neuroPatientLocation: {
    name: 'neuroPatientLocation',
    title: 'Patient Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    required: true,
    options: patientLocationOptions,
  },
  eegPatientLocation: {
    name: 'eegPatientLocation',
    title: 'Patient Location',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: patientLocationOptions,
  },
  eegStatOptions: {
    name: 'eegStatOptions',
    associatedField: 'eegPatientLocation',
    type: ConsultFieldTypeEnum.CHIP,
    options: [{ value: 'STATReadRequested', label: 'STAT read requested' }],
  },
  firstName: {
    name: 'firstName',
    label: 'First Name',
    type: ConsultFieldTypeEnum.INPUT,
    required: true,
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name',
    type: ConsultFieldTypeEnum.INPUT,
    required: true,
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    label: 'Date of Birth',
    type: ConsultFieldTypeEnum.DATE,
  },
  age: {
    name: 'age',
    label: 'Age',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
    inputType: 'number',
  },
  gender: {
    name: 'gender',
    title: 'Gender',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: genderOptions,
  },
  dateOfVisit: {
    name: 'dateOfVisit',
    label: 'Date of Visit',
    summaryLabel: 'Date of Clinic Visit',
    type: ConsultFieldTypeEnum.DATE,
    inlineLabel: true,
  },
  prehospitalBeamIn: {
    name: 'prehospitalBeamIn',
    label: 'Prehospital/Beam In',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'PrehospitalAlert', label: 'prehospital alert' },
      { value: 'DirectBeamIn', label: 'direct beam-in' },
    ],
    inlineLabel: true,
    excludeFromSummary: true,
  },
  facility: {
    name: 'facilityId',
    label: 'Facility',
    type: ConsultFieldTypeEnum.AUTOCOMPLETE,
  },
  referringProvider: {
    name: 'referringProvider',
    label: 'Referring Provider',
    summaryLabel: 'Referring Provider/PCP',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  callBackPhone: {
    name: 'callBackPhone',
    label: 'Call Back Phone',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  referringTechnician: {
    name: 'referringTechnician',
    label: 'Referring Technician',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  techCallbackNumber: {
    name: 'techCallbackNumber',
    label: 'Tech Call Back Phone',
    type: ConsultFieldTypeEnum.PHONE,
    excludeFromSummary: true,
  },
  cameraName: {
    name: 'cameraName',
    label: 'Camera Name',
    type: ConsultFieldTypeEnum.INPUT,
    excludeFromSummary: true,
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
    excludeFromSummary: true,
  },
  initialPageAt: {
    name: 'initialPageAt',
    label: 'Time of Initial Page',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  returnCallAt: {
    name: 'returnCallAt',
    label: 'Time of Return Call',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  phoneOnly: {
    name: 'phoneOnly',
    label: 'Phone Only',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'PhoneOnly',
        label: 'phone only',
      },
    ],
    excludeFromSummary: true,
  },
  phoneOnlyFreetext: {
    name: 'phoneOnlyFreetext',
    label: 'Phone Only Freetext',
    summaryLabel: 'Phone Only Consult',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
  },
};

export const clinicDemographicsFields = {
  ...demographicsFields,
  referringProvider: {
    ...demographicsFields.referringProvider,
    excludeFromSummary: false,
  },
};

export const eegDemographicsFields = {
  ...demographicsFields,
  consultType: {
    ...demographicsFields.consultType,
    title: 'Type of EEG Read',
    required: true,
    options: eEGTypeOptions,
  },
};

export default demographicsFields;
