import React from 'react';
import { InputAdornment } from '@material-ui/core';
import {
  ConsultFieldTypeEnum,
  FilterTypePropNames,
} from 'features/consults/utils/enums';

export const thrombolyticFieldName = 'thrombolyticInterventionPredefined';
export const thrombolyticValues = {
  IVThrombolytic: 'IVThrombolysis',
  IAIntervention: 'IAIntervention',
  IVAndIAIntervention: 'IVAndIAIntervention',
  NOTIVThrombolyticOrIAIntervention: 'NOTIVThrombolysisOrIAIntervention',
  PossibleIAcandidate: 'PossibleIAcandidate',
  IVThrombolyticPossibleIACandidate: 'IVThrombolyticPossibleIACandidate',
};
export const discussedWReferringProviderValue = 'DiscussedWReferringProvider';

export const thrombolyticOptions = [
  {
    value: thrombolyticValues.IVThrombolytic,
    label: 'IV thrombolytic',
    summaryLabel: 'IV Thrombolysis',
  },
  { value: thrombolyticValues.IAIntervention, label: 'IA Intervention' },
  {
    value: thrombolyticValues.IVAndIAIntervention,
    label: 'IV and IA Intervention',
    summaryLabel: 'IV Thrombolysis and IA Intervention Therapy',
  },
  {
    value: thrombolyticValues.NOTIVThrombolyticOrIAIntervention,
    label: 'NOT IV Thrombolytic or IA Intervention',
    summaryLabel: 'NOT IV Thrombolysis or IA Intervention candidate',
  },
  {
    value: thrombolyticValues.PossibleIAcandidate,
    label: 'Possible IA candidate',
  },
  {
    value: thrombolyticValues.IVThrombolyticPossibleIACandidate,
    label: 'IV thrombolytic and possible IA candidate',
  },
];

const imagingOptions = [
  {
    value: 'CTHeadWithout',
    label: 'CT Head without',
    summaryLabel: 'CT Head without contrast',
  },
  {
    value: 'CTAHeadPlusNeck',
    label: 'CTA Head + Neck',
    summaryLabel: 'CT Angiogram Head and CT Angiogram Neck',
  },
  {
    value: 'CTAHeadPlusNeckPlusCallback',
    label: 'CTA Head + Neck + callback',
    summaryLabel:
      'CT Angiogram Head and CT Angiogram Neck AND call back with results if abnormal',
  },
  {
    value: 'CTPCallback',
    label: 'CTP + callback',
    summaryLabel: 'CT Perfusion AND callback with results if abnormal',
    [FilterTypePropNames.NEURO]: true,
  },
  { value: 'CTAHead', label: 'CTA Head', summaryLabel: 'CT Angiogram Head' },
  { value: 'CTANeck', label: 'CTA Neck', summaryLabel: 'CT Angiogram Neck' },
  {
    value: 'MRAHeadWithout',
    label: 'MRA Head without',
    summaryLabel: 'MR Angiogram Head without contrast',
  },
  {
    value: 'MRANeckWith',
    label: 'MRA Neck with',
    summaryLabel: 'MR Angiogram Neck with contrast',
  },
  {
    value: 'MRIBrainWithout',
    label: 'MRI Brain without',
    summaryLabel: 'MRI Brain without contrast',
  },
  {
    value: 'MRIBrainWithPlusWithout',
    label: 'MRI Brain with + without',
    summaryLabel: 'MRI Brain with AND without contrast',
  },
  { value: 'MRICSpine', label: 'MRI C spine' },
  { value: 'MRITSpine', label: 'MRI T spine' },
  { value: 'MRILSpine', label: 'MRI L spine' },
  { value: 'USCarotid', label: 'US carotid' },
];

const planFields = {
  [thrombolyticFieldName]: {
    name: thrombolyticFieldName,
    title: 'Thrombolytic/Intervention',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: thrombolyticOptions,
  },
  dose: {
    name: 'dose',
    title: 'Thrombolytic Dosing',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      {
        value: 'IVAlteplaseZeroPointNineMgKg',
        label:
          'IV alteplase 0.9 mg/kg, max dose 90 mg; 10% of dose given over 1 minute IVP, remaining 90% given as infusion over 1 hour',
      },
      {
        value: 'IVTenecteplaseZeroPointTwentyFiveMgKg',
        label:
          'IV tenecteplase 0.25 mg/kg, max dose 25 mg; single bolus IVP over 5 seconds',
      },
    ],
  },
  thrombolyticExclusionLessThanThreeHourWindowPredefined: {
    name: 'thrombolyticExclusionLessThanThreeHourWindowPredefined',
    label: 'Thrombolytic Exclusion (< 3hr window)',
    summaryLabel: 'Thrombolytic Exclusion (< 3 hour window)',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'NOAC', label: 'NOAC', summaryLabel: 'actively on NOAC' },
      {
        value: 'WarfarinINRAboveOnePointSeven',
        label: 'Warfarin/ INR > 1.7',
        summaryLabel: 'actively on Coumadin/warfarin with INR > 1.7',
      },
      {
        value: 'TimeUnclear',
        label: 'time unclear',
        summaryLabel: 'time of onset unclear',
      },
      {
        value: 'OnAnticoagulation',
        label: 'on anticoagulation',
      },
      { value: 'FamilyPatientRefusal', label: 'family/ patient refusal' },
      { value: 'ICH', label: 'ICH' },
      { value: 'HxICH', label: 'hx ICH', summaryLabel: 'history of ICH' },
      { value: 'NIHSSEqualZero', label: 'NIHSS = 0' },
      {
        value: 'NonDisabling',
        label: 'non-disabling',
        summaryLabel: 'non-disabling deficit',
      },
      {
        value: 'NonDisablingDeficit',
        label: 'non-disabling deficit--discussed',
        summaryLabel:
          'Individualized disability discussion had with the patient and/or family, and they have determined the current deficits to be non-disabling and do not wish to proceed with thrombolytic',
      },
      {
        value: 'RecdThrombolyticPrehospital',
        label: "rec'd thrombolytic prehospital",
        summaryLabel: 'received thrombolytic prehospital (see time below)',
      },
      {
        value: 'StrokeWiThreeMo',
        label: 'stroke w/i 3 mo',
        summaryLabel: 'stroke within 3 months',
      },
      {
        value: 'MajorSurgBelowFourteenD',
        label: 'major surg < 14 d',
        summaryLabel: 'major surgery < 14 days',
      },
      { value: 'Other', label: 'other', summaryLabel: 'other (see below)' },
    ],
  },
  thrombolyticExclusionThreeToFourPointFiveHourWindowPredefined: {
    name: 'thrombolyticExclusionThreeToFourPointFiveHourWindowPredefined',
    label: 'Thrombolytic Exclusion (3-4.5hr window)',
    summaryLabel: 'Thrombolytic Exclusion (3-4.5 hour window)',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'PriorStrokeAndDM', label: 'prior stroke and DM' },
      { value: 'AgeAbove80', label: 'age > 80' },
      { value: 'OnAnticoagulation', label: 'on anticoagulation' },
      { value: 'ICH', label: 'ICH' },
      { value: 'HxOfICH', label: 'hx of ICH', summaryLabel: 'history of ICH' },
      { value: 'Refusal', label: 'refusal' },
      { value: 'Other', label: 'other', summaryLabel: 'other (see below)' },
    ],
  },
  thrombolyticExclusionPredefined: {
    name: 'thrombolyticExclusionPredefined',
    title: 'Thrombolytic Exclusion',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'AboveThreehours', label: '> 3 hours' },
      { value: 'AboveFourPointFiveHours', label: '> 4.5 hours' },
      { value: 'OtherSeeBelow', label: 'other (see below)' },
    ],
  },
  thrombolyticExclusionFreeText: {
    name: 'thrombolyticExclusionFreeText',
    associatedField: 'thrombolyticExclusionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  possibleIACandidatePredefined: {
    name: 'possibleIACandidatePredefined',
    label: 'Possible IA Candidate',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'SignsAndSymptomsOfLVO', label: 'signs and symptoms of LVO' },
      {
        value: 'NoSignsAndSymptomsOfLVO',
        label: 'no signs and symptoms of LVO',
      },
      { value: 'CtaPending', label: 'CTA pending' },
      { value: 'CtpPending', label: 'CTP pending' },
    ],
  },
  possibleIACandidateFreeText: {
    name: 'possibleIACandidateFreeText',
    associatedField: 'possibleIACandidatePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  iaExclusionPredefined: {
    name: 'iaExclusionPredefined',
    label: 'IA Exclusion',
    summaryLabel: 'Intraarterial Exclusion',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'ClotTooDistal', label: 'clot too distal' },
      {
        value: 'ClinicallyCWSmVesselDz',
        label: 'clinically c/w sm vessel dz',
        summaryLabel: 'clinically consistent with small vessel disease',
      },
      {
        value: 'ClinicallyNotCWStroke',
        label: 'clinically not c/w stroke',
        summaryLabel: 'clinically not consistent with stroke',
      },
      { value: 'ICH', label: 'ICH' },
      {
        value: 'NoLVO',
        label: 'no LVO',
        summaryLabel: 'no large vessel occlusion (LVO)',
      },
      { value: 'NonDisabling', label: 'non-disabling' },
      { value: 'PoorFunctionalBaseline', label: 'poor functional baseline' },
      {
        value: 'RapidImprovePIVAlteplase',
        label: 'rapid improve p IV thrombolytic',
        summaryLabel: 'rapid improvement after IV thrombolytic',
      },
      { value: 'Refusal', label: 'refusal' },
      {
        value: 'UnfavorableImagingHypodensity',
        label: 'unfavorable imaging/hypodensity',
      },
      { value: 'OtherSeeBelow', label: 'other' },
    ],
  },
  iaExclusionFreeText: {
    name: 'iaExclusionFreeText',
    associatedField: 'iaExclusionPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  thrombolyticIntraarterialExclusionPredefined: {
    name: 'thrombolyticIntraarterialExclusionPredefined',
    label: 'Thrombolytic/Intraarterial Exclusion',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'NotStroke',
        label: 'not stroke',
        summaryLabel:
          "IV thrombolytic and IA intervention considered but not recommended as this patient's symptoms are not clinically consistent with an assumed diagnosis of stroke",
      },
    ],
  },
  timeIVThrombolyticRecommendedMountainTime: {
    name: 'timeIVThrombolyticRecommendedMountainTime',
    label: 'Time IV Thrombolytic Recommended',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  timeThrombolyticBolusMountainTime: {
    name: 'timeThrombolyticBolusMountainTime',
    label: 'Time Thrombolytic Bolus',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  timeThrombolyticInfusionMountainTime: {
    name: 'timeThrombolyticInfusionMountainTime',
    label: 'Time Thrombolytic Infusion',
    type: ConsultFieldTypeEnum.DATETIME,
  },
  recommendations: {
    name: 'recommendations',
    label: 'Recommendations',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
    rows: 5,
  },
  bloodPressureManagementPredefined: {
    name: 'bloodPressureManagementPredefined',
    label: 'Blood Pressure Management',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'Nicardipine', label: 'nicardipine' },
      { value: 'Labetolol', label: 'labetolol' },
      { value: 'IVFluidBolus', label: 'IV fluid bolus' },
    ],
  },
  bloodPressureManagementFreeText: {
    name: 'bloodPressureManagementFreeText',
    associatedField: 'bloodPressureManagementPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  targetBloodPressureBelowPredefined: {
    name: 'targetBloodPressureBelowPredefined',
    title: 'Target Blood Pressure',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'SBPBelow220', label: 'SBP < 220' },
      { value: 'SBPBelow180', label: 'SBP < 180' },
      { value: 'SBPBelow160', label: 'SBP < 160' },
      { value: 'SBPBelow140', label: 'SBP < 140' },
    ],
  },
  targetBloodPressureAbovePredefined: {
    name: 'targetBloodPressureAbovePredefined',
    associatedField: 'targetBloodPressureBelowPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'SBPAbove140', label: 'SBP > 140' },
      { value: 'SBPAbove120', label: 'SBP > 120' },
      { value: 'SBPAbove110', label: 'SBP > 110' },
      { value: 'SBPAbove100', label: 'SBP > 100' },
    ],
  },
  targetBloodPressureDiastolicPredefined: {
    name: 'targetBloodPressureDiastolicPredefined',
    associatedField: 'targetBloodPressureBelowPredefined',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'DBPBelow120', label: 'DBP < 120' },
      { value: 'DBPBelow105', label: 'DBP < 105' },
    ],
  },
  targetBloodPressureFreeText: {
    name: 'targetBloodPressureFreeText',
    associatedField: 'targetBloodPressureBelowPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  labsPredefined: {
    name: 'labsPredefined',
    label: 'Labs',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'ABG', label: 'ABG' },
      { value: 'Ammonia', label: 'Ammonia' },
      { value: 'B12', label: 'B12' },
      {
        value: 'Basic',
        label: 'basic',
        summaryLabel: 'basic metabolic panel',
      },
      { value: 'CBC', label: 'CBC' },
      {
        value: 'Comprehensive',
        label: 'comprehensive',
        summaryLabel: 'comprehensive metabolic panel',
      },
      { value: 'ESR', label: 'ESR' },
      { value: 'HgbA1c', label: 'HgbA1c', summaryLabel: 'hemoglobin A1c' },
      { value: 'LFT', label: 'LFT', summaryLabel: 'liver function tests' },
      { value: 'Lipid', label: 'lipid', summaryLabel: 'lipid panel' },
      { value: 'Thiamine', label: 'thiamine' },
      { value: 'Troponin', label: 'troponin' },
      { value: 'TSH', label: 'TSH' },
      { value: 'UrineDrugScreen', label: 'urine drug screen' },
      { value: 'Ua', label: 'ua' },
    ],
  },
  labsFreeText: {
    name: 'labsFreeText',
    associatedField: 'labsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  imagingPredefined: {
    name: 'imagingPredefined',
    label: 'Imaging',
    type: ConsultFieldTypeEnum.CHIP,
    options: imagingOptions.filter(
      (option) => !option[FilterTypePropNames.NEURO],
    ),
  },
  imagingFreeText: {
    name: 'imagingFreeText',
    associatedField: 'imagingPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  imagingUrgencySTATPredefined: {
    name: 'imagingUrgencySTATPredefined',
    label: 'Imaging: (urgency: STAT)',
    type: ConsultFieldTypeEnum.CHIP,
    options: imagingOptions,
  },
  imagingUrgencySTATFreeText: {
    name: 'imagingUrgencySTATFreeText',
    associatedField: 'imagingUrgencySTATPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  imagingUrgencyRoutinePredefined: {
    name: 'imagingUrgencyRoutinePredefined',
    label: 'Imaging: (urgency: routine)',
    type: ConsultFieldTypeEnum.CHIP,
    options: imagingOptions,
  },
  imagingUrgencyRoutineFreeText: {
    name: 'imagingUrgencyRoutineFreeText',
    associatedField: 'imagingUrgencyRoutinePredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  diagnosticTestPredefined: {
    name: 'diagnosticTestPredefined',
    label: 'Diagnostic Test',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'EchoWithBubbleStudy', label: 'echo with bubble study' },
      {
        value: 'EchoWithoutBubble',
        label: 'echo w/o bubble',
        summaryLabel: 'echo without bubble study',
      },
      { value: 'EEG', label: 'EEG' },
      {
        value: 'LPCellsProtGluc',
        label: 'LP cells/prot/gluc',
        summaryLabel: 'lumbar puncture: cell count, protein, glucose',
      },
    ],
  },
  diagnosticTestFreeText: {
    name: 'diagnosticTestFreeText',
    associatedField: 'diagnosticTestPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  therapyEvaluationPredefined: {
    name: 'therapyEvaluationPredefined',
    label: 'Therapy/Evaluation',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'NPOUntilSwallowEval',
        label: 'NPO until swallow eval',
        summaryLabel: 'NPO until swallow evaluation',
      },
      { value: 'PTOT', label: 'PT/OT', summaryLabel: 'PT/OT evaluation' },
      {
        value: 'SpeechSwallowConsult',
        label: 'speech/swallow consult',
        summaryLabel: 'speech/swallow consultation',
      },
    ],
  },
  therapyEvaluationFreeText: {
    name: 'therapyEvaluationFreeText',
    associatedField: 'therapyEvaluationPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  medicationsPredefined: {
    name: 'medicationsPredefined',
    label: 'Medications',
    summaryLabel: 'Medication',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'Asa81Daily',
        label: 'asa 81 daily',
        summaryLabel: 'aspirin 81 mg daily',
      },
      {
        value: 'Asa325Daily',
        label: 'asa 325 daily',
        summaryLabel: 'aspirin 325 mg daily',
      },
      {
        value: 'Plavix75Daily',
        label: 'plavix 75  daily',
        summaryLabel: 'clopidogrel (Plavix) 75 mg daily',
      },
      {
        value: 'AspirinPlusPlavixX21DThenMono',
        label: 'aspirin + plavix x 21 d, then mono',
        summaryLabel:
          'aspirin 81 mg PLUS clopidogrel (Plavix) 75 mg for 21 days, then monotherapy therafter',
      },
      {
        value: 'HeparinInfusion',
        label: 'heparin infusion',
        summaryLabel: 'anticoagulation with heparin infusion',
      },
      {
        value: 'Lovenox',
        label: 'Lovenox',
        summaryLabel: 'anticoagulation with full dose enoxaparin (Lovenox)',
      },
      {
        value: 'Coumadin',
        label: 'coumadin',
        summaryLabel: 'anticoagulation with coumadin (Warfarin)',
      },
      { value: 'AnticoagulationWithNOAC', label: 'anticoagulation with NOAC' },
      {
        value: 'Statin',
        label: 'statin',
        summaryLabel: 'start statin with goal of LDL < 70',
      },
      {
        value: 'Keppra500BID',
        label: 'keppra 500 BID',
        summaryLabel: 'levetiracetam (Keppra) 500 mg twice daily',
      },
      {
        value: 'Keppra1000BID',
        label: 'keppra 1000 BID',
        summaryLabel: 'levetiracetam (Keppra) 1000 mg twice daily',
      },
      {
        value: 'Depakote500TID',
        label: 'depakote 500 TID',
        summaryLabel: 'depakote 500 mg three times daily',
      },
      { value: 'Depakote20mgkgIVLoad', label: 'depakote 20mg/kg IV load' },
      {
        value: 'Dilantin100TID',
        label: 'dilantin 100 TID',
        summaryLabel: 'phenytoin (Dilantin) 100 mg three times daily',
      },
      {
        value: 'Fosphenytoin20PEkgIV',
        label: 'fosphenytoin 20 PE/kg IV',
        summaryLabel: 'fosphenytoin 20 PE/kg IV load',
      },
      {
        value: 'MigraineCocktailToradolBenadrylAntiemetic',
        label: 'migraine cocktail: toradol/benadryl/antiemetic',
        summaryLabel:
          'migraine cocktail: Toradol 30 mg IV + Benadryl 25 mg IV + antiemetic IV',
      },
      {
        value: 'Solumedrol250mgIVq6hrs',
        label: 'solumedrol 250 mg IV q 6 hrs',
        [FilterTypePropNames.NEURO]: true,
      },
    ],
  },
  medicationsFreeText: {
    name: 'medicationsFreeText',
    associatedField: 'medicationsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  vteProphylaxisPredefined: {
    name: 'vteProphylaxisPredefined',
    label: 'VTE Prophylaxis',
    summaryLabel: 'DVT Prophylaxis',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      { value: 'SCD', label: 'SCD' },
      { value: 'ChemicalDVTProphylaxis', label: 'chemical DVT prophylaxis' },
      {
        value: 'Heparin5000sqq12',
        label: 'heparin 5000 sq q 12',
        summaryLabel: 'heparin 5000 units subcutaneously q 12 hours',
      },
      {
        value: 'Lovenox40sqDaily',
        label: 'lovenox 40 sq daily',
        summaryLabel: 'enoxaprin (Lovenox) 40 mg subcutaneously daily',
      },
      { value: 'Contraindication', label: 'contraindication' },
    ],
  },
  vteProphylaxisFreeText: {
    name: 'vteProphylaxisFreeText',
    associatedField: 'vteProphylaxisPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  thrombolyticAdministrationRecommendationsPredefined: {
    name: 'thrombolyticAdministrationRecommendationsPredefined',
    label: 'Thrombolytic Administration Recommendations',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'RiskBenefitAltDiscussedPatient',
        label: 'risk/benefit/alt discussed patient',
        summaryLabel:
          'I reviewed the risks/benefits/alternatives of IV thrombolytic therapy with the patient. They understand there is potential of life threatening hemorrhage from IV thrombolysis. I stated that I believe benefits outweighs risk. They wish to proceed with IV thrombolytic therapy.',
      },
      {
        value: 'UnconsentableNoFamilyBenefitsAboveRisks',
        label: 'unconsentable, no family, benefits > risks',
        summaryLabel:
          'Unable to obtain informed consent due to medical condition. No family available. In my opinion, benefits of IV thrombolytic therapy outweigh risks.',
      },
      {
        value: 'LKNBestAvailable',
        label: 'LKN best available',
        summaryLabel:
          'I have collected independent history specific to time last normal or last known well. We have collaborated with the ED provider and at this time, we have the most current timeline with the information that is available.',
      },
      {
        value: 'BPGoalBelow180105x24h',
        label: 'BP goal< 180/105 x 24h',
        summaryLabel:
          'BP goal< 180/105 for 24hrs post Thrombolytic administration',
      },
      {
        value: 'LabetololNicardipineForbp',
        label: 'labetolol/nicardipine for bp',
        summaryLabel:
          'Use Labetolol 10-20mg IV prn or Nicardipine gtt to maintain BP parameters',
      },
      {
        value: 'NoAntiplateletsAnticoagx24h',
        label: 'no antiplatelets/anticoag x 24h',
        summaryLabel:
          'No antiplatelets or anticoagulants for next 24 hrs unless indicated for emergent IA procedure or other life threatening situation',
      },
      { value: 'ICUAdmit', label: 'ICU admit', summaryLabel: 'ICU admission' },
      {
        value: 'CallBackIfConditionDeclines',
        label: 'call back if condition declines',
        summaryLabel:
          'Call back if there is any decline in neurological condition',
      },
      {
        value: 'XferToIACapable',
        label: 'x-fer to IA capable',
        summaryLabel:
          'Transfer to facility that is IA capable for consideration of mechanical thrombectomy',
      },
      {
        value: 'DisablingLowNIHSS',
        label: 'disabling--low NIHSS',
        summaryLabel: 'symptoms deemed to be disabling, despite low NIHSS',
      },
    ],
  },
  thrombolyticAdministrationRecommendationsFreeText: {
    name: 'thrombolyticAdministrationRecommendationsFreeText',
    associatedField: 'thrombolyticAdministrationRecommendationsPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  hemorrhageReversalPredefined: {
    name: 'hemorrhageReversalPredefined',
    label: 'Hemorrhage Reversal',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'AndexanetAndexXa',
        label: 'andexanet (AndexXa)',
        summaryLabel: 'andexanet alpha (AndexXa)',
      },
      {
        value: 'Cryoprecipitate',
        label: 'cryoprecipitate',
        summaryLabel: 'cryoprecipitate, follow by fibrinogen check in hour',
      },
      {
        value: 'FFP',
        label: 'FFP',
        summaryLabel: 'FFP (fresh frozen plasma)',
      },
      { value: 'IdarucizumabPraxbind', label: 'idarucizumab (Praxbind)' },
      {
        value: 'PCC',
        label: 'PCC',
        summaryLabel: 'PCC (prothrombin complex concentrate)',
      },
      { value: 'Platelets', label: 'platelets' },
      { value: 'VitKIV', label: 'vit K IV', summaryLabel: 'Vitamin K IV' },
      { value: 'VitKPO', label: 'vit K PO', summaryLabel: 'Vitamin K PO' },
      { value: 'TXA', label: 'TXA', summaryLabel: 'tranexamic acid' },
      {
        value: 'Labs',
        label: 'labs',
        summaryLabel:
          'CBC, PT/INR, PTT, fibrinogen level, type and cross-match',
      },
    ],
  },
  hemorrhageReversalFreeText: {
    name: 'hemorrhageReversalFreeText',
    associatedField: 'hemorrhageReversalPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  otherPredefined: {
    name: 'otherPredefined',
    label: 'Other',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'ConsultOnSiteNeuro',
        label: 'consult on site neuro',
        summaryLabel:
          'consult on-site neurology service for full work-up and evaluation recommendations',
        [FilterTypePropNames.NEURO]: true,
      },
      {
        value: 'ConsultNeurosurg',
        label: 'consult neurosurg',
        summaryLabel: 'consult neurosurgery',
      },
      { value: 'LDLLessThan70', label: 'LDL < 70' },
      {
        value: 'MigraineIfCTANegPlussxsResolvedMayDcOwAdmitForMRIAndSxControl',
        label:
          'migraine:If CTA neg +sxs resolved may dc; o/w admit for MRI and sx control',
        summaryLabel:
          'If CTA negative and symptoms resolved may dispo; otherwise would admit for MRI and symptom control',
        [FilterTypePropNames.NEURO]: true,
      },
      {
        value: 'CallBackIfNeuroDeterioration',
        label: 'call back if neuro deterioration',
        summaryLabel:
          'If patient has any neurological deterioration please call me back immediately',
      },
      { value: 'PermissiveHypertension', label: 'permissive hypertension' },
      {
        value: 'Telem',
        label: 'telem',
        summaryLabel: 'telemetry monitoring',
        [FilterTypePropNames.NEURO]: true,
      },
      {
        value: 'TelemHolterMonitor',
        label: 'telem/holter monitor',
        summaryLabel: 'telemetry or holter monitoring',
        [FilterTypePropNames.CLINIC]: true,
      },
      {
        value: 'SzPrecautions',
        label: 'sz precautions',
        summaryLabel: 'seizure precautions',
      },
      {
        value: 'WouldNotPursueStrokeWorkUpIfMRINeg',
        label: 'would not pursue stroke work-up if MRI neg',
        summaryLabel: 'would not pursue stroke work-up if MRI is negative',
        [FilterTypePropNames.NEURO]: true,
      },
      {
        value: discussedWReferringProviderValue,
        label: 'discussed w referring provider',
        summaryLabel:
          'I have discussed my recommendations with the referring provider',
      },
    ],
  },
  otherFreeText: {
    name: 'otherFreeText',
    associatedField: 'otherPredefined',
    type: ConsultFieldTypeEnum.INPUT,
    multiline: true,
  },
  additionalRecommendations: {
    name: 'additionalRecommendations',
    label: 'Additional Recommendations',
    type: ConsultFieldTypeEnum.DICTATION,
    multiline: true,
    rows: 3,
  },
  followUpPredefined: {
    name: 'followUpPredefined',
    title: 'Follow up',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'ThisWeek', label: 'this week' },
      { value: 'Week1', label: '1 week' },
      { value: 'Weeks2', label: '2 weeks' },
      { value: 'Month1', label: '1 month' },
      { value: 'Months2', label: '2 months' },
      { value: 'Months3', label: '3 months' },
      { value: 'Months6', label: '6 months' },
      { value: 'Year1', label: '1 year' },
      { value: 'InBlueSkyClinic', label: 'in Blue Sky Clinic' },
      { value: 'PRN', label: 'PRN' },
      { value: 'Admit', label: 'admit' },
    ],
  },
  followUpFreeText: {
    name: 'followUpFreeText',
    associatedField: 'followUpPredefined',
    type: ConsultFieldTypeEnum.INPUT,
  },
  timeSpentInDirectCareOfPatientMinutes: {
    name: 'timeSpentInDirectCareOfPatientMinutes',
    label: 'Time spent in care of patient',
    summaryLabel: 'Time (min) spent in direct care of patient',
    type: ConsultFieldTypeEnum.INPUT,
    inlineLabel: true,
    endAdornment: <InputAdornment>min</InputAdornment>,
  },
  timeSpentInDirectCareOfPatientCounselingCoordinationPredefined: {
    name: 'timeSpentInDirectCareOfPatientCounselingCoordinationPredefined',
    associatedField: 'timeSpentInDirectCareOfPatientMinutes',
    type: ConsultFieldTypeEnum.CHIP,
    options: [
      {
        value: 'AboveFiftyPercentCounselingCoordination',
        label: '> 50% counseling/ coordination',
        summaryLabel:
          'time spent face to face was greater than 50% counseling and coordination of care',
      },
    ],
  },
  dispositionPredefined: {
    name: 'dispositionPredefined',
    title: 'Disposition',
    type: ConsultFieldTypeEnum.TOGGLE,
    options: [
      { value: 'Admit', label: 'admit' },
      { value: 'ContinueAdmission', label: 'continue admission' },
      { value: 'Dc', label: 'dc', summaryLabel: 'discharge' },
      { value: 'Observation', label: 'observation' },
      { value: 'TransferToICU', label: 'transfer to ICU' },
      { value: 'Transfer', label: 'transfer' },
      {
        value: 'TransferToIACapableFacility',
        label: 'transfer to IA capable facility',
      },
      { value: 'TransferToSwedish', label: 'transfer to Swedish' },
      { value: 'Expired', label: 'expired' },
    ],
  },
};

export default planFields;
