import React from 'react';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import styled from 'styled-components';
import * as yup from 'yup';

import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import InputField from 'shared/components/forms/InputField/InputField';
import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import { Box } from 'styles/layout';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled(Box)`
  align-self: flex-end;
  margin: 10px 0 3px;

  ${({ $hasErrors }) =>
    $hasErrors &&
    `
    align-self: center;
    margin: 1px 0 23px;
  `}
`;

const schema = yup.object().shape({
  macroName: yup.string().trim().required('Macro name is required'),
});

const MacrosForm = ({ onSubmit }) => {
  const {
    register,
    canSubmitForm,
    hasFormErrors,
    getFieldError,
    setValue,
    getTrimmedValues,
    handleSubmit,
  } = useFormState(schema, { stateSlice: 'consults', showAsSnackbar: true });

  usePerformActionOnSuccess(SuccessActionTypesEnum.MACRO_CREATED, () => {
    setValue('macroName', null);
  });

  return (
    <Form onSubmit={handleSubmit(() => onSubmit(getTrimmedValues()))}>
      <Box mr={2}>
        <InputField
          inputRef={register}
          name="macroName"
          label="Macro Name"
          required
          fullWidth={false}
          error={getFieldError('macroName')}
        />
      </Box>
      <ButtonWrapper $hasErrors={hasFormErrors}>
        <ActionButton
          color="secondary"
          disabled={!canSubmitForm}
          icon={<Add />}
        >
          New Macro
        </ActionButton>
      </ButtonWrapper>
    </Form>
  );
};

MacrosForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default MacrosForm;
