import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';

import useFormState from 'shared/hooks/useFormState';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import InputField from 'shared/components/forms/InputField/InputField';
import PasswordField from 'shared/components/forms/PasswordField/PasswordField';
import { loadingSelector } from 'store/selectors';
import Paths from 'navigation/paths';
import { Box, FlexBoxJustify } from 'styles/layout';
import { authActions } from 'auth/store/slice';
import AuthFormWrapper from 'auth/components/AuthFormWrapper/AuthFormWrapper';
import GoogleLoginBtn from 'auth/components/GoogleLoginBtn/GoogleLoginBtn';
import { ForgotPasswordBtn } from './LoginPage.style';

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .matches(
      /^(?:[^\s]{1,99})$/i,
      'Please enter a valid username or email address.',
    ),
  password: yup.string().trim().required('Password is required'),
});

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    getTrimmedValues,
    handleSubmit,
    getFieldError,
    canSubmitForm,
    serverError,
  } = useFormState(schema, { stateSlice: 'auth' });

  const loading = useSelector(loadingSelector);

  const onLogin = () => {
    const { username, password } = getTrimmedValues();

    dispatch(authActions.loginUser({ username, password }));
  };

  const navigateToForgotPassword = () => {
    history.push(Paths.ForgotPassword);
  };

  return (
    <AuthFormWrapper onSubmit={handleSubmit(onLogin)}>
      <Box mb={1.5}>
        <InputField
          inputRef={register}
          name="username"
          label="Email Address or Username"
          size="medium"
          required
          error={getFieldError('username')}
        />
      </Box>
      <Box mb={5.5}>
        <PasswordField
          inputRef={register}
          size="medium"
          required
          error={getFieldError('password')}
        />
        {!!serverError && <ErrorMsg text={serverError} mt={2} mb={0} />}
      </Box>
      <Box mb={2}>
        <ActionButton
          size="large"
          fullWidth
          disabled={!canSubmitForm || loading}
        >
          Sign In
        </ActionButton>
      </Box>
      <FlexBoxJustify mb={3.5}>
        <ForgotPasswordBtn onClick={navigateToForgotPassword}>
          Forgot Password?
        </ForgotPasswordBtn>
      </FlexBoxJustify>
      <FlexBoxJustify mb={2.5}>
        <GoogleLoginBtn />
      </FlexBoxJustify>
    </AuthFormWrapper>
  );
};

export default LoginPage;
