import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import Paths from 'navigation/paths';
import MainWrapper from 'shared/components/layout/MainWrapper/MainWrapper';
import useBreakpoints from 'shared/hooks/useBreakpoints';
import ConsultRequestDetails from 'features/customer/components/ConsultRequestDetails/ConsultRequestDetails';
import useConsultRequestData from 'features/customer/hooks/useConsultRequestData';
import { ConsultRequestStatusEnum } from 'features/customer/utils/enums';

const ConsultRequestDetailsPage = () => {
  const history = useHistory();
  const { smDown } = useBreakpoints();
  const {
    consultRequest,
    consultType,
    facilityName,
    canAccessRequest,
    handleRequestAccept,
    handleRequestDecline,
    error,
  } = useConsultRequestData();

  useEffect(() => {
    history.replace({ state: { navigateOnBack: `/${consultType}` } });
  }, [consultRequest, history, consultType]);

  if (!canAccessRequest) {
    return <Redirect to={Paths.Restricted} />;
  }

  if (consultRequest.id) {
    // If the consult request has been closed
    // redirect to the created consult
    if (consultRequest.state === ConsultRequestStatusEnum.CLOSED) {
      return (
        <Redirect
          to={{
            pathname: `/${consultType}/${consultRequest.consultId}`,
            state: { navigateOnBack: `/${consultType}` },
          }}
        />
      );
    }

    const consultRequestType = consultRequest.type.toLowerCase();

    if (consultRequestType !== consultType) {
      return (
        <Redirect
          to={`${Paths.ConsultRequestDetails}/${consultRequestType}/${consultRequest.id}`}
        />
      );
    }
  }

  return (
    <MainWrapper sm={smDown}>
      <ConsultRequestDetails
        consultRequest={consultRequest}
        facilityName={facilityName}
        error={error}
        onAccept={handleRequestAccept}
        onDecline={handleRequestDecline}
      />
    </MainWrapper>
  );
};

export default ConsultRequestDetailsPage;
