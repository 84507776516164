import React from 'react';
import { useDispatch } from 'react-redux';

import googleLogo from 'auth/assets/google-logo.svg';
import { authActions } from 'auth/store/slice';
import { GoogleButtonContainer, GoogleLogo } from './GoogleLoginBtn.style';

const GoogleLoginBtn = () => {
  const dispatch = useDispatch();

  const handleSignIn = () => {
    dispatch(authActions.loginWithGoogle());
  };

  return (
    <GoogleButtonContainer type="button" onClick={handleSignIn}>
      <GoogleLogo src={googleLogo} alt="Google logo" />
      <span>Sign in with Google</span>
    </GoogleButtonContainer>
  );
};

export default GoogleLoginBtn;
