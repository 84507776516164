import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

import logo from 'auth/assets/bsl-logo.svg';
import { PageWrapper, FormWrapper, Form, Logo } from './AuthFormWrapper.style';

const AuthFormWrapper = ({ children, onSubmit }) => (
  <PageWrapper>
    <FormWrapper as={Paper} square elevation={3}>
      <Logo src={logo} alt="Blue Sky Link Logo" />
      <Form as="form" method="post" onSubmit={onSubmit}>
        {children}
      </Form>
    </FormWrapper>
  </PageWrapper>
);

AuthFormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onSubmit: PropTypes.func,
};

export default AuthFormWrapper;
