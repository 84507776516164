import { mapToggleFieldOptions } from 'features/consults/utils/formFields';

import fields from './formFields';

const NIHSSOptions = [
  mapToggleFieldOptions(fields.levelOfConsciousness),
  mapToggleFieldOptions(fields.locQuestions),
  mapToggleFieldOptions(fields.locCommands),
  mapToggleFieldOptions(fields.bestGaze),
  mapToggleFieldOptions(fields.visual),
  mapToggleFieldOptions(fields.facialPalsy),
  mapToggleFieldOptions(fields.motorArmLeft),
  mapToggleFieldOptions(fields.motorArmRight),
  mapToggleFieldOptions(fields.motorLegLeft),
  mapToggleFieldOptions(fields.motorLegRight),
  mapToggleFieldOptions(fields.limbAtaxia),
  mapToggleFieldOptions(fields.sensory),
  mapToggleFieldOptions(fields.bestLanguage),
  mapToggleFieldOptions(fields.dysarthria),
  mapToggleFieldOptions(fields.extinctionAndInattention),
];

const MRSOptions = [mapToggleFieldOptions(fields.modifiedRankinScale)];

const ICHOptions = [
  mapToggleFieldOptions(fields.ich),
  mapToggleFieldOptions(fields.ichAge),
  mapToggleFieldOptions(fields.ichVolume),
  mapToggleFieldOptions(fields.ichIntraventricularHemorhage),
  mapToggleFieldOptions(fields.ichInfraventricOrigin),
];

const VanOptions = [
  mapToggleFieldOptions(fields.vanArmWeakness),
  mapToggleFieldOptions(fields.vanVisualDisturbance),
  mapToggleFieldOptions(fields.vanAphasia),
  mapToggleFieldOptions(fields.vanNeglect),
];

const HuntHessSAHOptions = [mapToggleFieldOptions(fields.huntHessSAH)];

export const GROUPS = {
  NIHSS: 'nihss',
  MRS: 'mrs',
  ICH: 'ich',
  HUNT: 'huntHess',
  VAN: 'van',
};

export const GROUP_OPTIONS = {
  [GROUPS.NIHSS]: NIHSSOptions,
  [GROUPS.MRS]: MRSOptions,
  [GROUPS.ICH]: ICHOptions,
  [GROUPS.HUNT]: HuntHessSAHOptions,
  [GROUPS.VAN]: VanOptions,
};

export const GROUP_SCORE_LABELS = {
  [GROUPS.NIHSS]: 'NIHSS Total',
  [GROUPS.MRS]: 'mRS Total',
  [GROUPS.ICH]: 'ICH Total',
  [GROUPS.HUNT]: 'Hunt/Hess Total',
  [GROUPS.VAN]: 'Result',
};
