import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import { isFile } from 'utils/helpers';
import {
  StyledCard,
  StyledCardMedia,
  Label,
  UploadIcon,
  HiddenImage,
  HiddenInput,
} from './ImageInput.style';

const ImageInput = ({ control, name, disabled = false, error }) => {
  const [imagePreview, setImagePreview] = useState(null);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ value, onChange: onControllerChange }) => {
        const image = isFile(value) ? imagePreview : value;

        return (
          <>
            <StyledCard variant="outlined">
              <Label htmlFor={name} disabled={disabled}>
                <StyledCardMedia image={image}>
                  {!image && <UploadIcon disabled={disabled} />}
                </StyledCardMedia>
              </Label>
              {/*
          Since Amplify returns a URL even if the image doesn't exist
          check if the URL results in an error and if so, clear the form value
        */}
              {value && !isFile(value) && (
                <HiddenImage
                  src={value}
                  onError={() => onControllerChange(null)}
                />
              )}
              <HiddenInput
                id={name}
                type="file"
                accept="image/*"
                disabled={disabled}
                onChange={(e) => {
                  const file = e.target.files[0];
                  const reader = new FileReader();
                  reader.onloadend = () => setImagePreview(reader.result);
                  reader.readAsDataURL(file);
                  onControllerChange(file);
                }}
              />
            </StyledCard>
            {error && <ErrorMsg text={error.message} dense />}
          </>
        );
      }}
    />
  );
};

ImageInput.propTypes = {
  control: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default ImageInput;
