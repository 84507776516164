import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import InputField from 'shared/components/forms/InputField/InputField';
import { ConsultFieldTypeEnum } from 'features/consults/utils/enums';
import { FlexBoxColumn } from 'styles/layout';
import DictationControls from './DictationControls';

const DictationInput = ({ name, control, onChange, disabled, ...rest }) => {
  const handleChange = debounce(() => {
    onChange(name, ConsultFieldTypeEnum.DICTATION);
  }, 1000);

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ value, ...controllerProps }) => (
        <FlexBoxColumn>
          <InputField
            {...rest}
            name={name}
            value={value || ''}
            disabled={disabled}
            onChange={({ target }) => {
              const newValue = target.value;
              controllerProps.onChange(newValue);
              handleChange();
            }}
          />
          <DictationControls
            disabled={disabled}
            onChange={(dictation) => {
              const newValue = value ? `${value} ${dictation}` : `${dictation}`;
              controllerProps.onChange(newValue);
              handleChange();
            }}
          />
        </FlexBoxColumn>
      )}
    />
  );
};

DictationInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DictationInput;
