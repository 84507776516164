import React from 'react';
import PropTypes from 'prop-types';

import { Box, FlexBoxColumn } from 'styles/layout';
import { formatSummaryTimestamp } from 'features/consults/utils/dates';
import { getNamesWithTitle } from 'utils/helpers';
import { sectionHeaderStyle, lineBreakStyle } from './SummaryNote.style';

const AddendumList = ({ addenda, facilityTimezone }) => (
  <Box mt={3}>
    {addenda.map(
      (
        { text, addedAt, addedByFirstName, addedByLastName, addedByTitle },
        index,
      ) => (
        <FlexBoxColumn key={addedAt} mb={index !== addenda.length - 1 ? 3 : 0}>
          <h3 style={sectionHeaderStyle}>Addendum</h3>
          <Box mb={1.5}>{text}</Box>
          <Box fontSize="14px">
            {`Addendum added and electronically signed at ${formatSummaryTimestamp(
              addedAt,
              facilityTimezone,
            )} by ${getNamesWithTitle(
              addedByFirstName,
              addedByLastName,
              addedByTitle,
            )}`}
          </Box>
          <Box style={lineBreakStyle}>{'\r'}</Box>
        </FlexBoxColumn>
      ),
    )}
  </Box>
);

AddendumList.propTypes = {
  addenda: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  facilityTimezone: PropTypes.string,
};

export default AddendumList;
