import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'shared/components/buttons/Button/Button';
import { Box, FlexBoxWrap, FlexBoxColumn } from 'styles/layout';

const MacroButton = styled(Button)`
  text-transform: none;
  margin: 0 15px 10px 0;
`;

const MacrosList = ({ items, onSelect }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <FlexBoxColumn mb={2}>
      <Box mb={1}>Your Macros</Box>
      <FlexBoxWrap>
        {items.map((item) => (
          <MacroButton
            key={item.id}
            variant="outlined"
            onClick={() => onSelect(item.id)}
          >
            {item.name}
          </MacroButton>
        ))}
      </FlexBoxWrap>
    </FlexBoxColumn>
  );
};

MacrosList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func.isRequired,
};

export default MacrosList;
