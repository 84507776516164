import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { appStateActions } from 'store/appStateSlice';
import { successActionSelector } from 'store/selectors';

// eslint-disable-next-line import/prefer-default-export
export const usePerformActionOnSuccess = (actionType, onSuccessCallback) => {
  const dispatch = useDispatch();
  const successAction = useSelector(successActionSelector);

  useEffect(() => {
    if (successAction && successAction.type === actionType) {
      onSuccessCallback();
      dispatch(appStateActions.removeSuccessAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAction]);
};
