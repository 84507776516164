import { createGlobalStyle } from 'styled-components';

import colors from 'styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const GlobalStyles = createGlobalStyle`
  html {
    height: 100%;
    background: ${colors.backgroundLight};
  }

  body {
    min-height: 100%;
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  button, label {
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
`;
