import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import useFormState from 'shared/hooks/useFormState';
import { usePerformActionOnSuccess } from 'shared/hooks/usePerformActionOnSuccess';
import Paths from 'navigation/paths';
import { Box } from 'styles/layout';
import { appStateActions } from 'store/appStateSlice';
import { SuccessActionTypesEnum } from 'store/SuccessActionTypes.enum';
import ActionButton from 'shared/components/buttons/ActionButton/ActionButton';
import IconButton from 'shared/components/buttons/IconButton/IconButton';
import ErrorMsg from 'shared/components/feedback/ErrorMsg/ErrorMsg';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import InputField from 'shared/components/forms/InputField/InputField';
import AuthFormWrapper from 'auth/components/AuthFormWrapper/AuthFormWrapper';
import { authActions } from 'auth/store/slice';

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .required('Username is required')
    .matches(
      /^(?:[^\s]{1,99})$/i,
      'Please enter a valid username or email address.',
    ),
});

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    register,
    getTrimmedValues,
    handleSubmit,
    getFieldError,
    canSubmitForm,
    serverError,
  } = useFormState(schema, { stateSlice: 'auth' });

  usePerformActionOnSuccess(
    SuccessActionTypesEnum.VERIFICATION_CODE_SENT,
    () => {
      history.push(
        `${Paths.ResetPassword}?username=${encodeURIComponent(
          getTrimmedValues('username'),
        )}`,
      );

      dispatch(
        appStateActions.showSnackbar({
          text: 'If the username/email address is correct, you will receive a verification code',
          type: SnackbarTypeEnum.SUCCESS,
          duration: 5000,
        }),
      );
    },
  );

  const sendVerificationCode = () => {
    dispatch(
      authActions.sendForgotUserPasswordCode(getTrimmedValues('username')),
    );
  };

  return (
    <AuthFormWrapper onSubmit={handleSubmit(sendVerificationCode)}>
      <Box style={{ position: 'absolute', marginTop: '-8rem' }}>
        <IconButton color="primary" onClick={() => history.push('login')}>
          <ArrowBackIcon />
        </IconButton>
      </Box>
      <Box mb={7.5}>
        <InputField
          inputRef={register}
          name="username"
          label="Email Address or Username"
          size="medium"
          required
          error={getFieldError('username')}
        />
        {!!serverError && <ErrorMsg text={serverError} />}
      </Box>
      <Box>
        <ActionButton size="large" fullWidth disabled={!canSubmitForm}>
          Send Login Code
        </ActionButton>
      </Box>
    </AuthFormWrapper>
  );
};

export default ForgotPasswordPage;
