import * as yup from 'yup';

import { requiredString } from 'utils/constants';
import signatureFields from 'features/consults/shared/tabs/Signature/formFields';

export default yup.object().shape({
  [signatureFields.attestationOfConsultCompletion
    .name]: yup
    .string()
    .trim()
    .required(requiredString)
    .typeError(requiredString),
});
