import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Shift from 'features/schedule/components/Shift/Shift';
import colors from 'styles/colors';
import { Box } from 'styles/layout';

const Card = styled(Box)`
  background: ${colors.neutral};
  border: 1px solid
    ${({ $isToday }) => ($isToday ? colors.primary : colors.transparent)};
  overflow-y: hidden;
`;

const DayCard = forwardRef(({ isToday, items = [] }, ref) => (
  <Card ref={ref} $isToday={isToday} width="100%" height="100%" py={0.5}>
    {items.map((item) => (
      <Shift key={item.id} {...item} />
    ))}
  </Card>
));

DayCard.propTypes = {
  isToday: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DayCard;
