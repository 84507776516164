import React from 'react';
import { Switch, Route } from 'react-router-dom';

import RedirectToHome from 'navigation/components/RedirectToHome/RedirectToHome';
import Paths from 'navigation/paths';
import NeuroConsults from './pages/neuro';
import ClinicConsults from './pages/clinic';
import EegConsults from './pages/eeg';

const Consults = () => (
  <Switch>
    <Route path={Paths.Neuro} component={NeuroConsults} />
    <Route path={Paths.Clinic} component={ClinicConsults} />
    <Route path={Paths.Eeg} component={EegConsults} />
    <Route>
      <RedirectToHome />
    </Route>
  </Switch>
);

export default Consults;
