import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import debounce from 'lodash/debounce';

import { CircularProgress } from '@material-ui/core';

import api from 'api';
import useRequest from 'shared/hooks/useRequest';
import { getTableHeaders, prepareTableData } from 'utils/tables';
import { PAGINATION_PAGE_SIZE, DEBOUNCE_TIMEOUT } from 'utils/constants';
import { formatDate, US_DATE_FORMAT } from 'utils/dates';
import { OrderTypeEnum } from 'features/consults/utils/enums';

import {
  Box,
  FlexBoxSpaceBetween,
  FlexBoxJustify,
  FlexBoxAlign,
  FlexBoxColumn,
} from 'styles/layout';

import Button from 'shared/components/buttons/Button/Button';
import CloseBtn from 'shared/components/buttons/CloseButton/CloseButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';

import {
  SearchInput,
  SearchIcon,
  SearchTitle,
} from 'features/consults/components/ConsultMenu/ConsultIntegration/ConsultIntegration.style';

const formatedDate = (date) =>
  date ? formatDate(new Date(date), US_DATE_FORMAT) : '--';

const tableColumns = [
  {
    header: {
      id: 'dtOfTrnsaction',
      value: 'DOS',
    },
    formatValue: ({ dtOfTrnsaction }) => formatedDate(dtOfTrnsaction),
  },
  {
    header: {
      id: 'givenName',
      value: 'First Name',
    },
    dataKey: 'givenName',
  },
  {
    header: {
      id: 'familyName',
      value: 'Last Name',
    },
    dataKey: 'familyName',
  },
  {
    header: {
      id: 'dob',
      value: 'DOB',
    },
    formatValue: ({ dob }) => formatedDate(dob),
  },
];

const IntegrationOrderList = ({ consult, onClose, onReview }) => {
  const { consultType } = useParams();
  const { doRequest: getOrders, data: orderData } = useRequest(
    api.getAdminOrders,
  );
  const [page, setPage] = useState(1);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);

  useEffect(() => {
    getOrders({
      PageIndex: page - 1,
      PageSize: PAGINATION_PAGE_SIZE,
      ConsultId: consult.id,
      Query: searchTxt,
      Type: OrderTypeEnum[consultType],
    });
  }, [getOrders, page, consult.id, searchTxt, consultType]);

  const onClickRow = useCallback(
    (id) => {
      if (selectedRow?.id === id) {
        setSelectedRow(null);
        return;
      }
      const row = find(orderData?.hl7Orders, { id });
      if (row) setSelectedRow(row);
    },
    [orderData, selectedRow],
  );

  if (!orderData?.hl7Orders) return <CircularProgress />;

  return (
    <>
      <FlexBoxSpaceBetween>
        <FlexBoxColumn mt={2}>
          <SearchTitle>Unmatched ADT Orders</SearchTitle>
          <FlexBoxAlign>
            <SearchInput
              type="search"
              onChange={debounce(
                ({ target }) => setSearchTxt(target.value),
                DEBOUNCE_TIMEOUT,
              )}
            />
            <SearchIcon />
          </FlexBoxAlign>
        </FlexBoxColumn>
        <Box>
          <CloseBtn onClick={onClose} />
        </Box>
      </FlexBoxSpaceBetween>
      <PaginatedTable
        headings={getTableHeaders(tableColumns)}
        data={prepareTableData(orderData?.hl7Orders, tableColumns)}
        fallbackMsg="No orders to show"
        selectedRowId={selectedRow?.id}
        onClickRow={onClickRow}
        currentPage={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        totalPages={orderData?.pageCount}
      />
      <FlexBoxJustify mt={3} mb={3}>
        <Button
          disabled={!selectedRow}
          variant="contained"
          color="secondary"
          onClick={() => onReview(selectedRow)}
        >
          Review Order & Consult
        </Button>
      </FlexBoxJustify>
    </>
  );
};

IntegrationOrderList.propTypes = {
  consult: PropTypes.shape({
    id: PropTypes.number,
    integrationStatus: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onReview: PropTypes.func.isRequired,
};

export default IntegrationOrderList;
