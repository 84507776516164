import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { appStateActions } from 'store/appStateSlice';
import useAuthEvents from 'auth/hooks/useAuthEvents';
import AppRoutes from 'navigation/AppRoutes';
import LoadingSpinner from 'shared/components/feedback/LoadingSpinner/LoadingSpinner';
import Snackbar from 'shared/components/feedback/Snackbar/Snackbar';
import { profileActions } from 'features/profile/store/slice';
import 'auth/amplify/config';

const App = () => {
  const dispatch = useDispatch();
  const { isSessionChecked } = useAuthEvents();
  const {
    appState: { loading, snackbar },
    user,
  } = useSelector(({ appState, auth }) => ({
    appState,
    user: auth.user,
  }));

  useEffect(() => {
    if (!(isSessionChecked && user && user.username)) return;
    dispatch(profileActions.getProfile());
  }, [dispatch, isSessionChecked, user]);

  return (
    <>
      {(loading || !isSessionChecked) && <LoadingSpinner />}
      {isSessionChecked && <AppRoutes />}
      <Snackbar
        isVisible={snackbar.visible}
        message={snackbar.text}
        type={snackbar.type}
        duration={snackbar.duration}
        closable={snackbar.closable}
        requireManualClose={snackbar.requireManualClose}
        onClose={() => dispatch(appStateActions.hideSnackbar())}
      />
    </>
  );
};

export default App;
