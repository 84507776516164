import { mapToggleFieldOptions } from 'features/consults/utils/formFields';
import fields from 'features/consults/shared/tabs/Signature/formFields';

// eslint-disable-next-line import/prefer-default-export
export const leftPanels = [
  { elements: [fields.attestationOfConsultCompletion] },
  {
    elements: [mapToggleFieldOptions(fields.telemedicine)],
  },
  {
    elements: [mapToggleFieldOptions(fields.providerLocationPredefined)],
  },
  {
    elements: [fields.providerLocationFreeText],
  },
  {
    elements: [fields.patientLocation],
  },
  {
    // Label is separate from the field because it is too big and makes the field span the full width of the container
    label: fields.totalFaceToFaceTime.summaryLabel,
    elements: [fields.totalFaceToFaceTime],
  },
  { elements: [fields.criticalCareTime] },
  {
    elements: [fields.billingCode],
  },
];
