import React from 'react';
import { useDispatch } from 'react-redux';

import api from 'api';
import {
  Box,
  FlexBoxColumn,
  FlexBox,
  FlexBoxSpaceBetweenCenter,
} from 'styles/layout';
import colors from 'styles/colors';
import ExportButton from 'shared/components/buttons/ExportButton';
import PaginatedTable from 'shared/components/data/PaginatedTable/PaginatedTable';
import PageTitle from 'shared/components/layout/PageTitle/PageTitle';
import { SnackbarTypeEnum } from 'shared/components/feedback/Snackbar/SnackbarTypes.enum';
import { appStateActions } from 'store/appStateSlice';
import { mapConsultFilters } from 'features/consults/utils/mappers';
import useConsultListState from 'features/admin/hooks/useConsultListState';
import { MAX_PAGE_SIZE } from 'utils/constants';
import { downloadFile } from 'utils/helpers';
import ConsultListFilters from './ConsultListFilters';

const ConsultList = () => {
  const dispatch = useDispatch();
  const {
    page,
    pageCount,
    totalCount,
    sortBy,
    sortOrder,
    filters,
    handleResetFilters,
    handlePageChange,
    handleSortChange,
    handleFilter,
    navigateToConsultDetails,
    tableHeaders,
    tableData,
  } = useConsultListState();

  const handleExport = async () => {
    try {
      dispatch(appStateActions.setLoading(true));

      const { service, ...restFilters } = filters;
      const { data, headers } = await api.exportConsults(service, {
        sortBy,
        sortOrder,
        pageSize: MAX_PAGE_SIZE,
        filters: mapConsultFilters(restFilters),
      });

      downloadFile(data, headers);
    } catch {
      dispatch(
        appStateActions.showSnackbar({
          text:
            'An error occurred while downloading the file. Please, try again.',
          type: SnackbarTypeEnum.ERROR,
          duration: 5000,
        }),
      );
    } finally {
      dispatch(appStateActions.setLoading(false));
    }
  };

  return (
    <FlexBoxColumn>
      <FlexBoxSpaceBetweenCenter>
        <PageTitle>Consults</PageTitle>
        {Number.isInteger(totalCount) && (
          <Box color={colors.neutralDark}>
            {totalCount}
            {' '}
            Results
          </Box>
        )}
      </FlexBoxSpaceBetweenCenter>
      <ConsultListFilters
        filters={filters}
        onFilter={handleFilter}
        onResetFilters={handleResetFilters}
      >
        {filters.service && (
          <FlexBox>
            <ExportButton color="secondary" onClick={handleExport}>
              Download CSV
            </ExportButton>
          </FlexBox>
        )}
      </ConsultListFilters>

      <FlexBoxColumn>
        <PaginatedTable
          headings={tableHeaders}
          data={tableData}
          fallbackMsg="No consults found"
          sortBy={sortBy}
          sortOrder={sortOrder}
          currentPage={page}
          totalPages={pageCount}
          onPageChange={handlePageChange}
          onSortChange={handleSortChange}
          onClickRow={navigateToConsultDetails}
        />
      </FlexBoxColumn>
    </FlexBoxColumn>
  );
};

export default ConsultList;
