export default {
  Login: '/login',
  LoginConfirm: '/login-confirm',
  ForgotPassword: '/forgot-password',
  ResetPassword: '/reset-password',
  ChangePassword: '/change-password',
  Neuro: '/neuro',
  Clinic: '/clinic',
  Eeg: '/eeg',
  Macros: '/macros',
  MyFacility: '/my-facility',
  ConsultRequestDetails: '/consult-request-details',
  Messages: '/messages',
  Schedule: '/schedule',
  Admin: '/admin',
  Profile: '/profile',
  Restricted: '/restricted',
};
