import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import sortFields from 'api/sortFields';
import {
  ConsultFilterEnum,
  ConsultStates,
} from 'features/consults/utils/enums';
import { LAST24HOURS } from 'features/consults/utils/constants';

const filterList = (assignToUserId) => ({
  [ConsultFilterEnum.OPEN]: {
    label: 'Open',
    filters: {
      assignToUserId,
      state: ConsultStates.OPEN,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'asc',
      },
    },
  },
  [ConsultFilterEnum.ALL]: {
    label: 'All',
    filters: {
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
  [ConsultFilterEnum.LAST_24]: {
    label: 'Last 24 hours',
    filters: {
      fromCreatedAt: LAST24HOURS,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
  [ConsultFilterEnum.MY_LAST_24]: {
    label: 'My last 24 hours',
    filters: {
      assignToUserId,
      fromCreatedAt: LAST24HOURS,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
  [ConsultFilterEnum.MY_CLOSED]: {
    label: 'My closed',
    filters: {
      assignToUserId,
      state: ConsultStates.CLOSED,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
  [ConsultFilterEnum.ALL_CLOSED]: {
    label: 'All closed',
    filters: {
      state: ConsultStates.CLOSED,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
  [ConsultFilterEnum.ALL_OPEN]: {
    label: 'All open',
    filters: {
      state: ConsultStates.OPEN,
      orderOverride: {
        sortBy: sortFields.consults.createdAt,
        sortOrder: 'desc',
      },
    },
  },
});

const filterValues = [
  ConsultFilterEnum.MY_LAST_24,
  ConsultFilterEnum.LAST_24,
  ConsultFilterEnum.OPEN,
  ConsultFilterEnum.MY_CLOSED,
  ConsultFilterEnum.ALL_OPEN,
  ConsultFilterEnum.ALL_CLOSED,
];

const useConsultQuickFilter = (currentFilters) => {
  const profileData = useSelector(({ profile }) => profile.data);
  const quickFilters = filterList(String(profileData.id));

  const getQuickFilterOption = (value) => {
    const quickFilter = quickFilters[value];
    return (
      quickFilter && {
        value,
        label: quickFilter.label,
      }
    );
  };

  const getQuickFilterValues = (value) => quickFilters[value]?.filters;

  const activeQuickFilter = useMemo(() => {
    const enabledFilter = filterValues.find((filter) => {
      const quickFilterValues = getQuickFilterValues(filter);
      return Object.entries(quickFilterValues).every(
        ([filterKey, filterValue]) => {
          if (filterKey === 'orderOverride') return true;
          return String(currentFilters[filterKey]) === filterValue;
        },
      );
    });
    return enabledFilter || ConsultFilterEnum.ALL;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, quickFilters]);

  return {
    getQuickFilterOption,
    getQuickFilterValues,
    activeQuickFilter,
  };
};

export default useConsultQuickFilter;
