import {
  mapChipFieldOptions,
  mapToggleFieldOptions,
} from 'features/consults/utils/formFields';
import fields, { muscleGroupFields } from './formFields';

const muscleGroupPanels = [...Array(6)].flatMap((_, index) => {
  const muscleFields = muscleGroupFields(index + 1);
  const [
    sideGroup,
    muscleGroup,
    muscleGroupText,
    multiplier,
    unit,
    unitText,
  ] = Object.keys(muscleFields);

  return [
    {
      elements: [
        mapToggleFieldOptions(muscleFields[sideGroup]),
        mapChipFieldOptions(muscleFields[muscleGroup]),
        muscleFields[muscleGroupText],
      ],
    },
    {
      elements: [
        mapToggleFieldOptions(muscleFields[multiplier]),
        mapToggleFieldOptions(muscleFields[unit]),
        muscleFields[unitText],
      ],
    },
  ];
});

// eslint-disable-next-line import/prefer-default-export
export const panels = [
  {
    elements: [mapChipFieldOptions(fields.consentPredefined)],
  },
  {
    elements: [
      mapToggleFieldOptions(fields.botulinumToxinRatioPredefined),
      mapToggleFieldOptions(fields.botulinumToxinUnitPredefined),
      fields.botulinumToxinRatioFreeText,
    ],
  },
  {
    elements: [mapToggleFieldOptions(fields.emgGuidance)],
  },
  ...muscleGroupPanels,
  {
    elements: [mapChipFieldOptions(fields.standardProtocolsPredefined)],
  },
  {
    elements: [fields.standardProtocolsTotalDoseUnits],
  },
  {
    elements: [fields.standardProtocolsWastedUnits],
  },
  {
    elements: [
      mapChipFieldOptions(fields.complicationsPredefined),
      fields.complicationsFreeText,
    ],
  },
];
